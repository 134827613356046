import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexContactBanner1 = (props: Props) => {

    return (
        <div
            className={"IndexContactBanner1"}
        >
            <div
                className={"IndexContactBanner1Title"}
            >
                {props.lang === "es" && <h1
                >
                    Contacto
                </h1>}
                {props.lang === "en" && <h1
                >
                    Contact
                </h1>}
                {props.lang === "es" && <p>
                    Comunícate con nosotros para más información
                </p>}
                {props.lang === "en" && <p>
                    Contact us for more information
                </p>}
            </div>
        </div>
    )
}
export default IndexContactBanner1;
