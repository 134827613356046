import React, {useEffect, useRef} from "react";
//theme
import "../styles.scss";
//models
import {Customer} from "../../../../api/customer/customerModel";
//components
import NavBarTop from "../../../_components/NavBarTop";
import NavBarMenu from "../../../_components/NavBarMenu";
import Footer from "../../../_components/Footer";
import IndexAppDriverBanner1 from "../_components/banner_1";
import IndexAppDriverSection1 from "../_components/section_1";
import IndexAppDriverSection2 from "../_components/section_2";
import IndexAppDriverSection3 from "../_components/section_3";
import IndexAppDriverSection4 from "../_components/section_4";
import IndexHomeSection2 from "../../home/_components/section_2";
import IndexAppDriverSection5 from "../_components/section_5";
import IndexAppDriverSection6 from "../_components/section_6";
import IndexAppDriverSection7 from "../_components/section_7";
import IndexAppDriverSection8 from "../_components/section_8";
import IndexAppDriverSection9 from "../_components/section_9";
import IndexAppDriverBanner2 from "../_components/banner_2";
import IndexAppDriverSection10 from "../_components/section_10";
//interfaces
interface Props {
    customer: Customer;
    deleteCustomer?: () => void;
    deleteToken?: () => void;
}
//view
const IndexAppDriverEs = (props: Props) => {
    //refs
    const itemsRef = useRef<Array<any>>([]);
    const IndexAppDriverRef = useRef<any>(null);
    //effects
    useEffect(() => {
        document.title = `Aplicativo Conductor - Compatible con Android y iOS | DvGeo Platform`
        IndexAppDriverRef.current && IndexAppDriverRef.current.addEventListener('scroll', () => {
            handleScrollAnimation();
        })
        return () => {
            IndexAppDriverRef.current && IndexAppDriverRef.current.removeEventListener('scroll', () => {
                handleScrollAnimation();
            })
        }
    }, []);

    const scrollOffset = 100;

    const elementInView = (el: any, offset = 0) => {
        const elementTop = el.getBoundingClientRect().top;
        return (
            elementTop <= (IndexAppDriverRef.current.clientHeight - offset)
        );
    };

    const displayScrollElement = (element: any) => {
        element.childNodes[0].classList.add("scrolled");
    };


    const hideScrollElement = (element: any) => {
        element.childNodes[0].classList.remove('scrolled');
    }

    const handleScrollAnimation = () => {
        itemsRef.current && itemsRef.current.forEach((el: any) => {
            if (elementInView(el, scrollOffset)) {
                displayScrollElement(el);
            } else {
                hideScrollElement(el);
            }
        })
    }

    return (
        <div
            ref={IndexAppDriverRef}
            className={"IndexAppDriver"}
        >
            <NavBarTop
                lang={"es"}
                customer={props.customer}
                deleteCustomer={props.deleteCustomer}
                deleteToken={props.deleteToken}
            />
            <NavBarMenu
                lang={"es"}
            />
            <IndexAppDriverBanner1
                lang={"es"}
            />
            <IndexAppDriverBanner2
                lang={"es"}
            />
            <IndexAppDriverSection1
                ref={(ele) => itemsRef.current[1] = ele}
                lang={"es"}
            />
            <IndexAppDriverSection2
                ref={(ele) => itemsRef.current[2] = ele}
                lang={"es"}
            />
            <IndexAppDriverSection3
                ref={(ele) => itemsRef.current[3] = ele}
                lang={"es"}
            />
            <IndexAppDriverSection4
                ref={(ele) => itemsRef.current[4] = ele}
                lang={"es"}
            />
            <IndexAppDriverSection5
                ref={(ele) => itemsRef.current[5] = ele}
                lang={"es"}
            />
            <IndexAppDriverSection6
                ref={(ele) => itemsRef.current[6] = ele}
                lang={"es"}
            />
            <IndexAppDriverSection7
                ref={(ele) => itemsRef.current[7] = ele}
                lang={"es"}
            />
            <IndexAppDriverSection8
                ref={(ele) => itemsRef.current[8] = ele}
                lang={"es"}
            />
            <IndexAppDriverSection9
                ref={(ele) => itemsRef.current[9] = ele}
                lang={"es"}
            />
            <IndexAppDriverSection10
                ref={(ele) => itemsRef.current[10] = ele}
                lang={"es"}
            />
            <IndexHomeSection2
                lang={"es"}
            />
            <Footer
                lang={"es"}
                customer={props.customer}
            />
        </div>
    )
}
export default IndexAppDriverEs;
