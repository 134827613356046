import React, {FormEvent, useEffect, useRef, useState} from "react";
//theme
import "./styles.scss";
//api
import {CustomerAPI} from "../../../../../../../api/customer/customerAPI";
//models
import {Customer} from "../../../../../../../api/customer/customerModel";
//libs
import {CircularProgress} from "@material-ui/core";
//components
import TextInput from "../../../../../../_components/TextInput";
//types
import {Lang} from "../../../../../../../types";
import {SystemSnackbar} from "../../../../../../../store/actions/system.ac";

type Fields = {
    ruc: string;
    identification: string;
    address: string;
};
type Errors = {
    ruc?: string;
    identification?: string;
    address?: string;
};

//interfaces
interface Props {
    lang: Lang;
    customer: Customer;
    showSnackbar?: (system: SystemSnackbar) => void;
    hideSnackbar?: () => void;
    putCustomer?: (customer: Customer) => void;
}

//const
const TAG = `CustomerCompanyUpdateAddressViewForm1`;
const isDev = (process.env.NODE_ENV !== "production");
//component
const CustomerCompanyUpdateAddressViewForm1 = (props: Props) => {
    //refs
    const inputsRef = useRef<Array<any>>([]);
    //state
    const [customer, setCustomer] = useState<Customer>(props.customer);
    const [fields, setFields] = useState<Fields>({
        ruc: "",
        identification: "",
        address: "",
    });
    const [errors, setErrors] = useState<Errors>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    //effects
    useEffect(() => {
        setCustomer(props.customer);
        props.customer && setFields((prvFields) => ({
            ...prvFields,
            ruc: props.customer.ruc as string,
            identification: props.customer.identification as string,
            address: props.customer.address as string,
        }));
    }, [props.customer]);

    const validForm = (id: keyof Fields, value: string) => {
        switch (id) {
            case "ruc": {
                setFields((prevFields) => ({
                    ...prevFields,
                    ruc: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 3) {
                    inputsRef.current[0] && inputsRef.current[0]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        ruc: props.lang === "es" ? "Mínimo 4 caracteres..." : props.lang === "en" ? "Minimum 4 characters" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        ruc: "",
                    }));
                    return true;
                }
            }
            case "identification": {
                setFields((prevFields) => ({
                    ...prevFields,
                    identification: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 3) {
                    inputsRef.current[1] && inputsRef.current[1]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        identification: props.lang === "es" ? "Mínimo 4 caracteres..." : props.lang === "en" ? "Minimum 4 characters" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        identification: "",
                    }));
                    return true;
                }
            }
            case "address": {
                setFields((prevFields) => ({
                    ...prevFields,
                    address: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 3) {
                    inputsRef.current[2] && inputsRef.current[2]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        address: props.lang === "es" ? "Mínimo 4 caracteres..." : props.lang === "en" ? "Minimum 4 characters" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        address: "",
                    }));
                    return true;
                }
            }
            default : {
                return false
            }
        }
    }

    const save = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!customer || !customer.id) {
            props.showSnackbar && props.showSnackbar({
                message: "Vuelva a cargar la página...",
                severity: "error"
            });
            return;
        }
        let indexFields = 0;
        for (const field in fields) {
            if (!validForm(field as keyof Fields, Object.values(fields)[indexFields])) {
                return;
            }
            indexFields++;
        }
        setIsLoading(true);
        isDev && console.log(TAG + "//save", "Guardando...");
        await CustomerAPI.updateShippingAddress({
            model: {
                ...fields,
                id: customer.id,
            }
        }, props.lang).then(async res_1 => {
            isDev && console.log(TAG + "//save", res_1.code, res_1.message, res_1.error);
            if (res_1.code === 200) {
                props.putCustomer && props.putCustomer({
                    ...fields
                });
                props.showSnackbar && props.showSnackbar({
                    message: res_1.message,
                    severity: "success"
                });
            } else {
                props.showSnackbar && props.showSnackbar({
                    message: res_1.message,
                    severity: "error"
                });
            }
        }).catch(async res_1 => {
            isDev && console.error(TAG + "//save", res_1.code, res_1.error, res_1);
            props.showSnackbar && props.showSnackbar({
                message: res_1.error ? res_1.error : JSON.stringify(res_1),
                severity: "error"
            });
        });
        setIsLoading(false);
    }

    return (
        <div
            className={"CustomerCompanyUpdateAddressViewForm1"}
        >
            <form
                className={"CustomerCompanyUpdateAddressViewForm1Form"}
                onSubmit={(e) => save(e)}
            >
                <section>
                    <TextInput
                        label={props.lang === "es" ? "Nombre de la empresa:" : props.lang === "en" ? "Business name:" : ""}
                        value={customer && customer.business_name as string}
                        readOnly
                    />
                    <TextInput
                        inputRef={inputsRef.current[0]}
                        label={props.lang === "es" ? "RUC | Identificación de la empresa:" : props.lang === "en" ? "Identification of the company:" : ""}
                        placeholder={props.lang === "es" ? "Identificación de la empresa" : props.lang === "en" ? "Identification of the company" : ""}
                        value={fields.ruc}
                        onChangeText={(text) => validForm("ruc", text)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                inputsRef.current[1] && inputsRef.current[1].focus();
                            }
                        }}
                        error={errors?.ruc}
                    />
                </section>
                <section>
                    <TextInput
                        label={props.lang === "es" ? "Nombres:" : props.lang === "en" ? "Names:" : ""}
                        value={customer && customer.name as string}
                        readOnly
                    />
                    <TextInput
                        inputRef={inputsRef.current[1]}
                        label={props.lang === "es" ? "Cédula | Identificación personal:" : props.lang === "en" ? "Personal identification:" : ""}
                        placeholder={props.lang === "es" ? "Identificación personal" : props.lang === "en" ? "Personal identification" : ""}
                        value={fields.identification}
                        onChangeText={(text) => validForm("identification", text)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                inputsRef.current[2] && inputsRef.current[2].focus();
                            }
                        }}
                        error={errors?.identification}
                    />
                </section>
                <section>
                    <TextInput
                        label={props.lang === "es" ? "Correo electrónico:" : props.lang === "en" ? "Email:" : ""}
                        value={customer && customer.email as string}
                        readOnly
                    />
                    <TextInput
                        inputRef={inputsRef.current[2]}
                        label={props.lang === "es" ? "Dirección:" : props.lang === "en" ? "Address:" : ""}
                        placeholder={props.lang === "es" ? "Dirección" : props.lang === "en" ? "Address" : ""}
                        value={fields.address}
                        onChangeText={(text) => validForm("address", text)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                save(e as any);
                            }
                        }}
                        error={errors?.address}
                    />
                </section>
                <div
                    className={"CustomerCompanyUpdateAddressViewForm1FormContentBtn"}
                >
                    <button
                        className={"CustomerCompanyUpdateAddressViewForm1FormBtn"}
                        type={"submit"}
                        disabled={isLoading}
                    >
                        {!isLoading && props.lang === "es" && "Guardar"}
                        {!isLoading && props.lang === "en" && "Save"}

                        {isLoading && <CircularProgress
                            color={"inherit"}
                            size={20}
                        />}
                    </button>
                </div>
            </form>
        </div>
    )
}
export default CustomerCompanyUpdateAddressViewForm1;
