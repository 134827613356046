import React from "react";
//theme
import "./styles.scss";
//types
import {Lang} from "../../../../../types";
import config from "../../../../../config";
import Image from "../../../../_components/Image";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexAppAdminBanner1 = (props: Props, ref: any) => {
    return (
        <div
            ref={ref}
            className={"IndexAppAdminBanner1"}
        >
            <Image
                className={"IndexAppAdminBanner1Background"}
                srcPrev={`${config.host}/uploads/assets/admin_banner_1.png?w=124&h=64`}
                src={`${config.host}/uploads/assets/admin_banner_1.png`}
                alt={"dvgeo_admin_banner_1"}
            />
            <section
                className={"IndexAppAdminBanner1Sec"}
            >
                <div>
                    {props.lang === "es" && <h6
                    >
                        Aplicativo Web
                    </h6>}
                    {props.lang === "en" && <h6
                    >
                        Web Application
                    </h6>}
                    {props.lang === "es" && <h1>
                        Administrador
                    </h1>}
                    {props.lang === "en" && <h1>
                        Manager
                    </h1>}
                </div>
            </section>
        </div>
    )
}
export default React.forwardRef(IndexAppAdminBanner1);
