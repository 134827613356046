import React from "react";
//theme
import "./styles.scss";
//api
import {CustomerSupportAPI} from "../../../../../api/customer_support/customerSupportAPI";
//models
import {CustomerSupport} from "../../../../../api/customer_support/customerSupportModel";
//libs
import config from "../../../../../config";
import {Link} from "react-router-dom";
import ReactLoading from 'react-loading';
//types
import {Lang} from "../../../../../types";
import {Badge} from "@material-ui/core";

//interfaces
interface Props {
    lang: Lang;
    customer_supports: Array<CustomerSupport>;
    isLoading: boolean;
}

//const
const TAG = `CustomerSupportSection1`;
const isDev = (process.env.NODE_ENV !== "production");
//components
const CustomerSupportSection1 = (props: Props) => {

    const updateIsBadgeCustomer = async (id: number) => {
        await CustomerSupportAPI.updateIsBadgeCustomer({
            model: {
                is_badge_customer: 0,
                id
            }
        }, props.lang).then(async res_1 => {
            isDev && console.log(TAG + "//updateIsBadgeCustomer", res_1.code, res_1.message, res_1.error);
        }).catch(async res_1 => {
            isDev && console.log(TAG + "//updateIsBadgeCustomer", res_1.code, res_1.message, res_1.error);
        });
    }

    return (
        <div
            className={"CustomerSupportSection1"}
        >
            <div
                className={"CustomerSupportSection1Items"}
            >
                {props.isLoading && <ReactLoading
                    type={"bubbles"}
                    color={config.colorPrimary}
                    height={50}
                    width={120}
                    delay={2000}
                />}
                {!props.isLoading && props.customer_supports.map((value, index) => {
                    return (
                        <Badge
                            badgeContent={value.is_badge_customer ? 1 : 0}
                            color={"primary"}
                            classes={{
                                badge: "CustomerSupportSection1ItemBadge"
                            }}
                            style={{
                                margin: "1px 15px"
                            }}
                            title={value.is_close === 0 ? props.lang === "es" ? "Abierto" : props.lang === "en" ? "Open" : "" : props.lang === "es" ? "Cerrado" : props.lang === "en" ? "Close" : ""}
                        >
                            <Link
                                key={`${TAG}_customer_apps_${index}`}
                                className={"CustomerSupportSection1Item"}
                                style={{
                                    opacity: value.is_close === 1 ? .5 : 1
                                }}
                                to={`/${props.lang}/application/customer/support/tracking/${value.id}`}
                                onClick={() => updateIsBadgeCustomer(Number(value.id))}
                            >
                                <legend
                                    className={"CustomerSupportSection1ItemTitle"}
                                >
                                    {decodeURI(value.issue as string)}
                                </legend>
                                <label
                                    className={"CustomerSupportSection1ItemPackage"}
                                >
                                    {decodeURI(value.message as string)}
                                </label>
                                <p
                                    className={"CustomerSupportSection1ItemDescription"}
                                    style={{
                                        backgroundColor: (value.customer_support_priority_id === 1) ? "rgba(187, 7, 7, .1)" : (value.customer_support_priority_id === 2) ? "rgba(187, 112, 7, .1)" : (value.customer_support_priority_id === 3) ? "rgba(30,140,18,0.1)" : "",
                                        color: (value.customer_support_priority_id === 1) ? "rgba(187, 7, 7, 1)" : (value.customer_support_priority_id === 2) ? "rgba(187, 112, 7, 1)" : (value.customer_support_priority_id === 3) ? "rgba(30,140,18, 1)" : ""
                                    }}
                                >
                                    {value.customer_support_priority?.priority} - {value.customer_support_type?.type}
                                </p>
                            </Link>
                        </Badge>
                    )
                })}
            </div>
        </div>
    )
}
export default CustomerSupportSection1;
