import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexAboutSection1 = (props: Props) => {
    return (
        <div
            className={"IndexAboutSection1"}
        >
            <div
                className={"IndexAboutSection1Background"}
            >
                <div
                    className={"IndexAboutSection1BackgroundImg"}
                />
            </div>
            <div
                className={"IndexAboutSection1Sec"}
            >
                {props.lang === "es" && <h1>
                    ¿A qué nos dedicamos?
                </h1>}
                {props.lang === "en" && <h1>
                    What do we do?
                </h1>}
                {props.lang === "es" && <p>
                    DvGeo Platform, es una solución completa basada en la nube para empresas de transporte urbano,
                    interurbano, paquetería, winchas, limo, ejecutivo, corporativo, etc. Ofrecemos tres aplicaciones:
                    Pasajero, Conductor y Administrador, con
                    la marca comercial de nuestro cliente, <strong>potenciando así la imagen corporativa de cada
                    empresa</strong> que este trabajando con nuestra plataforma.
                </p>}
                {props.lang === "en" && <p>
                    DvGeo Platform, is a complete cloud-based solution for urban transport companies,
                    interurban, parcel, winches, limo, executive, corporate, etc. We offer three applications:
                    Passenger, Driver and Administrator, with
                    the commercial brand of our client, <strong> thus enhancing the corporate image of each
                    company </strong> that is working with our platform.
                </p>}
            </div>
        </div>
    )
}
export default IndexAboutSection1;
