import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppPassengerSection6 = (props: Props, ref: any) => {

    return (
        <div
            ref={ref}
            className={"IndexAppPassengerSection6Content"}
        >
            <div
                className={"IndexAppPassengerSection6 js-scroll slide-right"}
            >
                <section
                    className={"IndexAppPassengerSection6Sec"}
                >
                    {props.lang === "es" && <h1>
                        Ofrece <strong>cupones de descuento</strong> para tus pasajeros
                    </h1>}
                    {props.lang === "en" && <h1>
                        Offer <strong>discount coupons</strong> for your passengers
                    </h1>}
                    {props.lang === "es" && <p>
                        Potencia tu campaña de marketing con <strong>códigos promocionales</strong> para que tus nuevos usuarios se motiven aún más
                    </p>}
                    {props.lang === "en" && <p>
                        Boost your marketing campaign with <strong>promotional codes</strong> so that your new users get even more motivated
                    </p>}
                </section>
                <div
                    className={"IndexAppPassengerSection6Img"}
                >
                    <img
                        src={require("../../../../../assets/img/passenger_6.png").default}
                        alt={"dvgeo_passenger_6"}
                    />
                </div>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppPassengerSection6);
