import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminTravelSection1 = (props: Props) => {

    return (
        <div
            className={"IndexAppAdminTravelSection1"}
        >
            <section
                className={"IndexAppAdminTravelSection1Sec"}
            >
                {props.lang === "es" && <h1>
                    Consola de <strong>Despacho / Reservas</strong> Online
                </h1>}
                {props.lang === "en" && <h1>
                    <strong>Dispatch</strong> Console / Online <strong>Reservations</strong>
                </h1>}
                {props.lang === "es" && <p>
                    El <strong>despacho inteligente</strong> ideal para carreras solicitadas desde call center, medios electrónicos o en oficina
                </p>}
                {props.lang === "en" && <p>
                    The ideal <strong>intelligent dispatch</strong> for careers requested from the call center, electronic media or in the office
                </p>}
                {props.lang === "es" && <p>
                    <strong>¡Desplázate hacia abajo</strong> para aprender más!
                </p>}
                {props.lang === "en" && <p>
                    <strong>Scroll down</strong> to learn more!
                </p>}
            </section>
            <div
                className={"IndexAppAdminTravelSection1Img"}
            >
                <img
                    src={require("../../../../../assets/img/admin_travel_1.png").default}
                    alt={"dvgeo_admin_travel_1"}
                />
            </div>
        </div>
    )
}
export default IndexAppAdminTravelSection1;
