import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminChannelSection1 = (props: Props) => {

    return (
        <div
            className={"IndexAppAdminChannelSection1"}
        >
            <section
                className={"IndexAppAdminChannelSection1Sec"}
            >
                {props.lang === "es" && <h1>
                    <strong>Monitorea en tiempo real</strong> a tus conductores
                </h1>}
                {props.lang === "en" && <h1>
                    Offer all the <strong>services</strong> you need
                </h1>}
                {props.lang === "es" && <p>
                    Crea <strong>canales de monitoreo</strong> para verificar las rutas de tus conductores o ver sus viajes en tiempo real
                </p>}
                {props.lang === "en" && <p>
                    A service allows your passenger or client the possibility of <strong>requesting a trip or
                    race</strong>, either from city to city or locally
                </p>}
                {props.lang === "es" && <p>
                    <strong>¡Desplázate hacia abajo</strong> para aprender más!
                </p>}
                {props.lang === "en" && <p>
                    <strong>Scroll down</strong> to learn more!
                </p>}
            </section>
            <div
                className={"IndexAppAdminChannelSection1Img"}
            >
                <img
                    src={require("../../../../../assets/img/admin_channel_1.png").default}
                    alt={"dvgeo_admin_channel_1"}
                />
            </div>
        </div>
    )
}
export default IndexAppAdminChannelSection1;
