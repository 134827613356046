import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppDriverSection10 = (props: Props, ref: any) => {

    return (
        <div
            ref={ref}
            className={"IndexAppDriverSection10Content"}
        >
            <div
                className={"IndexAppDriverSection10 js-scroll slide-right"}
            >
                <section
                    className={"IndexAppDriverSection10Sec"}
                >
                    {props.lang === "es" && <h1>
                        <strong>Reclama o denuncia</strong> incidencias en tu viaje
                    </h1>}
                    {props.lang === "en" && <h1>
                        Claim or report incidents on your trip
                    </h1>}
                    {props.lang === "es" && <p>
                        <strong>Puedes reportar a la Administración</strong> sobre alguna incidencia sucedida dentro de alguno de tus viajes
                    </p>}
                    {props.lang === "en" && <p>
                        <strong>You can report to the Administration</strong> about any incident that occurred during any of your trips
                    </p>}
                </section>
                <div
                    className={"IndexAppDriverSection10Img"}
                >
                    <img
                        src={require("../../../../../assets/img/driver_12.png").default}
                        alt={"dvgeo_driver_12"}
                    />
                </div>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppDriverSection10);
