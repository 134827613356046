import React from "react";
//theme
import "./styles.scss";
//libs
import {Link} from "react-router-dom";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const CustomerCompanyViewBanner1 = (props: Props) => {
    return (
        <div
            className={"CustomerCompanyViewBanner1"}
        >
            <h1>
                {props.lang === "es" && "Datos de mi empresa"}
                {props.lang === "en" && "My company data"}
            </h1>
            <p>
                {props.lang === "es" && "Puedes modificar la información de tu cuenta, subir archivos o cambiar la contraseña"}
                {props.lang === "en" && "You can modify your account information, upload files or change your password"}
            </p>
            <div
                className={"CustomerCompanyViewBanner1Buttons"}
            >
                <Link
                    className={"CustomerCompanyViewBanner1ButtonsLink"}
                    to={`/${props.lang}/application/customer/company/update/password`}
                >
                    {props.lang === "es" && "Cambiar contraseña"}
                    {props.lang === "en" && "Change Password"}
                </Link>
                <Link
                    className={"CustomerCompanyViewBanner1ButtonsLink"}
                    to={`/${props.lang}/application/customer/company/update/address`}
                >
                    {props.lang === "es" && "Dirección de envío"}
                    {props.lang === "en" && "Shipping Address"}
                </Link>
            </div>
        </div>
    )
}
export default CustomerCompanyViewBanner1;
