const Valid = {
    isValidEmailAddress: (email_address: string) => {
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        return pattern.test(email_address);
    },
    validarCI: (cedula: string) => {
        let cad: string = cedula;
        let total = 0;
        let longitud = cad.length;
        let longcheck = longitud - 1;
        let flag_ci = false;

        if (cad !== "" && longitud === 10) {
            for (let i = 0; i < longcheck; i++) {
                if (i % 2 === 0) {
                    // @ts-ignore
                    let aux = cad.charAt(i) * 2;
                    if (aux > 9) aux -= 9;
                    total += aux;
                } else {
                    total += parseInt(cad.charAt(i));
                }
            }
            total = total % 10 ? 10 - total % 10 : 0;
            // @ts-ignore
            if (cad.charAt(longitud - 1) == total) {
                flag_ci = true;
            } else {
                flag_ci = false;
            }
        }
        return flag_ci;
    },
    validarRUC: (ruc: string) => {
        let flag_ruc = false;
        let ultimos_digitos = ruc.substring(10, 13);

        if (ultimos_digitos != '001') {
            flag_ruc = false;
        } else {
            let primeros_digitos = ruc.substring(0, 2);
            // @ts-ignore
            if (primeros_digitos > 24) {
                flag_ruc = false;
            } else {
                flag_ruc = true;
            }
        }
        return flag_ruc;
    },
    validarPlaca: (placa: string) => {
        let pattern = new RegExp('[A-Z|a-z]{3}[0-9]{4}');
        return pattern.test(placa);
    },
    validPass: (contrasenia: string) => {
        if (contrasenia.length >= 8) {
            let mayuscula = false;
            let minuscula = false;
            let numero = false;
            let caracter_raro = false;

            for (let i = 0; i < contrasenia.length; i++) {
                if (contrasenia.charCodeAt(i) >= 65 && contrasenia.charCodeAt(i) <= 90) {
                    mayuscula = true;
                } else if (contrasenia.charCodeAt(i) >= 97 && contrasenia.charCodeAt(i) <= 122) {
                    minuscula = true;
                } else if (contrasenia.charCodeAt(i) >= 48 && contrasenia.charCodeAt(i) <= 57) {
                    numero = true;
                } else {
                    caracter_raro = true;
                }
            }
            if (mayuscula && minuscula && caracter_raro && numero) {
                return true;
            }
        }
        return false;
    },
    valideCardType: (cur_val: string) => {
        // the regular expressions check for possible matches as you type, hence the OR operators based on the number
        // of chars regexp string length {0} provided for soonest detection of beginning of the card numbers this way
        // it could be used for BIN CODE detection also

        //JCB
        let jcb_regex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$'); //2131, 1800, 35 (3528-3589)
        // American Express
        let amex_regex = new RegExp('^3[47][0-9]{0,}$'); //34, 37
        // Diners Club
        let diners_regex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$'); //300-305, 309, 36, 38-39
        // Visa
        let visa_regex = new RegExp('^4[0-9]{0,}$'); //4
        // MasterCard
        let mastercard_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$'); //2221-2720, 51-55
        let maestro_regex = new RegExp('^(5[06789]|6)[0-9]{0,}$'); //always growing in the range: 60-69, started with /
                                                                   // not something else, but starting 5 must be
                                                                   // encoded as mastercard anyway
        //Discover
        let discover_regex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$');
        ////6011, 622126-622925, 644-649, 65


        // get rid of anything but numbers
        cur_val = cur_val.replace(/\D/g, '');

        // checks per each, as their could be multiple hits
        //fix: ordering matter in detection, otherwise can give false results in rare cases
        let sel_brand = "unknown";
        if (cur_val.match(jcb_regex)) {
            sel_brand = "jcb";
        } else if (cur_val.match(amex_regex)) {
            sel_brand = "amex";
        } else if (cur_val.match(diners_regex)) {
            sel_brand = "diners_club";
        } else if (cur_val.match(visa_regex)) {
            sel_brand = "visa";
        } else if (cur_val.match(mastercard_regex)) {
            sel_brand = "mastercard";
        } else if (cur_val.match(discover_regex)) {
            sel_brand = "discover";
        } else if (cur_val.match(maestro_regex)) {
            if (cur_val[0] === '5') { //started 5 must be mastercard
                sel_brand = "mastercard";
            } else {
                sel_brand = "maestro";
            }
        }

        return sel_brand;
    },
    validOnlyNumbers: (value: any) => {
        let reg = new RegExp('^[0-9]+$');
        return reg.test(value)
    },
    getOnlyNumbers: (value: any) => {
        const NUMERIC_REGEXP = new RegExp(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
        //console.log(value.match(NUMERIC_REGEXP));
        return value.match(NUMERIC_REGEXP) ? value.match(NUMERIC_REGEXP)[0] : value;
    }
}

export default Valid;
