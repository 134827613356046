import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexAboutSection3 = (props: Props) => {
    return (
        <div
            className={"IndexAboutSection3"}
        >
            <section
                className={"IndexAboutSection3Group"}
            >
                <div
                    className={"IndexAboutSection3Background"}
                >
                    <div
                        className={"IndexAboutSection3BackgroundImg"}
                        style={{
                            backgroundImage: `url(${require("../../../../../assets/img/josue.jpg").default})`,
                            filter: "grayscale(90%)",
                        }}
                    />
                </div>
                <div
                    className={"IndexAboutSection3Sec"}
                >
                    <h1>
                        Josue Chavez
                    </h1>
                    {props.lang === "es" && <h4>
                        Co-Fundador y Director Ejecutivo
                    </h4>}
                    {props.lang === "en" && <h4>
                        Co-Founder and Executive Director
                    </h4>}
                    {props.lang === "es" && <p>
                        Ingeniero en software, co-fundador de la empresa Devjos CIA LTDA. Tiene más de 10 años de
                        experiencia en tecnologías de software
                    </p>}
                    {props.lang === "en" && <p>
                        Software engineer, co-founder of the company Devjos CIA LTDA. He has more than 10 years of
                        experience in software technologies
                    </p>}
                </div>
            </section>
            <section
                className={"IndexAboutSection3Group"}
            >
                <div
                    className={"IndexAboutSection3Background"}
                >
                    <div
                        className={"IndexAboutSection3BackgroundImg"}
                        style={{
                            backgroundImage: `url(${require("../../../../../assets/img/marisol.jpg").default})`
                        }}
                    />
                </div>
                <div
                    className={"IndexAboutSection3Sec"}
                >
                    <h1>
                        Marisol Alcivar
                    </h1>
                    {props.lang === "es" && <h4>
                        Co-Fundadora y Directora de Ventas
                    </h4>}
                    {props.lang === "en" && <h4>
                        Co-Founder and Sales Director
                    </h4>}
                    {props.lang === "es" && <p>
                        Ingeniera Comercial, co-fundadora de la empresa Devjos CIA LTDA. Tiene más de 20 años de
                        experiencia en ventas de diferentes mercados.
                    </p>}
                    {props.lang === "en" && <p>
                        Commercial Engineer, co-founder of the company Devjos CIA LTDA. She has more than 20 years of
                        experience in sales of different markets.
                    </p>}
                </div>
            </section>
            <section
                className={"IndexAboutSection3Group"}
            >
                <div
                    className={"IndexAboutSection3Background"}
                >
                    <div
                        className={"IndexAboutSection3BackgroundImg"}
                        style={{
                            backgroundImage: `url(${require("../../../../../assets/img/diana.jpg").default})`
                        }}
                    />
                </div>
                <div
                    className={"IndexAboutSection3Sec"}
                >
                    <h1>
                        Diana Triana
                    </h1>
                    {props.lang === "es" && <h4>
                        Asesora Comercial
                    </h4>}
                    {props.lang === "en" && <h4>
                        Business consultant
                    </h4>}
                    {props.lang === "es" && <p>
                        Influencer y Modelo. Destaca su habilidad de presentar productos y servicios, con más de 5 años
                        de experiencia a nivel nacional.
                    </p>}
                    {props.lang === "en" && <p>
                        Influencer and Model. His ability to present products and services stands out, with more than 5 years
                        of experience at the national level.
                    </p>}
                </div>
            </section>
        </div>
    )
}
export default IndexAboutSection3;
