import React, {useEffect} from "react";
//theme
import "../styles.scss";
//models
import {Customer} from "../../../../api/customer/customerModel";
//components
import NavBarTop from "../../../_components/NavBarTop";
import NavBarMenu from "../../../_components/NavBarMenu";
import Footer from "../../../_components/Footer";
import IndexAppAdminGroupBanner1 from "../_components/banner_1";
import IndexAppAdminGroupSection1 from "../_components/section_1";
import IndexAppAdminGroupSection2 from "../_components/section_2";
import IndexHomeSection2 from "../../home/_components/section_2";
import IndexAppAdminGroupSection3 from "../_components/section_3";

//interfaces
interface Props {
    customer: Customer;
    deleteCustomer?: () => void;
    deleteToken?: () => void;
}

//view
const IndexAppAdminGroupEs = (props: Props) => {
    //effects
    useEffect(() => {
        document.title = `Módulo de grupos - Chat de conductores y administradores | DvGeo Platform`
        return () => {

        }
    }, []);

    return (
        <div
            className={"IndexAppAdminGroup"}
        >
            <NavBarTop
                lang={"es"}
                customer={props.customer}
                deleteCustomer={props.deleteCustomer}
                deleteToken={props.deleteToken}
            />
            <NavBarMenu
                lang={"es"}
            />
            <IndexAppAdminGroupBanner1
                lang={"es"}
            />
            <IndexAppAdminGroupSection1
                lang={"es"}
            />
            <IndexAppAdminGroupSection2
                lang={"es"}
            />
            <IndexAppAdminGroupSection3
                lang={"es"}
            />
            <IndexHomeSection2
                lang={"es"}
            />
            <Footer
                lang={"es"}
                customer={props.customer}
            />
        </div>
    )
}
export default IndexAppAdminGroupEs;
