import React, {useEffect, useRef, useState} from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminCouponSection2 = (props: Props) => {
    //refs
    const iTimer = useRef<NodeJS.Timeout | number>(0);
    const indexAppAdminServiceSection1DesktopSnapContentRef = useRef<any>(null);
    const items = useRef<Array<any>>([]);
    //state
    const [index, setIndex] = useState<number>(0);
    //effects
    useEffect(() => {
        init();
        return () => {
            clearIntervals();
        }
    }, []);

    const init = () => {
        try {
            iIndexTranslateX();
        } catch (error: any) {

        }
    }


    const iIndexTranslateX = () => {
        setIndex(0);
        setTranslateXValid(0);
        clearInterval(iTimer.current as NodeJS.Timeout);
        let indexCurrent = 0;
        iTimer.current = setInterval(() => {
            if (indexCurrent >= 0 && indexCurrent <= 1) {
                indexCurrent++;
                setTranslateXValid(indexCurrent);
                setIndex(indexCurrent);
            } else {
                iIndexTranslateX();
                indexCurrent = 0;
                setIndex(0);
            }
        }, 6000);
    }

    const clearIntervals = () => {
        clearInterval(iTimer.current as NodeJS.Timeout);
        iTimer.current = 0;
    }

    const setTranslateX = (index: number, value: string) => {
        try {
            items.current[index].style.transform = value;
        } catch (error: any) {
            setTimeout(() => setTranslateX(index, value), 1000 / 5);
        }
    }

    const setTranslateXValid = (index: number) => {
        if (index === 0) {
            setTimeout(() => setTranslateX(0, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-0vw)`), 1000 / 5);
        }
        if (index === 1) {
            setTimeout(() => setTranslateX(0, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-40vw)`), 1000 / 5);
        }
        if (index === 2) {
            setTimeout(() => setTranslateX(0, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-80vw)`), 1000 / 5);
        }
        if (index === 3) {
            setTimeout(() => setTranslateX(0, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-120vw)`), 1000 / 5);
        }
    }

    return (
        <div
            className={"IndexAppAdminCouponSection2"}
        >
            {props.lang === "es" && <h1
                className={"IndexAppAdminCouponSection2Title"}
            >
                ¿Cómo funciona?
            </h1>}
            {props.lang === "en" && <h1
                className={"IndexAppAdminCouponSection2Title"}
            >
                How does it work?
            </h1>}
            {/*apps en modo mobile*/}
            <div
                className={"IndexAppAdminCouponSection2Mobile"}
            >
                <section
                    className={"IndexAppAdminCouponSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminCouponSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminCouponSection2DesktopSnapControlBtnSpan"}
                        >
                           1
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Define tus cupones promocionales
                            </h6>}
                            {props.lang === "en" && <h6>
                                Define your promotional coupons
                            </h6>}
                            {props.lang === "es" && <label>
                                El objetivo es brindarles pequeños descuentos a sus pasajeros con descripciones llamativas
                            </label>}
                            {props.lang === "en" && <label>
                                The goal is to provide small discounts to your passengers with eye-catching descriptions
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminCouponSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_coupon_1.png").default}
                            alt={"dvgeo_admin_coupon_1"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppAdminCouponSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminCouponSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminCouponSection2DesktopSnapControlBtnSpan"}
                        >
                           2
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Crear los cupones
                            </h6>}
                            {props.lang === "en" && <h6>
                                Create coupons
                            </h6>}
                            {props.lang === "es" && <label>
                                Necesitas una descripción breve, un código, fecha de caducidad y alance de usuario.
                            </label>}
                            {props.lang === "en" && <label>
                                You need a short description, a code, expiration date and user range.
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminCouponSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_coupon_2.png").default}
                            alt={"dvgeo_admin_coupon_2"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppAdminCouponSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminCouponSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminCouponSection2DesktopSnapControlBtnSpan"}
                        >
                           3
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Comprobar la caducidad
                            </h6>}
                            {props.lang === "en" && <h6>
                                Check expiration
                            </h6>}
                            {props.lang === "es" && <label>
                                Cuando la fecha caduca se prohíbe a los usuarios seguir aplicando el cupón de descuento
                            </label>}
                            {props.lang === "en" && <label>
                                When the date expires, users are prohibited from continuing to apply the discount coupon
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminCouponSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_coupon_3.png").default}
                            alt={"dvgeo_admin_coupon_3"}
                        />
                    </div>
                </section>
            </div>
            {/*apps en modo desktop*/}
            <div
                className={"IndexAppAdminCouponSection2Desktop"}
            >
                <div
                    ref={indexAppAdminServiceSection1DesktopSnapContentRef}
                    className={"IndexAppAdminCouponSection2DesktopSnapControl"}
                >
                    <button
                        className={index === 0 ? "IndexAppAdminCouponSection2DesktopSnapControlBtnFocus" : "IndexAppAdminCouponSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(0);
                            setIndex(0);
                            clearIntervals();
                        }}
                    >
                        <span
                            className={index === 0 ? "IndexAppAdminCouponSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminCouponSection2DesktopSnapControlBtnSpan"}
                        >
                           1
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Define tus cupones promocionales
                            </h6>}
                            {props.lang === "en" && <h6>
                                Define your promotional coupons
                            </h6>}
                            {props.lang === "es" && <label>
                                El objetivo es brindarles pequeños descuentos a sus pasajeros con descripciones llamativas
                            </label>}
                            {props.lang === "en" && <label>
                                The goal is to provide small discounts to your passengers with eye-catching descriptions
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 1 ? "IndexAppAdminCouponSection2DesktopSnapControlBtnFocus" : "IndexAppAdminCouponSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(1);
                            setIndex(1);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 1 ? "IndexAppAdminCouponSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminCouponSection2DesktopSnapControlBtnSpan"}
                       >
                           2
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Crear los cupones
                            </h6>}
                            {props.lang === "en" && <h6>
                                Create coupons
                            </h6>}
                            {props.lang === "es" && <label>
                                Necesitas una descripción breve, un código, fecha de caducidad y alance de usuario.
                            </label>}
                            {props.lang === "en" && <label>
                                You need a short description, a code, expiration date and user range.
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 2 ? "IndexAppAdminCouponSection2DesktopSnapControlBtnFocus" : "IndexAppAdminCouponSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(2);
                            setIndex(2);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 2 ? "IndexAppAdminCouponSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminCouponSection2DesktopSnapControlBtnSpan"}
                       >
                           3
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Comprobar la caducidad
                            </h6>}
                            {props.lang === "en" && <h6>
                                Check expiration
                            </h6>}
                            {props.lang === "es" && <label>
                                Cuando la fecha caduca se prohíbe a los usuarios seguir aplicando el cupón de descuento
                            </label>}
                            {props.lang === "en" && <label>
                                When the date expires, users are prohibited from continuing to apply the discount coupon
                            </label>}
                        </section>
                    </button>
                </div>
                <div
                    ref={indexAppAdminServiceSection1DesktopSnapContentRef}
                    className={"IndexAppAdminCouponSection2DesktopSnapContent"}
                >
                    <div
                        className={"IndexAppAdminCouponSection2DesktopSnapContentBackground"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_monitor.png").default}
                            alt={""}
                        />
                    </div>
                    <section
                        ref={ref => items.current[0] = ref}
                        className={"IndexAppAdminCouponSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_coupon_1.png").default}
                            alt={"dvgeo_admin_coupon_2"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[1] = ref}
                        className={"IndexAppAdminCouponSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_coupon_2.png").default}
                            alt={"dvgeo_admin_coupon_3"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[2] = ref}
                        className={"IndexAppAdminCouponSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_coupon_3.png").default}
                            alt={"dvgeo_admin_coupon_1"}
                        />
                    </section>
                </div>
            </div>
        </div>
    )
}
export default IndexAppAdminCouponSection2;
