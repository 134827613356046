import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const CustomerAppsViewBanner1 = (props: Props) => {
    return (
        <div
            className={"CustomerAppsViewBanner1"}
        >
            <h1>
                {props.lang === "es" && "Mis Apps"}
                {props.lang === "en" && "My Apps"}
            </h1>
            <p>
                {props.lang === "es" && "Realiza seguimiento de las actualizaciones para tus apps"}
                {props.lang === "en" && "Track updates for your apps"}
            </p>
        </div>
    )
}
export default CustomerAppsViewBanner1;
