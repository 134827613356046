import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexFaqSection4 = (props: Props) => {
    //state
    const [indexGroup, setIndexGroup] = useState<Array<boolean>>([false, false, false, false, false, false]);

    const setIndexGroupFocus = (index: number) => {
        let indexGroupNew: Array<boolean> = [...indexGroup];
        for (let i = 0; i < indexGroupNew.length; i++) {
            if (i === index) {
                indexGroupNew[i] = !indexGroupNew[i];
            } else {
                indexGroupNew[i] = false;
            }
        }
        setIndexGroup(indexGroupNew);
    }

    return (
        <div
            className={"IndexFaqSection4"}
        >
            <div
                className={"IndexFaqSection4Title"}
            >
                {props.lang === "es" && <h1
                >
                    Aplicativo Conductor
                </h1>}
                {props.lang === "en" && <h1
                >
                    Driver App
                </h1>}
            </div>
            <section
                className={"IndexFaqSection4Sec"}
            >
                <button
                    className={"IndexFaqSection4SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(0)}
                >
                    {props.lang === "es" && <h2>
                        ¿Estará disponible el app de conductor en Android y iPhone?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Is the driver app available on Android and iPhone?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[0] && <div
                    className={"IndexFaqSection4SecGroup"}
                >
                    {props.lang === "es" && <p>
                        ¡Sí! El App de Conductor y App de Pasajero podrá descargarse gratis en Android y iPhone. El
                        teléfono inteligente o tableta utilizado debe contar con 3G / 4G y soporte para GPS.
                    </p>}
                    {props.lang === "en" && <p>
                        Yes! The Driver App and Passenger App can be downloaded for free on Android and iPhone. The
                        smartphone or tablet used must have 3G / 4G and GPS support.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection4SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(1)}
                >
                    {props.lang === "es" && <h2>
                        ¿Pueden los conductores elegir otra ruta diferente a la sugerida por el sistema?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can drivers choose a different route than the one suggested by the system?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[1] && <div
                    className={"IndexFaqSection4SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Los conductores pueden elegir la ruta que deseen. El sistema sólo brinda estimaciones y
                        sugerencias; pero es el conductor quien decide la ruta a seguir.
                    </p>}
                    {props.lang === "en" && <p>
                        Drivers can choose the route they want. The system only provides estimates and suggestions; but
                        it is the driver who decides the route to follow.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection4SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(2)}
                >
                    {props.lang === "es" && <h2>
                        Si hay lugares con baja señal, ¿el app de conductor será actualizado cuando la señal regrese?
                    </h2>}
                    {props.lang === "en" && <h2>
                        If there are places with low signal, will the driver app be updated when the signal returns?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[2] && <div
                    className={"IndexFaqSection4SecGroup"}
                >
                    {props.lang === "es" && <p>
                        ¡Sí! El sistema siempre tiene comunicación constante con el dispositivo del conductor. Si el
                        dispositivo es inaccesible por cualquier motivo, el sistema seguirá tratando de comunicarse con
                        él. Una vez que tenga éxito actualizará la ubicación del vehículo.
                    </p>}
                    {props.lang === "en" && <p>
                        Yes! The system always has constant communication with the driver's device. If the device is
                        inaccessible for any reason, the system will keep trying to communicate with it. Once it is
                        successful it will update the location of the vehicle.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection4SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(3)}
                >
                    {props.lang === "es" && <h2>
                        ¿Los conductores pueden registrarse con varios vehículos y ofrecer diferentes servicio?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can drivers register with multiple vehicles and offer different services?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[3] && <div
                    className={"IndexFaqSection4SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Si, cada conductor puede tener varios vehículos registrados o asignados y cada vehículo puede
                        aceptar diversos servicios. Cabe recalcar que la asignación de varios vehículos a un conductor
                        lo autoriza un operador del aplicativo Admin.
                    </p>}
                    {props.lang === "en" && <p>
                        Yes, each driver can have several registered or assigned vehicles and each vehicle can accept
                        different services. It should be noted that the assignment of several vehicles to a driver is
                        authorized by an operator of the Admin application.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection4SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(4)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo configurar un porcentaje de comisión por cada conductor?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I set a commission percentage for each driver?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[4] && <div
                    className={"IndexFaqSection4SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Si, en el aplicativo Admin un operador puede configurar un porcentaje de comisión por cada
                        conductor. Esta comisión se cobra completa cuando se termina un viaje y se cobra la mitad cuando
                        el conductor cancela un servicio.
                    </p>}
                    {props.lang === "en" && <p>
                        Yes, in the Admin application an operator can configure a commission percentage for each driver.
                        This commission is charged in full when a trip is completed and half is charged when the driver
                        cancels a service.
                    </p>}
                </div>}
            </section>
        </div>
    )
}
export default IndexFaqSection4;
