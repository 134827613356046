import React from "react";
//theme
import "./styles.scss";
//libs
import {useHistory} from "react-router-dom";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminSection8 = (props: Props, ref: any) => {
    //hooks
    const history = useHistory();

    return (
        <div
            ref={ref}
            className={"IndexAppAdminSection8Content"}
        >
            <div
                className={"IndexAppAdminSection8 js-scroll slide-right"}
            >
                <section
                    className={"IndexAppAdminSection8Sec"}
                >
                    {props.lang === "es" && <h1>
                        Genera <strong>incidencias o reclamaciones</strong>
                    </h1>}
                    {props.lang === "en" && <h1>
                        Generate incidents or claims
                    </h1>}
                    {props.lang === "es" && <p>
                        Puedes hacer un <strong>reclamo sobre un viaje cancelado o completado</strong> sea del pasajero o del conductor
                    </p>}
                    {props.lang === "en" && <p>
                        You can make a claim about a canceled or completed trip either from the passenger or the driver
                    </p>}
                </section>
                <div
                    className={"IndexAppAdminSection8Img"}
                >
                    <img
                        src={require("../../../../../assets/img/admin_feeback_1.png").default}
                        alt={"dvgeo_admin_coupon_1"}
                    />
                </div>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppAdminSection8);
