import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminUserSection1 = (props: Props) => {

    return (
        <div
            className={"IndexAppAdminUserSection1"}
        >
            <section
                className={"IndexAppAdminUserSection1Sec"}
            >
                {props.lang === "es" && <h1>
                    LLeva el <strong>control</strong> de tus usuarios
                </h1>}
                {props.lang === "en" && <h1>
                    Offer all the <strong>services</strong> you need
                </h1>}
                {props.lang === "es" && <p>
                    Controla toda la información que generen dentro de las apps, los usuarios: <strong>administradores,
                    comerciantes, pasajeros y conductores</strong>
                </p>}
                {props.lang === "en" && <p>
                    A service allows your passenger or client the possibility of <strong>requesting a trip or
                    race</strong>, either from city to city or locally
                </p>}
                {props.lang === "es" && <p>
                    <strong>¡Desplázate hacia abajo</strong> para aprender más!
                </p>}
                {props.lang === "en" && <p>
                    <strong>Scroll down</strong> to learn more!
                </p>}
            </section>
            <div
                className={"IndexAppAdminUserSection1Img"}
            >
                <img
                    src={require("../../../../../assets/img/admin_user_1.png").default}
                    alt={"dvgeo_admin_2"}
                />
            </div>
        </div>
    )
}
export default IndexAppAdminUserSection1;
