import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppPassengerSection2 = (props: Props, ref: any) => {
    //hooks

    return (
        <div
            ref={ref}
            className={"IndexAppPassengerSection2Content"}
        >
            <div
                className={"IndexAppPassengerSection2 js-scroll slide-right"}
            >
                <section
                    className={"IndexAppPassengerSection2Sec"}
                >
                    {props.lang === "es" && <h1>
                        Ofrece todos los <strong>Servicios</strong> que necesites
                    </h1>}
                    {props.lang === "en" && <h1>
                        Offer all the <strong>services</strong> you need
                    </h1>}
                    {props.lang === "es" && <p>
                        Un servicio le permite a tu pasajero o cliente la posibilidad de <strong>requerir un viaje o
                        carrera</strong>, sea de ciudad a ciudad o de forma local
                    </p>}
                    {props.lang === "en" && <p>
                        A service allows your passenger or client the possibility of <strong>requesting a trip or
                        race</strong>, either from city to city or locally
                    </p>}
                </section>
                <div
                    className={"IndexAppPassengerSection2Img"}
                >
                    <img
                        src={require("../../../../../assets/img/passenger_2.png").default}
                        alt={"dvgeo_passenger_2"}
                    />
                </div>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppPassengerSection2);
