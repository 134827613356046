import React, {useEffect, useRef, useState} from "react";
//theme
import "./Image.scss";
//libs
import config from "../../config";

//interfaces
interface Props {
    className?: string;
    srcPrev: string;
    src: string;
    alt: string;
}

//component
const Image = (props: Props) => {
    //refs
    //state
    const [isLoading, setIsLoading] = useState<boolean>(true);
    //effects
    useEffect(() => {
        return () => {

        }
    }, []);

    return (
        <div
            className={"Image"}
        >
            <img
                className={props.className}
                style={{
                    display: isLoading ? "none" : "inline-block",
                }}
                src={props.src}
                alt={props.alt}
                onLoad={() => {
                    setTimeout(() => setIsLoading(false), 1000);
                }}
                loading={"lazy"}
            />
            {isLoading && <img
                className={props.className + " ImageBlur"}
                src={props.srcPrev}
                alt={props.alt}
            />}
            <div
                className={"ImageProtect"}
            />
        </div>
    )
}
export default Image;
