import React, {useEffect} from 'react';
//store
import {deleteCustomer, postCustomer, putCustomer} from "./store/actions/customer.ac";
import {deleteToken, hideSnackbar, postToken, showSnackbar, SystemSnackbar} from "./store/actions/system.ac";
//API
import {CustomerAPI} from "./api/customer/customerAPI";
//models
import {Customer} from "./api/customer/customerModel";
//libs
import config from "./config";
import {BrowserRouter as Router, Route, Switch, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import AsyncStorage from "@react-native-community/async-storage";
//views
import IndexSwitch from "./views/index";
import CustomerSwitch from "./views/customer";
import AdminSwitch from "./views/admin";
//types
import {StoreDispatchProps, StoreStateProps} from "./store/types";
import {ErrorException} from "./types";

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

//interfaces
interface OwnProps {

}

//const
const TAG = `App`;
const isDev = (process.env.NODE_ENV !== "production");
//View
const App = (props: Props) => {
    //hooks
    const history = useHistory();
    //effects
    useEffect(() => {
        getData();
        return () => {

        }
    }, []);
    useEffect(() => {

    }, [props.customer])

    const getData = async () => {
        isDev && console.log(TAG + "//getData");
        try {
            const token = await AsyncStorage.getItem(`token::${config.package}`) || "";
            const session_str: string = await AsyncStorage.getItem(`user::${config.package}`) || "";
            if (token || session_str) {
                const session: Customer = JSON.parse(session_str);
                props.postCustomer && props.postCustomer(session);
                props.postToken && props.postToken(token);
                await getSession(session);
            }
        } catch (error: ErrorException | any) {
            isDev && console.error(TAG + "//getData", error);
        }
    }

    const getSession = async (customer: Customer) => {
        await CustomerAPI.getById(Number(customer.id), "es").then(async res_1 => {
            isDev && console.log(TAG + "//getSession", res_1.code, res_1.message, res_1.error);
            if (res_1.code === 200) {
                props.postCustomer && props.postCustomer(res_1.customer as Customer);
            } else {
                await AsyncStorage.removeItem(`token::${config.package}`);
                await AsyncStorage.removeItem(`user::${config.package}`);
                props.deleteCustomer && props.deleteCustomer();
                props.deleteToken && props.deleteToken();
                history.push("/");
            }
        }).catch(async res_1 => {
            isDev && console.error(TAG + "//getSession", res_1.code, res_1.error, res_1);
        })
    }

    return (
        <Router>
            <Switch>
                <Route
                    path={`/`}
                    component={IndexSwitch}
                    exact
                />
                <Route
                    path={[`/es/application/customer`, `/en/application/customer`]}
                    component={CustomerSwitch}
                />
                <Route
                    path={[`/es/application/admin`, `/en/application/admin`]}
                    component={AdminSwitch}
                />
                {/*default*/}
                <Route
                    path={`*`}
                    component={IndexSwitch}
                    exact
                />
            </Switch>
        </Router>
    );
}

const mapStateToProps = (state: StoreStateProps) => ({
    system: state.system,
    customer: state.customer,
});

const mapDispatchToProps = {
    //system
    postToken: (token: string) => (postToken(token)),
    deleteToken: () => (deleteToken()),
    showSnackbar: (snackbar: SystemSnackbar) => (showSnackbar(snackbar)),
    hideSnackbar: () => (hideSnackbar()),
    //customer
    postCustomer: (customer: Customer) => (postCustomer(customer)),
    putCustomer: (customer: Customer) => (putCustomer(customer)),
    deleteCustomer: () => (deleteCustomer()),
}
export default connect<StoreStateProps, StoreDispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(App);
