import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppDriverSection7 = (props: Props, ref: any) => {
    //hooks

    return (
        <div
            ref={ref}
            className={"IndexAppDriverSection7Content"}
        >
            <div
                className={"IndexAppDriverSection7 js-scroll slide-left"}
            >
                <div
                    className={"IndexAppDriverSection7Img"}
                >
                    <img
                        src={require("../../../../../assets/img/driver_9.png").default}
                        alt={"dvgeo_driver_9"}
                    />
                </div>
                <section
                    className={"IndexAppDriverSection7Sec"}
                >
                    {props.lang === "es" && <h1>
                        Revisa tus <strong>ganancias</strong>
                    </h1>}
                    {props.lang === "en" && <h1>
                        Check your <strong>earnings</strong>
                    </h1>}
                    {props.lang === "es" && <p>
                        Además de ver el saldo de tu cuenta puedes <strong>controlar tus ganancias</strong> gráficamente
                    </p>}
                    {props.lang === "en" && <p>
                        In addition to seeing your account balance, you can <strong>control your earnings</strong> graphically
                    </p>}
                </section>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppDriverSection7);
