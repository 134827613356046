import React, {useEffect} from "react";
//theme
import "../styles.scss";
//models
import {Customer} from "../../../../api/customer/customerModel";
//components
import NavBarTop from "../../../_components/NavBarTop";
import NavBarMenu from "../../../_components/NavBarMenu";
import Footer from "../../../_components/Footer";
import IndexAppAdminBanner1 from "../_components/banner_1";
import IndexAppAdminCouponSection1 from "../_components/section_1";
import IndexAppAdminCouponSection2 from "../_components/section_2";
import IndexHomeSection2 from "../../home/_components/section_2";
import IndexAppAdminCouponSection3 from "../_components/section_3";
//interfaces
interface Props {
    customer: Customer;
    deleteCustomer?: () => void;
    deleteToken?: () => void;
}
//view
const IndexAppAdminCouponEn = (props: Props) => {
    //effects
    useEffect(() => {
        document.title = `Coupon Module - Promotional codes with small discounts | DvGeo Platform`
        return () => {

        }
    }, []);

    return (
        <div
            className={"IndexAppAdminCoupon"}
        >
            <NavBarTop
                lang={"en"}
                customer={props.customer}
                deleteCustomer={props.deleteCustomer}
                deleteToken={props.deleteToken}
            />
            <NavBarMenu
                lang={"en"}
            />
            <IndexAppAdminBanner1
                lang={"en"}
            />
            <IndexAppAdminCouponSection1
                lang={"en"}
            />
            <IndexAppAdminCouponSection2
                lang={"en"}
            />
            <IndexAppAdminCouponSection3
                lang={"en"}
            />
            <IndexHomeSection2
                lang={"en"}
            />
            <Footer
                lang={"en"}
                customer={props.customer}
            />
        </div>
    )
}
export default IndexAppAdminCouponEn;
