import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppDriverSection9 = (props: Props, ref: any) => {
    //hooks

    return (
        <div
            ref={ref}
            className={"IndexAppDriverSection9Content"}
        >
            <div
                className={"IndexAppDriverSection9 js-scroll slide-left"}
            >
                <div
                    className={"IndexAppDriverSection9Img"}
                >
                    <img
                        src={require("../../../../../assets/img/driver_11.png").default}
                        alt={"dvgeo_driver_11"}
                    />
                </div>
                <section
                    className={"IndexAppDriverSection9Sec"}
                >
                    {props.lang === "es" && <h1>
                        Verifica tus <strong>transacciones</strong>
                    </h1>}
                    {props.lang === "en" && <h1>
                        Check your <strong>transactions</strong>
                    </h1>}
                    {props.lang === "es" && <p>
                        Por cada viaje se debitará un <strong>porcentaje de comisión configurable</strong> desde el aplicativo Admin
                    </p>}
                    {props.lang === "en" && <p>
                        For each trip a <strong>configurable commission percentage</strong> will be debited from the Admin application
                    </p>}
                </section>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppDriverSection9);
