import React, {useEffect, useState} from "react";
//theme
import "./view.scss";
//store
//API
import {CustomerAppAPI} from "../../../../api/customer_app/customerAppAPI";
//models
import {Customer} from "../../../../api/customer/customerModel";
import {CustomerApp} from "../../../../api/customer_app/customerAppModel";
//libs
//components
import NavBarTop from "../../../_components/NavBarTop";
import CustomerNavBarMenu from "../../_components/CustomerNavBarMenu";
import SnackBar from "../../../_components/SnackBar";
import Footer from "../../../_components/Footer";
import CustomerAppsBanner1 from "./banner_1";
import CustomerAppsSection1 from "./section_1";
//types
import {ErrorException, Lang} from "../../../../types";
import {SystemSnackbar} from "../../../../store/actions/system.ac";

//interfaces
interface Props {
    lang: Lang;
    customer: Customer;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
    deleteCustomer?: () => void;
}

//const
const TAG = `CustomerAppsView`;
const isDev = (process.env.NODE_ENV !== "production");
//view
const CustomerAppsView = (props: Props) => {
    //hooks
    //refs
    //state
    const [customer_apps, setCustomerApps] = useState<Array<CustomerApp>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    //effects
    useEffect(() => {
        getData();
        return () => {

        }
    }, []);
    useEffect(() => {
        if (props.customer && props.customer.id && typeof props.customer.id !== "undefined") {
            getCustomerApp(Number(props.customer.id))
        }
    }, [props.customer]);

    const getData = async () => {
        try {

        } catch (error: ErrorException | any) {
            isDev && console.error(TAG + "//getData", error);
        }
    }

    const getCustomerApp = async (customer_id: number) => {
        setIsLoading(true);
        isDev && console.log(TAG + "//getCustomerApp", "Cargando documentos del cliente...");
        await CustomerAppAPI.getByCustomerId(customer_id, "es").then(async res_1 => {
                isDev && console.log(TAG + "//getCustomerApp", res_1.code, res_1.message, res_1.error);
                if (res_1.code === 200) {
                    setCustomerApps(res_1.customer_apps as Array<CustomerApp>);
                } else if (res_1.code === 409) {

                } else {
                    props.showSnackbar && props.showSnackbar({
                        message: res_1.message,
                        severity: "warning"
                    });
                }
            }
        ).catch(async res_1 => {
            isDev && console.error(TAG + "//getCustomerApp", res_1.code, res_1.error, res_1);
            props.showSnackbar && props.showSnackbar({
                message: res_1.error ? res_1.error : JSON.stringify(res_1.error),
                severity: "warning"
            });
        });
        setIsLoading(false)
    }

    return (
        <div
            className={"CustomerAppsView"}
        >
            <NavBarTop
                lang={props.lang}
                customer={props.customer}
                deleteCustomer={props.deleteCustomer}
            />
            <CustomerNavBarMenu
                lang={props.lang}
            />
            <CustomerAppsBanner1
                lang={props.lang}
            />
            <CustomerAppsSection1
                lang={props.lang}
                customer_apps={customer_apps}
                isLoading={isLoading}
            />
            <Footer
                lang={props.lang}
                customer={props.customer}
            />
            <SnackBar/>
        </div>
    )
}
export default CustomerAppsView;
