import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexAppAdminGroupSection3 = (props: Props) => {
    //state
    const [indexGroup, setIndexGroup] = useState<Array<boolean>>([false, false, false, false, false, false, false]);

    const setIndexGroupFocus = (index: number) => {
        let indexGroupNew: Array<boolean> = [...indexGroup];
        for (let i = 0; i < indexGroupNew.length; i++) {
            if (i === index) {
                indexGroupNew[i] = !indexGroupNew[i];
            } else {
                indexGroupNew[i] = false;
            }
        }
        setIndexGroup(indexGroupNew);
    }

    return (
        <div
            className={"IndexAppAdminGroupSection3"}
        >
            <div
                className={"IndexAppAdminGroupSection3Title"}
            >
                {props.lang === "es" && <h1
                >
                    Preguntas Frecuentes
                </h1>}
                {props.lang === "en" && <h1
                >
                    FAQ
                </h1>}
            </div>
            <section
                className={"IndexAppAdminGroupSection3Sec"}
            >
                <button
                    className={"IndexAppAdminGroupSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(0)}
                >
                    {props.lang === "es" && <h2>
                        ¿Se puede asignar varios conductores en diferentes grupos?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can you assign multiple conductors on different groups?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[0] ? "IndexAppAdminGroupSection3SecGroupFocus" : "IndexAppAdminGroupSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Los conductores pueden ser asignados en diferentes canales aunque ya exista en otro canal.
                    </p>}
                    {props.lang === "en" && <p>
                        Drivers can be assigned on different groups even if it already exists on another group.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminGroupSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(1)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo enviar emojis a en el chat?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I send emojis to in chat?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[1] ? "IndexAppAdminGroupSection3SecGroupFocus" : "IndexAppAdminGroupSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Se puede enviar texto y emojis como un chat enriquecido
                    </p>}
                    {props.lang === "en" && <p>
                        You can send text and emojis as a rich chat
                    </p>}
                </div>

            </section>
        </div>
    )
}
export default IndexAppAdminGroupSection3;
