import React from "react";
//theme
import "./styles.scss";
//libs
import {useHistory} from "react-router-dom";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppPassengerSection7 = (props: Props, ref: any) => {
    //hooks
    const history = useHistory();

    return (
        <div
            ref={ref}
            className={"IndexAppPassengerSection7Content"}
        >
            <div
                className={"IndexAppPassengerSection7 js-scroll slide-left"}
            >
                <div
                    className={"IndexAppPassengerSection7Img"}
                >
                    <img
                        src={require("../../../../../assets/img/passenger_14.png").default}
                        alt={"dvgeo_passenger_14"}
                    />
                </div>
                <section
                    className={"IndexAppPassengerSection7Sec"}
                >
                    {props.lang === "es" && <h1>
                        <strong>Califica</strong> a tu conductor
                    </h1>}
                    {props.lang === "en" && <h1>
                        <strong>Rate</strong> your driver
                    </h1>}
                    {props.lang === "es" && <p>
                        Puedes calificar con <strong>estrellas y un comentario (opcional)</strong> cuando el conductor finalice el viaje
                    </p>}
                    {props.lang === "en" && <p>
                        You can rate with <strong>stars and a comment (optional)</strong> when the driver finishes the trip
                    </p>}
                </section>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppPassengerSection7);
