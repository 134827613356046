import React, {useEffect, useRef} from "react";
//theme
import "../styles.scss";
//models
import {Customer} from "../../../../api/customer/customerModel";
//components
import NavBarTop from "../../../_components/NavBarTop";
import NavBarMenu from "../../../_components/NavBarMenu";
import Footer from "../../../_components/Footer";
//views
import IndexHomeSection2 from "../../home/_components/section_2";
import IndexPriceBanner1 from "../_components/banner_1";
import IndexPriceSection1 from "../_components/section_1";
import IndexPriceSection2 from "../_components/section_2";
//interfaces
interface Props {
    customer: Customer;
    deleteCustomer?: () => void;
    deleteToken?: () => void;
}
//view
const IndexPriceEs = (props: Props) => {
    //refs
    const itemsRef = useRef<Array<any>>([]);
    const IndexPriceRef = useRef<any>(null);
    //effects
    useEffect(() => {
        document.title = `Precios accesibles - Sin contratos, sin tarifas ocultas, sin inversiones costosas ... ¡Lo que necesitas! | DvGeo Platform`
        IndexPriceRef.current && IndexPriceRef.current.addEventListener('scroll', () => {
            handleScrollAnimation();
        })
        return () => {
            IndexPriceRef.current && IndexPriceRef.current.removeEventListener('scroll', () => {
                handleScrollAnimation();
            })
        }
    }, []);

    const scrollOffset = 100;

    const elementInView = (el: any, offset = 0) => {
        const elementTop = el.getBoundingClientRect().top;
        return (
            elementTop <= (IndexPriceRef.current.clientHeight - offset)
        );
    };

    const displayScrollElement = (element: any) => {
        element.childNodes[0].classList.add("scrolled");
    };


    const hideScrollElement = (element: any) => {
        element.childNodes[0].classList.remove('scrolled');
    }

    const handleScrollAnimation = () => {
        itemsRef.current && itemsRef.current.forEach((el: any) => {
            if (elementInView(el, scrollOffset)) {
                displayScrollElement(el);
            } else {
                hideScrollElement(el);
            }
        })
    }

    return (
        <div
            ref={IndexPriceRef}
            className={"IndexPrice"}
        >
            <NavBarTop
                lang={"es"}
                customer={props.customer}
                deleteCustomer={props.deleteCustomer}
                deleteToken={props.deleteToken}
            />
            <NavBarMenu
                lang={"es"}
            />
            <IndexPriceBanner1
                lang={"es"}
            />
            <IndexPriceSection1
                lang={"es"}
            />
            <IndexPriceSection2
                lang={"es"}
            />
            <IndexHomeSection2
                lang={"es"}
            />
            <Footer
                lang={"es"}
                customer={props.customer}
            />
        </div>
    )
}
export default IndexPriceEs;
