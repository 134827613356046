import React from "react";
//theme
import {Redirect, Route, Switch} from "react-router-dom";
//libs
//views
import AdminHomeEs from "./home/es";
import AdminHomeEn from "./home/en";
//const
//component
const AdminSwitch = () => {
    return (
        <Switch>
            {/*es*/}
            <Route exact path="/es/application/admin" component={AdminHomeEs}/>
            {/*en*/}
            <Route exact path="/en/application/admin" component={AdminHomeEn}/>
            {/*default*/}
            <Redirect from={"*"} to={"/es/application/admin"}/>
        </Switch>
    )
}
export default AdminSwitch;
