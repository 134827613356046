import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexAppAdminCouponSection3 = (props: Props) => {
    //state
    const [indexGroup, setIndexGroup] = useState<Array<boolean>>([false, false, false, false, false, false, false]);

    const setIndexGroupFocus = (index: number) => {
        let indexGroupNew: Array<boolean> = [...indexGroup];
        for (let i = 0; i < indexGroupNew.length; i++) {
            if (i === index) {
                indexGroupNew[i] = !indexGroupNew[i];
            } else {
                indexGroupNew[i] = false;
            }
        }
        setIndexGroup(indexGroupNew);
    }

    return (
        <div
            className={"IndexAppAdminCouponSection3"}
        >
            <div
                className={"IndexAppAdminCouponSection3Title"}
            >
                {props.lang === "es" && <h1
                >
                    Preguntas Frecuentes
                </h1>}
                {props.lang === "en" && <h1
                >
                    FAQ
                </h1>}
            </div>
            <section
                className={"IndexAppAdminCouponSection3Sec"}
            >
                <button
                    className={"IndexAppAdminCouponSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(0)}
                >
                    {props.lang === "es" && <h2>
                        ¿Existe un límite de cupones?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Is there a coupon limit?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[0] ? "IndexAppAdminCouponSection3SecGroupFocus" : "IndexAppAdminCouponSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        No existe límite, puedes crear los cupones de descuento que desees.
                    </p>}
                    {props.lang === "en" && <p>
                        There is no limit, you can create the discount coupons you want.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminCouponSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(1)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo asignar cupones al pasajero desde el aplicativo Admin?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I assign coupons to the passenger from the Admin application?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[1] ? "IndexAppAdminCouponSection3SecGroupFocus" : "IndexAppAdminCouponSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Si, el pasajero puede hacerlo desde su dispositivo como tambíen el administrador puede asignarlo desde el módulo de pasajeros
                    </p>}
                    {props.lang === "en" && <p>
                        Yes, the passenger can do it from his device as well as the administrator can assign it from the passenger module
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminCouponSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(1)}
                >
                    {props.lang === "es" && <h2>
                        ¿Se puede limitar a cierto número usuarios por cupón?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can it be limited to a certain number of users per coupon?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[1] ? "IndexAppAdminCouponSection3SecGroupFocus" : "IndexAppAdminCouponSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Se puede limitar a un cierto alcance, también cabe recalcar que un usuarios solo puede aplicar el cupón una sola vez.
                    </p>}
                    {props.lang === "en" && <p>
                        It can be limited to a certain scope, it should also be noted that a user can only apply the coupon once.
                    </p>}
                </div>
            </section>
        </div>
    )
}
export default IndexAppAdminCouponSection3;
