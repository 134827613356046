import React, {useEffect, useRef, useState} from "react";
//theme
import "./view.scss";
//store
//models
import {Customer} from "../../../../../api/customer/customerModel";
//libs
import {useHistory, useParams} from "react-router-dom";
//components
import NavBarTop from "../../../../_components/NavBarTop";
import CustomerNavBarMenu from "../../../_components/CustomerNavBarMenu";
import SnackBar from "../../../../_components/SnackBar";
import Footer from "../../../../_components/Footer";
import CustomerSupportTrackingNewViewBanner1 from "./banner_1";
//types
import {ErrorException, Lang} from "../../../../../types";
import {SystemSnackbar} from "../../../../../store/actions/system.ac";
import CustomerSupportNewForm1 from "./form_1";

//interfaces
interface Props {
    lang: Lang;
    customer: Customer;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
    deleteCustomer?: () => void;
}

//const
const TAG = `CustomerSupportTrackingNewView`;
const isDev = (process.env.NODE_ENV !== "production");
//view
const CustomerSupportTrackingNewView = (props: Props) => {
    //hooks
    const history = useHistory();
    //refs
    const CustomerSupportTrackingNewViewRef = useRef<any>(null);
    //state
    //effects
    useEffect(() => {
        getData();
        return () => {

        }
    }, []);

    const getData = async () => {
        try {

        } catch (error: ErrorException | any) {
            isDev && console.error(TAG + "//getData", error);
        }
    }


    return (
        <div
            ref={CustomerSupportTrackingNewViewRef}
            className={"CustomerSupportTrackingNewView"}
        >
            <NavBarTop
                lang={props.lang}
                customer={props.customer}
                deleteCustomer={props.deleteCustomer}
            />
            <CustomerNavBarMenu
                lang={props.lang}
            />
            <CustomerSupportTrackingNewViewBanner1
                lang={props.lang}
            />
            <CustomerSupportNewForm1
                lang={props.lang}
                customer={props.customer}
                showSnackbar={props.showSnackbar}
                hideSnackbar={props.hideSnackbar}
            />
            <Footer
                lang={props.lang}
                customer={props.customer}
            />
            <SnackBar/>
        </div>
    )
}
export default CustomerSupportTrackingNewView;
