import React, {useEffect, useRef, useState} from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppPassengerBanner2 = (props: Props) => {
    //refs
    const iTimer = useRef<NodeJS.Timeout | number>(0);
    const indexAppAdminServiceSection1DesktopSnapContentRef = useRef<any>(null);
    const items = useRef<Array<any>>([]);
    //state
    const [index, setIndex] = useState<number>(0);
    const [screen, setScreen] = useState<"mobile" | "tablet" | "desktop" | "fullHD">("mobile");
    //effects
    useEffect(() => {
        init();
        if ('onorientationchange' in window) {
            window.addEventListener("orientationchange", () => getScreenType(), false);
        }
        window.addEventListener('resize', () => getScreenType());
        return () => {
            clearIntervals();
            window.removeEventListener("orientationchange", () => getScreenType());
            window.addEventListener('resize', () => getScreenType());
        }
    }, []);

    const getScreenType = () => {
        window.matchMedia("(max-width: 600px)").matches && setScreen("mobile");
        window.matchMedia("(min-width: 800px)").matches && setScreen("tablet");
        window.matchMedia("(min-width: 1000px)").matches && setScreen("desktop");
        window.matchMedia("(min-width: 1900px)").matches && setScreen("fullHD");
    }

    const init = () => {
        try {
            getScreenType();
            iIndexTranslateX();
        } catch (error: any) {

        }
    }


    const iIndexTranslateX = () => {
        setIndex(0);
        setTranslateXValid(0);
        clearInterval(iTimer.current as NodeJS.Timeout);
        let indexCurrent = 0;
        iTimer.current = setInterval(() => {
            if (indexCurrent >= 0 && indexCurrent <= 2) {
                indexCurrent++;
                setTranslateXValid(indexCurrent);
                setIndex(indexCurrent);
            } else {
                iIndexTranslateX();
                indexCurrent = 0;
                setIndex(0);
            }
        }, 6000);
    }

    const clearIntervals = () => {
        clearInterval(iTimer.current as NodeJS.Timeout);
        iTimer.current = 0;
    }

    const setTranslateX = (index: number, value: string) => {
        try {
            items.current[index].style.transform = value;
        } catch (error: any) {
            setTimeout(() => setTranslateX(index, value), 1000 / 5);
        }
    }

    const setTranslateXValid = (index: number) => {
        if (index === 0) {
            setTimeout(() => setTranslateX(0, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-0vw)`), 1000 / 5);
        }
        if (index === 1) {
            setTimeout(() => setTranslateX(0, `translateX(-20vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-20vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-20vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-20vw)`), 1000 / 5);
        }
        if (index === 2) {
            setTimeout(() => setTranslateX(0, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-40vw)`), 1000 / 5);
        }
        if (index === 3) {
            setTimeout(() => setTranslateX(0, `translateX(-60vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-60vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-60vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-60vw)`), 1000 / 5);
        }
    }

    return (
        <div
            className={"IndexAppPassengerBanner2"}
        >
            {/*apps en modo mobile*/}
            <div
                className={"IndexAppPassengerBanner2Mobile"}
            >
                {props.lang === "es" && <h1
                    className={"IndexAppPassengerBanner2Title"}
                >
                    ¿Cómo funciona?
                </h1>}
                {props.lang === "en" && <h1
                    className={"IndexAppPassengerBanner2Title"}
                >
                    How does it work?
                </h1>}
                <section
                    className={"IndexAppPassengerBanner2MobileSec"}
                >
                    <button
                        className={"IndexAppPassengerBanner2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppPassengerBanner2DesktopSnapControlBtnSpan"}
                        >
                           1
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Especifique la ubicación
                            </h6>}
                            {props.lang === "en" && <h6>
                                Specify the location
                            </h6>}
                            {props.lang === "es" && <label>
                                La ruta consiste de un punto de recogida y de un punto de destino.
                            </label>}
                            {props.lang === "en" && <label>
                                The route consists of a collection point and a destination point.
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppPassengerBanner2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/passenger_9.png").default}
                            alt={"dvgeo_passenger_9"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppPassengerBanner2MobileSec"}
                >
                    <button
                        className={"IndexAppPassengerBanner2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppPassengerBanner2DesktopSnapControlBtnSpan"}
                        >
                           2
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Ofrezca su precio
                            </h6>}
                            {props.lang === "en" && <h6>
                                Offer your price
                            </h6>}
                            {props.lang === "es" && <label>
                                El pasajero puede ofertar el precio o bien solicitar con el precio por defecto que se calcula con taxímetro.
                            </label>}
                            {props.lang === "en" && <label>
                                The passenger can offer the price or request with the default price that is calculated with the meter.
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppPassengerBanner2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/passenger_8.png").default}
                            alt={"dvgeo_passenger_8"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppPassengerBanner2MobileSec"}
                >
                    <button
                        className={"IndexAppPassengerBanner2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppPassengerBanner2DesktopSnapControlBtnSpan"}
                        >
                           3
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Búsqueda de conductores
                            </h6>}
                            {props.lang === "en" && <h6>
                                Driver search
                            </h6>}
                            {props.lang === "es" && <label>
                                Comenzaremos a buscar  de forma automática los conductores cercanos en una distancia estimada entre 5 a 15 minutos.
                            </label>}
                            {props.lang === "en" && <label>
                                We will begin to automatically search for nearby drivers in an estimated distance between 5 to 15 minutes.
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppPassengerBanner2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/passenger_10.png").default}
                            alt={"dvgeo_passenger_10"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppPassengerBanner2MobileSec"}
                >
                    <button
                        className={"IndexAppPassengerBanner2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppPassengerBanner2DesktopSnapControlBtnSpan"}
                        >
                           4
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Comienzo del viaje
                            </h6>}
                            {props.lang === "en" && <h6>
                                Start of the trip
                            </h6>}
                            {props.lang === "es" && <label>
                                Una vez confirmada la solicitud, la ubicación del conductor se enlazará para seguir su ruta en tiempo real.
                            </label>}
                            {props.lang === "en" && <label>
                                Once the request is confirmed, the driver's location will be linked to follow his route in real time.
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppPassengerBanner2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/passenger_11.png").default}
                            alt={"dvgeo_passenger_11"}
                        />
                    </div>
                </section>
            </div>
            {/*apps en modo desktop*/}
            <div
                className={"IndexAppPassengerBanner2Desktop"}
            >
                <div
                    ref={indexAppAdminServiceSection1DesktopSnapContentRef}
                    className={"IndexAppPassengerBanner2DesktopSnapControl"}
                >
                    {props.lang === "es" && <h1
                        className={"IndexAppPassengerBanner2Title"}
                    >
                        ¿Cómo funciona?
                    </h1>}
                    {props.lang === "en" && <h1
                        className={"IndexAppPassengerBanner2Title"}
                    >
                        How does it work?
                    </h1>}
                    <button
                        className={index === 0 ? "IndexAppPassengerBanner2DesktopSnapControlBtnFocus" : "IndexAppPassengerBanner2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(0);
                            setIndex(0);
                            clearIntervals();
                        }}
                    >
                        <span
                            className={index === 0 ? "IndexAppPassengerBanner2DesktopSnapControlBtnSpanFocus" : "IndexAppPassengerBanner2DesktopSnapControlBtnSpan"}
                        >
                           1
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Especifique la ubicación
                            </h6>}
                            {props.lang === "en" && <h6>
                                Specify the location
                            </h6>}
                            {props.lang === "es" && <label>
                                La ruta consiste de un punto de recogida y de un punto de destino.
                            </label>}
                            {props.lang === "en" && <label>
                                The route consists of a collection point and a destination point.
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 1 ? "IndexAppPassengerBanner2DesktopSnapControlBtnFocus" : "IndexAppPassengerBanner2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(1);
                            setIndex(1);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 1 ? "IndexAppPassengerBanner2DesktopSnapControlBtnSpanFocus" : "IndexAppPassengerBanner2DesktopSnapControlBtnSpan"}
                       >
                           2
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Ofrezca su precio
                            </h6>}
                            {props.lang === "en" && <h6>
                                Offer your price
                            </h6>}
                            {props.lang === "es" && <label>
                                El pasajero puede ofertar el precio o bien solicitar con el precio por defecto que se calcula con taxímetro.
                            </label>}
                            {props.lang === "en" && <label>
                                The passenger can offer the price or request with the default price that is calculated with the meter.
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 2 ? "IndexAppPassengerBanner2DesktopSnapControlBtnFocus" : "IndexAppPassengerBanner2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(2);
                            setIndex(2);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 2 ? "IndexAppPassengerBanner2DesktopSnapControlBtnSpanFocus" : "IndexAppPassengerBanner2DesktopSnapControlBtnSpan"}
                       >
                           3
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Búsqueda de conductores
                            </h6>}
                            {props.lang === "en" && <h6>
                                Driver search
                            </h6>}
                            {props.lang === "es" && <label>
                                Comenzaremos a buscar  de forma automática los conductores cercanos en una distancia estimada entre 5 a 15 minutos.
                            </label>}
                            {props.lang === "en" && <label>
                                We will begin to automatically search for nearby drivers in an estimated distance between 5 to 15 minutes.
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 3 ? "IndexAppPassengerBanner2DesktopSnapControlBtnFocus" : "IndexAppPassengerBanner2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(3);
                            setIndex(3);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 3 ? "IndexAppPassengerBanner2DesktopSnapControlBtnSpanFocus" : "IndexAppPassengerBanner2DesktopSnapControlBtnSpan"}
                       >
                           4
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Comienzo del viaje
                            </h6>}
                            {props.lang === "en" && <h6>
                                Start of the trip
                            </h6>}
                            {props.lang === "es" && <label>
                                Una vez confirmada la solicitud, la ubicación del conductor se enlazará para seguir su ruta en tiempo real.
                            </label>}
                            {props.lang === "en" && <label>
                                Once the request is confirmed, the driver's location will be linked to follow his route in real time.
                            </label>}
                        </section>
                    </button>
                </div>
                <div
                    ref={indexAppAdminServiceSection1DesktopSnapContentRef}
                    className={"IndexAppPassengerBanner2DesktopSnapContent"}
                >

                    <section
                        ref={ref => items.current[0] = ref}
                        className={"IndexAppPassengerBanner2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/passenger_9.png").default}
                            alt={"dvgeo_passenger_9"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[1] = ref}
                        className={"IndexAppPassengerBanner2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/passenger_8.png").default}
                            alt={"dvgeo_passenger_8"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[2] = ref}
                        className={"IndexAppPassengerBanner2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/passenger_10.png").default}
                            alt={"dvgeo_passenger_10"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[3] = ref}
                        className={"IndexAppPassengerBanner2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/passenger_11.png").default}
                            alt={"dvgeo_passenger_11"}
                        />
                    </section>
                </div>
            </div>
        </div>
    )
}
export default IndexAppPassengerBanner2;
