import React, {useEffect, useRef, useState} from "react";
//theme
import {Platform} from "react-native";
//api
import {CustomerSupportTypeAPI} from "../../../../api/customer_support_type/customerSupportTypeAPI";
//models
import {CustomerSupportType} from "../../../../api/customer_support_type/customerSupportTypeModel";
//libs
import ReactDOM from "react-dom";
//views
import TextInputAutocomplete, {TextInputAutocompleteItem} from "../../../_components/TextInputAutocomplete";
//types
import {ErrorException, Lang} from "../../../../types";

//interfaces
interface Props {
    lang: Lang;
    selectorRef?: React.RefObject<any> | undefined;
    label?: string;
    limit: string;
    defaultValue?: string;
    error?: string;
    size?: "small" | "normal" | "full";
    onSelectedValue?: (customer_support_type: CustomerSupportType) => void;
    onGetData?: (customer_support_types: Array<CustomerSupportType>) => void;
}

//const
const TAG = `${Platform.OS}//CustomerSupportTypeSelector`;
const isDev = (process.env.NODE_ENV !== "production");
//component
const CustomerSupportTypeSelector = (props: Props, ref: any) => {
    //refs
    const componentRef = useRef<any>(null);
    //state
    const [customer_support_types, setCustomerSupportTypes] = useState<Array<CustomerSupportType>>([]);
    const [customer_support_type, setCustomerSupportType] = useState<CustomerSupportType>({});
    const [fields, setFields] = useState<{ param: string }>({
        param: ""
    });
    const [errors, setErrors] = useState<{ param: string }>({
        param: ""
    });
    const [show, setShow] = useState<boolean>(false);
    const [index_selected, setIndexSelected] = useState<number>(0);
    const [service_id, setServiceId] = useState<number>(0);
    //effects
    useEffect(() => {
        //componentDidMount
        isDev && console.log(TAG + "//getCustomerSupportTypes", "componentDidMount");
        getData();
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            //componentWillUnmount
            isDev && console.log(TAG + "//getCustomerSupportTypes", "componentWillUnmount");
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, []);
    useEffect(() => {
        getData();
    }, [props.limit]);

    const getData = async () => {
        try {
            await getCustomerSupportTypes();
        } catch (error: ErrorException | any) {
            isDev && console.error(TAG + "//getData", error);
        }
    }

    const handleClickOutside = (event: MouseEvent) => {
        const domNode = ReactDOM.findDOMNode(componentRef.current);
        if (!domNode || !domNode.contains(event.target as Node)) {
            setShow(false);
        }
    }

    const _onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>, data: Array<CustomerSupportType>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            // @ts-ignore
            e.target.blur();
            setShow(false);
            props.onSelectedValue && props.onSelectedValue(customer_support_type);
        }
        if (e.key === "ArrowUp" && index_selected) {
            let item: CustomerSupportType = {};
            if (index_selected === 0 && !customer_support_type) {
                item = data[0]
                setIndexSelected(0);
            } else if (index_selected >= 0 && index_selected < data.length) {
                item = data[index_selected - 1];
                setIndexSelected(index_selected - 1);
            } else {
                item = data[0]
                setIndexSelected(0);
            }
            setCustomerSupportType(item);
        }
        if (e.key === "ArrowDown") {
            let item: CustomerSupportType = {};
            if (index_selected === 0 && !customer_support_type) {
                item = data[0]
                setIndexSelected(0);
            } else if (index_selected >= 0 && index_selected < data.length - 1) {
                item = data[index_selected + 1];
                setIndexSelected(index_selected + 1);
            } else {
                item = data[0]
                setIndexSelected(0);
            }
            setCustomerSupportType(item);
        }
    }

    const getCustomerSupportTypes = async () => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            param: "",
        }));
        isDev && console.log(TAG + "//getCustomerSupportTypes", "Obteniendo los conductores...");
        await CustomerSupportTypeAPI.getByAll(props.lang).then(async res => {
            isDev && console.log(TAG + "//getCustomerSupportTypes", res.code, res.message, res.error);
            if (res.code === 200) {
                setCustomerSupportTypes(res.customer_support_types as Array<CustomerSupportType>);
                props.onGetData && props.onGetData(res.customer_support_types as Array<CustomerSupportType>);
            } else {
                setCustomerSupportTypes([]);
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    param: res.message,
                }));
            }
        }).catch(async res => {
            isDev && console.error(TAG + "//getCustomerSupportTypes", res.code, res.error, res);
            setCustomerSupportTypes([]);
            setErrors((prevErrors) => ({
                ...prevErrors,
                param: res.error ? res.error : JSON.stringify(res),
            }));
        });
    }

    const getCustomerSupportTypesBySearch = async (param: string) => {
        setFields((prevFields) => ({
            ...prevFields,
            param,
        }));
        if (!param) {
            getCustomerSupportTypes();
            return;
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            param: "",
        }));
        isDev && console.log(TAG + "//getCustomerSupportTypesBySearch", "Obteniendo los conductores...");
        await CustomerSupportTypeAPI.getBySearch(param, props.lang).then(async res => {
            isDev && console.log(TAG + "//getCustomerSupportTypesBySearch", res.code, res.message, res.error);
            if (res.code === 200) {
                setCustomerSupportTypes(res.customer_support_types as Array<CustomerSupportType>);
                props.onGetData && props.onGetData(res.customer_support_types as Array<CustomerSupportType>);
            } else {
                setCustomerSupportTypes([]);
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    param: res.message,
                }));
            }
        }).catch(async res => {
            isDev && console.error(TAG + "//getCustomerSupportTypesBySearch", res.code, res.error, res);
            setCustomerSupportTypes([]);
            setErrors((prevErrors) => ({
                ...prevErrors,
                param: res.error ? res.error : JSON.stringify(res),
            }));
        });
    }

    const getDefaultValue = (id: number): CustomerSupportType => {
        return customer_support_types.filter((element) => element.id === id)[0];
    }

    return (
        <div
            ref={componentRef}
            onKeyDown={(e) => _onKeyPress(e, customer_support_types)}
        >
            <TextInputAutocomplete
                inputRef={props.selectorRef}
                label={props.label ? props.label : "Departamento:"}
                placeholder={getDefaultValue(Number(props.defaultValue))?.type ? getDefaultValue(Number(props.defaultValue))?.type : customer_support_type.type ? customer_support_type.type : "Seleccione el departamento..."}
                value={fields.param}
                size={props.size}
                data={customer_support_types}
                renderItem={({item, index}) => {
                    const customer_support_type_: CustomerSupportType = item;
                    return (
                        <TextInputAutocompleteItem
                            key={`${TAG}customer_support_type_${customer_support_type_.id}_${index}`}
                            focus={(index_selected === index)}
                            onSelect={async () => {
                                setIndexSelected(index);
                                setCustomerSupportType(customer_support_type_);
                                setShow(false);
                                setFields(() => ({
                                    param: ""
                                }));
                                props.onSelectedValue && props.onSelectedValue(customer_support_type_);
                                await getCustomerSupportTypes();
                            }}
                        >
                            <div
                                style={{
                                    display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        paddingLeft: 10,
                                        flex: 1,
                                        cursor: "pointer"
                                    }}
                                >
                                    <label
                                        style={{
                                            fontWeight: "bold",
                                            maxWidth: "80%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            cursor: "pointer"
                                        }}
                                    >
                                        {customer_support_type_.type}
                                    </label>
                                </div>
                            </div>
                        </TextInputAutocompleteItem>
                    )
                }}
                showRenderItem={show}
                onChangeText={(text) => getCustomerSupportTypesBySearch(text)}
                onKeyPress={(e) => {
                    // @ts-ignore
                    if (e.key === "Enter") e.target.blur();
                }}
                onFocus={() => {
                    setShow(true);
                }}
                onBlur={() => {
                    //setShow(false);
                }}
                error={props.error ? props.error : errors?.param}
            />
        </div>
    )
}

export default React.forwardRef(CustomerSupportTypeSelector);
