import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexAppAdminUserSection3 = (props: Props) => {
    //state
    const [indexGroup, setIndexGroup] = useState<Array<boolean>>([false, false, false, false, false, false, false]);

    const setIndexGroupFocus = (index: number) => {
        let indexGroupNew: Array<boolean> = [...indexGroup];
        for (let i = 0; i < indexGroupNew.length; i++) {
            if (i === index) {
                indexGroupNew[i] = !indexGroupNew[i];
            } else {
                indexGroupNew[i] = false;
            }
        }
        setIndexGroup(indexGroupNew);
    }

    return (
        <div
            className={"IndexAppAdminUserSection3"}
        >
            <div
                className={"IndexAppAdminUserSection3Title"}
            >
                {props.lang === "es" && <h1
                >
                    Preguntas Frecuentes
                </h1>}
                {props.lang === "en" && <h1
                >
                    FAQ
                </h1>}
            </div>
            <section
                className={"IndexAppAdminUserSection3Sec"}
            >
                <button
                    className={"IndexAppAdminUserSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(0)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo crear los usuarios pasajeros y conductores desde el aplicativo administrador?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I create passenger and driver users from the administrator application?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[0] ? "IndexAppAdminUserSection3SecGroupFocus" : "IndexAppAdminUserSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Claro, pero es recomendable que cada usuario se registre desde su propia aplicación, recordar que las aplicaciones para pasajero y conductor estarán publicadas en Google Play Store y App Store.
                    </p>}
                    {props.lang === "en" && <p>
                        Sure, but it is recommended that each user register from their own application, remember that the applications for passenger and driver will be published in the Google Play Store and the App Store.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminUserSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(1)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo subir los documentos legales del conductor desde el aplicativo administrador?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I upload the driver's legal documents from the administrator application?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[1] ? "IndexAppAdminUserSection3SecGroupFocus" : "IndexAppAdminUserSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                       Se pueden subir todos los archivos o documentos que se solicitan en los aplicativos móviles tanto para pasajero como para conductor.
                    </p>}
                    {props.lang === "en" && <p>
                        You can upload all the files or documents that are requested in the mobile applications for both passenger and driver.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminUserSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(2)}
                >
                    {props.lang === "es" && <h2>
                        ¿Hay límites de usuarios? ¿Hasta cuantos conductores puedo registrar?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Are there user limits? Up to how many drivers can I register?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[2] ? "IndexAppAdminUserSection3SecGroupFocus" : "IndexAppAdminUserSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        No hay límites, la plataforma se adapta a la demanda y a su vez crece junto con la base de datos asignada para su empresa.
                    </p>}
                    {props.lang === "en" && <p>
                        There are no limits, the platform adapts to demand and in turn grows along with the database assigned to your company.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminUserSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(3)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo definir un porcentaje de comisión para cada conductor?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I define a commission percentage for each driver?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[3] ? "IndexAppAdminUserSection3SecGroupFocus" : "IndexAppAdminUserSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Si, se puede configurar un porcentaje de comisión por defecto, también puede definirlo por cada socio conductor.
                    </p>}
                    {props.lang === "en" && <p>
                        Yes, you can configure a default commission percentage, you can also define it for each driving partner.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminUserSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(4)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo devolver el valor de la comisión cobrada al conductor?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I return the value of the commission charged to the driver?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[4] ? "IndexAppAdminUserSection3SecGroupFocus" : "IndexAppAdminUserSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Si, puede aplicar el método refund (reembolso) sobre la comisión para que el valor se le devuelva al saldo del conductor.
                    </p>}
                    {props.lang === "en" && <p>
                        Yes, you can apply the refund method on the commission so that the value is returned to the driver's balance.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminUserSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(5)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo cambiar la contraseña de algún usuario en específico?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I change the password of a specific user?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[5] ? "IndexAppAdminUserSection3SecGroupFocus" : "IndexAppAdminUserSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Solo se puede modificar la información si el usuario pasajero o conductor lo autoriza, para el resto de usuarios internos de su empresa si puede realizar cambios a medida. La contraseña solo puede ser reseteada, es decir, se genera una clave de letras y números aleatoreas.
                    </p>}
                    {props.lang === "en" && <p>
                        You can only modify the information if the passenger or driver user authorizes it, for the rest of the internal users of your company if you can make custom changes. The password can only be reset, that is, a key of random letters and numbers is generated.
                    </p>}
                </div>
            </section>
        </div>
    )
}
export default IndexAppAdminUserSection3;
