import React, {FormEvent, useEffect, useRef, useState} from "react";
//theme
import "./styles.scss";
//api
import {CustomerAPI} from "../../../../../api/customer/customerAPI";
//models
import {Customer} from "../../../../../api/customer/customerModel";
//libs
import valid from "../../../../../libs/valid";
import {CircularProgress} from "@material-ui/core";
//components
import TextInput from "../../../../_components/TextInput";
//types
import {Lang} from "../../../../../types";
import {SystemSnackbar} from "../../../../../store/actions/system.ac";

type Fields = {
    business_name: string;
    name: string;
    mobile: string;
    email: string;
    country: string;
    region: string;
};
type Errors = {
    business_name?: string;
    name?: string;
    mobile?: string;
    email?: string;
    country?: string;
    region?: string;
};

//interfaces
interface Props {
    lang: Lang;
    customer: Customer;
    showSnackbar?: (system: SystemSnackbar) => void;
    hideSnackbar?: () => void;
}

//const
const TAG = `CustomerCompanyViewForm1`;
const isDev = (process.env.NODE_ENV !== "production");
//component
const CustomerCompanyViewForm1 = (props: Props) => {
    //refs
    const inputsRef = useRef<Array<any>>([]);
    //state
    const [customer, setCustomer] = useState<Customer>(props.customer);
    const [fields, setFields] = useState<Fields>({
        business_name: "",
        name: "",
        mobile: "",
        email: "",
        country: "",
        region: "",
    });
    const [errors, setErrors] = useState<Errors>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    //effects
    useEffect(() => {
        setCustomer(props.customer);
        props.customer && setFields((prvFields) => ({
            ...prvFields,
            business_name: props.customer.business_name as string,
            name: props.customer.name as string,
            mobile: props.customer.mobile as string,
            email: props.customer.email as string,
            country: props.customer.country as string,
            region: props.customer.region as string,
        }));
    }, [props.customer]);

    const validForm = (id: keyof Fields, value: string) => {
        switch (id) {
            case "business_name": {
                setFields((prevFields) => ({
                    ...prevFields,
                    business_name: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 3) {
                    inputsRef.current[0] && inputsRef.current[0]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        business_name: props.lang === "es" ? "Mínimo 4 caracteres..." : props.lang === "en" ? "Minimum 4 characters" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        business_name: "",
                    }));
                    return true;
                }
            }
            case "name": {
                setFields((prevFields) => ({
                    ...prevFields,
                    name: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 3) {
                    inputsRef.current[1] && inputsRef.current[1]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        name: props.lang === "es" ? "Mínimo 4 caracteres..." : props.lang === "en" ? "Minimum 4 characters" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        name: "",
                    }));
                    return true;
                }
            }
            case "country": {
                setFields((prevFields) => ({
                    ...prevFields,
                    country: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "") {
                    inputsRef.current[2] && inputsRef.current[2]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        country: props.lang === "es" ? "Seleccione un país..." : props.lang === "en" ? "Select country" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        country: "",
                    }));
                    return true;
                }
            }
            case "region": {
                setFields((prevFields) => ({
                    ...prevFields,
                    region: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "") {
                    inputsRef.current[3] && inputsRef.current[3]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        region: props.lang === "es" ? "Seleccione una provincia..." : props.lang === "en" ? "Select region" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        region: "",
                    }));
                    return true;
                }
            }
            case "email": {
                setFields((prevFields) => ({
                    ...prevFields,
                    email: value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 2) {
                    inputsRef.current[4] && inputsRef.current[4]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email: props.lang === "es" ? "Ingrese una dirección de correo electrónico..." : props.lang === "en" ? "Enter an email address..." : "error",
                    }));
                    return false;
                } else if (!valid.isValidEmailAddress(value)) {
                    inputsRef.current[2] && inputsRef.current[2]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email: props.lang === "es" ? "Ingrese una dirección de correo electrónico válida..." : props.lang === "en" ? "Please enter a valid email address" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email: "",
                    }));
                    return true;
                }
            }
            case "mobile": {
                setFields((prevFields) => ({
                    ...prevFields,
                    mobile: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 3) {
                    inputsRef.current[5] && inputsRef.current[5]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        mobile: props.lang === "es" ? "Mínimo 4 caracteres..." : props.lang === "en" ? "Minimum 4 characters" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        mobile: "",
                    }));
                    return true;
                }
            }
            default : {
                return false
            }
        }
    }

    const save = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!customer || !customer.id) {
            props.showSnackbar && props.showSnackbar({
                message: "Vuelva a cargar la página...",
                severity: "error"
            });
            return;
        }
        let indexFields = 0;
        for (const field in fields) {
            if (!validForm(field as keyof Fields, Object.values(fields)[indexFields])) {
                return;
            }
            indexFields++;
        }
        setIsLoading(true);
        isDev && console.log(TAG + "//save", "Guardando...");
        await CustomerAPI.update({
            model: {
                ...fields,
                id: customer.id,
            }
        }, props.lang).then(async res_1 => {
            isDev && console.log(TAG + "//save", res_1.code, res_1.message, res_1.error);
            if (res_1.code === 200) {
                props.showSnackbar && props.showSnackbar({
                    message: res_1.message,
                    severity: "success"
                });
            } else {
                props.showSnackbar && props.showSnackbar({
                    message: res_1.message,
                    severity: "error"
                });
            }
        }).catch(async res_1 => {
            isDev && console.error(TAG + "//save", res_1.code, res_1.error, res_1);
            props.showSnackbar && props.showSnackbar({
                message: res_1.error ? res_1.error : JSON.stringify(res_1),
                severity: "error"
            });
        });
        setIsLoading(false);
    }

    return (
        <div
            className={"CustomerCompanyViewForm1"}
        >
            <form
                className={"CustomerCompanyViewForm1Form"}
                onSubmit={(e) => save(e)}
            >
                <section>
                    <TextInput
                        inputRef={inputsRef.current[0]}
                        label={props.lang === "es" ? "Nombre de la empresa:" : props.lang === "en" ? "Business name:" : ""}
                        placeholder={props.lang === "es" ? "Nombre de la empresa" : props.lang === "en" ? "Company name" : ""}
                        value={fields.business_name}
                        onChangeText={(text) => validForm("business_name", text)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                inputsRef.current[1] && inputsRef.current[1].focus();
                            }
                        }}
                        error={errors?.business_name}
                    />
                    <TextInput
                        inputRef={inputsRef.current[1]}
                        label={props.lang === "es" ? "Nombres:" : props.lang === "en" ? "Names:" : ""}
                        placeholder={props.lang === "es" ? "Nombre y Apellido" : props.lang === "en" ? "Name and surname" : ""}
                        value={fields.name}
                        onChangeText={(text) => validForm("name", text)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                inputsRef.current[2] && inputsRef.current[2].focus();
                            }
                        }}
                        error={errors?.name}
                    />
                    <TextInput
                        inputRef={inputsRef.current[2]}
                        label={props.lang === "es" ? "País:" : props.lang === "en" ? "Country:" : ""}
                        placeholder={props.lang === "es" ? "País de residencia" : props.lang === "en" ? "Country of residence" : ""}
                        value={fields.country}
                        onChangeText={(text) => validForm("country", text)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                inputsRef.current[3] && inputsRef.current[3].focus();
                            }
                        }}
                        error={errors?.country}
                    />
                </section>
                <section>
                    <TextInput
                        inputRef={inputsRef.current[3]}
                        label={props.lang === "es" ? "Provincia:" : props.lang === "en" ? "Region:" : ""}
                        placeholder={props.lang === "es" ? "Provincia de residencia" : props.lang === "en" ? "Province of residence" : ""}
                        value={fields.region}
                        onChangeText={(text) => validForm("region", text)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                inputsRef.current[4] && inputsRef.current[4].focus();
                            }
                        }}
                        error={errors?.region}
                    />
                    <TextInput
                        inputRef={inputsRef.current[4]}
                        label={props.lang === "es" ? "Correo electrónico:" : props.lang === "en" ? "Email:" : ""}
                        placeholder={props.lang === "es" ? "Correo electrónico" : props.lang === "en" ? "Email" : ""}
                        type={"email"}
                        value={fields.email}
                        onChangeText={(text) => validForm("region", text)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                inputsRef.current[5] && inputsRef.current[5].focus();
                            }
                        }}
                        error={errors?.email}
                    />
                    <TextInput
                        inputRef={inputsRef.current[5]}
                        label={props.lang === "es" ? "Teléfono:" : props.lang === "en" ? "Phone:" : ""}
                        placeholder={props.lang === "es" ? "Número móvil" : props.lang === "en" ? "Mobile number" : ""}
                        value={fields.mobile}
                        onChangeText={(text) => validForm("mobile", text)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                inputsRef.current[6] && inputsRef.current[6].focus();
                            }
                        }}
                        error={errors?.mobile}
                    />
                </section>
                <div
                    className={"CustomerCompanyViewForm1FormContentBtn"}
                >
                    <button
                        className={"CustomerCompanyViewForm1FormBtn"}
                        type={"submit"}
                        disabled={isLoading}
                    >
                        {!isLoading && props.lang === "es" && "Guardar"}
                        {!isLoading && props.lang === "en" && "Save"}

                        {isLoading && <CircularProgress
                            color={"inherit"}
                            size={20}
                        />}
                    </button>
                </div>
            </form>
        </div>
    )
}
export default CustomerCompanyViewForm1;
