import React, {useEffect, useRef, useState} from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
//views
import AppAdmin from "../app_admin";
//types
import {Lang} from "../../../../../types";
import AppPassenger from "../app_passenger";
import AppDriver from "../app_driver";

//interface
interface Props {
    lang: Lang;
    indexMenuApp: number;
    isStopIntervalIndexMenuApp: boolean;
    onChangeIndex: (index: number) => void;
}

//const
//component
const MenuAppContainer = (props: Props) => {
    //refs
    const iTimer = useRef<NodeJS.Timeout | number>(0);
    const items = useRef<Array<any>>([]);
    const appMenuContainerDesktopSnapContentRef = useRef<any>(null);
    //state
    const [index, setIndex] = useState<number>(0);
    //effects
    useEffect(() => {
        init();
        return () => {
            clearIntervals();
        }
    }, []);
    useEffect(() => {
        if (props.indexMenuApp !== index) {
            validIndexTranslateX(props.indexMenuApp);
        }
        setIndex(props.indexMenuApp);
        //appMenuContainerDesktopSnapContentRef.current && (appMenuContainerDesktopSnapContentRef.current.scrollLeft = items.current[props.indexMenuApp].offsetLeft)
    }, [props.indexMenuApp]);
    useEffect(() => {
        if (props.isStopIntervalIndexMenuApp) {
            clearIntervals();
        }
    }, [props.isStopIntervalIndexMenuApp]);

    const init = () => {
        try {
            iIndexTranslateX();
        } catch (error: any) {

        }
    }

    const iIndexTranslateX = () => {
        setIndex(0);
        props.onChangeIndex(0);
        validIndexTranslateX(0);
        clearInterval(iTimer.current as NodeJS.Timeout);
        let indexCurrent = 0;
        iTimer.current = setInterval(() => {
            if (indexCurrent >= 0 && indexCurrent <= 1) {
                indexCurrent++;
                props.onChangeIndex(indexCurrent);
                validIndexTranslateX(indexCurrent);
            } else {
                iIndexTranslateX();
                indexCurrent = 0;
            }
        }, 6000);
    }

    const clearIntervals = () => {
        clearInterval(iTimer.current as NodeJS.Timeout);
        iTimer.current = 0;
    }

    const setTranslateX = (index: number, value: string) => {
        try {
            items.current[index].style.transform = value;
        } catch (error: any) {
            setTimeout(() => setTranslateX(index, value), 1000 / 5);
        }
    }

    const validIndexTranslateX = (indexMenuApp: number) => {
        if (indexMenuApp > index) {
            if (props.indexMenuApp - 1 === 0) {
                setTimeout(() => setTranslateX(0, `translateX(-80vw)`), 1000 / 5);
                setTimeout(() => setTranslateX(1, `translateX(-80vw)`), 1000 / 5);
                setTimeout(() => setTranslateX(2, `translateX(-80vw)`), 1000 / 5);
            }
            if (props.indexMenuApp - 1 === 1) {
                setTimeout(() => setTranslateX(0, `translateX(-160vw)`), 1000 / 5);
                setTimeout(() => setTranslateX(1, `translateX(-160vw)`), 1000 / 3);
                setTimeout(() => setTranslateX(2, `translateX(-160vw)`), 1000 / 5);
            }
            if (props.indexMenuApp - 1 === 2) {
                setTimeout(() => setTranslateX(0, `translateX(-0vw)`), 1000 / 5);
                setTimeout(() => setTranslateX(1, `translateX(-0vw)`), 1000 / 5);
                setTimeout(() => setTranslateX(2, `translateX(-0vw)`), 1000 / 5);
            }
        } else {
            if (indexMenuApp + 1 === 0) {
                setTimeout(() => setTranslateX(0, `translateX(-160vw)`), 1000 / 5);
                setTimeout(() => setTranslateX(1, `translateX(-160vw)`), 1000 / 5);
                setTimeout(() => setTranslateX(2, `translateX(-160vw)`), 1000 / 5);
            }
            if (indexMenuApp + 1 === 1) {
                setTimeout(() => setTranslateX(0, `translateX(-0vw)`), 1000 / 5);
                setTimeout(() => setTranslateX(1, `translateX(-0vw)`), 1000 / 3);
                setTimeout(() => setTranslateX(2, `translateX(-0vw)`), 1000 / 5);
            }
            if (indexMenuApp + 1 === 2) {
                setTimeout(() => setTranslateX(0, `translateX(-80vw)`), 1000 / 5);
                setTimeout(() => setTranslateX(1, `translateX(-80vw)`), 1000 / 5);
                setTimeout(() => setTranslateX(2, `translateX(-80vw)`), 1000 / 5);
            }
        }
    }

    return (
        <div
            className={"MenuAppContainer"}
        >
            {/*apps en modo mobile*/}
            <div
                className={"MenuAppContainerMobile"}
            >
                <AppAdmin
                    lang={props.lang}
                />
                <AppPassenger
                    lang={props.lang}
                />
                <AppDriver
                    lang={props.lang}
                />
            </div>
            {/*apps en modo desktop*/}
            <div
                className={"MenuAppContainerDesktop"}
            >
                <button
                    className={"MenuAppContainerDesktopBtn"}
                    onClick={() => {
                        if (index === 0) {
                            setTimeout(() => setTranslateX(0, `translateX(-160vw)`), 1000 / 5);
                            setTimeout(() => setTranslateX(1, `translateX(-160vw)`), 1000 / 5);
                            setTimeout(() => setTranslateX(2, `translateX(-160vw)`), 1000 / 5);
                        }
                        if (index === 1) {
                            setTimeout(() => setTranslateX(0, `translateX(-0vw)`), 1000 / 5);
                            setTimeout(() => setTranslateX(1, `translateX(-0vw)`), 1000 / 5);
                            setTimeout(() => setTranslateX(2, `translateX(-0vw)`), 1000 / 5);
                        }
                        if (index === 2) {
                            setTimeout(() => setTranslateX(0, `translateX(-80vw)`), 1000 / 5);
                            setTimeout(() => setTranslateX(1, `translateX(-80vw)`), 1000 / 5);
                            setTimeout(() => setTranslateX(2, `translateX(-80vw)`), 1000 / 5);
                        }
                        if (index > 0) {
                            setIndex(index - 1);
                            props.onChangeIndex(index - 1);
                        } else {
                            setIndex(2);
                            props.onChangeIndex(2);
                        }
                        clearIntervals();
                    }}
                >
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                    />
                </button>
                <div
                    ref={appMenuContainerDesktopSnapContentRef}
                    className={"MenuAppContainerDesktopSnapContent"}
                >
                    <section
                        ref={ref => items.current[0] = ref}
                        className={"MenuAppContainerDesktopSec"}
                    >
                        <AppAdmin
                            lang={props.lang}
                        />
                    </section>
                    <section
                        ref={ref => items.current[1] = ref}
                        className={"MenuAppContainerDesktopSec"}
                    >
                        <AppPassenger
                            lang={props.lang}
                        />
                    </section>
                    <section
                        ref={ref => items.current[2] = ref}
                        className={"MenuAppContainerDesktopSec"}
                    >
                        <AppDriver
                            lang={props.lang}
                        />
                    </section>
                </div>
                <button
                    className={"MenuAppContainerDesktopBtn"}
                    onClick={() => {
                        if (index === 0) {
                            setTimeout(() => setTranslateX(0, `translateX(-80vw)`), 1000 / 5);
                            setTimeout(() => setTranslateX(1, `translateX(-80vw)`), 1000 / 5);
                            setTimeout(() => setTranslateX(2, `translateX(-80vw)`), 1000 / 5);
                        }
                        if (index === 1) {
                            setTimeout(() => setTranslateX(0, `translateX(-160vw)`), 1000 / 5);
                            setTimeout(() => setTranslateX(1, `translateX(-160vw)`), 1000 / 5);
                            setTimeout(() => setTranslateX(2, `translateX(-160vw)`), 1000 / 5);
                        }
                        if (index === 2) {
                            setTimeout(() => setTranslateX(0, `translateX(-0vw)`), 1000 / 5);
                            setTimeout(() => setTranslateX(1, `translateX(-0vw)`), 1000 / 5);
                            setTimeout(() => setTranslateX(2, `translateX(-0vw)`), 1000 / 5);
                        }
                        if (index <= 1) {
                            setIndex(index + 1);
                            props.onChangeIndex(index + 1);
                        } else {
                            setIndex(0);
                            props.onChangeIndex(0);
                        }
                        clearIntervals();
                    }}
                >
                    <FontAwesomeIcon
                        icon={faChevronRight}
                    />
                </button>
            </div>
        </div>
    )
}
export default MenuAppContainer;
