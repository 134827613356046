import React, {useEffect, useRef} from "react";
//theme
import "../styles.scss";
//models
import {Customer} from "../../../../api/customer/customerModel";
//components
import NavBarTop from "../../../_components/NavBarTop";
import NavBarMenu from "../../../_components/NavBarMenu";
import Footer from "../../../_components/Footer";
import IndexAppAdminBanner1 from "../_components/banner_1";
import IndexAppAdminSection1 from "../_components/section_1";
import IndexAppAdminSection2 from "../_components/section_2";
import IndexAppAdminSection3 from "../_components/section_3";
import IndexAppAdminSection4 from "../_components/section_4";
import IndexAppAdminSection5 from "../_components/section_5";
import IndexAppAdminSection6 from "../_components/section_6";
import IndexAppAdminSection7 from "../_components/section_7";
import IndexAppAdminSection8 from "../_components/section_8";
import IndexAppAdminSection9 from "../_components/section_9";
import IndexAppAdminSection10 from "../_components/section_10";
import IndexAppAdminSection11 from "../_components/section_11";
import IndexHomeSection2 from "../../home/_components/section_2";
//interfaces
interface Props {
    customer: Customer;
    deleteCustomer?: () => void;
    deleteToken?: () => void;
}
//view
const IndexAppAdminEn = (props: Props) => {
    //refs
    const itemsRef = useRef<Array<any>>([]);
    const IndexAppAdminRef = useRef<any>(null);
    //effects
    useEffect(() => {
        document.title = `Administrator Application - Includes dispatch system, online reservations, online office, recharges and corporate | DvGeo Platform`
        IndexAppAdminRef.current && IndexAppAdminRef.current.addEventListener('scroll', () => {
            handleScrollAnimation();
        })
        return () => {
            IndexAppAdminRef.current && IndexAppAdminRef.current.removeEventListener('scroll', () => {
                handleScrollAnimation();
            })
        }
    }, []);

    const scrollOffset = 100;

    const elementInView = (el: any, offset = 0) => {
        const elementTop = el.getBoundingClientRect().top;
        return (
            elementTop <= (IndexAppAdminRef.current.clientHeight - offset)
        );
    };

    const displayScrollElement = (element: any) => {
        element.childNodes[0].classList.add("scrolled");
    };


    const hideScrollElement = (element: any) => {
        element.childNodes[0].classList.remove('scrolled');
    }

    const handleScrollAnimation = () => {
        itemsRef.current && itemsRef.current.forEach((el: any) => {
            if (elementInView(el, scrollOffset)) {
                displayScrollElement(el);
            } else {
                hideScrollElement(el);
            }
        })
    }

    return (
        <div
            ref={IndexAppAdminRef}
            className={"IndexAppAdmin"}
        >
            <NavBarTop
                lang={"en"}
                customer={props.customer}
                deleteCustomer={props.deleteCustomer}
                deleteToken={props.deleteToken}
            />
            <NavBarMenu
                lang={"en"}
            />
            <IndexAppAdminBanner1
                ref={(ele) => itemsRef.current[0] = ele}
                lang={"en"}
            />
            <IndexAppAdminSection1
                ref={(ele) => itemsRef.current[1] = ele}
                lang={"en"}
            />
            <IndexAppAdminSection2
                ref={(ele) => itemsRef.current[2] = ele}
                lang={"en"}
            />
            <IndexAppAdminSection3
                ref={(ele) => itemsRef.current[3] = ele}
                lang={"en"}
            />
            <IndexAppAdminSection4
                ref={(ele) => itemsRef.current[4] = ele}
                lang={"en"}
            />
            <IndexAppAdminSection5
                ref={(ele) => itemsRef.current[5] = ele}
                lang={"en"}
            />
            <IndexAppAdminSection6
                ref={(ele) => itemsRef.current[6] = ele}
                lang={"en"}
            />
            <IndexAppAdminSection7
                ref={(ele) => itemsRef.current[7] = ele}
                lang={"en"}
            />
            <IndexAppAdminSection8
                ref={(ele) => itemsRef.current[8] = ele}
                lang={"en"}
            />
            <IndexAppAdminSection9
                ref={(ele) => itemsRef.current[9] = ele}
                lang={"en"}
            />
            <IndexAppAdminSection10
                ref={(ele) => itemsRef.current[10] = ele}
                lang={"en"}
            />
            <IndexAppAdminSection11
                ref={(ele) => itemsRef.current[11] = ele}
                lang={"en"}
            />
            <IndexHomeSection2
                lang={"en"}
            />
            <Footer
                lang={"en"}
                customer={props.customer}
            />
        </div>
    )
}
export default IndexAppAdminEn;
