import React from "react";
//theme
import "./styles.scss";
//libs
import {useHistory} from "react-router-dom";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminSection6 = (props: Props, ref: any) => {
    //hooks
    const history = useHistory();

    return (
        <div
            ref={ref}
            className={"IndexAppAdminSection6Content"}
        >
            <div
                className={"IndexAppAdminSection6 js-scroll slide-right"}
            >
                <section
                    className={"IndexAppAdminSection6Sec"}
                >
                    {props.lang === "es" && <h1>
                        Ofrece <strong>cupones de descuento</strong> para tus pasajeros
                    </h1>}
                    {props.lang === "en" && <h1>
                        Offer <strong>discount coupons</strong> for your passengers
                    </h1>}
                    {props.lang === "es" && <p>
                        Potencia tu campaña de marketing con <strong>códigos promocionales</strong> para que tus nuevos usuarios se motiven aún más
                    </p>}
                    {props.lang === "en" && <p>
                        Boost your marketing campaign with <strong>promotional codes</strong> so that your new users get even more motivated
                    </p>}
                    <button
                        onClick={() => {
                            history.push(`/${props.lang}/app/admin/module/coupon`);
                        }}
                    >
                        {props.lang === "es" && <label>
                            Más info
                        </label>}
                        {props.lang === "en" && <label>
                            More info
                        </label>}
                    </button>
                </section>
                <div
                    className={"IndexAppAdminSection6Img"}
                >
                    <img
                        src={require("../../../../../assets/img/admin_coupon_1.png").default}
                        alt={"dvgeo_admin_coupon_1"}
                    />
                </div>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppAdminSection6);
