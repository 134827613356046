const Utils = {
    toTitleCase: (str: string) => {
        return str.replace(/(?:^|\s)\w/g, function (match) {
            return match.toUpperCase();
        });
    },
    generatePassword: (longitud: number) => {
        const caracteres = "abcdefghijkmnpqrtuvwxyzABCDEFGHIJKLMNPQRTUVWXYZ2346789";
        let contrasenia = "";
        for (let i = 0; i < Number(longitud); i++) contrasenia += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
        return contrasenia;
    },
    generateDiscountCode(length_letters: number = 3, length_numbers = 4) {
        const chars_letters = "ABCDEFGHIJKLMNPQRTUVWXYZ";
        let code_letters = "";
        for (let i = 0; i < length_letters; i++) code_letters += chars_letters.charAt(Math.floor(Math.random() * chars_letters.length));
        const chars_numbers = "123456789";
        let code_numbers = "";
        for (let i = 0; i < length_numbers; i++) code_numbers += "" + chars_numbers.charAt(Math.floor(Math.random() * chars_numbers.length));
        return code_letters + code_numbers;
    },
    reversePriceWithDiscount: (price: number, percent: number): number => {
        const PERCENT_INIT: number = 1;
        let percent_temp = (PERCENT_INIT - percent);
        return price / percent_temp;
    },
    getUserStatus: (usuario_estado_id: number): { color: string; label: string } => {
        switch (usuario_estado_id) {
            case 1: {
                return {color: "green", label: "DISPONIBLE"}
            }
            case 2: {
                return {color: "red", label: "RECHAZADO"}
            }
            case 3: {
                return {color: "darkred", label: "BLOQUEADO"}
            }
            case 4: {
                return {color: "yellowgreen", label: "PENDIENTE"}
            }
            case 5: {
                return {color: "orange", label: "OCUPADO"}
            }
            default : {
                return {color: "black", label: ""}
            }
        }
    },
    getTravelUrbanStatus: (travel_urban_status_id: number): { color: string; label: string } => {
        switch (travel_urban_status_id) {
            case 1: {
                return {color: "orange", label: "INICIADO"}
            }
            case 2: {
                return {color: "yellowgreen", label: "CONFIRMADO"}
            }
            case 3: {
                return {color: "orange", label: "RUTA"}
            }
            case 4: {
                return {color: "green", label: "COMPLETADO"}
            }
            case 5: {
                return {color: "red", label: "CANCEL. PASAJERO"}
            }
            case 6: {
                return {color: "red", label: "CANCEL. CONDUCTOR"}
            }
            case 7: {
                return {color: "green", label: "RESERVADO"}
            }
            default : {
                return {color: "black", label: ""}
            }
        }
    },
}

export default Utils;
