import React, {useEffect, useState} from "react";
//theme
import "./styles.scss";
import {TouchableOpacity} from "react-native";
//store
//api
import {CustomerDocAPI} from "../../../../../../../api/customer_doc/customerDocAPI";
//models
import {Customer} from "../../../../../../../api/customer/customerModel";
import {CustomerDoc} from "../../../../../../../api/customer_doc/customerDocModel";
//libs
import config from "../../../../../../../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage, faSearch, faTimes, faUpload} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
//components
import Button from "../../../../../../_components/Button";
//types
import {Lang} from "../../../../../../../types";
import {SystemSnackbar} from "../../../../../../../store/actions/system.ac";

//interfaces
interface Props {
    lang: Lang;
    customer: Customer;
    customer_doc: CustomerDoc;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
}

//const
const TAG = `CustomerCompanyUploadIdentificationViewFile1`;
const isDev = (process.env.NODE_ENV !== "production");
//component
const CustomerCompanyUploadIdentificationViewFile1 = (props: Props) => {
    //hooks
    const history = useHistory();
    //refs
    //state
    const [customer, setCustomer] = useState<Customer>(props.customer as Customer);
    const [customer_doc, setCustomerDoc] = useState<CustomerDoc>(props.customer_doc as CustomerDoc);
    const [is_loading, setIsLoading] = useState<boolean>(false);
    const [is_disabled_button, setIsDisabledButton] = useState<boolean>(false);
    const [is_selected, setIsSelected] = useState<boolean>(false);
    //effects
    useEffect(() => {
        setCustomer(props.customer as Customer);
    }, [props.customer]);
    useEffect(() => {
        setCustomerDoc(props.customer_doc as Customer);
    }, [props.customer_doc]);

    const readURL = async () => {
        setIsLoading(true);
        let input = document.getElementById(`${TAG}-file`);
        // @ts-ignore
        if (!input.value.match(/(?:jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
            props.showSnackbar && props.showSnackbar({
                message: props.lang === "es" ? "Formato de imagen no válido" : props.lang === "en" ? "Invalid image format" : "error",
                severity: "error",
            });
            return;
        }
        // @ts-ignore
        if (input.files[0]) {
            // @ts-ignore
            let fileName = input.value.substr(input.value.lastIndexOf('\\') + 1).split('.')[0];
            let patternExt = /(?:\.([^.]+))?$/;
            // @ts-ignore
            let fileExtension = patternExt.exec(input.value)[1];
            let reader = new FileReader();
            reader.onload = async (e) => {
                setCustomerDoc((prevCustomer) => ({
                    ...prevCustomer,
                    url_identification: String(e?.target?.result),
                    photo: {
                        file: e?.target?.result,
                        fileName, fileExtension,
                    },
                }));
                setIsSelected(true);

            }
            // @ts-ignore
            reader.readAsDataURL(input.files[0]);
        }
        setIsLoading(false);
    }

    const upload = async () => {
        const {file, fileName, fileExtension} = customer_doc?.photo;
        setIsLoading(true);
        setIsDisabledButton(true);
        isDev && console.log(TAG + "//upload", "Subiendo foto de identificación...", customer.id);
        await CustomerDocAPI.updateIdentification({
            model: {
                customer_id: customer.id,
                file: file,
                fileName,
                fileExtension,
            }
        }, props.lang).then(async res => {
            isDev && console.log(TAG + "//upload", res.code, res.message);
            if (res.code === 200) {
                history.goBack();
            } else {
                props.showSnackbar && props.showSnackbar({
                    message: res.message,
                    severity: "warning"
                });
            }
        }).catch(async res => {
            isDev && console.error(TAG + "//upload", res.code, res.error, res);
            props.showSnackbar && props.showSnackbar({
                message: res.error ? res.error : res,
                severity: "error"
            });
        });
        setIsDisabledButton(false);
        setIsLoading(false);
    }

    return (
        <div
            className={"CustomerCompanyUploadIdentificationViewFile1"}
        >
            <input
                id={`${TAG}-file`}
                type="file"
                accept="image/*"
                style={{
                    display: 'none'
                }}
                onChange={readURL}
            />

            <div
                style={{
                    position: "relative", textAlign: "center"
                }}
            >
                <div
                    className={!customer_doc || !customer_doc.url_identification ? "CustomerCompanyUploadIdentificationViewFile1Icon" : "CustomerCompanyUploadIdentificationViewFile1IconImageRec"}
                >
                    {!customer_doc || !customer_doc.url_identification ? <FontAwesomeIcon
                        icon={faImage}
                        color={config.colorPrimary}
                    /> : <img
                        src={customer_doc.url_identification}
                        alt={"imagen"}
                    />}
                </div>
                {is_selected && <label
                    className={"CustomerCompanyUploadIdentificationViewFile1ImgButton"}
                >
                    <TouchableOpacity
                        onPress={() => {
                            // @ts-ignore
                            document.getElementById(`${TAG}-file`).value = "";
                            setCustomer((prevCustomer) => ({
                                ...prevCustomer,
                                url_identification: undefined,
                            }));
                            setIsSelected(false);
                        }}
                    >
                        <label>
                            <FontAwesomeIcon
                                icon={faTimes}
                            />
                        </label>
                    </TouchableOpacity>
                </label>}
            </div>

            <div
                className={"CustomerCompanyUploadIdentificationViewFile1TitleContent"}
            >
                <legend>
                    {props.lang === "es" && "Identificación de ciudadanía"}
                    {props.lang === "en" && "Identification of citizenship"}
                </legend>
                <p>
                    {props.lang === "es" && "Selecciona una imagen de la identificación de ciudadanía"}
                    {props.lang === "en" && "Select an image of the citizenship identification"}
                </p>
            </div>
            <div
                className={"CustomerCompanyUploadIdentificationViewFile1ButtonContent"}
            >
                {!is_selected ? <Button
                    style={{
                        marginTop: 20
                    }}
                    color={"primary"}
                    disabled={is_disabled_button}
                    onClick={() => {
                        // @ts-ignore
                        document.getElementById(`${TAG}-file`).value = "";
                        // @ts-ignore
                        document.getElementById(`${TAG}-file`).click();
                    }}
                >
                    <label>
                        Seleccionar
                    </label>
                    <FontAwesomeIcon
                        icon={faSearch}
                        color={"#fff"}
                        style={{
                            marginLeft: 10
                        }}
                    />
                </Button> : <React.Fragment>
                    <Button
                        style={{
                            marginTop: 20
                        }}
                        color={"primary"}
                        disabled={is_disabled_button}
                        onClick={() => upload()}
                    >
                        {is_loading ? <CircularProgress
                            color={"inherit"}
                            style={{
                                color: config.colorPrimary
                            }}
                            size={20}
                        /> : <React.Fragment>
                            <label>
                                Subir
                            </label>
                            <FontAwesomeIcon
                                icon={faUpload}
                                color={"#fff"}
                                style={{
                                    marginLeft: 10
                                }}
                            />
                        </React.Fragment>}
                    </Button>
                </React.Fragment>}
            </div>
        </div>
    )
}
export default CustomerCompanyUploadIdentificationViewFile1;
