import React from "react";
//theme
import {Redirect, Route, Switch} from "react-router-dom";
//store
import {deleteToken, hideSnackbar, postToken, showSnackbar, SystemSnackbar} from "../../store/actions/system.ac";
import {deleteCustomer, postCustomer, putCustomer} from "../../store/actions/customer.ac";
//models
import {Customer} from "../../api/customer/customerModel";
//libs
import {connect} from "react-redux";
//views
import IndexHomeEs from "./home/es";
import IndexHomeEn from "./home/en";
import IndexAppAdminEs from "./app_admin/es";
import IndexAppAdminEn from "./app_admin/en";
import IndexAppAdminServiceEs from "./app_admin_service/es";
import IndexAppAdminServiceEn from "./app_admin_service/en";
import IndexAppAdminUserEs from "./app_admin_user/es";
import IndexAppAdminUserEn from "./app_admin_user/en";
import IndexAppAdminChannelEs from "./app_admin_channel/es";
import IndexAppAdminChannelEn from "./app_admin_channel/en";
import IndexAppAdminGroupEs from "./app_admin_group/es";
import IndexAppAdminGroupEn from "./app_admin_group/en";
import IndexAppAdminCouponEs from "./app_admin_coupon/es";
import IndexAppAdminCouponEn from "./app_admin_coupon/en";
import IndexAppAdminVehicleEs from "./app_admin_vehicle/es";
import IndexAppAdminVehicleEn from "./app_admin_vehicle/en";
import IndexAppAdminTravelEs from "./app_admin_travel/es";
import IndexAppAdminTravelEn from "./app_admin_travel/en";
import IndexAppAdminCommerceEs from "./app_admin_commerce/es";
import IndexAppAdminCommerceEn from "./app_admin_commerce/en";
import IndexAppPassengerEs from "./app_passenger/es";
import IndexAppPassengerEn from "./app_passenger/en";
import IndexAppDriverEs from "./app_driver/es";
import IndexAppDriverEn from "./app_driver/en";
import IndexFaqEs from "./faq/es";
import IndexFaqEn from "./faq/en";
import IndexPriceEs from "./price/es";
import IndexPriceEn from "./price/en";
import IndexAboutEs from "./about/es";
import IndexAboutEn from "./about/en";
import IndexContactEs from "./contact/es";
import IndexContactEn from "./contact/en";
import IndexRegisterEs from "./register/es";
import IndexRegisterEn from "./register/en";
//types
import {StoreDispatchProps, StoreStateProps} from "../../store/types";

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

//interfaces
interface OwnProps {

}

//const
//component
const IndexSwitch = (props: Props) => {
    return (
        <Switch>
            {/*es*/}
            <Route exact path="/es"
                   component={() => <IndexHomeEs
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*es/app/admin*/}
            <Route exact path="/es/app/admin"
                   component={() => <IndexAppAdminEs
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            <Route exact path="/es/app/admin/module/service"
                   component={() => <IndexAppAdminServiceEs
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            <Route exact path="/es/app/admin/module/user"
                   component={() => <IndexAppAdminUserEs
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            <Route exact path="/es/app/admin/module/channel"
                   component={() => <IndexAppAdminChannelEs
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            <Route exact path="/es/app/admin/module/group"
                   component={() => <IndexAppAdminGroupEs
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            <Route exact path="/es/app/admin/module/coupon"
                   component={() => <IndexAppAdminCouponEs
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            <Route exact path="/es/app/admin/module/vehicle"
                   component={() => <IndexAppAdminVehicleEs
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            <Route exact path="/es/app/admin/module/travel"
                   component={() => <IndexAppAdminTravelEs
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            <Route exact path="/es/app/admin/module/commerce"
                   component={() => <IndexAppAdminCommerceEs
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*es/app/passenger*/}
            <Route exact path="/es/app/passenger"
                   component={() => <IndexAppPassengerEs
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*es/app/driver*/}
            <Route exact path="/es/app/driver"
                   component={() => <IndexAppDriverEs
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*es/faq*/}
            <Route exact path="/es/faq"
                   component={() => <IndexFaqEs
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*es/price*/}
            <Route exact path="/es/price"
                   component={() => <IndexPriceEs
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*es/about*/}
            <Route exact path="/es/about"
                   component={() => <IndexAboutEs
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*es/contact*/}
            <Route exact path="/es/contact"
                   component={() => <IndexContactEs
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*es/register*/}
            <Route exact path="/es/register"
                   component={() => <IndexRegisterEs
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*en*/}
            <Route exact path="/en"
                   component={() => <IndexHomeEn
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*en/app/admin*/}
            <Route exact path="/en/app/admin"
                   component={() => <IndexAppAdminEn
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            <Route exact path="/en/app/admin/module/service"
                   component={() => <IndexAppAdminServiceEn
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            <Route exact path="/en/app/admin/module/user"
                   component={() => <IndexAppAdminUserEn
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            <Route exact path="/en/app/admin/module/channel"
                   component={() => <IndexAppAdminChannelEn
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            <Route exact path="/en/app/admin/module/group"
                   component={() => <IndexAppAdminGroupEn
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            <Route exact path="/en/app/admin/module/coupon"
                   component={() => <IndexAppAdminCouponEn
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            <Route exact path="/en/app/admin/module/vehicle"
                   component={() => <IndexAppAdminVehicleEn
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            <Route exact path="/en/app/admin/module/travel"
                   component={() => <IndexAppAdminTravelEn
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            <Route exact path="/en/app/admin/module/commerce"
                   component={() => <IndexAppAdminCommerceEn
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*en/app/passenger*/}
            <Route exact path="/en/app/passenger"
                   component={() => <IndexAppPassengerEn
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*en/app/driver*/}
            <Route exact path="/en/app/driver"
                   component={() => <IndexAppDriverEn
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*en/faq*/}
            <Route exact path="/en/faq"
                   component={() => <IndexFaqEn
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*en/price*/}
            <Route exact path="/en/price"
                   component={() => <IndexPriceEn
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*en/about*/}
            <Route exact path="/en/about"
                   component={() => <IndexAboutEn
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*en/contact*/}
            <Route exact path="/en/contact"
                   component={() => <IndexContactEn
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*en/register*/}
            <Route exact path="/en/register"
                   component={() => <IndexRegisterEn
                       customer={props.customer?.item}
                       deleteCustomer={props.deleteCustomer}
                       deleteToken={props.deleteToken}
                   />}
            />
            {/*default*/}
            <Redirect from={"*"} to={"/es"}/>
        </Switch>
    )
}

const mapStateToProps = (state: StoreStateProps) => ({
    system: state.system,
    customer: state.customer,
});

const mapDispatchToProps = {
    //system
    postToken: (token: string) => (postToken(token)),
    deleteToken: () => (deleteToken()),
    showSnackbar: (snackbar: SystemSnackbar) => (showSnackbar(snackbar)),
    hideSnackbar: () => (hideSnackbar()),
    //customer
    postCustomer: (customer: Customer) => (postCustomer(customer)),
    putCustomer: (customer: Customer) => (putCustomer(customer)),
    deleteCustomer: () => (deleteCustomer()),
}
export default connect<StoreStateProps, StoreDispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(IndexSwitch);
