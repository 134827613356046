import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexAppAdminServiceSection3 = (props: Props) => {
    //state
    const [indexGroup, setIndexGroup] = useState<Array<boolean>>([false, false, false, false]);

    const setIndexGroupFocus = (index: number) => {
        let indexGroupNew: Array<boolean> = [...indexGroup];
        for (let i = 0; i < indexGroupNew.length; i++) {
            if (i === index) {
                indexGroupNew[i] = !indexGroupNew[i];
            } else {
                indexGroupNew[i] = false;
            }
        }
        setIndexGroup(indexGroupNew);
    }

    return (
        <div
            className={"IndexAppAdminServiceSection3"}
        >
            <div
                className={"IndexAppAdminServiceSection3Title"}
            >
                {props.lang === "es" && <h1
                >
                    Preguntas Frecuentes
                </h1>}
                {props.lang === "en" && <h1
                >
                    FAQ
                </h1>}
            </div>
            <section
                className={"IndexAppAdminServiceSection3Sec"}
            >
                <button
                    className={"IndexAppAdminServiceSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(0)}
                >
                    {props.lang === "es" && <h2>
                        ¿Se pueden ordenar los servicios en el menú del pasajero?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can services be ordered from the passenger menu?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[0] ? "IndexAppAdminServiceSection3SecGroupFocus" : "IndexAppAdminServiceSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Se puede ordenar mediante el campo posición de mayor a menor. Estos servicios se muestran en el
                        menú principal de la app pasajero.
                    </p>}
                    {props.lang === "en" && <p>
                        It can be sorted by the position field from highest to lowest. These services are shown in the
                        main menu of the passenger app.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminServiceSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(1)}
                >
                    {props.lang === "es" && <h2>
                        ¿Se puede crear un tarifario o taxímetro por cada servicio?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can a rate or taximeter be created for each service?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[1] ? "IndexAppAdminServiceSection3SecGroupFocus" : "IndexAppAdminServiceSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Así es, cada servicio puede tener su propio tarifario que son definidos por horarios y en cada horario se asignan los rangos por kilometros, dando como resultado un taxímetro digital.
                    </p>}
                    {props.lang === "en" && <p>
                        That's right, each service can have its own tariff that are defined by schedules and in each schedule the ranges are assigned by kilometers, resulting in a digital taximeter.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminServiceSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(2)}
                >
                    {props.lang === "es" && <h2>
                        ¿Hay límites al momento de crear los servicios?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Are there limits when creating services?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[2] ? "IndexAppAdminServiceSection3SecGroupFocus" : "IndexAppAdminServiceSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        No existe un limite definido para crear los servicios pero si debe registrar al menos un servicio.
                    </p>}
                    {props.lang === "en" && <p>
                        There is no defined limit to create services but you must register at least one service.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminServiceSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(3)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo crear un horario 24/7 no es necesario desglosar en varios horarios?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I create a 24/7 schedule, is it not necessary to break it down into multiple schedules?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[3] ? "IndexAppAdminServiceSection3SecGroupFocus" : "IndexAppAdminServiceSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Si la idea es tener un único horario por servicio puede definirlo como 24_7 que cubriría desde las 00H00 hasta las 23H00. Pero si se va a desglozar por ejemplo mañana, tarde, noche, madrugada, estos deben ser con horas secuenciales.
                    </p>}
                    {props.lang === "en" && <p>
                        If the idea is to have a single schedule per service, you can define it as 24_7 that would cover from 00H00 to 23H00. But if it is going to be broken down for example morning, afternoon, night, early morning, these must be with sequential hours.
                    </p>}
                </div>
            </section>
        </div>
    )
}
export default IndexAppAdminServiceSection3;
