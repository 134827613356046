import React, {useEffect, useRef, useState} from "react";
//theme
import "./NavBarMenu.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";

//types
import {Lang} from "../../types";
import {useHistory} from "react-router-dom";
import config from "../../config";

//interface
interface Props {
    lang: Lang;
}

//component
const NavBarMenu = (props: Props) => {
    //hooks
    const history = useHistory();
    //refs
    const navBarMenuRef = useRef<any>(null);
    const sectionMenuRef = useRef<any>(null);
    //state
    const [screen, setScreen] = useState<"mobile" | "tablet" | "desktop">("mobile");
    const [isShowMenu, setIsShowMenu] = useState<boolean>(true);
    //effects
    useEffect(() => {
        setTimeout(() => {
            if (navBarMenuRef.current) {
                navBarMenuRef.current.style.marginTop = 0
            }
        }, 1000 / 6);
        getScreenType();
        if ('onorientationchange' in window) {
            window.addEventListener("orientationchange", () => getScreenType(), false);
        }
        window.addEventListener('resize', () => getScreenType());
        return () => {
            window.removeEventListener("orientationchange", () => getScreenType());
            window.addEventListener('resize', () => getScreenType());
        }
    }, []);
    useEffect(() => {
        if (screen === "desktop") {
            setIsShowMenu(true);
        } else {
            setIsShowMenu(false);
        }
    }, [screen])

    const getScreenType = () => {
        window.matchMedia("(max-width: 600px)").matches && setScreen("mobile");
        window.matchMedia("(min-width: 800px)").matches && setScreen("tablet");
        window.matchMedia("(min-width: 1000px)").matches && setScreen("desktop");
    }

    return (
        <div
            ref={navBarMenuRef}
            className={"NavBarMenu"}
        >
            {/*section logo*/}
            <section
                className={"NavBarMenuSecLogo"}
                onClick={() => history.push(`/${props.lang}`)}
            >
                <img
                    src={require("../../assets/img/navbar_logo.png").default}
                    alt={"logo"}
                />
            </section>
            {/*botón de menu*/}
            <section
                className={"NavBarMenuSecMenuBtn"}
                onClick={() => setIsShowMenu(!isShowMenu)}
            >
                <FontAwesomeIcon
                    icon={faBars}
                />
            </section>
            {/*section menu*/}
            {(navBarMenuRef.current && isShowMenu) && <section
                ref={sectionMenuRef}
                className={(screen === "mobile" || screen === "tablet") ? "NavBarMenuSecMenuResponsive" : "NavBarMenuSecMenu"}
                style={{
                    top: navBarMenuRef.current.clientHeight,
                    right: 0,
                }}
            >
                {screen === "mobile" || screen === "tablet" && <button
                    className={(window.location.pathname.includes("app/admin")) ? "NavBarMenuSecMenuItemFocus" : "NavBarMenuSecMenuItem"}
                    onClick={() => history.push(`/${props.lang}/app/admin`)}
                >
                    {props.lang === "es" && <label>
                        App Admin
                    </label>}
                    {props.lang === "en" && <label>
                        Manage App
                    </label>}
                </button>}
                {screen === "mobile" || screen === "tablet" && <button
                    className={(window.location.pathname.includes("app/passenger")) ? "NavBarMenuSecMenuItemFocus" : "NavBarMenuSecMenuItem"}
                    onClick={() => history.push(`/${props.lang}/app/passenger`)}
                >
                    {props.lang === "es" && <label>
                        App Pasajero
                    </label>}
                    {props.lang === "en" && <label>
                        Passenger App
                    </label>}
                </button>}
                {screen === "mobile" || screen === "tablet" && <button
                    className={(window.location.pathname.includes("app/driver")) ? "NavBarMenuSecMenuItemFocus" : "NavBarMenuSecMenuItem"}
                    onClick={() => history.push(`/${props.lang}/app/driver`)}
                >
                    {props.lang === "es" && <label>
                        App Conductor
                    </label>}
                    {props.lang === "en" && <label>
                        Driver App
                    </label>}
                </button>}
                <button
                    className={(window.location.pathname.includes("price")) ? "NavBarMenuSecMenuItemFocus" : "NavBarMenuSecMenuItem"}
                    onClick={() => history.push(`/${props.lang}/price`)}
                >
                    {props.lang === "es" && <label>
                        Precios
                    </label>}
                    {props.lang === "en" && <label>
                        Prices
                    </label>}
                </button>
                <button
                    className={(window.location.pathname.includes("faq")) ? "NavBarMenuSecMenuItemFocus" : "NavBarMenuSecMenuItem"}
                    onClick={() => history.push(`/${props.lang}/faq`)}
                >
                    {props.lang === "es" && <label>
                        Preguntas Frecuentes
                    </label>}
                    {props.lang === "en" && <label>
                        FAQ
                    </label>}
                </button>
                <button
                    className={(window.location.pathname.includes("about")) ? "NavBarMenuSecMenuItemFocus" : "NavBarMenuSecMenuItem"}
                    onClick={() => history.push(`/${props.lang}/about`)}
                >
                    {props.lang === "es" && <label>
                        Acerca de Nosotros
                    </label>}
                    {props.lang === "en" && <label>
                        About Us
                    </label>}
                </button>
                <button
                    className={(window.location.pathname.includes("contact")) ? "NavBarMenuSecMenuItemFocus" : "NavBarMenuSecMenuItem"}
                    onClick={() => history.push(`/${props.lang}/contact`)}
                >
                    {props.lang === "es" && <label>
                        Contacto
                    </label>}
                    {props.lang === "en" && <label>
                        Contact
                    </label>}
                </button>
                <button
                    className={"NavBarMenuSecMenuItemPromo"}
                    onClick={() => history.push(`/${props.lang}/register`)}
                >
                    {props.lang === "es" && <label>
                        Demo
                    </label>}
                    {props.lang === "en" && <label>
                        Contact
                    </label>}
                </button>
            </section>}
        </div>
    )
}
export default NavBarMenu;
