import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import './style.scss';
import App from './App';
import {AppRegistry} from "react-native";
import configureStore from "./store/store";

let store = configureStore();

const backup = console.warn;

console.warn = function filterWarnings(msg) {
    const supressedWarnings = ['React Hook useEffect', 'other warning text'];

    if (!supressedWarnings.some(entry => msg.includes(entry))) {
        // @ts-ignore
        backup.apply(console, arguments);
    }
};

console.error = function filterErrors(msg) {
    const supressedErrors = ['Warning: Can\'t perform a React state', 'XMLHttpRequest', "Access-Control-Allow-Origin", "Origin", "cannot load"];

    if (!supressedErrors.some(entry => msg.includes(entry))) {
        // @ts-ignore
        backup.apply(console, arguments);
    }
};

const AppGlobal = () => {
    console.disableYellowBox = true
    return (
        <Provider
            store={store}
        >
            <App/>
        </Provider>
    )
}

ReactDOM.render(<AppGlobal/>, document.getElementById("root"))
AppRegistry.registerComponent('App', () => AppGlobal);
AppRegistry.runApplication('App', {rootTag: document.getElementById('root')});
