import React from "react";
//theme
import "./styles.scss";
//libs
import {useHistory} from "react-router-dom";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminSection11 = (props: Props, ref: any) => {
    //hooks
    const history = useHistory();

    return (
        <div
            ref={ref}
            className={"IndexAppAdminSection11Content"}
        >
            <div
                className={"IndexAppAdminSection11 js-scroll slide-left"}
            >
                <div
                    className={"IndexAppAdminSection11Img"}
                >
                    <img
                        src={require("../../../../../assets/img/admin_commerce_1.png").default}
                        alt={"dvgeo_admin_user_1"}
                    />
                </div>
                <section
                    className={"IndexAppAdminSection11Sec"}
                >
                    {props.lang === "es" && <h1>
                        Vende <strong>Recargas</strong>, cobra <strong>Comisiones</strong>
                    </h1>}
                    {props.lang === "en" && <h1>
                        Sell <strong>refills</strong>, collect <strong>commissions</strong>
                    </h1>}
                    {props.lang === "es" && <p>
                        Puedes <strong>distribuir paquetes de saldo</strong> con socios comerciales para expandir tu marca que servirán para recargar la cuenta de cada conductor
                    </p>}
                    {props.lang === "en" && <p>
                        You can <strong>distribute balance packages</strong> with business partners to expand your brand that will serve to recharge the account of each driver
                    </p>}
                    <button
                        onClick={() => {
                            history.push(`/${props.lang}/app/admin/module/commerce`);
                        }}
                    >
                        {props.lang === "es" && <label>
                            Más info
                        </label>}
                        {props.lang === "en" && <label>
                            More Info
                        </label>}
                    </button>
                </section>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppAdminSection11);
