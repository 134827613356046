import React, {useState} from "react";
//theme
import "./styles.scss";
//api
import {CustomerSupportAPI} from "../../../../../../api/customer_support/customerSupportAPI";
//models
import {CustomerSupport} from "../../../../../../api/customer_support/customerSupportModel";
//libs
import config from "../../../../../../config";
import {Link, useHistory} from "react-router-dom";
import {Breadcrumbs, CircularProgress, Typography} from "@material-ui/core";
import ReactLoading from "react-loading";
//types
import {Lang} from "../../../../../../types";
import {SystemSnackbar} from "../../../../../../store/actions/system.ac";

//interfaces
interface Props {
    lang: Lang;
    customer_support: CustomerSupport;
    isLoading: boolean;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
}

//const
const TAG = `CustomerSupportTrackingViewBanner1`;
const isDev = (process.env.NODE_ENV !== "production");
//component
const CustomerSupportTrackingViewBanner1 = (props: Props) => {
    //hooks
    const history = useHistory();
    //state
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const close = async () => {
        setIsLoading(true);
        await CustomerSupportAPI.updateIsClose({
            model: {
                id: props.customer_support.id,
                is_close: 1,
            }
        }, props.lang).then(async res_1 => {
            isDev && console.log(TAG + "//insert", res_1.code, res_1.message, res_1.error);
            if (res_1.code === 200) {
                history.goBack();
            } else {
                props.showSnackbar && props.showSnackbar({
                    message: res_1.message,
                    severity: "error"
                });
            }
        }).catch(async res_1 => {
            isDev && console.log(TAG + "//insert", res_1.code, res_1.message, res_1.error);
            props.showSnackbar && props.showSnackbar({
                message: res_1.error ? res_1.error : JSON.stringify(res_1),
                severity: "error"
            });
        });
        setIsLoading(false);
    }

    return (
        <div
            className={"CustomerSupportTrackingViewBanner1"}
        >
            {props.isLoading && <ReactLoading
                type={"bubbles"}
                color={config.colorPrimary}
                height={150}
                width={120}
                delay={2000}
            />}
            {(!props.isLoading || !isLoading) && <h1>
                <span>
                    {props.customer_support.is_close === 1 ? props.lang === "es" ? "[CERRADO]" : props.lang === "en" ? "[CLOSED]" : "" : ""}
                </span>
                {decodeURI(props.customer_support.issue as string)}
            </h1>}
            {(!props.isLoading || !isLoading) && <p>
                <label>
                    <strong>
                        {props.lang === "es" && `Prioridad:`}
                        {props.lang === "en" && `Priority:`}
                    </strong>
                    {props.customer_support.customer_support_priority_id}
                </label>
                <label>
                    <strong>
                        {props.lang === "es" && `Departamento:`}
                        {props.lang === "en" && `Department:`}
                    </strong>
                    {props.customer_support.customer_support_type?.type}
                </label>
            </p>}
            <Breadcrumbs
                className={"CustomerSupportTrackingViewBanner1LinkContent"}
            >
                <Link
                    className={"CustomerSupportTrackingViewBanner1Link"}
                    to={`/${props.lang}/application/customer`}
                >
                    {props.lang === "es" && "Inicio"}
                    {props.lang === "en" && "Home"}
                </Link>
                <Link
                    className={"CustomerSupportTrackingViewBanner1Link"}
                    to={`/${props.lang}/application/customer/support`}
                >
                    {props.lang === "es" && "Soporte"}
                    {props.lang === "en" && "Support"}
                </Link>
                <Typography
                    color="inherit"
                >
                    {props.lang === "es" && "Seguimiento"}
                    {props.lang === "en" && "Follow-up"}
                </Typography>
            </Breadcrumbs>
            <div
                className={"CustomerSupportTrackingViewBanner1Buttons"}
            >
                <Link
                    className={"CustomerSupportViewBanner1ButtonsLink"}
                    to={`/${props.lang}/application/customer/support/update/${props.customer_support.id}`}
                >
                    {props.lang === "es" && "Modificar ticket"}
                    {props.lang === "en" && "Modify ticket"}
                </Link>
                <button
                    className={"CustomerSupportViewBanner1ButtonsLink"}
                    onClick={() => close()}
                >
                    {!isLoading && props.lang === "es" && "Cerrar ticket"}
                    {!isLoading && props.lang === "en" && "Close ticket"}
                    {isLoading && <CircularProgress
                        color={"inherit"}
                        size={20}
                    />}
                </button>
            </div>
        </div>
    )
}
export default CustomerSupportTrackingViewBanner1;
