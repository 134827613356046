import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppPassengerSection9 = (props: Props, ref: any) => {
    //hooks

    return (
        <div
            ref={ref}
            className={"IndexAppPassengerSection9Content"}
        >
            <div
                className={"IndexAppPassengerSection9 js-scroll slide-left"}
            >
                <div
                    className={"IndexAppPassengerSection9Img"}
                >
                    <img
                        src={require("../../../../../assets/img/passenger_13.png").default}
                        alt={"dvgeo_passenger_13"}
                    />
                </div>
                <section
                    className={"IndexAppPassengerSection9Sec"}
                >
                    {props.lang === "es" && <h1>
                        <strong>Conversa</strong> con tu conductor en camino
                    </h1>}
                    {props.lang === "en" && <h1>
                        <strong>Notify</strong> important news
                    </h1>}
                    {props.lang === "es" && <p>
                        El pasajero puede <strong>comunicarse con el conductor</strong> mediante un chat interno de los aplicativos pasajero y conductor. También tiene la opción de Whatsapp
                    </p>}
                    {props.lang === "en" && <p>
                        <strong>Share your trip details</strong> with friends and family to keep them informed
                    </p>}
                </section>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppPassengerSection9);
