import React from "react";
//theme
import "./styles.scss";
//models
import {CustomerApp} from "../../../../../../api/customer_app/customerAppModel";
//libs
import config from "../../../../../../config";
import {Link} from "react-router-dom";
import {Breadcrumbs, Typography} from "@material-ui/core";
import ReactLoading from "react-loading";
//types
import {Lang} from "../../../../../../types";

//interfaces
interface Props {
    lang: Lang;
    customer_app: CustomerApp;
    isLoading: boolean;
}

//component
const CustomerAppsLogViewBanner1 = (props: Props) => {
    return (
        <div
            className={"CustomerAppsLogViewBanner1"}
        >
            {props.isLoading && <ReactLoading
                type={"bubbles"}
                color={config.colorPrimary}
                height={150}
                width={120}
                delay={2000}
            />}
            {!props.isLoading && <h1>
                {props.lang === "es" && `App: ${props.customer_app.name}`}
                {props.lang === "en" && `App: ${props.customer_app.name}`}
            </h1>}
            {!props.isLoading && <p>
                {props.lang === "es" && `Paquete: ${props.customer_app.package}`}
                {props.lang === "en" && `Package: ${props.customer_app.package}`}
            </p>}
            <Breadcrumbs
                className={"CustomerAppsLogViewBanner1LinkContent"}
            >
                <Link
                    className={"CustomerAppsLogViewBanner1Link"}
                    to={`/${props.lang}/application/customer`}
                >
                    {props.lang === "es" && "Inicio"}
                    {props.lang === "en" && "Home"}
                </Link>
                <Link
                    className={"CustomerAppsLogViewBanner1Link"}
                    to={`/${props.lang}/application/customer/apps`}
                >
                    {props.lang === "es" && "Mis Apps"}
                    {props.lang === "en" && "My Apps"}
                </Link>
                <Typography
                    color="inherit"
                >
                    {props.lang === "es" && "Registro de mi app"}
                    {props.lang === "en" && "My app registration"}
                </Typography>
            </Breadcrumbs>
        </div>
    )
}
export default CustomerAppsLogViewBanner1;
