import React, {useEffect, useState} from "react";
//theme
import "./view.scss";
//store
//api
import {CustomerDocAPI} from "../../../../../../api/customer_doc/customerDocAPI";
//models
import {Customer} from "../../../../../../api/customer/customerModel";
import {CustomerDoc} from "../../../../../../api/customer_doc/customerDocModel";
//libs
//components
import CustomerNavBarMenu from "../../../../_components/CustomerNavBarMenu";
import NavBarTop from "../../../../../_components/NavBarTop";
import CustomerCompanyUploadIdentificationViewFile1 from "../_components/file_1";
import SnackBar from "../../../../../_components/SnackBar";
import Footer from "../../../../../_components/Footer";
//types
import {ErrorException, Lang} from "../../../../../../types";
import {SystemSnackbar} from "../../../../../../store/actions/system.ac";

//interfaces
interface Props {
    lang: Lang;
    customer: Customer;
    deleteCustomer?: () => void;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
    deleteToken?: () => void;
}

//const
const TAG = `CustomerCompanyUploadIdentificationView`;
const isDev = (process.env.NODE_ENV !== "production");
//view
const CustomerCompanyUploadIdentificationView = (props: Props) => {
    //hooks
    //refs
    //state
    const [customer_doc, setCustomerDoc] = useState<CustomerDoc>({});
    //effects
    useEffect(() => {
        getData();
        return () => {

        }
    }, []);

    const getData = async () => {
        try {
            await getCustomerDoc();
        } catch (error: ErrorException | any) {
            isDev && console.error(TAG + "//getData", error);
        }
    }

    const getCustomerDoc = async () => {
        if (!props.customer || !props.customer.id) {
            setTimeout(() => getCustomerDoc(), 1000);
            return;
        }
        isDev && console.log(TAG + "//getCustomerDoc", "Cargando documentos del cliente...");
        await CustomerDocAPI.getByCustomerId(props.customer.id, "es").then(async res_1 => {
                isDev && console.log(TAG + "//getCustomerDoc", res_1.code, res_1.message, res_1.error);
                if (res_1.code === 200) {
                    setCustomerDoc(res_1.customer_doc as CustomerDoc);
                } else {
                    props.showSnackbar && props.showSnackbar({
                        message: res_1.message,
                        severity: "warning"
                    });
                }
            }
        ).catch(async res_1 => {
            isDev && console.error(TAG + "//getCustomerDoc", res_1.code, res_1.error, res_1);
            props.showSnackbar && props.showSnackbar({
                message: res_1.error ? res_1.error : JSON.stringify(res_1.error),
                severity: "warning"
            });
        });
    }

    return (
        <div
            className={"CustomerCompanyUploadIdentificationView"}
        >
            <NavBarTop
                lang={props.lang}
                customer={props.customer}
                deleteCustomer={props.deleteCustomer}
                deleteToken={props.deleteToken}
            />
            <CustomerNavBarMenu
                lang={props.lang}
            />
            <CustomerCompanyUploadIdentificationViewFile1
                lang={props.lang}
                customer={props.customer}
                customer_doc={customer_doc}
                showSnackbar={props.showSnackbar}
                hideSnackbar={props.hideSnackbar}
            />
            <Footer
                lang={props.lang}
                customer={props.customer}
            />
            <SnackBar/>
        </div>
    )
}
export default CustomerCompanyUploadIdentificationView;
