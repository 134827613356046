import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../../../types";
import {Link} from "react-router-dom";
import {Breadcrumbs, Typography} from "@material-ui/core";

//interfaces
interface Props {
    lang: Lang;
}

//component
const CustomerCompanyUpdatePasswordViewBanner1 = (props: Props) => {
    return (
        <div
            className={"CustomerCompanyUpdatePasswordViewBanner1"}
        >
            <h1>
                {props.lang === "es" && "Cambiar Contraseña"}
                {props.lang === "en" && "Change Password"}
            </h1>
            <p>
                {props.lang === "es" && "Modifica la contraseña actual por una que realmente recuerdes"}
                {props.lang === "en" && "Change the current password to one that you really remember"}
            </p>
            <Breadcrumbs
                className={"CustomerCompanyUpdatePasswordViewBanner1LinkContent"}
            >
                <Link
                    className={"CustomerCompanyUpdatePasswordViewBanner1Link"}
                    to={`/${props.lang}/application/customer`}
                >
                    {props.lang === "es" && "Inicio"}
                    {props.lang === "en" && "Home"}
                </Link>
                <Link
                    className={"CustomerCompanyUpdatePasswordViewBanner1Link"}
                    to={`/${props.lang}/application/customer/company`}
                >
                    {props.lang === "es" && "Empresa"}
                    {props.lang === "en" && "Company"}
                </Link>
                <Typography
                    color="inherit"
                >
                    {props.lang === "es" && "Cambiar contraseña"}
                    {props.lang === "en" && "Change password"}
                </Typography>
            </Breadcrumbs>
        </div>
    )
}
export default CustomerCompanyUpdatePasswordViewBanner1;
