import React, {useEffect, useRef} from "react";
//theme
import "./view.scss";
//models
import {Customer} from "../../../../api/customer/customerModel";
//libs
import {useHistory} from "react-router-dom";
//components
import NavBarTop from "../../../_components/NavBarTop";
import Footer from "../../../_components/Footer";
import CustomerRecoveryViewForm1 from "../_components/form_1";
//types
import {ErrorException, Lang} from "../../../../types";
import {SystemSnackbar} from "../../../../store/actions/system.ac";

//interfaces
interface Props {
    lang: Lang;
    customer: Customer;
    putCustomer?: (customer: Customer) => void;
    deleteCustomer?: () => void;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
    postToken?: (token: string) => void;
    deleteToken?: () => void;
}

//const
const TAG = `CustomerRecoveryView`;
const isDev = (process.env.NODE_ENV !== "production");
//view
const CustomerRecoveryView = (props: Props) => {
    //hooks
    const history = useHistory();
    const customer = useRef<Customer>(props.customer);
    //state
    //effects
    useEffect(() => {
        document.title = `Inicia sesión - Software de despacho para taxi en la nube | Sistema para empresas de taxi | DvGeo Platform`;
        getData();
        return () => {

        }
    }, []);
    useEffect(() => {
        customer.current = props.customer as Customer;
    }, [props.customer]);

    const getData = () => {
        try {
            getSession();
        } catch (error: ErrorException | any) {
            isDev && console.error(TAG + "//getData", error);
        }
    }

    const getSession = () => {
        if (typeof customer.current === "undefined") {
            setTimeout(() => getSession, 1000);
        } else if (customer.current && customer.current.id) {
            history.push(`/es/application/customer`);
        }
    }

    return (
        <div
            className={"CustomerRecoveryView"}
        >
            <NavBarTop
                lang={props.lang}
                customer={customer.current}
                deleteCustomer={props.deleteCustomer}
            />
            <CustomerRecoveryViewForm1
                lang={props.lang}
                putCustomer={props.putCustomer}
                showSnackbar={props.showSnackbar}
                hideSnackbar={props.hideSnackbar}
                postToken={props.postToken}
            />
            <Footer
                lang={props.lang}
            />
        </div>
    )
}
export default CustomerRecoveryView;
