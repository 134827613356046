import React from "react";
//theme
import "./styles.scss";
//libs
import {Link} from "react-router-dom";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const CustomerSupportViewBanner1 = (props: Props) => {
    return (
        <div
            className={"CustomerSupportViewBanner1"}
        >
            <h1>
                {props.lang === "es" && "Soporte"}
                {props.lang === "en" && "Support"}
            </h1>
            <p>
                {props.lang === "es" && "Comunícate con nosotros si necesitas ayuda sobre nuestra plataforma, las apps que contraste, facturación"}
                {props.lang === "en" && "Contact us if you need help with our platform, the apps you compare, billing"}
            </p>
            <div
                className={"CustomerSupportViewBanner1Buttons"}
            >
                <Link
                    className={"CustomerSupportViewBanner1ButtonsLink"}
                    to={`/${props.lang}/application/customer/support/new`}
                >
                    {props.lang === "es" && "Crear ticket"}
                    {props.lang === "en" && "Create ticket"}
                </Link>
            </div>
        </div>
    )
}
export default CustomerSupportViewBanner1;
