import React, {useEffect, useRef, useState} from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminGroupSection2 = (props: Props) => {
    //refs
    const iTimer = useRef<NodeJS.Timeout | number>(0);
    const indexAppAdminServiceSection1DesktopSnapContentRef = useRef<any>(null);
    const items = useRef<Array<any>>([]);
    //state
    const [index, setIndex] = useState<number>(0);
    //effects
    useEffect(() => {
        init();
        return () => {
            clearIntervals();
        }
    }, []);

    const init = () => {
        try {
            iIndexTranslateX();
        } catch (error: any) {

        }
    }


    const iIndexTranslateX = () => {
        setIndex(0);
        setTranslateXValid(0);
        clearInterval(iTimer.current as NodeJS.Timeout);
        let indexCurrent = 0;
        iTimer.current = setInterval(() => {
            if (indexCurrent >= 0 && indexCurrent <= 1) {
                indexCurrent++;
                setTranslateXValid(indexCurrent);
                setIndex(indexCurrent);
            } else {
                iIndexTranslateX();
                indexCurrent = 0;
                setIndex(0);
            }
        }, 6000);
    }

    const clearIntervals = () => {
        clearInterval(iTimer.current as NodeJS.Timeout);
        iTimer.current = 0;
    }

    const setTranslateX = (index: number, value: string) => {
        try {
            items.current[index].style.transform = value;
        } catch (error: any) {
            setTimeout(() => setTranslateX(index, value), 1000 / 5);
        }
    }

    const setTranslateXValid = (index: number) => {
        if (index === 0) {
            setTimeout(() => setTranslateX(0, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-0vw)`), 1000 / 5);
        }
        if (index === 1) {
            setTimeout(() => setTranslateX(0, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-40vw)`), 1000 / 5);
        }
        if (index === 2) {
            setTimeout(() => setTranslateX(0, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-80vw)`), 1000 / 5);
        }
        if (index === 3) {
            setTimeout(() => setTranslateX(0, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-120vw)`), 1000 / 5);
        }
    }

    return (
        <div
            className={"IndexAppAdminGroupSection2"}
        >
            {props.lang === "es" && <h1
                className={"IndexAppAdminGroupSection2Title"}
            >
                ¿Cómo funciona?
            </h1>}
            {props.lang === "en" && <h1
                className={"IndexAppAdminGroupSection2Title"}
            >
                How does it work?
            </h1>}
            {/*apps en modo mobile*/}
            <div
                className={"IndexAppAdminGroupSection2Mobile"}
            >
                <section
                    className={"IndexAppAdminGroupSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminGroupSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminGroupSection2DesktopSnapControlBtnSpan"}
                        >
                           1
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Crea grupos de chat
                            </h6>}
                            {props.lang === "en" && <h6>
                                Create chat groups
                            </h6>}
                            {props.lang === "es" && <label>
                                Un grupo te permite organizar a tus conductores con el objetivo de mensajear entre administración y conductores o solo entre conductores.
                            </label>}
                            {props.lang === "en" && <label>
                                A group allows you to organize your drivers with the aim of messaging between management and drivers or just between drivers.
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminGroupSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_group_1.png").default}
                            alt={"dvgeo_admin_group_1"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppAdminGroupSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminGroupSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminGroupSection2DesktopSnapControlBtnSpan"}
                        >
                           2
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Asigna a sus conductores
                            </h6>}
                            {props.lang === "en" && <h6>
                                Assign your drivers
                            </h6>}
                            {props.lang === "es" && <label>
                                Simplemente selecciona un conductor para agregarlo al grupo.
                            </label>}
                            {props.lang === "en" && <label>
                                Simply select a driver to add to the group.
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminGroupSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_group_2.png").default}
                            alt={"dvgeo_admin_group_2"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppAdminGroupSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminGroupSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminGroupSection2DesktopSnapControlBtnSpan"}
                        >
                           3
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Bandeja de entrada
                            </h6>}
                            {props.lang === "en" && <h6>
                                Inbox
                            </h6>}
                            {props.lang === "es" && <label>
                                Chatea con tus conductores con una pantalla optimizada para recibir y enviar mensajes masivos
                            </label>}
                            {props.lang === "en" && <label>
                                Chat with your drivers with a screen optimized to receive and send mass messages
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminGroupSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_group_3.png").default}
                            alt={"dvgeo_admin_group_3"}
                        />
                    </div>
                </section>
            </div>
            {/*apps en modo desktop*/}
            <div
                className={"IndexAppAdminGroupSection2Desktop"}
            >
                <div
                    ref={indexAppAdminServiceSection1DesktopSnapContentRef}
                    className={"IndexAppAdminGroupSection2DesktopSnapControl"}
                >
                    <button
                        className={index === 0 ? "IndexAppAdminGroupSection2DesktopSnapControlBtnFocus" : "IndexAppAdminGroupSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(0);
                            setIndex(0);
                            clearIntervals();
                        }}
                    >
                        <span
                            className={index === 0 ? "IndexAppAdminGroupSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminGroupSection2DesktopSnapControlBtnSpan"}
                        >
                           1
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Crea grupos de chat
                            </h6>}
                            {props.lang === "en" && <h6>
                                Create chat groups
                            </h6>}
                            {props.lang === "es" && <label>
                                Un grupo te permite organizar a tus conductores con el objetivo de mensajear entre administración y conductores o solo entre conductores.
                            </label>}
                            {props.lang === "en" && <label>
                                A group allows you to organize your drivers with the aim of messaging between management and drivers or just between drivers.
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 1 ? "IndexAppAdminGroupSection2DesktopSnapControlBtnFocus" : "IndexAppAdminGroupSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(1);
                            setIndex(1);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 1 ? "IndexAppAdminGroupSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminGroupSection2DesktopSnapControlBtnSpan"}
                       >
                           2
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Asigna a sus conductores
                            </h6>}
                            {props.lang === "en" && <h6>
                                Assign your drivers
                            </h6>}
                            {props.lang === "es" && <label>
                                Simplemente selecciona un conductor para agregarlo al grupo.
                            </label>}
                            {props.lang === "en" && <label>
                                Simply select a driver to add to the group.
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 2 ? "IndexAppAdminGroupSection2DesktopSnapControlBtnFocus" : "IndexAppAdminGroupSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(2);
                            setIndex(2);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 2 ? "IndexAppAdminGroupSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminGroupSection2DesktopSnapControlBtnSpan"}
                       >
                           3
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                               Bandeja de entrada
                            </h6>}
                            {props.lang === "en" && <h6>
                                Inbox
                            </h6>}
                            {props.lang === "es" && <label>
                                Chatea con tus conductores con una pantalla optimizada para recibir y enviar mensajes masivos
                            </label>}
                            {props.lang === "en" && <label>
                                Chat with your drivers with a screen optimized to receive and send mass messages
                            </label>}
                        </section>
                    </button>
                </div>
                <div
                    ref={indexAppAdminServiceSection1DesktopSnapContentRef}
                    className={"IndexAppAdminGroupSection2DesktopSnapContent"}
                >
                    <div
                        className={"IndexAppAdminGroupSection2DesktopSnapContentBackground"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_monitor.png").default}
                            alt={""}
                        />
                    </div>
                    <section
                        ref={ref => items.current[0] = ref}
                        className={"IndexAppAdminGroupSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_group_1.png").default}
                            alt={"dvgeo_admin_group_2"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[1] = ref}
                        className={"IndexAppAdminGroupSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_group_2.png").default}
                            alt={"dvgeo_admin_group_3"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[2] = ref}
                        className={"IndexAppAdminGroupSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_group_3.png").default}
                            alt={"dvgeo_admin_group_1"}
                        />
                    </section>
                </div>
            </div>
        </div>
    )
}
export default IndexAppAdminGroupSection2;
