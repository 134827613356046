import React from "react";
//theme
import "./styles.scss";
//libs
import {useHistory} from "react-router-dom";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminSection9 = (props: Props, ref: any) => {
    //hooks
    const history = useHistory();

    return (
        <div
            ref={ref}
            className={"IndexAppAdminSection9Content"}
        >
            <div
                className={"IndexAppAdminSection9 js-scroll slide-left"}
            >
                <div
                    className={"IndexAppAdminSection9Img"}
                >
                    <img
                        src={require("../../../../../assets/img/admin_vehicle_1.png").default}
                        alt={"dvgeo_admin_user_1"}
                    />
                </div>
                <section
                    className={"IndexAppAdminSection9Sec"}
                >
                    {props.lang === "es" && <h1>
                        <strong>Supervisa los vehículos</strong> de tus conductores
                    </h1>}
                    {props.lang === "en" && <h1>
                        Monitor your drivers' <strong>vehicles</strong>
                    </h1>}
                    {props.lang === "es" && <p>
                        Un conductor puede tener varios <strong>vehículos asignados</strong>, el aplicativo Admin permite controlar el vehículo de turno para brindar seguridad y confianza a los usuarios
                    </p>}
                    {props.lang === "en" && <p>
                        A driver can have several <strong>assigned vehicles</strong>, the Admin application allows controlling the vehicle on duty to provide security and confidence to users
                    </p>}
                    <button
                        onClick={() => {
                            history.push(`/${props.lang}/app/admin/module/vehicle`);
                        }}
                    >
                        {props.lang === "es" && <label>
                            Más info
                        </label>}
                        {props.lang === "en" && <label>
                            More Info
                        </label>}
                    </button>
                </section>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppAdminSection9);
