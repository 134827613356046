import React, {useEffect, useState} from "react";
//theme
import "./view.scss";
//store
//API
import {CustomerSupportAPI} from "../../../../api/customer_support/customerSupportAPI";
//models
import {Customer} from "../../../../api/customer/customerModel";
import {CustomerSupport} from "../../../../api/customer_support/customerSupportModel";
//libs
//components
import NavBarTop from "../../../_components/NavBarTop";
import CustomerNavBarMenu from "../../_components/CustomerNavBarMenu";
import SnackBar from "../../../_components/SnackBar";
import CustomerSupportBanner1 from "./banner_1";
import Footer from "../../../_components/Footer";
import CustomerSupportSection1 from "./section_1";
//types
import {ErrorException, Lang} from "../../../../types";
import {SystemSnackbar} from "../../../../store/actions/system.ac";

//interfaces
interface Props {
    lang: Lang;
    customer: Customer;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
    deleteCustomer?: () => void;
}

//const
const TAG = `CustomerSupportView`;
const isDev = (process.env.NODE_ENV !== "production");
//view
const CustomerSupportView = (props: Props) => {
    //hooks
    //refs
    //state
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [customer_supports, setCustomerSupports] = useState<Array<CustomerSupport>>([]);
    //effects
    useEffect(() => {
        getData();
        return () => {

        }
    }, []);
    useEffect(() => {
        if (props.customer && props.customer.id && typeof props.customer.id !== "undefined") {
            getCustomerSupports(Number(props.customer.id))
        }
    }, [props.customer]);

    const getData = async () => {
        try {

        } catch (error: ErrorException | any) {
            isDev && console.error(TAG + "//getData", error);
        }
    }

    const getCustomerSupports = async (customer_id: number) => {
        isDev && console.log(TAG + "//getCustomerSupports", "Cargando documentos del cliente...");
        await CustomerSupportAPI.getByCustomerId(customer_id, "es").then(async res_1 => {
                isDev && console.log(TAG + "//getCustomerSupports", res_1.code, res_1.message, res_1.error);
                if (res_1.code === 200) {
                    setCustomerSupports(res_1.customer_supports as Array<CustomerSupport>);
                } else if (res_1.code === 409) {

                } else {
                    props.showSnackbar && props.showSnackbar({
                        message: res_1.message,
                        severity: "warning"
                    });
                }
            }
        ).catch(async res_1 => {
            isDev && console.error(TAG + "//getCustomerSupports", res_1.code, res_1.error, res_1);
            props.showSnackbar && props.showSnackbar({
                message: res_1.error ? res_1.error : JSON.stringify(res_1.error),
                severity: "warning"
            });
        });
    }

    return (
        <div
            className={"CustomerSupportView"}
        >
            <NavBarTop
                lang={props.lang}
                customer={props.customer}
                deleteCustomer={props.deleteCustomer}
            />
            <CustomerNavBarMenu
                lang={props.lang}
            />
            <CustomerSupportBanner1
                lang={props.lang}
            />
            <CustomerSupportSection1
                lang={props.lang}
                customer_supports={customer_supports}
                isLoading={isLoading}
            />
            <Footer
                lang={props.lang}
                customer={props.customer}
            />
            <SnackBar/>
        </div>
    )
}
export default CustomerSupportView;
