import React, {CSSProperties} from "react";
//theme
import "./Modal.scss";
import {TouchableOpacity} from "react-native";
import CircularProgress from '@material-ui/core/CircularProgress';
//libs
import config from "../../config";
//types
//interfaces
//const
//component
const Modal = (props: { title?: any; subtitle?: any; children?: any; isBtnCancel?: boolean; isBtnAccept?: boolean, btnCancelLabel?: string; btnAcceptLabel?: string; disabledButtons?: boolean; loading?: boolean; fullScreen?: boolean; mode: "modal" | "guide"; bodyStyle?: CSSProperties; onHide?: () => void; onConfirm?: () => void; }) => {
    return (
        <>
            {props.mode === "modal" && <div
                className={"Modal"}
            >
                <div
                    className={props.fullScreen ? "ModalContentFullScreen" : "ModalContent"}
                >
                    {(props.title || props.subtitle) && <ModalTitle
                        title={props.title}
                        subtitle={props.subtitle}
                    />}
                    <div
                        style={{
                            ...props.bodyStyle && props.bodyStyle
                        }}
                        className={"ModalContentBody"}
                    >
                        {props.children && props.children}
                    </div>
                    {props.loading ? <div
                        className={"ModalContentButtonLoading"}
                    >
                        <CircularProgress
                            color={"inherit"}
                            style={{
                                color: config.colorPrimary
                            }}
                            size={30}
                        />
                    </div> : (props.isBtnCancel || props.isBtnAccept) && <div
                        className={"ModalContentButton"}
                    >
                        {props.isBtnCancel && <ModalContentButtonItem
                            label={props.btnCancelLabel ? props.btnCancelLabel : "Cancelar"}
                            disabled={props.disabledButtons}
                            onClick={props.onHide}
                        />}
                        {props.isBtnAccept && <ModalContentButtonItem
                            label={props.btnAcceptLabel ? props.btnAcceptLabel : "Aceptar"}
                            disabled={props.disabledButtons}
                            onClick={props.onConfirm}
                        />}
                    </div>}
                </div>
            </div>}
            {props.mode === "guide" && <div
                className={"ModalGuide"}
            >
                <div
                    className={"ModalContent"}
                >
                    {(props.title || props.subtitle) && <ModalTitle
                        title={props.title}
                        subtitle={props.subtitle}
                    />}
                    <div
                        style={{
                            ...props.bodyStyle && props.bodyStyle
                        }}
                        className={"ModalContentBody"}
                    >
                        {props.children && props.children}
                    </div>
                    {props.loading ? <div
                        className={"ModalContentButtonLoading"}
                    >
                        <CircularProgress
                            color={"inherit"}
                            style={{
                                color: config.colorPrimary
                            }}
                            size={30}
                        />
                    </div> : (props.isBtnCancel || props.isBtnAccept) && <div
                        className={"ModalContentButton"}
                    >
                        {props.isBtnCancel && <ModalContentButtonItem
                            label={props.btnCancelLabel ? props.btnCancelLabel : "Cancelar"}
                            disabled={props.disabledButtons}
                            onClick={props.onHide}
                        />}
                        {props.isBtnAccept && <ModalContentButtonItem
                            label={props.btnAcceptLabel ? props.btnAcceptLabel : "Aceptar"}
                            disabled={props.disabledButtons}
                            onClick={props.onConfirm}
                        />}
                    </div>}
                </div>
            </div>}
        </>
    )
}


export const ModalTitle = (props: { title?: any; subtitle?: any; }) => {
    return (
        <div
            className={"ModalContentTitle"}
        >
            {props.title && <label
                className={"ModalContentTitle1"}
            >
                {props.title}
            </label>}
            {props.subtitle && <label
                className={"ModalContentTitle2"}
            >
                {props.subtitle}
            </label>}
        </div>
    )
}

const ModalContentButtonItem = (props: { icon?: any; label: string; disabled?: boolean; onClick?: () => void; }) => {
    return (
        <div
            className={"ModalContentButtonItem"}
        >
            <TouchableOpacity
                disabled={props.disabled}
                onPress={() => props.onClick && props.onClick()}
            >
                <div
                    className={props.disabled ? "ModalContentButtonItemContentDisabled" : "ModalContentButtonItemContent"}
                >
                    {props.icon && <div
                        className={"ModalContentButtonItemIcon"}
                    >
                        {props.icon}
                    </div>}
                    {props.label && <div
                        className={"ModalContentButtonItemLabel"}
                    >
                        {props.label}
                    </div>}
                </div>
            </TouchableOpacity>
        </div>
    )
}

export default Modal;
