import React from "react";
//theme
import "./styles.scss";
//models
//libs
import {Link} from "react-router-dom";
import {Breadcrumbs, Typography} from "@material-ui/core";
//types
import {Lang} from "../../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const CustomerSupportTrackingNewViewBanner1 = (props: Props) => {
    return (
        <div
            className={"CustomerSupportTrackingNewViewBanner1"}
        >
            <h1>
                {props.lang === "es" && `Nuevo ticket de soporte`}
                {props.lang === "en" && `New support ticket`}
            </h1>
            <Breadcrumbs
                className={"CustomerSupportTrackingNewViewBanner1LinkContent"}
            >
                <Link
                    className={"CustomerSupportTrackingNewViewBanner1Link"}
                    to={`/${props.lang}/application/customer`}
                >
                    {props.lang === "es" && "Inicio"}
                    {props.lang === "en" && "Home"}
                </Link>
                <Link
                    className={"CustomerSupportTrackingNewViewBanner1Link"}
                    to={`/${props.lang}/application/customer/support`}
                >
                    {props.lang === "es" && "Soporte"}
                    {props.lang === "en" && "Support"}
                </Link>
                <Typography
                    color="inherit"
                >
                    {props.lang === "es" && "Nuevo"}
                    {props.lang === "en" && "New"}
                </Typography>
            </Breadcrumbs>
        </div>
    )
}
export default CustomerSupportTrackingNewViewBanner1;
