import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexFaqSection3 = (props: Props) => {
    //state
    const [indexGroup, setIndexGroup] = useState<Array<boolean>>([false, false, false, false]);

    const setIndexGroupFocus = (index: number) => {
        let indexGroupNew: Array<boolean> = [...indexGroup];
        for (let i = 0; i < indexGroupNew.length; i++) {
            if (i === index) {
                indexGroupNew[i] = !indexGroupNew[i];
            } else {
                indexGroupNew[i] = false;
            }
        }
        setIndexGroup(indexGroupNew);
    }

    return (
        <div
            className={"IndexFaqSection3"}
        >
            <div
                className={"IndexFaqSection3Title"}
            >
                {props.lang === "es" && <h1
                >
                    Aplicativo Pasajero
                </h1>}
                {props.lang === "en" && <h1
                >
                    Passenger App
                </h1>}
            </div>
            <section
                className={"IndexFaqSection3Sec"}
            >
                <button
                    className={"IndexFaqSection1SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(0)}
                >
                    {props.lang === "es" && <h2>
                        ¿La aplicación pasajero tendrá el logotipo y nombre de mi empresa?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Will the passenger application have my company logo and name?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[0] && <div
                    className={"IndexFaqSection1SecGroup"}
                >
                    {props.lang === "es" && <p>
                        ¡Sí! DvGeo crea tu app de pasajero con tu nombre, logotipo y colores corporativos. Solo los
                        vehículos de tu flota serán visibles con tu propia base de datos.
                    </p>}
                    {props.lang === "en" && <p>
                        Indeed, the three apps on the DvGeo platform: Admin, Passenger and Driver will be available with
                        your company logo.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(1)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo ver dónde está el vehículo que he reservado y cuándo va a venir a recogerme?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I see where the vehicle I have reserved is and when it is coming to pick me up?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[1] && <div
                    className={"IndexFaqSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        ¡Claro! En el mapa de la aplicación podrás ver la posición en tiempo real del vehículo que se ha
                        asignado, así como una estimación del tiempo que que tardará en recogerte.
                    </p>}
                    {props.lang === "en" && <p>
                        Of course! On the map of the application you will be able to see the real-time position of the
                        vehicle that has been assigned, as well as an estimate of the time it will take to pick you up.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(2)}
                >
                    {props.lang === "es" && <h2>
                        ¿Es posible evaluar el conductor que me recogió?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Is it possible to evaluate the driver who picked me up?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[2] && <div
                    className={"IndexFaqSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        ¡Sí! Una vez que el servicio ha terminado, el app te da la posibilidad de calificar a tu
                        conductor y el viaje con 1 a 5 estrellas. También puedes dejar un comentario para describir tu
                        experiencia. Esta información se guarda y está disponible en el Panel de Administración. También
                        puedes leer los comentarios anteriores en el historial de viajes.
                    </p>}
                    {props.lang === "en" && <p>
                        Yes! Once the service has finished, the app gives you the possibility to rate your driver and
                        the trip with 1 to 5 stars. You can also leave a comment to describe your experience. This
                        information is saved and available in the Administration Panel. You can also read the previous
                        comments in the travel history.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(3)}
                >
                    {props.lang === "es" && <h2>
                        ¿Los pasajeros pueden reportar un problema que ocurrió durante el servicio o viaje?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can passengers report a problem that occurred during service or travel?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[3] && <div
                    className={"IndexFaqSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Si, todo viaje o servicio quedá registrado en cada aplicativo, el pasajero podrá denunciar o
                        reportar a la administración de tu empresa.
                    </p>}
                    {props.lang === "en" && <p>
                        Yes, every trip or service is registered in each application, the passenger may report or report
                        to the administration of your company.
                    </p>}
                </div>}
            </section>
        </div>
    )
}
export default IndexFaqSection3;
