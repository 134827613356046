import React from "react";
//theme
import "./styles.scss";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexContactSection1 = (props: Props) => {
    return (
        <div
            className={"IndexContactSection1"}
        >
            {props.lang === "es" && <h1
                className={"IndexContactSection1Title"}
            >
                Contáctenos:
            </h1>}
            {props.lang === "en" && <h1
                className={"IndexContactSection1Title"}
            >
                Contact Us:
            </h1>}
            <div
                className={"IndexContactSection1Body"}
            >
                <section
                    className={"IndexContactSection1BodySec"}
                >
                    {props.lang === "es" && <label>
                        Servicio al cliente
                    </label>}
                    {props.lang === "en" && <label>
                        Customer service
                    </label>}
                    <a
                        href={"mailto:devjos.ec@gmail.com"}
                    >
                        devjos.ec@gmail.com
                    </a>
                </section>
                <section
                    className={"IndexContactSection1BodySec"}
                >
                    {props.lang === "es" && <label>
                        Ventas DvGeo
                    </label>}
                    {props.lang === "en" && <label>
                        DvGeo Sales
                    </label>}
                    <a
                        href={"mailto:dvgeo.ec@gmail.com"}
                    >
                        dvgeo.ec@gmail.com
                    </a>
                </section>
                <section
                    className={"IndexContactSection1BodySec"}
                >
                    {props.lang === "es" && <label>
                        Número Móvil / WhatsApp
                    </label>}
                    {props.lang === "en" && <label>
                        Mobile Number / WhatsApp
                    </label>}
                    <a
                        href={"tel:+593982348054"}
                    >
                        +593 982348054
                    </a>
                </section>
            </div>
        </div>
    )
}
export default IndexContactSection1;
