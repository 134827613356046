import React, {useEffect, useState} from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//types
import {Lang} from "../../../../../types";
import {faCar, faUser, faUsersCog} from "@fortawesome/free-solid-svg-icons";

//interfaces
interface Props {
    lang: Lang;
    indexMenuApp: number;
    onChangeIndex: (index: number) => void;
    onStopIntervalIndexMenuApp: (action: boolean) => void;
}

//component
const MenuApp = (props: Props) => {
    //state
    const [index, setIndex] = useState<number>(0);
    //effects
    useEffect(() => {
        setIndex(props.indexMenuApp);
    }, [props.indexMenuApp]);

    return (
        <div
            className={"MenuApp"}
        >
            <div
                className={"MenuAppOptions"}
            >
                <button
                    className={index === 0 ? "MenuAppOptionsOptionFocus" : "MenuAppOptionsOption"}
                    onClick={() => {
                        setIndex(0);
                        props.onChangeIndex(0);
                        props.onStopIntervalIndexMenuApp(true);
                    }}
                >
                    <i>
                        <FontAwesomeIcon
                            icon={faUsersCog}
                        />
                    </i>
                    {props.lang === "es" && <h6>
                        Aplicacion web
                    </h6>}
                    {props.lang === "en" && <h6>
                        Web App
                    </h6>}
                    {props.lang === "es" && <label>
                        Administrador
                    </label>}
                    {props.lang === "en" && <label>
                        Manager
                    </label>}
                </button>
                <button
                    className={index === 1 ? "MenuAppOptionsOptionFocus" : "MenuAppOptionsOption"}
                    onClick={() => {
                        setIndex(1);
                        props.onChangeIndex(1);
                        props.onStopIntervalIndexMenuApp(true);
                    }}
                >
                    <i>
                        <FontAwesomeIcon
                            icon={faUser}
                        />
                    </i>
                    {props.lang === "es" && <h6>
                        Aplicacion Móvil
                    </h6>}
                    {props.lang === "en" && <h6>
                        Mobile App
                    </h6>}
                    {props.lang === "es" && <label>
                        App Pasajero
                    </label>}
                    {props.lang === "en" && <label>
                        Passenger App
                    </label>}
                </button>
                <button
                    className={index === 2 ? "MenuAppOptionsOptionFocus" : "MenuAppOptionsOption"}
                    onClick={() => {
                        setIndex(2);
                        props.onChangeIndex(2);
                        props.onStopIntervalIndexMenuApp(true);
                    }}
                >
                    <i>
                        <FontAwesomeIcon
                            icon={faCar}
                        />
                    </i>
                    {props.lang === "es" && <h6>
                        Aplicacion Móvil
                    </h6>}
                    {props.lang === "en" && <h6>
                        Mobile App
                    </h6>}
                    {props.lang === "es" && <label>
                        App Conductor
                    </label>}
                    {props.lang === "en" && <label>
                        Driver App
                    </label>}
                </button>
            </div>

        </div>
    )
}
export default MenuApp;
