import React, {useEffect, useRef, useState} from "react";
//theme
import "../styles.scss";
//models
import {Customer} from "../../../../api/customer/customerModel";
//views
import NavBarTop from "../../../_components/NavBarTop";
import NavBarMenu from "../../../_components/NavBarMenu";
import Banner1 from "../_components/banner_1";
import AppMenu from "../_components/menu_app";
import AppMenuContainer from "../_components/menu_app_container";
import Section1 from "../_components/section_1";
import Section2 from "../_components/section_2";
import Footer from "../../../_components/Footer";
//interfaces
interface Props {
    customer: Customer;
    deleteCustomer?: () => void;
    deleteToken?: () => void;
}
//view
const IndexHomeEn = (props: Props) => {
    //refs
    const itemsRef = useRef<Array<any>>([]);
    const IndexHomeRef = useRef<any>(null);
    //state
    const [indexMenuApp, setIndexMenuApp] = useState<number>(0);
    const [isStopIntervalIndexMenuApp, setIsStopIntervalIndexMenuApp] = useState<boolean>(false);
    //effects
    useEffect(() => {
        document.title = `DvGeo Platform - Cloud Taxi Dispatch Software | System for taxi companies`;
        IndexHomeRef.current && IndexHomeRef.current.addEventListener('scroll', () => {
            handleScrollAnimation();
        })
        return () => {
            IndexHomeRef.current && IndexHomeRef.current.removeEventListener('scroll', () => {
                handleScrollAnimation();
            })
        }
    }, []);

    const scrollOffset = 100;

    const elementInView = (el: any, offset = 0) => {
        const elementTop = el.getBoundingClientRect().top;
        return (
            elementTop <= (IndexHomeRef.current.clientHeight - offset)
        );
    };

    const displayScrollElement = (element: any) => {
        element.childNodes[0].classList.add("scrolled");
    };


    const hideScrollElement = (element: any) => {
        element.childNodes[0].classList.remove('scrolled');
    }

    const handleScrollAnimation = () => {
        itemsRef.current && itemsRef.current.forEach((el: any) => {
            if (elementInView(el, scrollOffset)) {
                displayScrollElement(el);
            } else {
                hideScrollElement(el);
            }
        })
    }

    return (
        <div
            ref={IndexHomeRef}
            className={"IndexHome"}
        >
            <NavBarTop
                lang={"en"}
                customer={props.customer}
                deleteCustomer={props.deleteCustomer}
                deleteToken={props.deleteToken}
            />
            <NavBarMenu
                lang={"en"}
            />
            <Banner1
                lang={"en"}
            />
            <AppMenu
                lang={"en"}
                indexMenuApp={indexMenuApp}
                onChangeIndex={(index) => setIndexMenuApp(index)}
                onStopIntervalIndexMenuApp={(action) => setIsStopIntervalIndexMenuApp(action)}
            />
            <AppMenuContainer
                lang={"en"}
                indexMenuApp={indexMenuApp}
                isStopIntervalIndexMenuApp={isStopIntervalIndexMenuApp}
                onChangeIndex={(index) => setIndexMenuApp(index)}
            />
            <Section1
                ref={(ele) => itemsRef.current[0] = ele}
                lang={"en"}
            />
            <Section2
                ref={(ele) => itemsRef.current[1] = ele}
                lang={"en"}
            />
            <Footer
                lang={"en"}
                customer={props.customer}
            />
        </div>
    )
}
export default IndexHomeEn;
