//store
import {
    SystemStoreState, SystemStoreAction
} from '../actions/system.ac';
//state
const initialState: SystemStoreState = {
    token: "",
    is_drawer: true,
    drawer_open: false,
    drawer_title: "",
    drawer_subtitle: "",
    snackbar: {
        open: false,
        message: "",
        severity: "success",
        duration: 5000,
    },
    is_indicator_notification: false,
}
//module
const systemReducer = (state: SystemStoreState = initialState, action: SystemStoreAction) => {
    switch (action.type) {
        case "POST_TOKEN": {
            return {
                ...state,
                token: action.token,
            }
        }
        case "DELETE_TOKEN": {
            return {
                ...state,
                token: "",
            }
        }
        case "PUT_IS_DRAWER": {
            return {
                ...state,
                is_drawer: action.is_drawer,
            }
        }
        case "PUT_DRAWER_TITLE": {
            return {
                ...state,
                drawer_title: action.drawer_title,
            }
        }
        case "PUT_DRAWER_SUBTITLE": {
            return {
                ...state,
                drawer_subtitle: action.drawer_subtitle,
            }
        }
        case "PUT_DRAWER_FOCUS": {
            return {
                ...state,
                drawer_focus: action.drawer_focus,
            }
        }
        case "PUT_DRAWER_OPEN": {
            return {
                ...state,
                drawer_open: action.drawer_open,
            }
        }
        case "SHOW_SNACKBAR": {
            return {
                ...state,
                snackbar: {
                    ...state.snackbar,
                    ...action.snackbar,
                    open: true,
                    duration: action.snackbar?.duration === 0 ? undefined :  action.snackbar?.duration,
                },
            }
        }
        case "HIDE_SNACKBAR": {
            return {
                ...state,
                snackbar: {
                    ...state.snackbar,
                    open: false,
                },
            }
        }
        case "PUT_IS_INDICATOR_NOTIFICATION": {
            return {
                ...state,
                is_indicator_notification: action.is_indicator_notification,
            }
        }
        default:
            return state
    }
}
export default systemReducer;
