import React from "react";
//theme
import "./styles.scss";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexAppAdminVehicleBanner1 = (props: Props) => {
    return (
        <div
            className={"IndexAppAdminVehicleBanner1"}
        >
            <div
                className={"IndexAppAdminVehicleBanner1Background"}
            />
            <section
                className={"IndexAppAdminVehicleBanner1Sec"}
            >
                <div>
                    {props.lang === "es" && <h6
                    >
                        Módulo de
                    </h6>}
                    {props.lang === "en" && <h6
                    >
                        Module
                    </h6>}
                    {props.lang === "es" && <h1>
                        Vehículos
                    </h1>}
                    {props.lang === "en" && <h1>
                        Vehicles
                    </h1>}
                </div>
            </section>
        </div>
    )
}
export default IndexAppAdminVehicleBanner1;
