import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexFaqSection5 = (props: Props) => {
    //state
    const [indexGroup, setIndexGroup] = useState<Array<boolean>>([false, false, false, false, false, false, false, false, false, false]);

    const setIndexGroupFocus = (index: number) => {
        let indexGroupNew: Array<boolean> = [...indexGroup];
        for (let i = 0; i < indexGroupNew.length; i++) {
            if (i === index) {
                indexGroupNew[i] = !indexGroupNew[i];
            } else {
                indexGroupNew[i] = false;
            }
        }
        setIndexGroup(indexGroupNew);
    }

    return (
        <div
            className={"IndexFaqSection5"}
        >
            <div
                className={"IndexFaqSection5Title"}
            >
                {props.lang === "es" && <h1
                >
                    Aplicativo Administrador
                </h1>}
                {props.lang === "en" && <h1
                >
                    Manage App
                </h1>}
            </div>
            <section
                className={"IndexFaqSection5Sec"}
            >
                <button
                    className={"IndexFaqSection5SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(0)}
                >
                    {props.lang === "es" && <h2>
                        ¿El aplicativo Admin puede abrirse en dispositivos móviles?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can the Admin application be opened on mobile devices?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[0] && <div
                    className={"IndexFaqSection5SecGroup"}
                >
                    {props.lang === "es" && <p>
                        ¡Sí! El aplicativo Admin está optimizado para pantallas móviles, solo debes ingresar el link que
                        te brindamos para acceder desde cualquier dispositivo conectado a internet mediante un navegador
                        web.
                    </p>}
                    {props.lang === "en" && <p>
                        Yes! The Admin application is optimized for mobile screens, you just have to enter the link that
                        we provide to access from any device connected to the internet through a web browser.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection5SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(1)}
                >
                    {props.lang === "es" && <h2>
                        Cuando un cliente pide un servicio a través de una reserva online, ¿aparece en la consola de despacho?
                    </h2>}
                    {props.lang === "en" && <h2>
                        When a customer requests a service through an online reservation, does it appear in the dispatch console?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                { indexGroup[1]&& <div
                    className={"IndexFaqSection5SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Cualquier reserva online aparecerá en la sección de despacho. El sistema asignará el vehículo
                        correspondiente en función de la estrategia de asignación (proximidad, tiempo, etc).
                    </p>}
                    {props.lang === "en" && <p>
                        Any online reservation will appear in the dispatch section. The system will assign the
                        corresponding vehicle based on the assignment strategy (proximity, time, etc.).
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection5SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(2)}
                >
                    {props.lang === "es" && <h2>
                        ¿Cómo asigna el sistema servicios automáticamente?
                    </h2>}
                    {props.lang === "en" && <h2>
                        How does the system assign services automatically?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[2] && <div
                    className={"IndexFaqSection5SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Solo debes configurar a tus conductores para que estos reciban las solicitudes de servicio que
                        los pasajeros requieran.
                    </p>}
                    {props.lang === "en" && <p>
                        You just have to configure your drivers so that they receive the service requests that
                        passengers require.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection5SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(3)}
                >
                    {props.lang === "es" && <h2>
                        ¿Pueden varios despachadores conectarse al mismo tiempo?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can multiple dispatchers connect at the same time?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[3] && <div
                    className={"IndexFaqSection5SecGroup"}
                >
                    {props.lang === "es" && <p>
                        ¡Por supuesto! Puedes tener tantos despachadores online al mismo tiempo como desees. La
                        plataforma no tiene límites.
                    </p>}
                    {props.lang === "en" && <p>
                        Of course! You can have as many dispatchers online at the same time as you want. The platform
                        has no limits.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection5SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(4)}
                >
                    {props.lang === "es" && <h2>
                        ¿Es posible ver las rutas que los conductores han realizado?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Is it possible to see the routes that the drivers have taken?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[4] && <div
                    className={"IndexFaqSection5SecGroup"}
                >
                    {props.lang === "es" && <p>
                        ¡Sí! Sigue las rutas que han viajado, su velocidad media, distancia media y otra información
                        útil. Esto te dará una visión general de las operaciones de tu empresa, la eficiencia de los
                        vehículos y sus rutas reservadas más populares.
                    </p>}
                    {props.lang === "en" && <p>
                        Yes! Track the routes they have traveled, their average speed, average distance, and other
                        useful information. This will give you an overview of your business operations, vehicle
                        efficiency, and their most popular booked routes.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection5SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(5)}
                >
                    {props.lang === "es" && <h2>
                        ¿Cuántos operadores de despacho o conductores podemos añadir en el panel administrativo?
                    </h2>}
                    {props.lang === "en" && <h2>
                        How many dispatch operators or drivers can we add to the administrative panel?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[5] && <div
                    className={"IndexFaqSection5SecGroup"}
                >
                    {props.lang === "es" && <p>
                        No hay límite para añadir operadores de despacho y conductores. El sistema solo cobra por la
                        cantidad total de viajes mensualmente.
                    </p>}
                    {props.lang === "en" && <p>
                        There is no limit to adding dispatch operators and drivers. The system only charges for the
                        total amount of trips on a monthly basis.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection5SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(6)}
                >
                    {props.lang === "es" && <h2>
                        ¿Se puede generar informes de facturación y planillas de salario para conductores y clientes?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can you generate billing reports and pay sheets for drivers and customers?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[6] && <div
                    className={"IndexFaqSection5SecGroup"}
                >
                    {props.lang === "es" && <p>
                        ¡Por supuesto! Puedes descargar facturas para enviarlas a las cuentas personales y corporativas,
                        así como generar informes detallados para ver fácilmente el precio a cobrar para cada conductor.
                        En caso de necesitar un formato de informe que no esté dentro de nuestras opciones usuales,
                        puedes solicitar también informes personalizados.
                    </p>}
                    {props.lang === "en" && <p>
                        Of course! You can download invoices to send them to personal and corporate accounts, as well as
                        generate detailed reports to easily see the price to charge for each driver. In case you need a
                        report format that is not within our usual options, you can also request custom reports.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection5SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(7)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo registrar los departamentos de mi empresa para dar acceso a mas asesores o empleados?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I register the departments of my company to give access to more advisors or employees?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[7] && <div
                    className={"IndexFaqSection5SecGroup"}
                >
                    {props.lang === "es" && <p>
                        ¡Claro! Puedes registrar los departamentos como perfiles y a cada uno darles permisos de acceso
                        para cada función del aplicativo Admin.
                    </p>}
                    {props.lang === "en" && <p>
                        Of course! You can register the departments as profiles and give each one access permissions for
                        each function of the Admin application.
                    </p>}
                </div>}
            </section>
        </div>
    )
}
export default IndexFaqSection5;
