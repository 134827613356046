import React, {CSSProperties} from "react";
//theme
import "./Button.scss";
import {TouchableOpacity} from "react-native";
import CircularProgress from "@material-ui/core/CircularProgress";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
//types
//interfaces
interface Props {
    style?: CSSProperties;
    styleContent?: CSSProperties;
    color: "primary" | "dark" | "white";
    loading?: boolean;
    disabled?: boolean;
    children: any;
    fullWidth?: boolean;
    iconStart?: any;
    iconEnd?: any;
    onClick?: () => void;
}

//component
const Button = (props: Props) => {
    return (
        <label
            className={"ButtonContent"}
            style={{
                ...props.style && props.style,
                width: props.fullWidth ? "100%" : "200px"
            }}
        >
            <TouchableOpacity
                onPress={() => props.onClick && props.onClick()}
                disabled={props.disabled}
            >
                <div
                    style={{
                        ...props.styleContent && props.styleContent,
                    }}
                    className={props.disabled ? "ButtonDisabled" : props.color === "primary" ? "ButtonPrimary" : props.color === "dark" ? "buttonDark" : "Button"}
                >
                    {props.loading ? <CircularProgress
                        color={"inherit"}
                        size={20}
                    /> : <React.Fragment>
                        {props.iconStart && <div
                            className={"ButtonIcon"}
                        >
                            <FontAwesomeIcon
                                icon={props.iconStart}
                            />
                        </div>}
                        {props.children && <div
                            className={"ButtonChild"}
                        >
                            {props.children}
                        </div>}
                        {props.iconEnd && <div
                            className={"ButtonIcon"}
                        >
                            <FontAwesomeIcon
                                icon={props.iconEnd}
                            />
                        </div>}
                    </React.Fragment>}
                </div>
            </TouchableOpacity>
        </label>
    )
}

export default Button;
