import React from "react";
//theme
//store
import {hideSnackbar, showSnackbar, SystemSnackbar} from "../../store/actions/system.ac";
//libs
import {connect} from "react-redux";
//views
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
//types
import {StoreDispatchProps, StoreStateProps} from "../../store/types";

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

//interfaces
interface OwnProps {

}

//const
//component
const SnackBar = (props: Props) => {

    const hideSnackbar = () => {
        props.hideSnackbar && props.hideSnackbar();
    }

    return (
        <Snackbar
            open={props.system?.snackbar?.open}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            autoHideDuration={props.system?.snackbar?.duration}
            onClose={() => props.system?.snackbar?.duration !== undefined && hideSnackbar()}
        >
            {(props.system?.snackbar?.duration !== undefined) ? <Alert
                severity={props.system.snackbar.severity}
                variant={"filled"}
                onClose={() => hideSnackbar()}
            >
                {props.system?.snackbar?.message}
            </Alert> : <Alert
                severity={props.system?.snackbar?.severity}
                variant={"filled"}
            >
                {props.system?.snackbar?.message}
            </Alert>}
        </Snackbar>
    )
}
const mapStateToProps = (state: StoreStateProps) => ({
    system: state.system,
})
const maDispatchToProps = {
    //system
    showSnackbar: (snackbar: SystemSnackbar) => (showSnackbar(snackbar)),
    hideSnackbar: () => (hideSnackbar()),
}

export default connect(mapStateToProps, maDispatchToProps)(SnackBar);
