import React from "react";
//theme
import "../styles.scss";
//view
const AdminHomeEs = () => {
    return (
        <div
            className={"AdminHome"}
        >

        </div>
    )
}
export default AdminHomeEs;
