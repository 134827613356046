//libs
import config from '../../config';
import ResolverFetch from "../../libs/_resolver_fetch";
import {getToken} from "../system/_token";
//model
import {CustomerSupportTracking, CustomerSupportTrackingResponse} from "./customerSupportTrackingModel";
//types
import {Lang} from "../../types";
//api
export const CustomerSupportTrackingAPI = {
    getByCustomerSupportId: async (customer_support_id: number, lang: Lang) => {
        return await new Promise<CustomerSupportTrackingResponse>(async (resolve: (value: CustomerSupportTrackingResponse) => void, reject: (reason: CustomerSupportTrackingResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support/tracking?type=getByCustomerSupportId&customer_support_id=${customer_support_id}&lang=${lang}`, {
                "method": "GET",
                "headers": {
                    "Accept": "application/json",
                    "authorization": await getToken()
                },
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support_trackings: (res.is_resolve && res.data) && res.data.customer_support_tracking,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    insert: async (body: { model: CustomerSupportTracking }, lang: Lang) => {
        return await new Promise<CustomerSupportTrackingResponse>(async (resolve: (value: CustomerSupportTrackingResponse) => void, reject: (reason: CustomerSupportTrackingResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support/tracking?type=insert&lang=${lang}`, {
                "method": "POST",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "authorization": await getToken()
                },
                "body": JSON.stringify(body),
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support_tracking: (res.is_resolve && res.data) && res.data.customer_support_tracking,
                    id: (res.is_resolve && res.data) && res.data.id,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    updateImageCustomer: async (body: { model: CustomerSupportTracking & CustomerSupportTracking["photo"] }, lang: Lang) => {
        return await new Promise<CustomerSupportTrackingResponse>(async (resolve: (value: CustomerSupportTrackingResponse) => void, reject: (reason: CustomerSupportTrackingResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support/tracking?type=updateImageCustomer&lang=${lang}`, {
                "method": "PUT",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "authorization": await getToken()
                },
                "body": JSON.stringify(body),
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support_tracking: (res.is_resolve && res.data) && res.data.customer_support_tracking,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    updateImageAdmin: async (body: { model: CustomerSupportTracking & CustomerSupportTracking["photo"] }, lang: Lang) => {
        return await new Promise<CustomerSupportTrackingResponse>(async (resolve: (value: CustomerSupportTrackingResponse) => void, reject: (reason: CustomerSupportTrackingResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support/tracking?type=updateImageAdmin&lang=${lang}`, {
                "method": "PUT",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "authorization": await getToken()
                },
                "body": JSON.stringify(body),
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support_tracking: (res.is_resolve && res.data) && res.data.customer_support_tracking,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    deleteAll: async (customer_support_id: number, lang: Lang) => {
        return await new Promise<CustomerSupportTrackingResponse>(async (resolve: (value: CustomerSupportTrackingResponse) => void, reject: (reason: CustomerSupportTrackingResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support/tracking?type=deleteAll&customer_support_id=${customer_support_id}&lang=${lang}`, {
                "method": "DELETE",
                "headers": {
                    "Accept": "application/json",
                    "authorization": await getToken()
                },
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support_tracking: (res.is_resolve && res.data) && res.data.customer_support_tracking,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
}
