//libs
import config from '../../config';
import ResolverFetch from "../../libs/_resolver_fetch";
import {getToken, setToken} from "../system/_token";
//model
import {Customer, CustomerResponse} from "./customerModel";
//types
import {Lang} from "../../types";

//api
export const CustomerAPI = {
    login: async (email: string, password: string, lang: Lang) => {
        return await new Promise<CustomerResponse>(async (resolve: (value: CustomerResponse) => void, reject: (reason: CustomerResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer?type=login&email=${email}&password=${password}&lang=${lang}`, {
                "method": "GET",
                "headers": {
                    "Accept": "application/json",
                    "authorization": await getToken()
                },
            }).then(async res => {
                setToken((res.is_resolve && res.data) && res.data.token);
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer: (res.is_resolve && res.data) && res.data.customer,
                    token: (res.is_resolve && res.data) && res.data.token,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    getById: async (id: number, lang: Lang) => {
        return await new Promise<CustomerResponse>(async (resolve: (value: CustomerResponse) => void, reject: (reason: CustomerResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer?type=getById&id=${id}&lang=${lang}`, {
                "method": "GET",
                "headers": {
                    "Accept": "application/json",
                    "authorization": await getToken()
                },
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer: (res.is_resolve && res.data) && res.data.customer,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    insert: async (body: { model: Customer }, lang: Lang) => {
        return await new Promise<CustomerResponse>(async (resolve: (value: CustomerResponse) => void, reject: (reason: CustomerResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer?type=register&lang=${lang}`, {
                "method": "POST",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "authorization": await getToken()
                },
                "body": JSON.stringify(body),
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer: (res.is_resolve && res.data) && res.data.customer,
                    id: (res.is_resolve && res.data) && res.data.id,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    update: async (body: { model: Customer }, lang: Lang) => {
        return await new Promise<CustomerResponse>(async (resolve: (value: CustomerResponse) => void, reject: (reason: CustomerResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer?type=update&lang=${lang}`, {
                "method": "PUT",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "authorization": await getToken()
                },
                "body": JSON.stringify(body),
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer: (res.is_resolve && res.data) && res.data.customer,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    updateShippingAddress: async (body: { model: Customer }, lang: Lang) => {
        return await new Promise<CustomerResponse>(async (resolve: (value: CustomerResponse) => void, reject: (reason: CustomerResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer?type=updateShippingAddress&lang=${lang}`, {
                "method": "PUT",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "authorization": await getToken()
                },
                "body": JSON.stringify(body),
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer: (res.is_resolve && res.data) && res.data.customer,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    updatePassword: async (body: { model: Customer }, lang: Lang) => {
        return await new Promise<CustomerResponse>(async (resolve: (value: CustomerResponse) => void, reject: (reason: CustomerResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer?type=updatePassword&lang=${lang}`, {
                "method": "PUT",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "authorization": await getToken()
                },
                "body": JSON.stringify(body),
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer: (res.is_resolve && res.data) && res.data.customer,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    updateImage: async (body: { model: Customer & Customer["photo"] }, lang: Lang) => {
        return await new Promise<CustomerResponse>(async (resolve: (value: CustomerResponse) => void, reject: (reason: CustomerResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer?type=updateImage&lang=${lang}`, {
                "method": "PUT",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "authorization": await getToken()
                },
                "body": JSON.stringify(body),
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer: (res.is_resolve && res.data) && res.data.customer,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    resetPassword: async (body: { model: Customer & Customer["photo"] }, lang: Lang) => {
        return await new Promise<CustomerResponse>(async (resolve: (value: CustomerResponse) => void, reject: (reason: CustomerResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer?type=resetPassword&lang=${lang}`, {
                "method": "PUT",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "authorization": await getToken()
                },
                "body": JSON.stringify(body),
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer: (res.is_resolve && res.data) && res.data.customer,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
}
