import React from "react";
//theme
import "./Footer.scss";
//models
import {Customer} from "../../api/customer/customerModel";
//libs
import config from "../../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faYoutube, faTwitter, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {Tooltip} from "@material-ui/core";
import {Link} from "react-router-dom";
//types
import {Lang} from "../../types";

//interface
interface Props {
    lang: Lang;
    customer?: Customer;
}

//component
const Footer = (props: Props) => {

    return (
        <div
            className={"Footer"}
        >
            <button
                className={"FooterWs"}
                onClick={() => window.open(`https://api.whatsapp.com/send?phone=593982348054&text=Vengo del sitio DvGeo Platform y quiero más información...`)}
            >
                <FontAwesomeIcon icon={faWhatsapp}/>
            </button>
            <div
                className={"FooterLinks"}
            >
                <section
                    className={"FooterLinksConcept"}
                >
                    <Link
                        to={`/${props.lang}`}
                    >
                        <img
                            src={require("../../assets/img/navbar_logo.png").default}
                            alt={"dvgeo_logo"}
                        />
                    </Link>
                    {props.lang === "es" && <p>
                        <strong>DvGeo</strong><i> (se pronuncia "dev-yio")</i>, es una plataforma basada en la nube, con
                        todas las apps, módulos y funciones que necesita tu empresa de taxi o compañía de servicio
                        ejecutivo para establecerse en el mercado digital.
                    </p>}
                    {props.lang === "en" && <p>
                        <strong>DvGeo</strong><i> (pronounced "dev-yio")</i>, is a cloud-based platform, with all the
                        apps, modules and functions that your taxi company or executive service company needs to
                        establish itself in the digital market.
                    </p>}
                </section>
                <section
                    className={"FooterLinksNavBar"}
                >
                    <section>
                        <Link
                            className={(window.location.pathname.includes("app/admin")) ? "FooterLinksNavBarLinkFocus" : "FooterLinksNavBarLink"}
                            to={`/${props.lang}/app/admin`}
                        >
                            {props.lang === "es" && <label>
                                App Admin
                            </label>}
                            {props.lang === "en" && <label>
                                Manage App
                            </label>}
                        </Link>
                        <Link
                            className={(window.location.pathname.includes("app/passenger")) ? "FooterLinksNavBarLinkFocus" : "FooterLinksNavBarLink"}
                            to={`/${props.lang}/app/passenger`}
                        >
                            {props.lang === "es" && <label>
                                App Pasajero
                            </label>}
                            {props.lang === "en" && <label>
                                Passenger App
                            </label>}
                        </Link>
                        <Link
                            className={(window.location.pathname.includes("app/driver")) ? "FooterLinksNavBarLinkFocus" : "FooterLinksNavBarLink"}
                            to={`/${props.lang}/app/driver`}
                        >
                            {props.lang === "es" && <label>
                                App Conductor
                            </label>}
                            {props.lang === "en" && <label>
                                Driver App
                            </label>}
                        </Link>
                    </section>
                    <section>
                        <Link
                            className={(window.location.pathname.includes("price")) ? "FooterLinksNavBarLinkFocus" : "FooterLinksNavBarLink"}
                            to={`/${props.lang}/price`}
                        >
                            {props.lang === "es" && <label>
                                Precios
                            </label>}
                            {props.lang === "en" && <label>
                                Prices
                            </label>}
                        </Link>
                        <Link
                            className={(window.location.pathname.includes("faq")) ? "FooterLinksNavBarLinkFocus" : "FooterLinksNavBarLink"}
                            to={`/${props.lang}/faq`}
                        >
                            {props.lang === "es" && <label>
                                Preguntas Frecuentes
                            </label>}
                            {props.lang === "en" && <label>
                                FAQ
                            </label>}
                        </Link>
                        <Link
                            className={(window.location.pathname.includes("about")) ? "FooterLinksNavBarLinkFocus" : "FooterLinksNavBarLink"}
                            to={`/${props.lang}/about`}
                        >
                            {props.lang === "es" && <label>
                                Acerca de Nosotros
                            </label>}
                            {props.lang === "en" && <label>
                                About Us
                            </label>}
                        </Link>
                    </section>
                    <section>
                        <Link
                            className={(window.location.pathname.includes("contact")) ? "FooterLinksNavBarLinkFocus" : "FooterLinksNavBarLink"}
                            to={`/${props.lang}/contact`}
                        >
                            {props.lang === "es" && <label>
                                Contacto
                            </label>}
                            {props.lang === "en" && <label>
                                Contact
                            </label>}
                        </Link>
                        <Link
                            className={(window.location.pathname.includes("register")) ? "FooterLinksNavBarLinkFocus" : "FooterLinksNavBarLink"}
                            to={`/${props.lang}/register`}
                        >
                            {props.lang === "es" && <label>
                                Registrarme
                            </label>}
                            {props.lang === "en" && <label>
                                Register
                            </label>}
                        </Link>
                        <Link
                            className={(window.location.pathname.includes("login")) ? "FooterLinksNavBarLinkFocus" : "FooterLinksNavBarLink"}
                            to={(!props.customer || !props.customer.id) ? `/${props.lang}/application/customer/login` : `/${props.lang}/application/customer`}
                        >
                            {(!props.customer || !props.customer.id) && props.lang === "es" && <label>
                                Iniciar sesión
                            </label>}
                            {(!props.customer || !props.customer.id) && props.lang === "en" && <label>
                                Log In
                            </label>}

                            {(props.customer && props.customer.id) && props.lang === "es" && <label>
                                Hola, {props.customer.name?.split(" ")[0].toLowerCase()}
                            </label>}
                            {(props.customer && props.customer.id) && props.lang === "en" && <label>
                                Hello, {props.customer.name?.split(" ")[0].toLowerCase()}
                            </label>}
                        </Link>
                    </section>
                    <section>
                        <label>
                            EC (GYE) +593 982348054
                        </label>
                        <label>
                            devjos.ec@gmail.com
                        </label>
                        <label>
                            dvgeo.ec@gmail.com
                        </label>
                        <div>
                            <Tooltip title={"Facebook"}>
                                <span
                                    className={"FooterLinksNavBarLink"}
                                    onClick={() => window.open("https://facebook.com/devjos.ec")}
                                >
                                    <FontAwesomeIcon
                                        icon={faFacebook}
                                        size={"lg"}
                                    />
                                </span>
                            </Tooltip>
                            <Tooltip title={"Instagram"}>
                                <span
                                    className={"FooterLinksNavBarLink"}
                                    onClick={() => window.open("https://instagram.com/devjos.ec")}
                                >
                                    <FontAwesomeIcon
                                        icon={faInstagram}
                                        size={"lg"}
                                    />
                                </span>
                            </Tooltip>
                            <Tooltip title={"Twitter"}>
                                <span
                                    className={"FooterLinksNavBarLink"}
                                    onClick={() => window.open("https://twitter.com/devjos_ec")}
                                >
                                    <FontAwesomeIcon
                                        icon={faTwitter}
                                        size={"lg"}
                                    />
                                </span>
                            </Tooltip>
                            <Tooltip title={"Youtube"}>
                                <span
                                    className={"FooterLinksNavBarLink"}
                                    onClick={() => window.open("https://www.youtube.com/channel/UCYAXkdSyKOOJwZ9DMkgJDtQ")}
                                >
                                    <FontAwesomeIcon
                                        icon={faYoutube}
                                        size={"lg"}
                                    />
                                </span>
                            </Tooltip>
                        </div>
                    </section>
                </section>
            </div>
            <div
                className={"FooterCopyright"}
            >
                <div
                    className={"FooterCopyrightText"}
                >
                    <p>
                        Copyright © 2022 <strong>DvGeo Platform</strong>
                    </p>
                    {props.lang === "es" && <label>
                        Pertenece a <strong>Devjos CIA LTDA</strong>
                    </label>}
                    {props.lang === "en" && <label>
                        It belongs to <strong>Devjos CIA LTDA</strong>
                    </label>}
                </div>
                <span
                    onClick={() => window.open("https://api.dvgeo.app/uploads/dvgeo/terms.html")}
                >
                    {props.lang === "es" && "Términos y Condiciones"}
                    {props.lang === "en" && "Terms and Conditions"}
                </span>
                <span
                    onClick={() => window.open("https://api.dvgeo.app/uploads/dvgeo/privacity.html")}
                >
                    {props.lang === "es" && "Política de privacidad"}
                    {props.lang === "en" && "Privacy Policy"}
                </span>
                <span
                    onClick={() => window.open("https://api.dvgeo.app/uploads/dvgeo/refund.html")}
                >
                    {props.lang === "es" && "Política de reembolso"}
                    {props.lang === "en" && "Refund Policy"}
                </span>
                <span
                    onClick={() => window.open(`${config.host}/uploads/devjos_contrato_servicios_cloud.pdf`)}
                >
                    {props.lang === "es" && "Contrato de Servicios"}
                    {props.lang === "en" && "Services contract"}
                </span>
            </div>
        </div>
    )
}
export default Footer;
