import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminCommerceSection1 = (props: Props) => {

    return (
        <div
            className={"IndexAppAdminCommerceSection1"}
        >
            <section
                className={"IndexAppAdminCommerceSection1Sec"}
            >
                {props.lang === "es" && <h1>
                    Vende <strong>recargas</strong>, cobra <strong>comisiones</strong>
                </h1>}
                {props.lang === "en" && <h1>
                    Sell <strong>refills</strong>, collect <strong>commissions</strong>
                </h1>}
                {props.lang === "es" && <p>
                    Puedes <strong>distribuir paquetes de saldo</strong> con socios comerciales para expandir tu marca que servirán para recargar la cuenta de cada conductor
                </p>}
                {props.lang === "en" && <p>
                    You can <strong>distribute balance packages</strong> with business partners to expand your brand that will serve to recharge the account of each driver
                </p>}
                {props.lang === "es" && <p>
                    <strong>¡Desplázate hacia abajo</strong> para aprender más!
                </p>}
                {props.lang === "en" && <p>
                    <strong>Scroll down</strong> to learn more!
                </p>}
            </section>
            <div
                className={"IndexAppAdminCommerceSection1Img"}
            >
                <img
                    src={require("../../../../../assets/img/admin_commerce_1.png").default}
                    alt={"dvgeo_admin_channel_1"}
                />
            </div>
        </div>
    )
}
export default IndexAppAdminCommerceSection1;
