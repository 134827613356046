import React, {useEffect, useState} from "react";
//theme
import "./styles.scss";
//api
import {CustomerAppLogAPI} from "../../../../../../api/customer_app_log/customerAppLogAPI";
//models
import {CustomerApp} from "../../../../../../api/customer_app/customerAppModel";
import {CustomerAppLog} from "../../../../../../api/customer_app_log/customerAppLogModel";
//libs
import config from "../../../../../../config";
import {Link} from "react-router-dom";
import ReactLoading from 'react-loading';
//types
import {ErrorException, Lang} from "../../../../../../types";
import {SystemSnackbar} from "../../../../../../store/actions/system.ac";

//interfaces
interface Props {
    lang: Lang;
    customer_app: CustomerApp;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
}

//const
const TAG = `CustomerAppsLogViewSection1`;
const isDev = (process.env.NODE_ENV !== "production");
//components
const CustomerAppsLogViewSection1 = (props: Props) => {
    //state
    const [customer_app_logs, setCustomerAppLogs] = useState<Array<CustomerAppLog>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    //effects
    useEffect(() => {
        getData();
        return () => {

        }
    }, []);
    useEffect(() => {
        props.customer_app && getCustomerAppLogsLogs(Number(props.customer_app.id));
    }, [props.customer_app])

    const getData = async () => {
        try {

        } catch (error: ErrorException | any) {
            isDev && console.error(TAG + "//getData", error);
        }
    }


    const getCustomerAppLogsLogs = async (customer_app_id: number) => {
        setIsLoading(true);
        isDev && console.log(TAG + "//getCustomerAppLogsLogs", "Cargando documentos del cliente...");
        await CustomerAppLogAPI.getByCustomerAppId(customer_app_id, "es").then(async res_1 => {
                isDev && console.log(TAG + "//getCustomerAppLogs", res_1.code, res_1.message, res_1.error);
                if (res_1.code === 200) {
                    setCustomerAppLogs(res_1.customer_app_logs as Array<CustomerAppLog>);
                } else if (res_1.code === 409) {

                } else {
                    props.showSnackbar && props.showSnackbar({
                        message: res_1.message,
                        severity: "warning"
                    });
                }
            }
        ).catch(async res_1 => {
            isDev && console.error(TAG + "//getCustomerAppLogs", res_1.code, res_1.error, res_1);
            props.showSnackbar && props.showSnackbar({
                message: res_1.error ? res_1.error : JSON.stringify(res_1.error),
                severity: "warning"
            });
        });
        setIsLoading(false);
    }

    return (
        <div
            className={"CustomerAppsLogViewSection1"}
        >
            <div
                className={"CustomerAppsLogViewSection1Items"}
            >
                {isLoading && <ReactLoading
                    type={"bubbles"}
                    color={config.colorPrimary}
                    height={50}
                    width={120}
                    delay={2000}
                />}
                {!isLoading && customer_app_logs.map((value, index) => {
                    return (
                        <div
                            key={`${TAG}_customer_apps_${index}`}
                            className={"CustomerAppsLogViewSection1Item"}
                        >
                            <legend
                                className={"CustomerAppsLogViewSection1ItemTitle"}
                            >
                                {value.build} <span>({value.version})</span>
                            </legend>
                            <label
                                className={"CustomerAppsLogViewSection1ItemPackage"}
                            >
                                {value.created_at}
                            </label>
                            <p
                                className={"CustomerAppsLogViewSection1ItemDescription"}
                            >
                                {value.observation}
                            </p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default CustomerAppsLogViewSection1;
