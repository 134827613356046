import React from "react";
//theme
import "./styles.scss";
//libs
import {useHistory} from "react-router-dom";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminSection10 = (props: Props, ref: any) => {
    //hooks
    const history = useHistory();

    return (
        <div
            ref={ref}
            className={"IndexAppAdminSection10Content"}
        >
            <div
                className={"IndexAppAdminSection10 js-scroll slide-right"}
            >
                <section
                    className={"IndexAppAdminSection10Sec"}
                >
                    {props.lang === "es" && <h1>
                        Consola de <strong>Despacho / Reservas</strong> Online
                    </h1>}
                    {props.lang === "en" && <h1>
                        <strong>Dispatch</strong> Console / Online <strong>Reservations</strong>
                    </h1>}
                    {props.lang === "es" && <p>
                        El <strong>despacho inteligente</strong> ideal para carreras solicitadas desde call center, medios electrónicos o en oficina
                    </p>}
                    {props.lang === "en" && <p>
                        The ideal <strong>intelligent dispatch</strong> for careers requested from the call center, electronic media or in the office
                    </p>}
                    <button
                        onClick={() => {
                            history.push(`/${props.lang}/app/admin/module/travel`);
                        }}
                    >
                        {props.lang === "es" && <label>
                            Más Info
                        </label>}
                        {props.lang === "en" && <label>
                            More Info
                        </label>}
                    </button>
                </section>
                <div
                    className={"IndexAppAdminSection10Img"}
                >
                    <img
                        src={require("../../../../../assets/img/admin_travel_1.png").default}
                        alt={"dvgeo_admin_travel_1"}
                    />
                </div>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppAdminSection10);
