import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppPassengerSection8 = (props: Props, ref: any) => {

    return (
        <div
            ref={ref}
            className={"IndexAppPassengerSection8Content"}
        >
            <div
                className={"IndexAppPassengerSection8 js-scroll slide-right"}
            >
                <section
                    className={"IndexAppPassengerSection8Sec"}
                >
                    {props.lang === "es" && <h1>
                        <strong>Compartir</strong> mi viaje en tiempo real
                    </h1>}
                    {props.lang === "en" && <h1>
                        <strong>Share</strong> my trip in real time
                    </h1>}
                    {props.lang === "es" && <p>
                        <strong>Comparte los detalles de tu viaje</strong> con amigos y familiares para mantenerlos informados
                    </p>}
                    {props.lang === "en" && <p>
                        <strong>Share your trip details</strong> with friends and family to keep them informed
                    </p>}
                </section>
                <div
                    className={"IndexAppPassengerSection8Img"}
                >
                    <img
                        src={require("../../../../../assets/img/passenger_16.png").default}
                        alt={"dvgeo_passenger_16"}
                    />
                </div>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppPassengerSection8);
