import React, {useEffect, useState} from "react";
//theme
import "./view.scss";
//store
//API
import {CustomerAppAPI} from "../../../../../api/customer_app/customerAppAPI";
//models
import {Customer} from "../../../../../api/customer/customerModel";
import {CustomerApp} from "../../../../../api/customer_app/customerAppModel";
//libs
import {useHistory, useParams} from "react-router-dom";
//components
import NavBarTop from "../../../../_components/NavBarTop";
import CustomerNavBarMenu from "../../../_components/CustomerNavBarMenu";
import SnackBar from "../../../../_components/SnackBar";
import Footer from "../../../../_components/Footer";
import CustomerAppsLogViewBanner1 from "./banner_1";
//types
import {ErrorException, Lang} from "../../../../../types";
import {SystemSnackbar} from "../../../../../store/actions/system.ac";
import {CustomerAppsLogStackParamList} from "../types";
import CustomerAppsLogViewSection1 from "./section_1";

//interfaces
interface Props {
    lang: Lang;
    customer: Customer;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
    deleteCustomer?: () => void;
}

//const
const TAG = `CustomerAppsLogView`;
const isDev = (process.env.NODE_ENV !== "production");
//view
const CustomerAppsLogView = (props: Props) => {
    //hooks
    const params = useParams<CustomerAppsLogStackParamList>();
    const history = useHistory();
    //refs
    //state
    const [customer_app, setCustomerApp] = useState<CustomerApp>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    //effects
    useEffect(() => {
        getData();
        return () => {

        }
    }, []);

    const getData = async () => {
        try {
            if (!params.id) {
                history.goBack();
                return;
            }
            await getCustomerApp(Number(params.id));
        } catch (error: ErrorException | any) {
            isDev && console.error(TAG + "//getData", error);
        }
    }

    const getCustomerApp = async (id: number) => {
        setIsLoading(true);
        isDev && console.log(TAG + "//getCustomerApp", "Cargando documentos del cliente...");
        await CustomerAppAPI.getById(id, "es").then(async res_1 => {
                isDev && console.log(TAG + "//getCustomerApp", res_1.code, res_1.message, res_1.error);
                if (res_1.code === 200) {
                    setCustomerApp(res_1.customer_app as CustomerApp);
                } else if (res_1.code === 409) {

                } else {
                    props.showSnackbar && props.showSnackbar({
                        message: res_1.message,
                        severity: "warning"
                    });
                }
            }
        ).catch(async res_1 => {
            isDev && console.error(TAG + "//getCustomerApp", res_1.code, res_1.error, res_1);
            props.showSnackbar && props.showSnackbar({
                message: res_1.error ? res_1.error : JSON.stringify(res_1.error),
                severity: "warning"
            });
        });
        setIsLoading(false);
    }

    return (
        <div
            className={"CustomerAppsLogView"}
        >
            <NavBarTop
                lang={props.lang}
                customer={props.customer}
                deleteCustomer={props.deleteCustomer}
            />
            <CustomerNavBarMenu
                lang={props.lang}
            />
            <CustomerAppsLogViewBanner1
                lang={props.lang}
                customer_app={customer_app}
                isLoading={isLoading}
            />
            <CustomerAppsLogViewSection1
                lang={props.lang}
                customer_app={customer_app}
            />
            <Footer
                lang={props.lang}
                customer={props.customer}
            />
            <SnackBar/>
        </div>
    )
}
export default CustomerAppsLogView;
