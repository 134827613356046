import React, {useEffect, useState} from "react";
//store
import {
    deleteToken,
    hideSnackbar,
    postToken,
    showSnackbar,
    SystemSnackbar
} from "../../../../../../store/actions/system.ac";
import {deleteCustomer, postCustomer} from "../../../../../../store/actions/customer.ac";
//models
import {Customer} from "../../../../../../api/customer/customerModel";
//libs
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import CustomerAccountAPI from "../../../../account/api";
//components
import CustomerCompanyViewUploadRuc from "../_components/view";
//types
import {StoreDispatchProps, StoreStateProps} from "../../../../../../store/types";
import {ErrorException} from "../../../../../../types";

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

//interfaces
interface OwnProps {

}

//const
const TAG = `CustomerCompanyUploadLogoEn`;
const isDev = (process.env.NODE_ENV !== "production");
//view
const CustomerCompanyUploadLogoEn = (props: Props) => {
    //hooks
    const history = useHistory();
    //refs
    //state
    const [customer, setCustomer] = useState<Customer>(props.customer?.item as Customer);
    const [intentsSession, setIntentsSession] = useState<number>(0);
    //effects
    useEffect(() => {
        document.title = `Panel del cliente - Software de despacho para taxi en la nube | Sistema para empresas de taxi | DvGeo Platform`;
        getData();
        return () => {

        }
    }, []);
    useEffect(() => {
        if (props.customer?.item) {
            setCustomer(props.customer?.item as Customer);
        }
    }, [props.customer?.item]);
    useEffect(() => {
        if (props.system?.token === "") {
            if (intentsSession >= 3) {
                history.push(`/es/application/customer/login`);
            }
            setIntentsSession((prevIntentsSession) => prevIntentsSession + 1);
        } else {
            CustomerAccountAPI.getSession(history, "es", props.system?.token as string, props.customer?.item);
        }
    }, [props.system?.token, props.customer?.item, intentsSession]);

    const getData = async () => {
        try {
        } catch (error: ErrorException | any) {
            isDev && console.error(TAG + "//getData", error);
        }
    }

    return (
        <CustomerCompanyViewUploadRuc
            lang={"en"}
            customer={customer}
            deleteCustomer={props.deleteCustomer}
            deleteToken={props.deleteToken}
            showSnackbar={props.showSnackbar}
            hideSnackbar={props.hideSnackbar}
        />
    )
}
const mapStateToProps = (state: StoreStateProps) => ({
    system: state.system,
    customer: state.customer,
});

const mapDispatchToProps = {
    //system
    postToken: (token: string) => (postToken(token)),
    deleteToken: () => (deleteToken()),
    showSnackbar: (snackbar: SystemSnackbar) => (showSnackbar(snackbar)),
    hideSnackbar: () => (hideSnackbar()),
    //customer
    postCustomer: (customer: Customer) => (postCustomer(customer)),
    deleteCustomer: () => (deleteCustomer()),
}
export default connect<StoreStateProps, StoreDispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(CustomerCompanyUploadLogoEn);
