import React, {useEffect, useRef, useState} from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminChannelSection2 = (props: Props) => {
    //refs
    const iTimer = useRef<NodeJS.Timeout | number>(0);
    const indexAppAdminServiceSection1DesktopSnapContentRef = useRef<any>(null);
    const items = useRef<Array<any>>([]);
    //state
    const [index, setIndex] = useState<number>(0);
    //effects
    useEffect(() => {
        init();
        return () => {
            clearIntervals();
        }
    }, []);

    const init = () => {
        try {
            iIndexTranslateX();
        } catch (error: any) {

        }
    }


    const iIndexTranslateX = () => {
        setIndex(0);
        setTranslateXValid(0);
        clearInterval(iTimer.current as NodeJS.Timeout);
        let indexCurrent = 0;
        iTimer.current = setInterval(() => {
            if (indexCurrent >= 0 && indexCurrent <= 1) {
                indexCurrent++;
                setTranslateXValid(indexCurrent);
                setIndex(indexCurrent);
            } else {
                iIndexTranslateX();
                indexCurrent = 0;
                setIndex(0);
            }
        }, 6000);
    }

    const clearIntervals = () => {
        clearInterval(iTimer.current as NodeJS.Timeout);
        iTimer.current = 0;
    }

    const setTranslateX = (index: number, value: string) => {
        try {
            items.current[index].style.transform = value;
        } catch (error: any) {
            setTimeout(() => setTranslateX(index, value), 1000 / 5);
        }
    }

    const setTranslateXValid = (index: number) => {
        if (index === 0) {
            setTimeout(() => setTranslateX(0, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-0vw)`), 1000 / 5);
        }
        if (index === 1) {
            setTimeout(() => setTranslateX(0, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-40vw)`), 1000 / 5);
        }
        if (index === 2) {
            setTimeout(() => setTranslateX(0, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-80vw)`), 1000 / 5);
        }
        if (index === 3) {
            setTimeout(() => setTranslateX(0, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-120vw)`), 1000 / 5);
        }
    }

    return (
        <div
            className={"IndexAppAdminChannelSection2"}
        >
            {props.lang === "es" && <h1
                className={"IndexAppAdminChannelSection2Title"}
            >
                ¿Cómo funciona?
            </h1>}
            {props.lang === "en" && <h1
                className={"IndexAppAdminChannelSection2Title"}
            >
                How does it work?
            </h1>}
            {/*apps en modo mobile*/}
            <div
                className={"IndexAppAdminChannelSection2Mobile"}
            >
                <section
                    className={"IndexAppAdminChannelSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminChannelSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminChannelSection2DesktopSnapControlBtnSpan"}
                        >
                           1
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Crea canales de monitoreo
                            </h6>}
                            {props.lang === "en" && <h6>
                                Create monitoring channels
                            </h6>}
                            {props.lang === "es" && <label>
                                Un canal te permite organizar a tus conductores según las regiones, ciudades, etc.
                            </label>}
                            {props.lang === "en" && <label>
                                A channel allows you to organize your drivers according to regions, cities, etc.
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminChannelSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_channel_1.png").default}
                            alt={"dvgeo_admin_channel_1"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppAdminChannelSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminChannelSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminChannelSection2DesktopSnapControlBtnSpan"}
                        >
                           2
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Asigna a tus conductores
                            </h6>}
                            {props.lang === "en" && <h6>
                                Assign your drivers
                            </h6>}
                            {props.lang === "es" && <label>
                                Simplemente selecciona un conductor para agregarlo al canal.
                            </label>}
                            {props.lang === "en" && <label>
                                Simply select a conductor to add to the channel.
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminChannelSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_channel_2.png").default}
                            alt={"dvgeo_admin_channel_2"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppAdminChannelSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminChannelSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminChannelSection2DesktopSnapControlBtnSpan"}
                        >
                           3
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Monitorea en tiempo real
                            </h6>}
                            {props.lang === "en" && <h6>
                                Monitor in real time
                            </h6>}
                            {props.lang === "es" && <label>
                                Una vez que el conductor está asignado al canal ya podrás ver su ubicación, su estado en caso de que este en un viaje o que se encuentre ocupado.
                            </label>}
                            {props.lang === "en" && <label>
                                Once the driver is assigned to the channel, you will be able to see his location, his status in case he is on a trip or he is busy.
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminChannelSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_channel_3.png").default}
                            alt={"dvgeo_admin_channel_3"}
                        />
                    </div>
                </section>
            </div>
            {/*apps en modo desktop*/}
            <div
                className={"IndexAppAdminChannelSection2Desktop"}
            >
                <div
                    ref={indexAppAdminServiceSection1DesktopSnapContentRef}
                    className={"IndexAppAdminChannelSection2DesktopSnapControl"}
                >
                    <button
                        className={index === 0 ? "IndexAppAdminChannelSection2DesktopSnapControlBtnFocus" : "IndexAppAdminChannelSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(0);
                            setIndex(0);
                            clearIntervals();
                        }}
                    >
                        <span
                            className={index === 0 ? "IndexAppAdminChannelSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminChannelSection2DesktopSnapControlBtnSpan"}
                        >
                           1
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Crea canales de monitoreo
                            </h6>}
                            {props.lang === "en" && <h6>
                                Create monitoring channels
                            </h6>}
                            {props.lang === "es" && <label>
                                Un canal te permite organizar a tus conductores según las regiones, ciudades, etc.
                            </label>}
                            {props.lang === "en" && <label>
                                A channel allows you to organize your drivers according to regions, cities, etc.
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 1 ? "IndexAppAdminChannelSection2DesktopSnapControlBtnFocus" : "IndexAppAdminChannelSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(1);
                            setIndex(1);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 1 ? "IndexAppAdminChannelSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminChannelSection2DesktopSnapControlBtnSpan"}
                       >
                           2
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Asigna a tus conductores
                            </h6>}
                            {props.lang === "en" && <h6>
                                Assign your drivers
                            </h6>}
                            {props.lang === "es" && <label>
                                Simplemente selecciona un conductor para agregarlo al canal.
                            </label>}
                            {props.lang === "en" && <label>
                                Simply select a conductor to add to the channel.
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 2 ? "IndexAppAdminChannelSection2DesktopSnapControlBtnFocus" : "IndexAppAdminChannelSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(2);
                            setIndex(2);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 2 ? "IndexAppAdminChannelSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminChannelSection2DesktopSnapControlBtnSpan"}
                       >
                           3
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                               Monitorea en tiempo real
                            </h6>}
                            {props.lang === "en" && <h6>
                                Monitor in real time
                            </h6>}
                            {props.lang === "es" && <label>
                                Una vez que el conductor está asignado al canal ya podrás ver su ubicación, su estado en caso de que este en un viaje o que se encuentre ocupado.
                            </label>}
                            {props.lang === "en" && <label>
                                Once the driver is assigned to the channel, you will be able to see his location, his status in case he is on a trip or he is busy.
                            </label>}
                        </section>
                    </button>
                </div>
                <div
                    ref={indexAppAdminServiceSection1DesktopSnapContentRef}
                    className={"IndexAppAdminChannelSection2DesktopSnapContent"}
                >
                    <div
                        className={"IndexAppAdminChannelSection2DesktopSnapContentBackground"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_monitor.png").default}
                            alt={""}
                        />
                    </div>
                    <section
                        ref={ref => items.current[0] = ref}
                        className={"IndexAppAdminChannelSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_channel_2.png").default}
                            alt={"dvgeo_admin_channel_2"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[1] = ref}
                        className={"IndexAppAdminChannelSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_channel_3.png").default}
                            alt={"dvgeo_admin_channel_3"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[2] = ref}
                        className={"IndexAppAdminChannelSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_channel_1.png").default}
                            alt={"dvgeo_admin_channel_1"}
                        />
                    </section>
                </div>
            </div>
        </div>
    )
}
export default IndexAppAdminChannelSection2;
