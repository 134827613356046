import React from "react";
//theme
import "./styles.scss";
//models
import {Customer} from "../../../../../api/customer/customerModel";
import {CustomerDoc} from "../../../../../api/customer_doc/customerDocModel";
//libs
import config from "../../../../../config";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
    customer: Customer;
    customer_doc: CustomerDoc;
}

//component
const CustomerHomeSection1 = (props: Props) => {
    return (
        <div
            className={"CustomerHomeSection1Content"}
        >
            <div
                className={"CustomerHomeSection1"}
            >
                <div
                    className={"CustomerHomeSection1CustomerLogo"}
                >
                    <img
                        src={props.customer_doc.url_logo ? props.customer_doc.url_logo : `${config.host}/uploads/not_file.png`}
                        alt={"customer_logo"}
                    />
                </div>
                <h1
                    className={"CustomerHomeSection1CustomerCompany"}
                >
                    {props.customer ? props.customer.business_name : "..."}
                </h1>
            </div>
        </div>
    )
}
export default CustomerHomeSection1;
