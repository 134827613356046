import React from "react";
//theme
import "./styles.scss";
//libs
import {Link} from "react-router-dom";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const CustomerInvoiceViewBanner1 = (props: Props) => {
    return (
        <div
            className={"CustomerInvoiceViewBanner1"}
        >
            <h1>
                {props.lang === "es" && "Mis facturas"}
                {props.lang === "en" && "My bills"}
            </h1>
            <p>
                {props.lang === "es" && "Recuerda que todas tus facturas se emiten el 30 del mes actual y se pagan máximo hasta el 4 del mes entrante"}
                {props.lang === "en" && "Remember that all your invoices are issued on the 30th of the current month and are paid maximum until the 4th of the next month"}
            </p>
        </div>
    )
}
export default CustomerInvoiceViewBanner1;
