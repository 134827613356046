import React from "react";
//theme
import "./styles.scss";
//libs
import {useHistory} from "react-router-dom";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const AppAdmin = (props: Props) => {
    //hooks
    const history = useHistory();

    return (
        <div
            className={"AppAdmin"}
        >
            <div
                className={"AppAdminImg"}
            >
                <img
                    src={require("../../../../../assets/img/admin_1.png").default}
                    alt={"dvgeo_admin_1"}
                />
            </div>
            <section
                className={"AppAdminSec"}
            >
                {props.lang === "es" && <h1>
                    <strong>Monitorea</strong> todo el flujo de tu negocio
                </h1>}
                {props.lang === "en" && <h1>
                    <strong>Monitor</strong> the entire flow of your business
                </h1>}
                {props.lang === "es" && <p>
                    <strong>Administra</strong> tu empresa online con nuestros módulos esenciales, despacho para call
                    center y funciones de rastreo satelital de las rutas de tus vehículos.
                </p>}
                {props.lang === "en" && <p>
                    <strong>Manage</strong> your company online with our essential modules, dispatch for call center and
                    satellite tracking functions of the routes of your vehicles.
                </p>}
                <button
                    onClick={() => {
                        history.push(`/${props.lang}/app/admin`);
                    }}
                >
                    {props.lang === "es" && <label>
                        Más Info
                    </label>}
                    {props.lang === "en" && <label>
                        More Info
                    </label>}
                </button>
            </section>
        </div>
    )
}
export default AppAdmin;
