import React, {FormEvent, useRef, useState} from "react";
//theme
import "./styles.scss";
//api
import {CustomerAPI} from "../../../../../api/customer/customerAPI";
//models
import {Customer} from "../../../../../api/customer/customerModel";
//libs
import config from "../../../../../config";
import valid from "../../../../../libs/valid";
import {CountryDropdown, RegionDropdown} from 'react-country-region-selector';
import AsyncStorage from "@react-native-community/async-storage";
import {CircularProgress} from "@material-ui/core";
import {useHistory, useLocation, Link} from "react-router-dom";
//components
import Modal from "../../../../_components/Modal";
//types
import {Lang} from "../../../../../types";

type Fields = {
    business_name: string;
    name: string;
    country: string;
    region: string;
    email: string;
    email_repeat: string;
    mobile: string;
    password: string;
    password_repeat: string;
}
type Errors = {
    general?: string;
    business_name?: string;
    name?: string;
    email?: string;
    email_repeat?: string;
    password?: string;
    password_repeat?: string;
    mobile?: string;
    country?: string;
    region?: string;
}

//interface
interface Props {
    lang: Lang;
}

//const
const TAG = `IndexRegisterForm1`;
const isDev = (process.env.NODE_ENV !== "production");
//component
const IndexRegisterForm1 = (props: Props) => {
    //hooks
    const history = useHistory();
    const location = useLocation();
    //refs
    const inputs = useRef<Array<HTMLInputElement | null>>([]);
    const selectCountryRef = useRef<any>(null);
    const selectRegionRef = useRef<any>(null);
    //state
    const [fields, setFields] = useState<Fields>({
        business_name: "",
        name: "",
        country: "",
        region: "",
        email: "",
        email_repeat: "",
        mobile: "",
        password: "",
        password_repeat: "",
    });
    const [errors, setErrors] = useState<Errors>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowModalResult, setIsShowModalResult] = useState<boolean>(false);

    const validForm = (id: keyof Fields, value: string) => {
        switch (id) {
            case "business_name": {
                setFields((prevFields) => ({
                    ...prevFields,
                    business_name: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 3) {
                    inputs.current[0] && inputs.current[0]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        business_name: props.lang === "es" ? "Mínimo 4 caracteres..." : props.lang === "en" ? "Minimum 4 characters" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        business_name: "",
                    }));
                    return true;
                }
            }
            case "name": {
                setFields((prevFields) => ({
                    ...prevFields,
                    name: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 3) {
                    inputs.current[1] && inputs.current[1]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        name: props.lang === "es" ? "Mínimo 4 caracteres..." : props.lang === "en" ? "Minimum 4 characters" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        name: "",
                    }));
                    return true;
                }
            }
            case "country": {
                setFields((prevFields) => ({
                    ...prevFields,
                    country: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "") {
                    selectCountryRef.current && selectCountryRef.current.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        country: props.lang === "es" ? "Seleccione un país..." : props.lang === "en" ? "Select country" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        country: "",
                    }));
                    return true;
                }
            }
            case "region": {
                setFields((prevFields) => ({
                    ...prevFields,
                    region: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "") {
                    selectRegionRef.current && selectRegionRef.current.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        region: props.lang === "es" ? "Seleccione una provincia..." : props.lang === "en" ? "Select region" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        region: "",
                    }));
                    return true;
                }
            }
            case "email": {
                setFields((prevFields) => ({
                    ...prevFields,
                    email: value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 2) {
                    inputs.current[2] && inputs.current[2]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email: props.lang === "es" ? "Ingrese una dirección de correo electrónico..." : props.lang === "en" ? "Enter an email address..." : "error",
                    }));
                    return false;
                } else if (!valid.isValidEmailAddress(value)) {
                    inputs.current[2] && inputs.current[2]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email: props.lang === "es" ? "Ingrese una dirección de correo electrónico válida..." : props.lang === "en" ? "Please enter a valid email address" : "error",
                    }));
                    return false;
                } else if (fields.email_repeat.length && fields.email_repeat !== value) {
                    inputs.current[2] && inputs.current[2]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email: props.lang === "es" ? "Las direcciones de correo no coinciden..." : props.lang === "en" ? "Email addresses do not match" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email: "",
                    }));
                    return true;
                }
            }
            case "email_repeat": {
                setFields((prevFields) => ({
                    ...prevFields,
                    email_repeat: value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 2) {
                    inputs.current[3] && inputs.current[3]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email_repeat: props.lang === "es" ? "Ingrese una dirección de correo electrónico..." : props.lang === "en" ? "Enter an email address..." : "error",
                    }));
                    return false;
                } else if (!valid.isValidEmailAddress(value)) {
                    inputs.current[3] && inputs.current[3]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email_repeat: props.lang === "es" ? "Ingrese una dirección de correo electrónico válida..." : props.lang === "en" ? "Please enter a valid email address" : "error",
                    }));
                    return false;
                } else if (fields.email.length && fields.email !== value) {
                    inputs.current[3] && inputs.current[3]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email_repeat: props.lang === "es" ? "Las direcciones de correo no coinciden..." : props.lang === "en" ? "Email addresses do not match" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email_repeat: "",
                    }));
                    return true;
                }
            }
            case "mobile": {
                setFields((prevFields) => ({
                    ...prevFields,
                    mobile: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 3) {
                    inputs.current[4] && inputs.current[4]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        mobile: props.lang === "es" ? "Mínimo 4 caracteres..." : props.lang === "en" ? "Minimum 4 characters" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        mobile: "",
                    }));
                    return true;
                }
            }
            case "password": {
                setFields((prevFields) => ({
                    ...prevFields,
                    password: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 7) {
                    inputs.current[5] && inputs.current[5]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password: props.lang === "es" ? "Mínimo 8 caracteres..." : props.lang === "en" ? "Minimum 8 characters" : "error",
                    }));
                    return false;
                } else if (fields.password_repeat.length && fields.password_repeat !== value) {
                    inputs.current[5] && inputs.current[5]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password: props.lang === "es" ? "Las contraseñas no coinciden..." : props.lang === "en" ? "Passwords do not match" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password: "",
                    }));
                    return true;
                }
            }
            case "password_repeat": {
                setFields((prevFields) => ({
                    ...prevFields,
                    password_repeat: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 7) {
                    inputs.current[6] && inputs.current[6]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password_repeat: props.lang === "es" ? "Mínimo 8 caracteres..." : props.lang === "en" ? "Minimum 8 characters" : "error",
                    }));
                    return false;
                } else if (fields.password.length && fields.password !== value) {
                    inputs.current[6] && inputs.current[6]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password_repeat: props.lang === "es" ? "Las contraseñas no coinciden..." : props.lang === "en" ? "Passwords do not match" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password_repeat: "",
                    }));
                    return true;
                }
            }
            default : {
                return false
            }
        }
    }

    const register = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let indexFields = 0;
        for (const field in fields) {
            if (!validForm(field as keyof Fields, Object.values(fields)[indexFields])) {
                return;
            }
            indexFields++;
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            general: "",
        }));
        setIsLoading(true);
        isDev && console.log(TAG + "//register", "Registrando...");
        await CustomerAPI.insert({
            model: {
                ...fields,
            }
        }, props.lang).then(async res_1 => {
            isDev && console.log(TAG + "//register", res_1.code, res_1.message, res_1.error);
            if (res_1.code === 200) {
                await CustomerAPI.login(fields.email, fields.password, props.lang).then(async res_2 => {
                    isDev && console.log(TAG + "//login", res_2.code, res_2.message, res_2.error);
                    if (res_2.code === 200) {
                        const session = res_2.customer as Customer;
                        const token = res_2.token;
                        isDev && console.log(`${TAG}//login`, "session:", session);
                        isDev && console.log(`${TAG}//login`, "token:", token);
                        await AsyncStorage.removeItem(`user::${config.package}`);
                        await AsyncStorage.setItem(`user::${config.package}`, JSON.stringify(session));
                        await AsyncStorage.setItem(`token::${config.package}`, token);
                        const query = new URLSearchParams(location.search);
                        const url = query.get('url') ? query.get('url') : `/${props.lang}/application/customer`;
                        history.push(url ? url.includes("login") ? `/${props.lang}/application/customer` : url : `/${props.lang}/application/customer`);
                    } else {
                        setIsShowModalResult(true);
                        setErrors((prevErrors) => ({
                            ...prevErrors,
                            general: res_2.message,
                        }));
                    }
                }).catch(res_2 => {
                    isDev && console.error(TAG + "//login", res_2.code, res_2.error, res_2);
                    setIsShowModalResult(true);
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        general: res_2.message,
                    }));
                })
            } else {
                setIsShowModalResult(true);
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    general: res_1.message,
                }));
            }
        }).catch(async res_1 => {
            isDev && console.error(TAG + "//register", res_1.code, res_1.error, res_1);
            setIsShowModalResult(true);
            setErrors((prevErrors) => ({
                ...prevErrors,
                general: res_1.message,
            }));
        });
        setIsLoading(false);
    }

    return (
        <div
            className={"IndexRegisterForm1"}
        >
            <h1
                className={"IndexRegisterForm1Title"}
            >
                {props.lang === "es" && "Crea tu cuenta"}
                {props.lang === "en" && "Creat your account"}
            </h1>
            <p
                className={"IndexRegisterForm1Subtitle"}
            >
                {props.lang === "es" && "No se necesita tarjeta de crédito ni realizar ningún pago para el registro, es completamente gratis."}
                {props.lang === "en" && "You do not need a credit card or make any payment for registration, it is completely free.."}
            </p>
            <form
                className={"IndexRegisterForm1Form"}
                onSubmit={(e) => register(e)}
            >
                <div
                    className={"IndexRegisterForm1FormIpt"}
                >
                    <label>
                        {props.lang === "es" && "Empresa:"}
                        {props.lang === "en" && "Company:"}
                        <input
                            ref={(ele) => inputs.current[0] = ele}
                            style={errors?.business_name?.length ? {
                                border: "1px solid darkred",
                            } : {}}
                            placeholder={props.lang === "es" ? "Nombre de la empresa" : props.lang === "en" ? "Company name" : ""}
                            value={fields.business_name}
                            onChange={e => validForm("business_name", e.target.value)}
                        />
                        {(errors?.business_name?.length) ? <span
                            className={"IndexRegisterForm1FormIptError"}
                        >
                            {errors.business_name}
                        </span> : null}
                    </label>
                </div>
                <div
                    className={"IndexRegisterForm1FormIpt"}
                >
                    <label>
                        {props.lang === "es" && "Nombre:"}
                        {props.lang === "en" && "Name:"}
                        <input
                            ref={(ele) => inputs.current[1] = ele}
                            style={errors?.name?.length ? {
                                border: "1px solid darkred",
                            } : {}}
                            placeholder={props.lang === "es" ? "Nombre y Apellido" : props.lang === "en" ? "Name and surname" : ""}
                            value={fields.name}
                            onChange={e => validForm("name", e.target.value)}
                        />
                        {(errors?.name?.length) ? <span
                            className={"IndexRegisterForm1FormIptError"}
                        >
                            {errors.name}
                        </span> : null}
                    </label>
                </div>
                <div
                    className={"IndexRegisterForm1FormIpt"}
                >
                    <label>
                        {props.lang === "es" && "País:"}
                        {props.lang === "en" && "Country:"}
                        <CountryDropdown
                            defaultOptionLabel={props.lang === "es" ? "Seleccione el país" : props.lang === "en" ? "Select country" : ""}
                            value={fields.country}
                            onChange={(val) => validForm("country", val)}
                        />
                        {(errors?.country?.length) ? <span
                            className={"IndexRegisterForm1FormIptError"}
                        >
                            {errors.country}
                        </span> : null}
                    </label>
                </div>
                <div
                    className={"IndexRegisterForm1FormIpt"}
                >
                    <label
                        ref={(ele) => selectCountryRef.current = ele}
                    >
                        {props.lang === "es" && "Provincia:"}
                        {props.lang === "en" && "Region:"}
                        <RegionDropdown
                            defaultOptionLabel={props.lang === "es" ? "Seleccione la región" : props.lang === "en" ? "Select region" : ""}
                            country={fields.country}
                            value={fields.region}
                            onChange={(val) => validForm("region", val)}
                        />
                        {(errors?.region?.length) ? <span
                            className={"IndexRegisterForm1FormIptError"}
                        >
                            {errors.region}
                        </span> : null}
                    </label>
                </div>
                <div
                    className={"IndexRegisterForm1FormIpt"}
                >
                    <label
                        ref={(ele) => selectRegionRef.current = ele}
                    >
                        {props.lang === "es" && "Correo electrónico:"}
                        {props.lang === "en" && "Email:"}
                        <input
                            ref={(ele) => inputs.current[2] = ele}
                            style={errors?.email?.length ? {
                                border: "1px solid darkred",
                            } : {}}
                            type={"email"}
                            placeholder={props.lang === "es" ? "Correo electrónico" : props.lang === "en" ? "Email" : ""}
                            value={fields.email}
                            onChange={e => validForm("email", e.target.value)}
                        />
                        {(errors?.email?.length) ? <span
                            className={"IndexRegisterForm1FormIptError"}
                        >
                            {errors.email}
                        </span> : null}
                    </label>
                </div>
                <div
                    className={"IndexRegisterForm1FormIpt"}
                >
                    <label>
                        {props.lang === "es" && "Repetir email:"}
                        {props.lang === "en" && "Repeat email:"}
                        <input
                            ref={(ele) => inputs.current[3] = ele}
                            style={errors?.email_repeat?.length ? {
                                border: "1px solid darkred",
                            } : {}}
                            type={"email"}
                            placeholder={props.lang === "es" ? "Repita el correo electrónico" : props.lang === "en" ? "Repeat Email" : ""}
                            value={fields.email_repeat}
                            onChange={e => validForm("email_repeat", e.target.value)}
                        />
                        {(errors?.email_repeat?.length) ? <span
                            className={"IndexRegisterForm1FormIptError"}
                        >
                            {errors.email_repeat}
                        </span> : null}
                    </label>
                </div>
                <div
                    className={"IndexRegisterForm1FormIpt"}
                >
                    <label>
                        {props.lang === "es" && "Teléfono:"}
                        {props.lang === "en" && "Phone:"}
                        <input
                            ref={(ele) => inputs.current[4] = ele}
                            style={errors?.mobile?.length ? {
                                border: "1px solid darkred",
                            } : {}}
                            type={"tel"}
                            placeholder={props.lang === "es" ? "Número móvil" : props.lang === "en" ? "Mobile number" : ""}
                            value={fields.mobile}
                            onChange={e => validForm("mobile", e.target.value)}
                        />
                        {(errors?.mobile?.length) ? <span
                            className={"IndexRegisterForm1FormIptError"}
                        >
                            {errors.mobile}
                        </span> : null}
                    </label>
                </div>
                <div
                    className={"IndexRegisterForm1FormIpt"}
                >
                    <label>
                        {props.lang === "es" && "Contraseña:"}
                        {props.lang === "en" && "Password:"}
                        <input
                            ref={(ele) => inputs.current[5] = ele}
                            style={errors?.password?.length ? {
                                border: "1px solid darkred",
                            } : {}}
                            type={"password"}
                            placeholder={props.lang === "es" ? "Contraseña" : props.lang === "en" ? "Password" : ""}
                            value={fields.password}
                            onChange={e => validForm("password", e.target.value)}
                        />
                        {(errors?.password?.length) ? <span
                            className={"IndexRegisterForm1FormIptError"}
                        >
                            {errors.password}
                        </span> : null}
                    </label>
                </div>
                <div
                    className={"IndexRegisterForm1FormIpt"}
                >
                    <label>
                        {props.lang === "es" && "Repetir Contraseña:"}
                        {props.lang === "en" && "Repeat Password:"}
                        <input
                            ref={(ele) => inputs.current[6] = ele}
                            style={errors?.password_repeat?.length ? {
                                border: "1px solid darkred",
                            } : {}}
                            type={"password"}
                            placeholder={props.lang === "es" ? "Repita la contraseña" : props.lang === "en" ? "Repeat password" : ""}
                            value={fields.password_repeat}
                            onChange={e => validForm("password_repeat", e.target.value)}
                        />
                        {(errors?.password_repeat?.length) ? <span
                            className={"IndexRegisterForm1FormIptError"}
                        >
                            {errors.password_repeat}
                        </span> : null}
                    </label>
                </div>
                <button
                    className={"IndexRegisterForm1FormIptBtn"}
                    type={"submit"}
                    disabled={isLoading}
                >
                    {!isLoading && props.lang === "es" && "Regístrate"}
                    {!isLoading && props.lang === "en" && "Sign up"}

                    {isLoading && <CircularProgress
                        color={"inherit"}
                        size={20}
                    />}
                </button>
                <div
                    className={"IndexRegisterForm1FormIptLogin"}
                >
                    <label>
                        {props.lang === "es" && "¿Ya tienes cuenta?"}
                        {props.lang === "en" && "Do you already have an account?"}
                    </label>
                    <Link
                        to={`/${props.lang}/application/customer/login`}
                    >
                        {props.lang === "es" && "Inicia sesión"}
                        {props.lang === "en" && "Log in"}
                    </Link>
                </div>
            </form>
            {isShowModalResult && <Modal
                mode={"modal"}
                btnCancelLabel={"Entendido"}
                isBtnCancel={true}
                onHide={() => setIsShowModalResult(false)}
                bodyStyle={{
                    backgroundColor: "#fff", justifyContent: "center", alignItems: "center"
                }}
            >
                {(errors?.general?.length) ? <span
                    style={{
                        marginLeft: 0, fontSize: "1rem"
                    }}
                    className={"IndexRegisterForm1FormIptError"}
                >
                    {errors.general}
                </span> : null}
            </Modal>}
        </div>
    )
}
export default IndexRegisterForm1;
