import React from "react";
//theme
import "./styles.scss";
//libs
import config from "../../../../../config";
import {useHistory} from "react-router-dom";
//components
import Image from "../../../../_components/Image";

//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const AppDriver = (props: Props) => {
    //hooks
    const history = useHistory();

    return (
        <div
            className={"AppDriver"}
        >
            <div
                className={"AppDriverImg"}
            >
                <Image
                    srcPrev={`${config.host}/uploads/assets/driver_1.png?w=64&h=124`}
                    src={`${config.host}/uploads/assets/driver_1.png`}
                    alt={"dvgeo_driver_11"}
                />
            </div>
            <section
                className={"AppDriverSec"}
            >
                {props.lang === "es" && <h1>
                    <strong>Solución</strong> potente y eficaz
                </h1>}
                {props.lang === "en" && <h1>
                    Powerful and effective <strong>solution</strong>
                </h1>}
                {props.lang === "es" && <p>
                    Ofrece a tus conductores más opciones de aceptar servicios, con todas las funciones necesarias
                    para <strong>mantenerlos conectados y productivos</strong>.
                </p>}
                {props.lang === "en" && <p>
                    Give your drivers more options to accept services, with all the necessary functions
                    to keep them <strong>connected and productive</strong>.
                </p>}
                <button
                    onClick={() => {
                        history.push(`/${props.lang}/app/driver`);
                    }}
                >
                    {props.lang === "es" && <label>
                        Más Info
                    </label>}
                    {props.lang === "en" && <label>
                        More Info
                    </label>}
                </button>
            </section>
        </div>
    )
}
export default AppDriver;
