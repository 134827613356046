import React from "react";
//theme
import "./styles.scss";
//libs
import {useHistory} from "react-router-dom";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppPassengerSection3 = (props: Props, ref: any) => {
    //hooks
    const history = useHistory();

    return (
        <div
            ref={ref}
            className={"IndexAppPassengerSection3Content"}
        >
            <div
                className={"IndexAppPassengerSection3 js-scroll slide-left"}
            >
                <div
                    className={"IndexAppPassengerSection3Img"}
                >
                    <img
                        src={require("../../../../../assets/img/passenger_3.png").default}
                        alt={"dvgeo_passenger_3"}
                    />
                </div>
                <section
                    className={"IndexAppPassengerSection3Sec"}
                >
                    {props.lang === "es" && <h1>
                        Verifica tus <strong>calificaciones</strong>
                    </h1>}
                    {props.lang === "en" && <h1>
                        Check your <strong>qualifications</strong>
                    </h1>}
                    {props.lang === "es" && <p>
                        Los usuarios Pasajero y Conductor pueden <strong>calificar la calidad del viaje</strong> cuando este finalice, dejando un total de estrellas y un comentario opcional
                    </p>}
                    {props.lang === "en" && <p>
                        Passenger and Driver users can <strong>rate the quality of the trip</strong> when it ends, leaving a total of stars and an optional comment
                    </p>}
                </section>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppPassengerSection3);
