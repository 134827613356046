import React from "react";
//theme
import "./styles.scss";
//types
import {Lang} from "../../../../../types";
import config from "../../../../../config";
//components
import Image from "../../../../_components/Image";

//interfaces
interface Props {
    lang: Lang;
}

//View
const Banner1 = (props: Props) => {
    return (
        <div
            className={"Banner1"}
        >
            <div
                className={"Banner1Background"}
            />
            <div
                className={"Banner1Content"}
            >
                {props.lang === "es" && <h1>
                    Potencia tus Servicios de Movilidad
                </h1>}
                {props.lang === "en" && <h1>
                    Boost your Mobility Services
                </h1>}
                {props.lang === "es" && <p>
                    Con nuestra plataforma digital basada en la nube
                </p>}
                {props.lang === "en" && <p>
                    With our cloud-based digital platform
                </p>}
                <Image
                    srcPrev={`${config.host}/uploads/assets/web_family.png?w=64&h=34`}
                    src={`${config.host}/uploads/assets/web_family.png`}
                    alt={"dvgeo_family_2022"}
                />
            </div>
        </div>
    )
}
export default Banner1;
