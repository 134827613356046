import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../../../types";
import {Link} from "react-router-dom";
import {Breadcrumbs, Typography} from "@material-ui/core";

//interfaces
interface Props {
    lang: Lang;
}

//component
const CustomerCompanyUpdateAddressViewBanner1 = (props: Props) => {
    return (
        <div
            className={"CustomerCompanyUpdateAddressViewBanner1"}
        >
            <h1>
                {props.lang === "es" && "Datos de Facturación"}
                {props.lang === "en" && "My company data"}
            </h1>
            <p>
                {props.lang === "es" && "La dirección de envío nos permite emitir recibos con los datos de tu empresa"}
                {props.lang === "en" && "The shipping address allows us to issue receipts with your company data"}
            </p>
            <Breadcrumbs
                className={"CustomerCompanyUpdateAddressViewBanner1LinkContent"}
            >
                <Link
                    className={"CustomerCompanyUpdateAddressViewBanner1Link"}
                    to={`/${props.lang}/application/customer`}
                >
                    {props.lang === "es" && "Inicio"}
                    {props.lang === "en" && "Home"}
                </Link>
                <Link
                    className={"CustomerCompanyUpdateAddressViewBanner1Link"}
                    to={`/${props.lang}/application/customer/company`}
                >
                    {props.lang === "es" && "Empresa"}
                    {props.lang === "en" && "Company"}
                </Link>
                <Typography
                    color="inherit"
                >
                    {props.lang === "es" && "Dirección de envío"}
                    {props.lang === "en" && "Shipping Address"}
                </Typography>
            </Breadcrumbs>
        </div>
    )
}
export default CustomerCompanyUpdateAddressViewBanner1;
