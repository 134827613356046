import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexFaqSection2 = (props: Props) => {
    //state
    const [indexGroup, setIndexGroup] = useState<Array<boolean>>([false, false, false, false]);

    const setIndexGroupFocus = (index: number) => {
        let indexGroupNew: Array<boolean> = [...indexGroup];
        for (let i = 0; i < indexGroupNew.length; i++) {
            if (i === index) {
                indexGroupNew[i] = !indexGroupNew[i];
            } else {
                indexGroupNew[i] = false;
            }
        }
        setIndexGroup(indexGroupNew);
    }

    return (
        <div
            className={"IndexFaqSection2"}
        >
            <div
                className={"IndexFaqSection2Title"}
            >
                {props.lang === "es" && <h1
                >
                    Prueba gratis y configuración
                </h1>}
                {props.lang === "en" && <h1
                >
                    Free trial and setup
                </h1>}
            </div>
            <section
                className={"IndexFaqSection2Sec"}
            >
                <button
                    className={"IndexFaqSection2SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(0)}
                >
                    {props.lang === "es" && <h2>
                        ¿Por cuántos días puedo probar la plataforma DvGeo?
                    </h2>}
                    {props.lang === "en" && <h2>
                        For how many days can I test the DvGeo platform?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[0] && <div
                    className={"IndexFaqSection2SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Lamentable no brindamos un sistema Demo gratis, solo podemos demostrarte la solución completa de
                        DvGeo a través de video conferencias, en vivo o presencial en nuestras oficinas. Lo hacemos de
                        esta forma porque las tres apps de la plataforma DvGeo se crean desde el inicio con el logotipo
                        de su empresa y este proceso representa un costo para nosotros.
                    </p>}
                    {props.lang === "en" && <p>
                        Unfortunately we do not provide a free Demo system, we can only demonstrate the complete DvGeo
                        solution through video conferences, live or in person at our offices. We do it this way because
                        the three apps on the DvGeo platform are created from the beginning with your company logo and
                        this process represents a cost for us.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection2SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(1)}
                >
                    {props.lang === "es" && <h2>
                        ¿Qué necesito para adquirir la plataforma completa de DvGeo?
                    </h2>}
                    {props.lang === "en" && <h2>
                        What do I need to purchase the complete DvGeo platform?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[1] && <div
                    className={"IndexFaqSection2SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Primero debes registrar tu datos de forma gratuita, luego iniciar sesión en tu cuenta y añadir
                        los documentos: Identificación comercial, Indentificación de ciudadanía y Logotipo para las
                        apps. Un asesor comercial se contactará contigo para iniciar el proceso.
                    </p>}
                    {props.lang === "en" && <p>
                        That's right, each service can have its own tariff that are defined by schedules and in each
                        schedule the ranges are assigned by kilometers, resulting in a digital taximeter.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection2SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(2)}
                >
                    {props.lang === "es" && <h2>
                        ¿Qué equipos necesito para la plataforma DvGeo?
                    </h2>}
                    {props.lang === "en" && <h2>
                        What equipment do I need for the DvGeo platform?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[2] && <div
                    className={"IndexFaqSection2SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Dado que DvGeo es una solución basada en la nube, se puede acceder a la plataforma desde
                        cualquier lugar en dispositivos estándar. Tan solo necesitas un PC (para el aplicativo Admin)
                        con un mínimo de 2 GB de RAM y al menos un 2 GHz CPU de doble núcleo (disponible en la mayoría
                        de los ordenadores estándar) y un teléfono inteligente / tableta para el App de conductor
                        (cualquier dispositivo Android / iOS con el GPS activado y 3G / 4G conexión a Internet).
                    </p>}
                    {props.lang === "en" && <p>
                        Since DvGeo is a cloud-based solution, the platform can be accessed from anywhere on standard
                        devices. You only need a PC (for the Admin application) with a minimum of 2 GB of RAM and at
                        least a 2 GHz dual-core CPU (available in most standard computers) and a smartphone / tablet for
                        the Driver App (any Android / iOS device with GPS enabled and 3G / 4G Internet connection).
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection2SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(3)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo negociar o tener descuentos de los precios de la plataforma DvGeo?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I negotiate or have discounts on the prices of the DvGeo platform?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[3] && <div
                    className={"IndexFaqSection2SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Nuestros precios son muy accesibles, a pesar de eso, podemos escuchar tu propuesta para
                        analizarla y de ser justa podemos colaborar contigo.
                    </p>}
                    {props.lang === "en" && <p>
                        Our prices are very accessible, despite that, we can listen to your proposal to analyze and if
                        we are fair we can collaborate with you.
                    </p>}
                </div>}
            </section>
        </div>
    )
}
export default IndexFaqSection2;
