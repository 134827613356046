//controllers
import {Customer} from '../../api/customer/customerModel';

//interface
export interface CustomerStoreState {
    item?: Customer | any;
    items?: Array<Customer> | any;
    is_loading?: boolean;
}

export interface CustomerStoreDispatch {
    postCustomers?: (items: Array<Customer>) => void;
    putCustomers?: (items: Array<Customer>) => void;
    deleteCustomers?: (items: Array<Customer>) => void;
    postCustomer?: (item: Customer) => void;
    putCustomer?: (item: Customer) => void;
    deleteCustomer?: () => void;
    addCustomers?: (item: Customer) => void;
    removeCustomers?: (item: Customer) => void;
}

export interface CustomerStoreAction extends CustomerStoreState {
    type: "POST_CUSTOMER" | "PUT_CUSTOMER" | "DELETE_CUSTOMER" | "POST_CUSTOMERS" | "DELETE_CUSTOMERS" | "ADD_CUSTOMERS" | "REMOVE_CUSTOMERS"
}

//Inserta el administrador
export const postCustomer = (item: Customer): CustomerStoreAction => ({
    type: "POST_CUSTOMER",
    item
});

//Modifica el administrador
export const putCustomer = (item: Customer): CustomerStoreAction => ({
    type: "PUT_CUSTOMER",
    item
});

//Elimina el administrador
export const deleteCustomer = (): CustomerStoreAction => ({
    type: "DELETE_CUSTOMER",
});

//Inserta un array de administradors
export const postCustomers = (items: Array<Customer>): CustomerStoreAction => ({
    type: "POST_CUSTOMERS",
    items
});

//Elimina el array de administradors
export const deleteCustomers = (): CustomerStoreAction => ({
    type: "DELETE_CUSTOMERS",
});

//Agrega un administrador al array de administradors
export const addCustomers = (item: Customer): CustomerStoreAction => ({
    type: "POST_CUSTOMERS",
    item
});

//Elimina un administrador del array de administradors
export const removeCustomers = (item: Customer): CustomerStoreAction => ({
    type: "REMOVE_CUSTOMERS",
    item
});
