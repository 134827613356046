import React, {useEffect, useRef, useState} from "react";
//theme
import "./CustomerNavBarMenu.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";

//types
import {Lang} from "../../../types";

//interface
interface Props {
    lang: Lang;
}

//component
const CustomerNavBarMenu = (props: Props) => {
    //hooks
    const history = useHistory();
    //refs
    const navBarMenuRef = useRef<any>(null);
    const sectionMenuRef = useRef<any>(null);
    //state
    const [screen, setScreen] = useState<"mobile" | "tablet" | "desktop">("mobile");
    const [isShowMenu, setIsShowMenu] = useState<boolean>(true);
    //effects
    useEffect(() => {
        setTimeout(() => {
            if (navBarMenuRef.current) {
                navBarMenuRef.current.style.marginTop = 0
            }
        }, 1000 / 6);
        getScreenType();
        if ('onorientationchange' in window) {
            window.addEventListener("orientationchange", () => getScreenType(), false);
        }
        window.addEventListener('resize', () => getScreenType());
        return () => {
            window.removeEventListener("orientationchange", () => getScreenType());
            window.addEventListener('resize', () => getScreenType());
        }
    }, []);
    useEffect(() => {
        if (screen === "desktop") {
            setIsShowMenu(true);
        } else {
            setIsShowMenu(false);
        }
    }, [screen])

    const getScreenType = () => {
        window.matchMedia("(max-width: 600px)").matches && setScreen("mobile");
        window.matchMedia("(min-width: 800px)").matches && setScreen("tablet");
        window.matchMedia("(min-width: 1000px)").matches && setScreen("desktop");
    }

    return (
        <div
            ref={navBarMenuRef}
            className={"CustomerNavBarMenu"}
        >
            {/*section logo*/}
            <section
                className={"CustomerNavBarMenuSecLogo"}
                onClick={() => history.push(`/${props.lang}/application/customer`)}
            >
                <img
                    src={require("../../../assets/img/logo.png").default}
                    alt={"logo"}
                />
                <section>
                    <label>
                        {props.lang === "es" && "Panel del"}
                        {props.lang === "en" && "Panel"}
                    </label>
                    <label>
                        {props.lang === "es" && "Cliente"}
                        {props.lang === "en" && "Customer"}
                    </label>
                </section>
            </section>
            {/*botón de menu*/}
            <section
                className={"CustomerNavBarMenuSecMenuBtn"}
                onClick={() => setIsShowMenu(!isShowMenu)}
            >
                <FontAwesomeIcon
                    icon={faBars}
                />
            </section>
            {/*section menu*/}
            {(navBarMenuRef.current && isShowMenu) && <section
                ref={sectionMenuRef}
                className={(screen === "mobile" || screen === "tablet") ? "CustomerNavBarMenuSecMenuResponsive" : "CustomerNavBarMenuSecMenu"}
                style={{
                    top: navBarMenuRef.current.clientHeight,
                    right: 0,
                }}
            >
                <button
                    className={(window.location.pathname === `/${props.lang}/application/customer`) ? "CustomerNavBarMenuSecMenuItemFocus" : "CustomerNavBarMenuSecMenuItem"}
                    onClick={() => history.push(`/${props.lang}/application/customer`)}
                >
                    {props.lang === "es" && <label>
                        Inicio
                    </label>}
                    {props.lang === "en" && <label>
                        Home
                    </label>}
                </button>
                <button
                    className={(window.location.pathname.includes("company")) ? "CustomerNavBarMenuSecMenuItemFocus" : "CustomerNavBarMenuSecMenuItem"}
                    onClick={() => history.push(`/${props.lang}/application/customer/company`)}
                >
                    {props.lang === "es" && <label>
                        Empresa
                    </label>}
                    {props.lang === "en" && <label>
                        Company
                    </label>}
                </button>
                <button
                    className={(window.location.pathname.includes("invoice")) ? "CustomerNavBarMenuSecMenuItemFocus" : "CustomerNavBarMenuSecMenuItem"}
                    onClick={() => history.push(`/${props.lang}/application/customer/invoice`)}
                >
                    {props.lang === "es" && <label>
                        Facturas
                    </label>}
                    {props.lang === "en" && <label>
                        Invoices
                    </label>}
                </button>
                <button
                    className={(window.location.pathname.includes("support")) ? "CustomerNavBarMenuSecMenuItemFocus" : "CustomerNavBarMenuSecMenuItem"}
                    onClick={() => history.push(`/${props.lang}/application/customer/support`)}
                >
                    {props.lang === "es" && <label>
                        Soporte
                    </label>}
                    {props.lang === "en" && <label>
                        Support
                    </label>}
                </button>
                <button
                    className={(window.location.pathname.includes("apps")) ? "CustomerNavBarMenuSecMenuItemFocus" : "CustomerNavBarMenuSecMenuItem"}
                    onClick={() => history.push(`/${props.lang}/application/customer/apps`)}
                >
                    {props.lang === "es" && <label>
                        Mis Apps
                    </label>}
                    {props.lang === "en" && <label>
                        My Apps
                    </label>}
                </button>
            </section>}
        </div>
    )
}
export default CustomerNavBarMenu;
