import React from "react";
//theme
import "./styles.scss";
//libs
import {useHistory} from "react-router-dom";
//components
import Image from "../../../../_components/Image";
//types
import {Lang} from "../../../../../types";
import config from "../../../../../config";

//interfaces
interface Props {
    lang: Lang
}

//view
const AppPassenger = (props: Props) => {
    //hooks
    const history = useHistory();

    return (
        <div
            className={"AppPassenger"}
        >
            <div
                className={"AppPassengerImg"}
            >
                <Image
                    srcPrev={`${config.host}/uploads/assets/passenger_1.png?w=64&h=124`}
                    src={`${config.host}/uploads/assets/passenger_1.png`}
                    alt={"dvgeo_passenger_1"}
                />
            </div>
            <section
                className={"AppPassengerSec"}
            >
                {props.lang === "es" && <h1>
                    <strong>Crea</strong> tu propia marca digital
                </h1>}
                {props.lang === "en" && <h1>
                    <strong>Create</strong> your own digital brand
                </h1>}
                {props.lang === "es" && <p>
                    <strong> Olvídate de la competencia desleal</strong>, atrae nuevos clientes y automatiza reservas
                    con tus propias apps
                    de pasajero
                </p>}
                {props.lang === "en" && <p>
                    <strong>Forget about unfair competition</strong>, attract new customers and automate reservations
                    with your own
                    passenger apps
                </p>}
                <button
                    onClick={() => {
                        history.push(`/${props.lang}/app/passenger`);
                    }}
                >
                    {props.lang === "es" && <label>
                        Más Info
                    </label>}
                    {props.lang === "en" && <label>
                        More Info
                    </label>}
                </button>
            </section>
        </div>
    )
}
export default AppPassenger;
