import React from "react";
//theme
import "./styles.scss";
//libs
import {useHistory} from "react-router-dom";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminSection5 = (props: Props, ref: any) => {
    //hooks
    const history = useHistory();

    return (
        <div
            ref={ref}
            className={"IndexAppAdminSection5Content"}
        >
            <div
                className={"IndexAppAdminSection5 js-scroll slide-left"}
            >
                <div
                    className={"IndexAppAdminSection5Img"}
                >
                    <img
                        src={require("../../../../../assets/img/admin_group_1.png").default}
                        alt={"dvgeo_admin_user_1"}
                    />
                </div>
                <section
                    className={"IndexAppAdminSection5Sec"}
                >
                    {props.lang === "es" && <h1>
                        <strong>Comunícate</strong> con tus conductores
                    </h1>}
                    {props.lang === "en" && <h1>
                        <strong>Communicate</strong> with your drivers
                    </h1>}
                    {props.lang === "es" && <p>
                        Mediante <strong>grupos de chat</strong> puedes asignar a tus socios conductores para mantener la comunicación constante en el mismo aplicativo
                    </p>}
                    {props.lang === "en" && <p>
                        Through <strong>chat groups</strong> you can assign your driving partners to maintain constant communication in the same application
                    </p>}
                    <button
                        onClick={() => {
                            history.push(`/${props.lang}/app/admin/module/group`);
                        }}
                    >
                        {props.lang === "es" && <label>
                            Más info
                        </label>}
                        {props.lang === "en" && <label>
                            More Info
                        </label>}
                    </button>
                </section>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppAdminSection5);
