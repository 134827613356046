import React, {useEffect, useRef, useState} from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminTravelSection2 = (props: Props) => {
    //refs
    const iTimer = useRef<NodeJS.Timeout | number>(0);
    const indexAppAdminServiceSection1DesktopSnapContentRef = useRef<any>(null);
    const items = useRef<Array<any>>([]);
    //state
    const [index, setIndex] = useState<number>(0);
    //effects
    useEffect(() => {
        init();
        return () => {
            clearIntervals();
        }
    }, []);

    const init = () => {
        try {
            iIndexTranslateX();
        } catch (error: any) {

        }
    }

    const iIndexTranslateX = () => {
        setIndex(0);
        setTranslateXValid(0);
        clearInterval(iTimer.current as NodeJS.Timeout);
        let indexCurrent = 0;
        iTimer.current = setInterval(() => {
            if (indexCurrent >= 0 && indexCurrent <= 2) {
                indexCurrent++;
                setTranslateXValid(indexCurrent);
                setIndex(indexCurrent);
            } else {
                iIndexTranslateX();
                indexCurrent = 0;
                setIndex(0);
            }
        }, 6000);
    }

    const clearIntervals = () => {
        clearInterval(iTimer.current as NodeJS.Timeout);
        iTimer.current = 0;
    }

    const setTranslateX = (index: number, value: string) => {
        try {
            items.current[index].style.transform = value;
        } catch (error: any) {
            setTimeout(() => setTranslateX(index, value), 1000 / 5);
        }
    }

    const setTranslateXValid = (index: number) => {
        if (index === 0) {
            setTimeout(() => setTranslateX(0, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-0vw)`), 1000 / 5);
        }
        if (index === 1) {
            setTimeout(() => setTranslateX(0, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-40vw)`), 1000 / 5);
        }
        if (index === 2) {
            setTimeout(() => setTranslateX(0, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-80vw)`), 1000 / 5);
        }
        if (index === 3) {
            setTimeout(() => setTranslateX(0, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-120vw)`), 1000 / 5);
        }
    }

    return (
        <div
            className={"IndexAppAdminTravelSection2"}
        >
            {props.lang === "es" && <h1
                className={"IndexAppAdminTravelSection2Title"}
            >
                ¿Cómo funciona?
            </h1>}
            {props.lang === "en" && <h1
                className={"IndexAppAdminTravelSection2Title"}
            >
                How does it work?
            </h1>}
            {/*apps en modo mobile*/}
            <div
                className={"IndexAppAdminTravelSection2Mobile"}
            >
                <section
                    className={"IndexAppAdminTravelSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminTravelSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminTravelSection2DesktopSnapControlBtnSpan"}
                        >
                           1
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Consola de despacho / reserva
                            </h6>}
                            {props.lang === "en" && <h6>
                                Dispatch / reservation console
                            </h6>}
                            {props.lang === "es" && <label>
                                Conductores cercanos en el mapa, información escencial del viaje, táximetro por servicio y otras funciones importantes para despachar tu viaje
                            </label>}
                            {props.lang === "en" && <label>
                                Nearby drivers on the map, essential trip information, service taximeter and other important functions to dispatch your trip
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminTravelSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_travel_1.png").default}
                            alt={"dvgeo_admin_travel_1"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppAdminTravelSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminTravelSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminTravelSection2DesktopSnapControlBtnSpan"}
                        >
                           2
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Historial de pedidos
                            </h6>}
                            {props.lang === "en" && <h6>
                                Orders history
                            </h6>}
                            {props.lang === "es" && <label>
                                Revisa toda la información de cada uno de los viajes realizados o solicitados por tus usuarios
                            </label>}
                            {props.lang === "en" && <label>
                                Check all the information of each of the trips made or requested by your users
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminTravelSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_travel_2.png").default}
                            alt={"dvgeo_admin_travel_2"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppAdminTravelSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminTravelSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminTravelSection2DesktopSnapControlBtnSpan"}
                        >
                           3
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Monitorea las rutas de los viajes
                            </h6>}
                            {props.lang === "en" && <h6>
                                Monitor travel routes
                            </h6>}
                            {props.lang === "es" && <label>
                                Puedes dar seguimiento en tiempo real de la ruta de tus conductores en los viajes en curso
                            </label>}
                            {props.lang === "en" && <label>
                                You can track in real time the route of your drivers on current trips
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminTravelSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_travel_3.png").default}
                            alt={"dvgeo_admin_travel_3"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppAdminTravelSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminTravelSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminTravelSection2DesktopSnapControlBtnSpan"}
                        >
                           4
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Cambia de conductor
                            </h6>}
                            {props.lang === "en" && <h6>
                                Change driver
                            </h6>}
                            {props.lang === "es" && <label>
                                En caso de emergencia puedes reemplazar el conductor del viaje en curso por otro cercano, tus pasajeros estarán agradecidos
                            </label>}
                            {props.lang === "en" && <label>
                                In an emergency, you can replace the driver of the trip in progress with a nearby one, your passengers will be grateful
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminTravelSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_travel_4.png").default}
                            alt={"dvgeo_admin_travel_4"}
                        />
                    </div>
                </section>
            </div>
            {/*apps en modo desktop*/}
            <div
                className={"IndexAppAdminTravelSection2Desktop"}
            >
                <div
                    ref={indexAppAdminServiceSection1DesktopSnapContentRef}
                    className={"IndexAppAdminTravelSection2DesktopSnapControl"}
                >
                    <button
                        className={index === 0 ? "IndexAppAdminTravelSection2DesktopSnapControlBtnFocus" : "IndexAppAdminTravelSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(0);
                            setIndex(0);
                            clearIntervals();
                        }}
                    >
                        <span
                            className={index === 0 ? "IndexAppAdminTravelSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminTravelSection2DesktopSnapControlBtnSpan"}
                        >
                           1
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Consola de despacho / reserva
                            </h6>}
                            {props.lang === "en" && <h6>
                                Dispatch / reservation console
                            </h6>}
                            {props.lang === "es" && <label>
                                Conductores cercanos en el mapa, información escencial del viaje, táximetro por servicio y otras funciones importantes para despachar tu viaje
                            </label>}
                            {props.lang === "en" && <label>
                                Nearby drivers on the map, essential trip information, service taximeter and other important functions to dispatch your trip
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 1 ? "IndexAppAdminTravelSection2DesktopSnapControlBtnFocus" : "IndexAppAdminTravelSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(1);
                            setIndex(1);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 1 ? "IndexAppAdminTravelSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminTravelSection2DesktopSnapControlBtnSpan"}
                       >
                           2
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Historial de pedidos
                            </h6>}
                            {props.lang === "en" && <h6>
                                Orders history
                            </h6>}
                            {props.lang === "es" && <label>
                                Revisa toda la información de cada uno de los viajes realizados o solicitados por tus usuarios
                            </label>}
                            {props.lang === "en" && <label>
                                Check all the information of each of the trips made or requested by your users
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 2 ? "IndexAppAdminTravelSection2DesktopSnapControlBtnFocus" : "IndexAppAdminTravelSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(2);
                            setIndex(2);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 2 ? "IndexAppAdminTravelSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminTravelSection2DesktopSnapControlBtnSpan"}
                       >
                           3
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                               Monitorea las rutas de los viajes
                            </h6>}
                            {props.lang === "en" && <h6>
                                Monitor travel routes
                            </h6>}
                            {props.lang === "es" && <label>
                                Puedes dar seguimiento en tiempo real de la ruta de tus conductores en los viajes en curso
                            </label>}
                            {props.lang === "en" && <label>
                                You can track in real time the route of your drivers on current trips
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 3 ? "IndexAppAdminTravelSection2DesktopSnapControlBtnFocus" : "IndexAppAdminTravelSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(3);
                            setIndex(3);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 3 ? "IndexAppAdminTravelSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminTravelSection2DesktopSnapControlBtnSpan"}
                       >
                           4
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Cambia de conductor
                            </h6>}
                            {props.lang === "en" && <h6>
                                Change driver
                            </h6>}
                            {props.lang === "es" && <label>
                                En caso de emergencia puedes reemplazar el conductor del viaje en curso por otro cercano, tus pasajeros estarán agradecidos
                            </label>}
                            {props.lang === "en" && <label>
                                In an emergency, you can replace the driver of the trip in progress with a nearby one, your passengers will be grateful
                            </label>}
                        </section>
                    </button>
                </div>
                <div
                    ref={indexAppAdminServiceSection1DesktopSnapContentRef}
                    className={"IndexAppAdminTravelSection2DesktopSnapContent"}
                >
                    <div
                        className={"IndexAppAdminTravelSection2DesktopSnapContentBackground"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_monitor.png").default}
                            alt={""}
                        />
                    </div>
                    <section
                        ref={ref => items.current[0] = ref}
                        className={"IndexAppAdminTravelSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_travel_1.png").default}
                            alt={"dvgeo_admin_travel_1"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[1] = ref}
                        className={"IndexAppAdminTravelSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_travel_2.png").default}
                            alt={"dvgeo_admin_travel_2"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[2] = ref}
                        className={"IndexAppAdminTravelSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_travel_3.png").default}
                            alt={"dvgeo_admin_travel_3"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[3] = ref}
                        className={"IndexAppAdminTravelSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_travel_4.png").default}
                            alt={"dvgeo_admin_travel_4"}
                        />
                    </section>
                </div>
            </div>
        </div>
    )
}
export default IndexAppAdminTravelSection2;
