import React, {useEffect, useRef, useState} from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminUserSection2 = (props: Props) => {
    //refs
    const iTimer = useRef<NodeJS.Timeout | number>(0);
    const indexAppAdminServiceSection1DesktopSnapContentRef = useRef<any>(null);
    const items = useRef<Array<any>>([]);
    //state
    const [index, setIndex] = useState<number>(0);
    //effects
    useEffect(() => {
        init();
        return () => {
            clearIntervals();
        }
    }, []);

    const init = () => {
        try {
            iIndexTranslateX();
        } catch (error: any) {

        }
    }

    const iIndexTranslateX = () => {
        setIndex(0);
        setTranslateXValid(0);
        clearInterval(iTimer.current as NodeJS.Timeout);
        let indexCurrent = 0;
        iTimer.current = setInterval(() => {
            if (indexCurrent >= 0 && indexCurrent <= 2) {
                indexCurrent++;
                setTranslateXValid(indexCurrent);
                setIndex(indexCurrent);
            } else {
                iIndexTranslateX();
                indexCurrent = 0;
                setIndex(0);
            }
        }, 6000);
    }

    const clearIntervals = () => {
        clearInterval(iTimer.current as NodeJS.Timeout);
        iTimer.current = 0;
    }

    const setTranslateX = (index: number, value: string) => {
        try {
            items.current[index].style.transform = value;
        } catch (error: any) {
            setTimeout(() => setTranslateX(index, value), 1000 / 5);
        }
    }

    const setTranslateXValid = (index: number) => {
        if (index === 0) {
            setTimeout(() => setTranslateX(0, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-0vw)`), 1000 / 5);
        }
        if (index === 1) {
            setTimeout(() => setTranslateX(0, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-40vw)`), 1000 / 5);
        }
        if (index === 2) {
            setTimeout(() => setTranslateX(0, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-80vw)`), 1000 / 5);
        }
        if (index === 3) {
            setTimeout(() => setTranslateX(0, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-120vw)`), 1000 / 5);
        }
    }

    return (
        <div
            className={"IndexAppAdminUserSection2"}
        >
            {props.lang === "es" && <h1
                className={"IndexAppAdminUserSection2Title"}
            >
                ¿Cómo funciona?
            </h1>}
            {props.lang === "en" && <h1
                className={"IndexAppAdminUserSection2Title"}
            >
                How does it work?
            </h1>}
            {/*apps en modo mobile*/}
            <div
                className={"IndexAppAdminUserSection2Mobile"}
            >
                <section
                    className={"IndexAppAdminUserSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminUserSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminUserSection2DesktopSnapControlBtnSpan"}
                        >
                           1
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Registra a tus operadores
                            </h6>}
                            {props.lang === "en" && <h6>
                                Register your operators
                            </h6>}
                            {props.lang === "es" && <label>
                                Los administradores controlan toda la oficina online, los comerciantes distribuyen saldo para los conductores, los pasajeros y conductores en sus respectivas apps móviles.
                            </label>}
                            {props.lang === "en" && <label>
                                Administrators control the entire online office, merchants distribute credit to drivers, passengers and drivers in their respective mobile apps.
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminUserSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_user_1.png").default}
                            alt={"dvgeo_admin_user_1"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppAdminUserSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminUserSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminUserSection2DesktopSnapControlBtnSpan"}
                        >
                           2
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Otorga permisos de acceso a todos los módulos
                            </h6>}
                            {props.lang === "en" && <h6>
                                Grant access permissions to all modules
                            </h6>}
                            {props.lang === "es" && <label>
                                Mediante los perfiles puedes controlar las acciones que realizan los usuarios administradores. Por ejemplo puedes permitir que un administrador solo pueda realizar despacho de carreras.
                            </label>}
                            {props.lang === "en" && <label>
                                Through profiles you can control the actions that admin users take. For example, you can allow an administrator to only perform career dispatch.
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminUserSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_user_2.png").default}
                            alt={"dvgeo_admin_user_2"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppAdminUserSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminUserSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminUserSection2DesktopSnapControlBtnSpan"}
                        >
                           3
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Verifica los documentos legales de tus conductores
                            </h6>}
                            {props.lang === "en" && <h6>
                                Check the legal documents of your drivers
                            </h6>}
                            {props.lang === "es" && <label>
                                Cuando un conductor se registra en el aplicativo móvil, le pide información legal y confidencial con el objetivo de verificar una cuenta real. Los documentos son: cédula, licencia, matricula e información del vehículo.
                            </label>}
                            {props.lang === "en" && <label>
                                When a driver registers in the mobile application, he asks for legal and confidential information in order to verify a real account. The documents are: identity card, license, registration and vehicle information.
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminUserSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_user_3.png").default}
                            alt={"dvgeo_admin_user_3"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppAdminUserSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminUserSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminUserSection2DesktopSnapControlBtnSpan"}
                        >
                           4
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Informes / Historial de viajes, transacciones y más...
                            </h6>}
                            {props.lang === "en" && <h6>
                                Reports / History of trips, transactions and more ...
                            </h6>}
                            {props.lang === "es" && <label>
                                Cada solicitud queda registrada en la plataforma para que puedas ver tus ganancias, mejorar la calidad del servicio o ver las rutas más solicitadas por tus usuarios. Tambien puedes comprobar los recibos generados por compra de recargas y cobro de comisiones.
                            </label>}
                            {props.lang === "en" && <label>
                                Each request is registered on the platform so that you can see your earnings, improve the quality of the service or see the routes most requested by your users. You can also check the receipts generated for the purchase of recharges and collection of commissions.
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminUserSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_user_4.png").default}
                            alt={"dvgeo_admin_user_4"}
                        />
                    </div>
                </section>
            </div>
            {/*apps en modo desktop*/}
            <div
                className={"IndexAppAdminUserSection2Desktop"}
            >
                <div
                    ref={indexAppAdminServiceSection1DesktopSnapContentRef}
                    className={"IndexAppAdminUserSection2DesktopSnapControl"}
                >
                    <button
                        className={index === 0 ? "IndexAppAdminUserSection2DesktopSnapControlBtnFocus" : "IndexAppAdminUserSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(0);
                            setIndex(0);
                            clearIntervals();
                        }}
                    >
                        <span
                            className={index === 0 ? "IndexAppAdminUserSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminUserSection2DesktopSnapControlBtnSpan"}
                        >
                           1
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Registra a tus operadores
                            </h6>}
                            {props.lang === "en" && <h6>
                                Register your operators
                            </h6>}
                            {props.lang === "es" && <label>
                                Los administradores controlan toda la oficina online, los comerciantes distribuyen saldo para los conductores, los pasajeros y conductores en sus respectivas apps móviles.
                            </label>}
                            {props.lang === "en" && <label>
                                Administrators control the entire online office, merchants distribute credit to drivers, passengers and drivers in their respective mobile apps.
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 1 ? "IndexAppAdminUserSection2DesktopSnapControlBtnFocus" : "IndexAppAdminUserSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(1);
                            setIndex(1);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 1 ? "IndexAppAdminUserSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminUserSection2DesktopSnapControlBtnSpan"}
                       >
                           2
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Otorga permisos de acceso a todos los módulos
                            </h6>}
                            {props.lang === "en" && <h6>
                                Grant access permissions to all modules
                            </h6>}
                            {props.lang === "es" && <label>
                                Mediante los perfiles puedes controlar las acciones que realizan los usuarios administradores. Por ejemplo puedes permitir que un administrador solo pueda realizar despacho de carreras.
                            </label>}
                            {props.lang === "en" && <label>
                                Through profiles you can control the actions that admin users take. For example, you can allow an administrator to only perform career dispatch.
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 2 ? "IndexAppAdminUserSection2DesktopSnapControlBtnFocus" : "IndexAppAdminUserSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(2);
                            setIndex(2);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 2 ? "IndexAppAdminUserSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminUserSection2DesktopSnapControlBtnSpan"}
                       >
                           3
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                               Verifica los documentos legales de tus conductores
                            </h6>}
                            {props.lang === "en" && <h6>
                                Check the legal documents of your drivers
                            </h6>}
                            {props.lang === "es" && <label>
                                Cuando un conductor se registra en el aplicativo móvil, le pide información legal y confidencial con el objetivo de verificar una cuenta real. Los documentos son: cédula, licencia, matricula e información del vehículo.
                            </label>}
                            {props.lang === "en" && <label>
                                When a driver registers in the mobile application, he asks for legal and confidential information in order to verify a real account. The documents are: identity card, license, registration and vehicle information.
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 3 ? "IndexAppAdminUserSection2DesktopSnapControlBtnFocus" : "IndexAppAdminUserSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(3);
                            setIndex(3);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 3 ? "IndexAppAdminUserSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminUserSection2DesktopSnapControlBtnSpan"}
                       >
                           4
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Informes / Historial de viajes, transacciones y más...
                            </h6>}
                            {props.lang === "en" && <h6>
                                Reports / History of trips, transactions and more ...
                            </h6>}
                            {props.lang === "es" && <label>
                                Cada solicitud queda registrada en la plataforma para que puedas ver tus ganancias, mejorar la calidad del servicio o ver las rutas más solicitadas por tus usuarios. Tambien puedes comprobar los recibos generados por compra de recargas y cobro de comisiones.
                            </label>}
                            {props.lang === "en" && <label>
                                Each request is registered on the platform so that you can see your earnings, improve the quality of the service or see the routes most requested by your users. You can also check the receipts generated for the purchase of recharges and collection of commissions.
                            </label>}
                        </section>
                    </button>
                </div>
                <div
                    ref={indexAppAdminServiceSection1DesktopSnapContentRef}
                    className={"IndexAppAdminUserSection2DesktopSnapContent"}
                >
                    <div
                        className={"IndexAppAdminUserSection2DesktopSnapContentBackground"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_monitor.png").default}
                            alt={""}
                        />
                    </div>
                    <section
                        ref={ref => items.current[0] = ref}
                        className={"IndexAppAdminUserSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_user_1.png").default}
                            alt={"dvgeo_admin_user_1"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[1] = ref}
                        className={"IndexAppAdminUserSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_user_2.png").default}
                            alt={"dvgeo_admin_user_2"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[2] = ref}
                        className={"IndexAppAdminUserSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_user_3.png").default}
                            alt={"dvgeo_admin_user_3"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[3] = ref}
                        className={"IndexAppAdminUserSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_user_4.png").default}
                            alt={"dvgeo_admin_user_4"}
                        />
                    </section>
                </div>
            </div>
        </div>
    )
}
export default IndexAppAdminUserSection2;
