import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppDriverSection2 = (props: Props, ref: any) => {
    //hooks

    return (
        <div
            ref={ref}
            className={"IndexAppDriverSection2Content"}
        >
            <div
                className={"IndexAppDriverSection2 js-scroll slide-right"}
            >
                <section
                    className={"IndexAppDriverSection2Sec"}
                >
                    {props.lang === "es" && <h1>
                        <strong>Comunícate</strong> con tu pasajero
                    </h1>}
                    {props.lang === "en" && <h1>
                        <strong>Communicate</strong> with your passenger
                    </h1>}
                    {props.lang === "es" && <p>
                        Mediante un <strong>chat dentro de la app</strong> o mensajes de WhatsApp al número del cliente
                    </p>}
                    {props.lang === "en" && <p>
                        Through a <strong>chat within the app</strong> or WhatsApp messages to the customer's number
                    </p>}
                </section>
                <div
                    className={"IndexAppDriverSection2Img"}
                >
                    <img
                        src={require("../../../../../assets/img/driver_5.png").default}
                        alt={"dvgeo_driver_5"}
                    />
                </div>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppDriverSection2);
