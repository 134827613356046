import React, {CSSProperties, HTMLInputTypeAttribute, useEffect, useState} from "react";
//theme
import "./TextInput.scss";
//libs
//types
//interfaces
interface Props {
    inputRef?: React.RefObject<any>;
    style?: CSSProperties;
    fullWidth?: boolean;
    placeholder?: string;
    type?: HTMLInputTypeAttribute | undefined;
    label?: string;
    error?: string;
    value: string;
    defaultChecked?: boolean | undefined;
    checked?: boolean | undefined;
    step?: string;
    min?: string;
    max?: string;
    maxLength?: number;
    size?: "small" | "normal" | "full";
    pattern?: string;
    multiline?: boolean;
    contentRight?: any;
    readOnly?: boolean;
    onChangeText?: (text: string) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onError?: (error: string, length: number) => void;
}

//component
const TextInput: React.FC<Props> = (props: Props) => {

    const getValueLength = (value: string): { color: string; text: string } => {
        const value_length: number = value.length;
        const max_length: number = props.maxLength ? props.maxLength : 0;
        const total_length: number = max_length - value_length;
        if (value_length >= (max_length - 5) && value_length <= max_length) {
            return {color: "red", text: total_length + ""};
        } else if (total_length <= 0) {
            props.onError && props.onError(total_length + "", total_length)
            return {color: "red", text: total_length + ""};
        } else {
            return {color: "black", text: total_length + ""};
        }
    }

    return (
        <div
            style={{
                ...props.style,
            }}
            className={"TextInputContent"}
        >
            {props.label && <div
                className={"TextInputContentLabel"}
            >
                {props.label}
            </div>}
            {props.multiline ? <textarea
                ref={props.inputRef && props.inputRef}
                className={props.error ? `TextInputError ${props.size && props.size === "small" ? "TextInputContentSmall" : props.size === "normal" ? "TextInputContentNormal" : props.size === "full" ? "TextInputContentFull" : ""}` : `TextInput ${props.size && props.size === "small" ? "TextInputContentSmall" : props.size === "normal" ? "TextInputContentNormal" : props.size === "full" ? "TextInputContentFull" : ""}`}
                placeholder={props.placeholder && props.placeholder}
                value={props.value}
                rows={3}
                readOnly={props.readOnly}
                onChange={(e) => props.onChangeText && props.onChangeText(e.target.value)}
                onKeyPress={(e) => props.onKeyPress && props.onKeyPress(e)}
            /> : <input
                ref={props.inputRef && props.inputRef}
                className={props.error ? `TextInputError ${props.size && props.size === "small" ? "TextInputContentSmall" : props.size === "normal" ? "TextInputContentNormal" : props.size === "full" ? "TextInputContentFull" : ""}` : `TextInput ${props.size && props.size === "small" ? "TextInputContentSmall" : props.size === "normal" ? "TextInputContentNormal" : props.size === "full" ? "TextInputContentFull" : ""}`}
                placeholder={props.placeholder && props.placeholder}
                type={props.type}
                value={props.value}
                defaultChecked={props.defaultChecked}
                checked={props.checked}
                step={props.step}
                min={props.min}
                max={props.max}
                pattern={props.pattern}
                readOnly={props.readOnly}
                onChange={(e) => props.onChangeText && props.onChangeText(e.target.value)}
                onKeyPress={(e) => props.onKeyPress && props.onKeyPress(e)}
            />}
            {props.error && <div
                className={`TextInputErrorContent `}
            >
                {props.error}
            </div>}
            {(!props.error && props.value && props.maxLength) && <div
                style={{
                    color: getValueLength(props.value).color
                }}
                className={`TextInputMaxLength `}
            >
                {getValueLength(props.value).text}
            </div>}
            {props.contentRight && <div
                className={"TextInputContentRight"}
            >
                {props.contentRight}
            </div>}
        </div>
    )
}
TextInput.defaultProps = {
    size: "normal"
}
export default TextInput;
