//libs
import config from '../../config';
import ResolverFetch from "../../libs/_resolver_fetch";
import {getToken} from "../system/_token";
//model
import {CustomerSupportType, CustomerSupportTypeResponse} from "./customerSupportTypeModel";
//types
import {Lang} from "../../types";
//api
export const CustomerSupportTypeAPI = {
    getByAll: async (lang: Lang) => {
        return await new Promise<CustomerSupportTypeResponse>(async (resolve: (value: CustomerSupportTypeResponse) => void, reject: (reason: CustomerSupportTypeResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support/type?type=getByAll&lang=${lang}`, {
                "method": "GET",
                "headers": {
                    "Accept": "application/json",
                    "authorization": await getToken()
                },
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support_types: (res.is_resolve && res.data) && res.data.customer_support_type,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    getBySearch: async (param: string, lang: Lang) => {
        return await new Promise<CustomerSupportTypeResponse>(async (resolve: (value: CustomerSupportTypeResponse) => void, reject: (reason: CustomerSupportTypeResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support/type?type=getBySearch&param=${param}&lang=${lang}`, {
                "method": "GET",
                "headers": {
                    "Accept": "application/json",
                    "authorization": await getToken()
                },
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support_types: (res.is_resolve && res.data) && res.data.customer_support_type,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
}
