import React from "react";
//theme
import "./styles.scss";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const Section1 = (props: Props, ref: any) => {
    return (
        <div
            ref={ref}
            className={"Section1Content"}
        >
            <div
                className={"Section1 js-scroll fade-in-bottom"}
            >
                <div
                    className={"Section1Title"}
                >
                    {props.lang === "es" && <h1
                    >
                        ¿Por qué elegir la plataforma DvGeo?
                    </h1>}
                    {props.lang === "en" && <h1
                    >
                        Why choose DvGeo Platform?
                    </h1>}
                </div>
                <section
                    className={"Section1Sec"}
                >
                    <div
                        className={"Section1SecGroup"}
                    >
                        {props.lang === "es" && <h2>
                            Solución Completa
                        </h2>}
                        {props.lang === "en" && <h2>
                            Complete Solution
                        </h2>}
                        {props.lang === "es" && <p>
                            En tan <strong>solo 4 días</strong> ya tienes la <strong>plataforma completa con el logo de
                            tu
                            empresa</strong> para que puedan ofrecer todos los servicios de movilidad en <strong>tiempo
                            record!</strong>
                        </p>}
                        {props.lang === "en" && <p>
                            <strong>In just 4 days</strong> you already have the <strong>complete platform with your
                            company
                            logo</strong> so that they can offer all mobility services in <strong>record time!</strong>
                        </p>}
                    </div>
                    <div
                        className={"Section1SecGroup"}
                    >
                        {props.lang === "es" && <h2>
                            Integración Express
                        </h2>}
                        {props.lang === "en" && <h2>
                            Express integration
                        </h2>}
                        {props.lang === "es" && <p>
                            Nuestra plataforma es <strong>súper fácil de entender</strong> para que tus asesores puedan
                            capacitarse sin problema y <strong>rápida de integrarse con tu organización sin tanto
                            papeleo</strong>
                        </p>}
                        {props.lang === "en" && <p>
                            Our platform is <strong>super easy to understand</strong> so that your advisors can train
                            without problems and <strong>quickly integrate with your organization without so much
                            paperwork</strong>
                        </p>}
                    </div>
                    <div
                        className={"Section1SecGroup"}
                    >
                        {props.lang === "es" && <h2>
                            Ahorro Garantizado
                        </h2>}
                        {props.lang === "en" && <h2>
                            Guaranteed Savings
                        </h2>}
                        {props.lang === "es" && <p>
                            Todos los modulos, requerimientos, servicios de <strong>Google Maps Platform</strong> y
                            otros
                            costos de desarrollo están incluidos en <strong>un solo valor súper ecónomico!</strong>
                        </p>}
                        {props.lang === "en" && <p>
                            All modules, requirements, <strong>Google Maps Platform services</strong> and other
                            development
                            costs are included in a single, <strong>super affordable value!</strong>
                        </p>}
                    </div>
                </section>
            </div>
        </div>
    )
}
export default React.forwardRef(Section1);
