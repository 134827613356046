import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppDriverSection1 = (props: Props, ref: any) => {

    return (
        <div
            ref={ref}
            className={"IndexAppDriverSection1Content"}
        >
            <div
                className={"IndexAppDriverSection1 js-scroll slide-left"}
            >
                <div
                    className={"IndexAppDriverSection1Img"}
                >
                    <img
                        src={require("../../../../../assets/img/driver_1.png").default}
                        alt={"dvgeo_admin_1"}
                    />
                </div>
                <section
                    className={"IndexAppDriverSection1Sec"}
                >
                    {props.lang === "es" && <h1>
                        <strong>Solución</strong> potente y eficaz
                    </h1>}
                    {props.lang === "en" && <h1>
                        Powerful and effective <strong>solution</strong>
                    </h1>}
                    {props.lang === "es" && <p>
                        Ofrece a tus conductores más opciones de aceptar servicios, con todas las funciones necesarias
                        para <strong>mantenerlos conectados y productivos</strong>.
                    </p>}
                    {props.lang === "en" && <p>
                        Give your drivers more options to accept services, with all the necessary functions
                        to keep them <strong>connected and productive</strong>.
                    </p>}
                    {props.lang === "es" && <p>
                        <strong>¡Desplázate hacia abajo</strong> para aprender más!
                    </p>}
                    {props.lang === "en" && <p>
                        <strong>Scroll down</strong> to learn more!
                    </p>}
                </section>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppDriverSection1);
