import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexAppAdminTravelSection3 = (props: Props) => {
    //state
    const [indexGroup, setIndexGroup] = useState<Array<boolean>>([false, false, false, false, false, false, false]);

    const setIndexGroupFocus = (index: number) => {
        let indexGroupNew: Array<boolean> = [...indexGroup];
        for (let i = 0; i < indexGroupNew.length; i++) {
            if (i === index) {
                indexGroupNew[i] = !indexGroupNew[i];
            } else {
                indexGroupNew[i] = false;
            }
        }
        setIndexGroup(indexGroupNew);
    }

    return (
        <div
            className={"IndexAppAdminTravelSection3"}
        >
            <div
                className={"IndexAppAdminTravelSection3Title"}
            >
                {props.lang === "es" && <h1
                >
                    Preguntas Frecuentes
                </h1>}
                {props.lang === "en" && <h1
                >
                    FAQ
                </h1>}
            </div>
            <section
                className={"IndexAppAdminTravelSection3Sec"}
            >
                <button
                    className={"IndexAppAdminTravelSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(0)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo realizar reservaciones de viajes en el navegador web?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I make travel reservations in the web browser?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[0] ? "IndexAppAdminTravelSection3SecGroupFocus" : "IndexAppAdminTravelSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                       Claro que si, el aplicativo Admin tiene el módulo de despacho pensado para que sea responsivo y puedes autorizar a los administradores para que puedan tener solo acceso a este módulo. Puedes reservar simplemente estableciendo una fecha y hora de reserva.
                    </p>}
                    {props.lang === "en" && <p>
                        Of course, the Admin application has the dispatch module designed to be responsive and you can authorize administrators so that they can only have access to this module. You can book simply by setting a reservation date and time.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminTravelSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(1)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo seleccionar un conductor cercano que aparezca en el mapa?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I select a nearby driver to appear on the map?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[1] ? "IndexAppAdminTravelSection3SecGroupFocus" : "IndexAppAdminTravelSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                       Si, puedes seleccionar cualquier conductor cercano que aparezca en el mapa para poder despachar el viaje.
                    </p>}
                    {props.lang === "en" && <p>
                        Yes, you can select any nearby driver that appears on the map to be able to dispatch the trip.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminTravelSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(2)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo enviar la solicitud de viaje a otro conductor?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I send the ride request to another driver?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[2] ? "IndexAppAdminTravelSection3SecGroupFocus" : "IndexAppAdminTravelSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Claro, puedes notificar la solicitud de viaje a otro conductor, incluso cambiarlo cuando ya se encuentre en curso.
                    </p>}
                    {props.lang === "en" && <p>
                        Sure, you can notify the ride request to another driver, even change it when it is already in progress.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminTravelSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(3)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo modificar el punto de origen o destino?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I modify the point of origin or destination?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[3] ? "IndexAppAdminTravelSection3SecGroupFocus" : "IndexAppAdminTravelSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Puedes modificar toda la información del viaje luego de enviar el despacho
                    </p>}
                    {props.lang === "en" && <p>
                        You can modify all the travel information after sending the dispatch
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminTravelSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(4)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo ver los viajes que solicitan los pasajeros en la app móvil?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I see the trips requested by passengers in the mobile app?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[4] ? "IndexAppAdminTravelSection3SecGroupFocus" : "IndexAppAdminTravelSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Si, toda la información que procesan los usuarios en los aplicativos móviles quedarán registrados en el aplicativo admin debido a que es una plataforma en la nube.
                    </p>}
                    {props.lang === "en" && <p>
                        Yes, all the information that users process in the mobile applications will be registered in the admin application because it is a cloud platform.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminTravelSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(5)}
                >
                    {props.lang === "es" && <h2>
                        ¿El pasajero puede enviar mensajes dentro de la app móvil para comunicarse con el conductor?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can the passenger send messages within the mobile app to communicate with the driver?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[5] ? "IndexAppAdminTravelSection3SecGroupFocus" : "IndexAppAdminTravelSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        En el aplicativo móvil existen el botón de whatsapp y el de mensajes como opciones para que pueda comunicarse con el conductor, si elije la opción de mensajes podrá enviar texto dentro de la misma app.
                    </p>}
                    {props.lang === "en" && <p>
                        In the mobile application there are the WhatsApp button and the message button as options so that you can communicate with the driver, if you choose the message option you can send text within the same app.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminTravelSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(6)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo revisar la conversación entre el pasajero y conductor luego de que el viaje finalice?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I review the conversation between the passenger and the driver after the trip ends?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[6] ? "IndexAppAdminTravelSection3SecGroupFocus" : "IndexAppAdminTravelSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Se pueden revisar todos los mensajes enviados dentro del viaje con el objetivo de calidad y seguridad. Esto no afecta la privacidad de los usuarios.
                    </p>}
                    {props.lang === "en" && <p>
                        All messages sent within the trip can be reviewed with the objective of quality and safety. This does not affect the privacy of the users.
                    </p>}
                </div>
            </section>
        </div>
    )
}
export default IndexAppAdminTravelSection3;
