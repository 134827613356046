import React from "react";
//theme
import "./styles.scss";
//libs
import {useHistory} from "react-router-dom";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminSection2 = (props: Props, ref: any) => {
    //hooks
    const history = useHistory();

    return (
        <div
            ref={ref}
            className={"IndexAppAdminSection2Content"}
        >
            <div
                className={"IndexAppAdminSection2 js-scroll slide-right"}
            >
                <section
                    className={"IndexAppAdminSection2Sec"}
                >
                    {props.lang === "es" && <h1>
                        Ofrece todos los <strong>Servicios</strong> que necesites
                    </h1>}
                    {props.lang === "en" && <h1>
                        Offer all the <strong>services</strong> you need
                    </h1>}
                    {props.lang === "es" && <p>
                        Un servicio le permite a tu pasajero o cliente la posibilidad de <strong>requerir un viaje o
                        carrera</strong>, sea de ciudad a ciudad o de forma local
                    </p>}
                    {props.lang === "en" && <p>
                        A service allows your passenger or client the possibility of <strong>requesting a trip or
                        race</strong>, either from city to city or locally
                    </p>}
                    <button
                        onClick={() => {
                            history.push(`/${props.lang}/app/admin/module/service`);
                        }}
                    >
                        {props.lang === "es" && <label>
                            Más Info
                        </label>}
                        {props.lang === "en" && <label>
                            More Info
                        </label>}
                    </button>
                </section>
                <div
                    className={"IndexAppAdminSection2Img"}
                >
                    <img
                        src={require("../../../../../assets/img/admin_service_1.png").default}
                        alt={"dvgeo_admin_2"}
                    />
                </div>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppAdminSection2);
