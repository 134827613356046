import React from "react";
//theme
import "./styles.scss";
//models
import {CustomerApp} from "../../../../../api/customer_app/customerAppModel";
//libs
import config from "../../../../../config";
import {Link} from "react-router-dom";
import ReactLoading from 'react-loading';
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
    customer_apps: Array<CustomerApp>;
    isLoading: boolean;
}

//const
const TAG = `CustomerAppsSection1`;
const isDev = (process.env.NODE_ENV !== "production");
//components
const CustomerAppsSection1 = (props: Props) => {
    return (
        <div
            className={"CustomerAppsSection1"}
        >
            <div
                className={"CustomerAppsSection1Items"}
            >
                {props.isLoading && <ReactLoading
                    type={"bubbles"}
                    color={config.colorPrimary}
                    height={50}
                    width={120}
                    delay={2000}
                />}
                {!props.isLoading && props.customer_apps.map((value, index) => {
                    return (
                        <Link
                            key={`${TAG}_customer_apps_${index}`}
                            className={"CustomerAppsSection1Item"}
                            to={`/${props.lang}/application/customer/apps/log/${value.id}`}
                        >
                            <div
                                className={"CustomerAppsSection1ItemImg"}
                            >
                                <img
                                    src={value.url_icon ? value.url_icon : `${config.host}/uploads/not_file.png`}
                                    alt={""}
                                />
                            </div>
                            <legend
                                className={"CustomerAppsSection1ItemTitle"}
                            >
                                {value.name}
                            </legend>
                            <label
                                className={"CustomerAppsSection1ItemPackage"}
                            >
                                {value.package}
                            </label>
                            <p
                                className={"CustomerAppsSection1ItemDescription"}
                            >
                                {value.type} / {value.os}
                            </p>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}
export default CustomerAppsSection1;
