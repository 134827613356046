import React from "react";
//theme
import "./view.scss";
//models
import {Customer} from "../../../../../../api/customer/customerModel";
//components
import NavBarTop from "../../../../../_components/NavBarTop";
import CustomerNavBarMenu from "../../../../_components/CustomerNavBarMenu";
import CustomerCompanyUpdateAddressViewBanner1 from "./banner_1";
import CustomerCompanyUpdateAddressViewForm1 from "./form_1";
import SnackBar from "../../../../../_components/SnackBar";
import Footer from "../../../../../_components/Footer";
//types
import {Lang} from "../../../../../../types";
import {SystemSnackbar} from "../../../../../../store/actions/system.ac";

//interfaces
interface Props {
    lang: Lang;
    customer: Customer;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
    deleteCustomer?: () => void;
    deleteToken?: () => void;
    putCustomer?: (customer: Customer) => void;
}

//view
const CustomerCompanyUpdateAddressView = (props: Props) => {
    return (
        <div
            className={"CustomerCompanyUpdateAddressView"}
        >
            <NavBarTop
                lang={props.lang}
                customer={props.customer}
                deleteCustomer={props.deleteCustomer}
                deleteToken={props.deleteToken}
            />
            <CustomerNavBarMenu
                lang={props.lang}
            />
            <CustomerCompanyUpdateAddressViewBanner1
                lang={props.lang}
            />
            <CustomerCompanyUpdateAddressViewForm1
                lang={props.lang}
                customer={props.customer}
                showSnackbar={props.showSnackbar}
                hideSnackbar={props.hideSnackbar}
                putCustomer={props.putCustomer}
            />
            <Footer
                lang={props.lang}
                customer={props.customer}
            />
            <SnackBar/>
        </div>
    )
}
export default CustomerCompanyUpdateAddressView;
