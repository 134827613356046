//models
import {Customer} from "../../../../api/customer/customerModel";
//libs
import AsyncStorage from "@react-native-community/async-storage";
import config from "../../../../config";
//types
import {Lang} from "../../../../types";
//const
const TAG = `CustomerAccountAPI`;
const isDev = (process.env.NODE_ENV !== "production");
//temp
let intentsGetSession = 0;
let ouIntentsGetSession: NodeJS.Timeout | number = 0;
//API
const CustomerAccountAPI = {
    getSession: async (
        history: any, lang: Lang, token: string, customer: Customer,
    ) => {
        isDev && console.log(TAG + "//getSession");
        if (!token || typeof customer === "undefined" || !customer) {
            clearTimeout(ouIntentsGetSession as NodeJS.Timeout);
            ouIntentsGetSession = setTimeout(() => {
                intentsGetSession++;
                CustomerAccountAPI.getSession(history, lang, token, customer);
            }, 1000);
            if (intentsGetSession >= 3) {
                intentsGetSession = 0;
                clearTimeout(ouIntentsGetSession as NodeJS.Timeout);
                history.push(`/es/application/customer/login`);
            }
            return;
        }
    },
    closeSession: async (
        history: any, lang: Lang, deleteCustomer?: () => void, deleteToken?: () => void,
    ) => {
        isDev && console.log(TAG + "//closeSession");
        await AsyncStorage.removeItem(`token::${config.package}`);
        await AsyncStorage.removeItem(`user::${config.package}`);
        deleteCustomer && deleteCustomer();
        deleteToken && deleteToken();
        history.push(`/${lang}/application/customer/login`);
    }
}
export default CustomerAccountAPI;
