import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexAboutBanner1 = (props: Props) => {

    return (
        <div
            className={"IndexAboutBanner1"}
        >
            <div
                className={"IndexAboutBanner1Title"}
            >
                {props.lang === "es" && <h1
                >
                    DvGeo - Solucionamos con calidad los servicios de movilidad
                </h1>}
                {props.lang === "en" && <h1
                >
                    DvGeo - We provide quality solutions for mobility services
                </h1>}
                {props.lang === "es" && <p>
                    Una marca de la empresa <strong>Devjos CIA LTDA</strong>
                </p>}
                {props.lang === "en" && <p>
                    A brand of the company <strong>Devjos CIA LTDA</strong>
                </p>}
            </div>
        </div>
    )
}
export default IndexAboutBanner1;
