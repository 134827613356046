import React from "react";
//theme
import "./styles.scss";
//models
import {CustomerDoc} from "../../../../../api/customer_doc/customerDocModel";
//libs
import {Link} from "react-router-dom";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
    customer_doc: CustomerDoc;
}

//component
const CustomerCompanyViewFile1 = (props: Props) => {
    return (
        <div
            className={"CustomerCompanyViewFile1"}
        >
            <section
                className={"CustomerCompanyViewFile1Sec"}
            >
                <div
                    className={"CustomerCompanyViewFile1SecImgRec"}
                >
                    <img
                        src={props.customer_doc.url_ruc}
                        alt={""}
                    />
                </div>
                <Link
                    className={"CustomerCompanyViewFile1SecLink"}
                    to={`/${props.lang}/application/customer/company/upload/ruc`}
                >
                    Identificación de empresa
                </Link>
            </section>
            <section
                className={"CustomerCompanyViewFile1Sec"}
            >
                <div
                    className={"CustomerCompanyViewFile1SecImgRec"}
                >
                    <img
                        src={props.customer_doc.url_identification}
                        alt={""}
                    />
                </div>
                <Link
                    className={"CustomerCompanyViewFile1SecLink"}
                    to={`/${props.lang}/application/customer/company/upload/identification`}
                >
                    Identificación personal
                </Link>
            </section>
            <section
                className={"CustomerCompanyViewFile1Sec"}
            >
                <div
                    className={"CustomerCompanyViewFile1SecImg"}
                >
                    <img
                        src={props.customer_doc.url_logo + "?w=120&h=120"}
                        alt={""}
                    />
                </div>
                <Link
                    className={"CustomerCompanyViewFile1SecLink"}
                    to={`/${props.lang}/application/customer/company/upload/logo`}
                >
                    Logo de empresa
                </Link>
            </section>
        </div>
    )
}
export default CustomerCompanyViewFile1;
