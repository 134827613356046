import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexAppAdminVehicleSection3 = (props: Props) => {
    //state
    const [indexGroup, setIndexGroup] = useState<Array<boolean>>([false, false, false, false, false, false, false]);

    const setIndexGroupFocus = (index: number) => {
        let indexGroupNew: Array<boolean> = [...indexGroup];
        for (let i = 0; i < indexGroupNew.length; i++) {
            if (i === index) {
                indexGroupNew[i] = !indexGroupNew[i];
            } else {
                indexGroupNew[i] = false;
            }
        }
        setIndexGroup(indexGroupNew);
    }

    return (
        <div
            className={"IndexAppAdminVehicleSection3"}
        >
            <div
                className={"IndexAppAdminVehicleSection3Title"}
            >
                {props.lang === "es" && <h1
                >
                    Preguntas Frecuentes
                </h1>}
                {props.lang === "en" && <h1
                >
                    FAQ
                </h1>}
            </div>
            <section
                className={"IndexAppAdminVehicleSection3Sec"}
            >
                <button
                    className={"IndexAppAdminVehicleSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(0)}
                >
                    {props.lang === "es" && <h2>
                        ¿En dónde se registran los vehículos?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Where are the vehicles registered?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[0] ? "IndexAppAdminVehicleSection3SecGroupFocus" : "IndexAppAdminVehicleSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                       Principalmente a través del aplicativo móvil del conductor pero también puede registrarse manualmente desde el aplicativo Admin
                    </p>}
                    {props.lang === "en" && <p>
                        Mainly through the driver's mobile application but you can also register manually from the Admin application
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminVehicleSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(1)}
                >
                    {props.lang === "es" && <h2>
                        ¿Hasta cuántos servicios se pueden asignar al vehículo?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Up to how many services can be assigned to the vehicle?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[1] ? "IndexAppAdminVehicleSection3SecGroupFocus" : "IndexAppAdminVehicleSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                       Se pueden asignar todos los servicios al vehículo, pero debes tener al menos un servicio asignado para que el conductor pueda recibir solicitudes de viaje.
                    </p>}
                    {props.lang === "en" && <p>
                        All services can be assigned to the vehicle, but you must have at least one assigned service for the driver to receive ride requests.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminVehicleSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(2)}
                >
                    {props.lang === "es" && <h2>
                        ¿Hasta cuántos vehículos pueden asignarse al conductor?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Up to how many vehicles can be assigned to the driver?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[2] ? "IndexAppAdminVehicleSection3SecGroupFocus" : "IndexAppAdminVehicleSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Puedes asignar varios vehículos sin límites pero un usuario conductor solo puede trabajar con un vehículo a la vez.
                    </p>}
                    {props.lang === "en" && <p>
                        You can assign multiple vehicles without limits but a driver user can only work with one vehicle at a time.
                    </p>}
                </div>
            </section>
        </div>
    )
}
export default IndexAppAdminVehicleSection3;
