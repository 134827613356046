import React, {useEffect, useRef, useState} from "react";
//theme
import "./NavBarTop.scss";
//models
import {Customer} from "../../api/customer/customerModel";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {useHistory} from "react-router-dom";
import {faCaretDown, faGlobeAmericas, faUser} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "@material-ui/core";
//types
import {Lang} from "../../types";
import AsyncStorage from "@react-native-community/async-storage";
import config from "../../config";
import CustomerAccountAPI from "../customer/account/api";

//interface
interface Props {
    lang: Lang;
    customer?: Customer;
    deleteCustomer?: () => void;
    deleteToken?: () => void;
}

//component
const NavBarTop = (props: Props) => {
    //hooks
    const history = useHistory();
    //refs
    const NavBarTopRef = useRef<any>(null);
    const btnLangRef = useRef<any>(null);
    const btnLoginProfileRef = useRef<any>(null);
    const btnSocialFacebookRef = useRef<any>(null);
    const btnSocialInstagramRef = useRef<any>(null);
    const btnSocialTwitterRef = useRef<any>(null);
    //state
    const [isShowMenuLang, setIsShowMenuLang] = useState<boolean>(false);
    const [isShowMenuProfile, setIsShowMenuProfile] = useState<boolean>(false);
    //effects
    useEffect(() => {
        setTimeout(() => {
            NavBarTopRef.current && (NavBarTopRef.current.style.marginTop = 0)
        }, 1000 / 6);
        window.addEventListener('click', onClickOut);
        return () => {
            window.removeEventListener('click', onClickOut);
        }
    }, []);
    useEffect(() => {
        if (!props.customer || !props.customer.id) {
            //signOut();
        }
    }, [props.customer])

    const onClickOut = (e: MouseEvent) => {
        // @ts-ignore
        if (document.getElementById('NavBarTop')?.contains(e?.target)) {
            // Clicked in box
        } else {
            setIsShowMenuLang(false);
            setIsShowMenuProfile(false);
        }
    }

    const getLanguageByLang = (): string => {
        switch (props.lang) {
            case "es": {
                return "Español"
            }
            case "en": {
                return "English"
            }
            default : {
                return "Español"
            }
        }
    }

    const signOut = async () => {
        await CustomerAccountAPI.closeSession(history, props.lang, props.deleteCustomer, props.deleteToken);
    }

    return (
        <div
            id={"NavBarTop"}
            ref={NavBarTopRef}
            className={"NavBarTop"}
        >
            {/*section social*/}
            <section
                className={"NavBarTopSecSocial"}
            >
                <Tooltip
                    arrow
                    title="Facebook"
                >
                    <button
                        ref={btnSocialFacebookRef}
                        className={"NavBarTopSocialBtn"}
                        onClick={() => window.open("https://facebook.com/devjos.ec")}
                    >
                        <FontAwesomeIcon
                            icon={faFacebook}
                        />
                    </button>
                </Tooltip>
                <Tooltip
                    arrow
                    title="Instagram"
                >
                    <button
                        ref={btnSocialInstagramRef}
                        className={"NavBarTopSocialBtn"}
                        onClick={() => window.open("https://instagram.com/devjos.ec")}
                    >
                        <FontAwesomeIcon
                            icon={faInstagram}
                        />
                    </button>
                </Tooltip>
                <Tooltip
                    arrow
                    title="Twitter"
                >
                    <button
                        ref={btnSocialTwitterRef}
                        className={"NavBarTopSocialBtn"}
                        onClick={() => window.open("https://twitter.com/devjos_ec")}
                    >
                        <FontAwesomeIcon
                            icon={faTwitter}
                        />
                    </button>
                </Tooltip>
                <Tooltip
                    arrow
                    title="Youtube"
                >
                    <button
                        className={"NavBarTopSocialBtn"}
                        onClick={() => window.open("https://www.youtube.com/channel/UCYAXkdSyKOOJwZ9DMkgJDtQ")}
                    >
                        <FontAwesomeIcon
                            icon={faYoutube}
                        />
                    </button>
                </Tooltip>
            </section>
            {/*section de sesión*/}
            <section
                className={"NavBarTopSecSession"}
            >
                <button
                    ref={btnLoginProfileRef}
                    className={"NavBarTopSessionBtn"}
                    onClick={() => {
                        if (!props.customer || !props.customer.id) {
                            history.push(`/${props.lang}/application/customer/login`);
                        } else {
                            setIsShowMenuProfile(!isShowMenuProfile);
                            setIsShowMenuLang(false);
                        }
                    }}
                >
                    {(!props.customer || !props.customer.id) && <i>
                        < FontAwesomeIcon
                            icon={faUser}
                        />
                    </i>}
                    {(!props.customer || !props.customer.id) && props.lang === "es" && <label>
                        Iniciar Sesión
                    </label>}
                    {(!props.customer || !props.customer.id) && props.lang === "en" && <label>
                        Log In
                    </label>}

                    {(props.customer && props.customer.id) && <span>
                        <img
                            src={props.customer.url_image + "?w=32&h=32"}
                            alt={"customer_img"}
                        />
                    </span>}
                    {(props.customer && props.customer.id) && props.lang === "es" && <label>
                        Hola, {props.customer.name?.toLowerCase()}
                        <FontAwesomeIcon
                            icon={faCaretDown}
                            style={{
                                paddingLeft: 5
                            }}
                        />
                    </label>}
                    {(props.customer && props.customer.id) && props.lang === "en" && <label>
                        Hello, {props.customer.name?.toLowerCase()}
                        <FontAwesomeIcon
                            icon={faCaretDown}
                            style={{
                                paddingLeft: 5
                            }}
                        />
                    </label>}
                </button>
                {/*menu profile*/}
                {isShowMenuProfile && <div
                    className={"NavBarTopSessionBtnMenu"}
                    style={{
                        top: NavBarTopRef.current.clientHeight,
                        left: btnLoginProfileRef.current.getBoundingClientRect().left,
                        width: btnLoginProfileRef.current.clientWidth
                    }}
                >
                    <button
                        className={"NavBarTopSessionBtnMenuItem"}
                        onClick={() => {
                            setIsShowMenuProfile(false);
                            history.push(`/${props.lang}/application/customer`);
                        }}
                    >
                        <label>
                            Panel
                        </label>
                    </button>
                    <button
                        className={"NavBarTopSessionBtnMenuItem"}
                        onClick={() => {
                            setIsShowMenuProfile(false);
                            history.push(`/${props.lang}/application/customer`);
                            signOut();
                        }}
                    >
                        <label>
                            Cerrar Sesión
                        </label>
                    </button>
                </div>}
                <button
                    ref={btnLangRef}
                    className={"NavBarTopSessionBtn"}
                    onClick={() => {
                        setIsShowMenuLang(!isShowMenuLang);
                        setIsShowMenuProfile(false);
                    }}
                >
                    <i>
                        <FontAwesomeIcon
                            icon={faGlobeAmericas}
                        />
                    </i>
                    <label>
                        {getLanguageByLang()}
                        <FontAwesomeIcon
                            icon={faCaretDown}
                            style={{
                                paddingLeft: 5
                            }}
                        />
                    </label>
                </button>
                {/*menu lang*/}
                {isShowMenuLang && <div
                    className={"NavBarTopSessionBtnMenu"}
                    style={{
                        top: NavBarTopRef.current.clientHeight,
                        left: btnLangRef.current.getBoundingClientRect().left,
                        width: btnLangRef.current.clientWidth
                    }}
                >
                    <button
                        className={"NavBarTopSessionBtnMenuItem"}
                        onClick={() => {
                            setIsShowMenuLang(false);
                            let path = window.location.pathname;
                            history.push(path.replace("en", "es"));
                        }}
                    >
                        <label>
                            Español
                        </label>
                    </button>
                    <button
                        className={"NavBarTopSessionBtnMenuItem"}
                        onClick={() => {
                            setIsShowMenuLang(false);
                            let path = window.location.pathname;
                            history.push(path.replace("es", "en"));
                        }}
                    >
                        <label>
                            English
                        </label>
                    </button>
                </div>}
            </section>
        </div>
    )
}
export default NavBarTop;
