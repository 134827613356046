import {
    CustomerStoreState, CustomerStoreAction
} from '../actions/customer.ac';
//models
import {Customer} from "../../api/customer/customerModel";
//state
const initialState: CustomerStoreState = {
    items: [],
    item: null,
    is_loading: false,
}
//reducer
const customerReducer = (state: CustomerStoreState = initialState, action: CustomerStoreAction) => {
    switch (action.type) {
        case "POST_CUSTOMER": {
            return {
                ...state,
                item: action.item,
            }
        }
        case "PUT_CUSTOMER": {
            return {
                ...state,
                item: {
                    ...state.item,
                    ...action.item,
                },
            }
        }
        case "DELETE_CUSTOMER": {
            return {
                ...state,
                item: {},
            }
        }
        case "POST_CUSTOMERS": {
            return {
                ...state,
                items: action.items,
            }
        }
        case "DELETE_CUSTOMERS": {
            return {
                ...state,
                items: [],
            }
        }
        case "ADD_CUSTOMERS": {
            return {
                ...state,
                items: state.items.concat(action.item),
            }
        }
        case "REMOVE_CUSTOMERS": {
            return {
                ...state,
                items: state.items.filter((item: Customer) => item !== action.item),
            }
        }
        default:
            return state
    }
};
export default customerReducer;
