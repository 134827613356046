import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppDriverSection6 = (props: Props, ref: any) => {

    return (
        <div
            ref={ref}
            className={"IndexAppDriverSection6Content"}
        >
            <div
                className={"IndexAppDriverSection6 js-scroll slide-right"}
            >
                <section
                    className={"IndexAppDriverSection6Sec"}
                >
                    {props.lang === "es" && <h1>
                        Registra el <strong>cobro del viaje</strong>
                    </h1>}
                    {props.lang === "en" && <h1>
                        Offer <strong>discount coupons</strong> for your passengers
                    </h1>}
                    {props.lang === "es" && <p>
                        Cobra en efectivo, tarjeta de crédito / débito, billetera digital <i>(las formas de pago aplican restricciones)</i>
                    </p>}
                    {props.lang === "en" && <p>
                        Boost your marketing campaign with <strong>promotional codes</strong> so that your new users get even more motivated
                    </p>}
                </section>
                <div
                    className={"IndexAppDriverSection6Img"}
                >
                    <img
                        src={require("../../../../../assets/img/driver_7.png").default}
                        alt={"dvgeo_driver_6"}
                    />
                </div>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppDriverSection6);
