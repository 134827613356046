import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexPriceBanner1 = (props: Props) => {

    return (
        <div
            className={"IndexPriceBanner1"}
        >
            <div
                className={"IndexPriceBanner1Title"}
            >
                {props.lang === "es" && <h1
                >
                    Precios accesibles
                </h1>}
                {props.lang === "en" && <h1
                >
                    Affordable prices
                </h1>}
                {props.lang === "es" && <p>
                    Sin contratos, sin tarifas ocultas, sin inversiones costosas ... ¡Lo que necesitas!
                </p>}
                {props.lang === "en" && <p>
                    <strong>Find answers to your questions</strong> about the <strong>DvGeo</strong> platform
                </p>}
            </div>
        </div>
    )
}
export default IndexPriceBanner1;
