import React from "react";
//theme
import "./styles.scss";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexContactSection2 = (props: Props) => {
    return (
        <div
            className={"IndexContactSection2"}
        >
            {props.lang === "es" && <h1
                className={"IndexContactSection2Title"}
            >
                Nuestra oficina
            </h1>}
            {props.lang === "en" && <h1
                className={"IndexContactSection2Title"}
            >
                Our office
            </h1>}
            <div
                className={"IndexContactSection2Body"}
            >
                <section
                    className={"IndexContactSection2BodySec"}
                >
                    {props.lang === "es" && <label>
                        Guano y 2º Pa. 19c, Guayaquil, Ecuador.
                    </label>}
                    {props.lang === "en" && <label>
                        Guano and 2º Pa. 19c, Guayaquil, Ecuador.
                    </label>}
                    <a
                        href={"https://www.google.com.ec/maps/place/Devjos+Corporation/@-2.1280228,-79.9220677,19.91z/data=!4m12!1m6!3m5!1s0x902d72a0e1bfffff:0x5d2f3a41d88faee0!2sDevjos+Corporation!8m2!3d-2.127902!4d-79.9219811!3m4!1s0x902d72a0e1bfffff:0x5d2f3a41d88faee0!8m2!3d-2.127902!4d-79.9219811?hl=es"}
                    >
                        Ver ubicación
                    </a>
                </section>
            </div>
        </div>
    )
}
export default IndexContactSection2;
