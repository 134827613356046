import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppDriverSection5 = (props: Props, ref: any) => {
    //hooks

    return (
        <div
            ref={ref}
            className={"IndexAppDriverSection5Content"}
        >
            <div
                className={"IndexAppDriverSection5 js-scroll slide-left"}
            >
                <div
                    className={"IndexAppDriverSection5Img"}
                >
                    <img
                        src={require("../../../../../assets/img/driver_8.png").default}
                        alt={"dvgeo_driver_8"}
                    />
                </div>
                <section
                    className={"IndexAppDriverSection5Sec"}
                >
                    {props.lang === "es" && <h1>
                        <strong>Comunicación fácil y rápida</strong> para conductores y operadores
                    </h1>}
                    {props.lang === "en" && <h1>
                        <strong>Quick and easy communication</strong> for drivers and operators
                    </h1>}
                    {props.lang === "es" && <p>
                        <strong>Envía mensajes</strong>, así como / mensajes predefinidos en la sección grupos de chat
                    </p>}
                    {props.lang === "en" && <p>
                        <strong>Send messages</strong> as well as / predefined messages in the chat group section
                    </p>}
                </section>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppDriverSection5);
