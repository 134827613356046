import React, {useRef} from 'react';
//theme
import {View, Text} from 'react-native';
import config from '../../../../../../config';
import {ErrorException} from "../../../../../../types";
//types
type Props = {
    name: string;
    created_at: string;
    url_image: string;
    message: string;
    url_media: string;
}
//component
const CustomerSupportTrackingEmisor = (props: Props) => {
    //refs
    const imageRef = useRef<HTMLImageElement>(null);

    return (
        <View
            style={{
                position: 'relative',
                marginHorizontal: 15,
                marginVertical: 10,
                borderRadius: 10,
                borderColor: '#e0e0e0',
                borderWidth: 1,
                alignSelf: 'flex-end',
                backgroundColor: '#fff',
                marginBottom: 30,
                zIndex: 100,
            }}
        >
            <View
                style={{
                    flex: 1,
                    position: 'absolute',
                    backgroundColor: config.colorPrimary,
                    opacity: .2,
                    height: '100%',
                    borderRadius: 10,
                    right: 0,
                }}
            />

            {props.url_media && <img
                ref={imageRef}
                style={{
                    width: "auto",
                    height: "10rem",
                    overflow: 'hidden',
                    backgroundColor: "#e0e0e0",
                    cursor: "pointer"
                }}
                src={props.url_media}
                onClick={(e) => {
                    if (!imageRef.current) {
                        return;
                    }
                    try {
                        let canvas = document.createElement("canvas");
                        canvas.width = imageRef.current.clientWidth;
                        canvas.height = imageRef.current.clientHeight;

                        let ctx = canvas.getContext("2d");
                        ctx && ctx.drawImage(imageRef.current, 0, 0);

                        let dataURL = canvas.toDataURL("image/png");
                        dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
                        window.open(dataURL);
                    } catch (error: ErrorException | any) {
                        imageRef.current.src && window.open(imageRef.current.src);
                    }
                }}
            />}

            <Text
                style={{
                    textAlign: 'right', marginVertical: 10, marginHorizontal: 10,
                }}
            >
                {props.message}
            </Text>

            <Text
                style={{
                    textAlign: 'right',
                    fontSize: 10,
                    position: 'absolute',
                    bottom: -20,
                    right: 5,
                    fontWeight: '200',
                    color: '#666',
                }}
            >
                {props.created_at.split(' ')[1]}
            </Text>
        </View>
    );
}
export default CustomerSupportTrackingEmisor;
