import React, {useEffect, useRef, useState} from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminServiceSection2 = (props: Props) => {
    //refs
    const iTimer = useRef<NodeJS.Timeout | number>(0);
    const indexAppAdminServiceSection1DesktopSnapContentRef = useRef<any>(null);
    const items = useRef<Array<any>>([]);
    //state
    const [index, setIndex] = useState<number>(0);
    //effects
    useEffect(() => {
        init();
        return () => {
            clearIntervals();
        }
    }, []);

    const init = () => {
        try {
            iIndexTranslateX();
        } catch (error: any) {

        }
    }

    const iIndexTranslateX = () => {
        setIndex(0);
        setTranslateXValid(0);
        clearInterval(iTimer.current as NodeJS.Timeout);
        let indexCurrent = 0;
        iTimer.current = setInterval(() => {
            if (indexCurrent >= 0 && indexCurrent <= 2) {
                indexCurrent++;
                setTranslateXValid(indexCurrent);
                setIndex(indexCurrent);
            } else {
                iIndexTranslateX();
                indexCurrent = 0;
                setIndex(0);
            }
        }, 6000);
    }

    const clearIntervals = () => {
        clearInterval(iTimer.current as NodeJS.Timeout);
        iTimer.current = 0;
    }

    const setTranslateX = (index: number, value: string) => {
        try {
            items.current[index].style.transform = value;
        } catch (error: any) {
            setTimeout(() => setTranslateX(index, value), 1000 / 5);
        }
    }

    const setTranslateXValid = (index: number) => {
        if (index === 0) {
            setTimeout(() => setTranslateX(0, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-0vw)`), 1000 / 5);
        }
        if (index === 1) {
            setTimeout(() => setTranslateX(0, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-40vw)`), 1000 / 5);
        }
        if (index === 2) {
            setTimeout(() => setTranslateX(0, `translateX(-80.1vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-80.1vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-80.1vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-80.1vw)`), 1000 / 5);
        }
        if (index === 3) {
            setTimeout(() => setTranslateX(0, `translateX(-120.15vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-120.15vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-120.15vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-120.15vw)`), 1000 / 5);
        }
    }

    return (
        <div
            className={"IndexAppAdminServiceSection2"}
        >
            {props.lang === "es" && <h1
                className={"IndexAppAdminServiceSection2Title"}
            >
                ¿Cómo funciona?
            </h1>}
            {props.lang === "en" && <h1
                className={"IndexAppAdminServiceSection2Title"}
            >
                How does it work?
            </h1>}
            {/*apps en modo mobile*/}
            <div
                className={"IndexAppAdminServiceSection2Mobile"}
            >
                <section
                    className={"IndexAppAdminServiceSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminServiceSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminServiceSection2DesktopSnapControlBtnSpan"}
                        >
                           1
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Define que servicios quieres ofrecer
                            </h6>}
                            {props.lang === "en" && <h6>
                                Define what services you want to offer
                            </h6>}
                            {props.lang === "es" && <label>
                                Puedes configurar todos los servicios que ofrecerás sin límite para que aparezcan en el
                                menú principal de la app pasajero.
                            </label>}
                            {props.lang === "en" && <label>
                                You can configure all the services that you will offer without limit so that they appear
                                in the
                                main menu of the passenger app.
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminServiceSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_service_1.png").default}
                            alt={"dvgeo_admin_service_1"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppAdminServiceSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminServiceSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminServiceSection2DesktopSnapControlBtnSpan"}
                        >
                           2
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Tarifa Negociable / Tarifa Dinámica
                            </h6>}
                            {props.lang === "en" && <h6>
                                Negotiable Rate / Dynamic Rate
                            </h6>}
                            {props.lang === "es" && <label>
                                Cada servicio tiene su propio taxímetro definido por horarios: 24/7 o
                                mañana, tarde, etc. También puedes configurar que los pasajeros oferten su propio precio
                                del viaje.
                            </label>}
                            {props.lang === "en" && <label>
                                Each service has its own taximeter defined by hours: 24/7 or
                                morning, afternoon, etc. You can also configure that passengers offer their own price
                                From the trip.
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminServiceSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_service_2.png").default}
                            alt={"dvgeo_admin_service_2"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppAdminServiceSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminServiceSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminServiceSection2DesktopSnapControlBtnSpan"}
                        >
                           3
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Crea campos adicionales para los servicios
                            </h6>}
                            {props.lang === "es" && <h6>
                                Create additional fields for services
                            </h6>}
                            {props.lang === "es" && <label>
                                Permite que tus pasajeros puedan llenar
                                información adicional según el tipo de servicio. Si el servicio fuese de
                                paquetería, un campo adicional sería: ¿A quién debo entregar?
                            </label>}
                            {props.lang === "es" && <label>
                                Allow your passengers to fill
                                additional information depending on the type of service. If the service were
                                parcel delivery, an additional field would be: Who should I deliver to?
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminServiceSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_service_3.png").default}
                            alt={"dvgeo_admin_service_3"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppAdminServiceSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminServiceSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminServiceSection2DesktopSnapControlBtnSpan"}
                        >
                           4
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Personaliza el servicio
                            </h6>}
                            {props.lang === "es" && <h6>
                                Customize the service
                            </h6>}
                            {props.lang === "es" && <label>
                                Una buena presentación de tus servicios en la app siempre es bien recibida por los
                                pasajeros, esto les permite identificar aún más el servicio que van a requerir.
                            </label>}
                            {props.lang === "es" && <label>
                                A good presentation of your services in the app is always well received by the
                                passengers, this allows them to further identify the service they will require.
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminServiceSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_service_4.png").default}
                            alt={"dvgeo_admin_service_4"}
                        />
                    </div>
                </section>
            </div>
            {/*apps en modo desktop*/}
            <div
                className={"IndexAppAdminServiceSection2Desktop"}
            >
                <div
                    ref={indexAppAdminServiceSection1DesktopSnapContentRef}
                    className={"IndexAppAdminServiceSection2DesktopSnapControl"}
                >
                    <button
                        className={index === 0 ? "IndexAppAdminServiceSection2DesktopSnapControlBtnFocus" : "IndexAppAdminServiceSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(0);
                            setIndex(0);
                            clearIntervals();
                        }}
                    >
                        <span
                            className={index === 0 ? "IndexAppAdminServiceSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminServiceSection2DesktopSnapControlBtnSpan"}
                        >
                           1
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Define que servicios quieres ofrecer
                            </h6>}
                            {props.lang === "en" && <h6>
                                Define what services you want to offer
                            </h6>}
                            {props.lang === "es" && <label>
                                Puedes configurar todos los servicios que ofrecerás sin límite para que aparezcan en el
                                menú principal de la app pasajero.
                            </label>}
                            {props.lang === "en" && <label>
                                You can configure all the services that you will offer without limit so that they appear
                                in the
                                main menu of the passenger app.
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 1 ? "IndexAppAdminServiceSection2DesktopSnapControlBtnFocus" : "IndexAppAdminServiceSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(1);
                            setIndex(1);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 1 ? "IndexAppAdminServiceSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminServiceSection2DesktopSnapControlBtnSpan"}
                       >
                           2
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Tarifa Negociable / Tarifa Dinámica
                            </h6>}
                            {props.lang === "en" && <h6>
                                Negotiable Rate / Dynamic Rate
                            </h6>}
                            {props.lang === "es" && <label>
                                Cada servicio tiene su propio taxímetro definido por horarios: 24/7 o
                                mañana, tarde, etc. También puedes configurar que los pasajeros oferten su propio precio
                                del viaje.
                            </label>}
                            {props.lang === "en" && <label>
                                Each service has its own taximeter defined by hours: 24/7 or
                                morning, afternoon, etc. You can also configure that passengers offer their own price
                                From the trip.
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 2 ? "IndexAppAdminServiceSection2DesktopSnapControlBtnFocus" : "IndexAppAdminServiceSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(2);
                            setIndex(2);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 2 ? "IndexAppAdminServiceSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminServiceSection2DesktopSnapControlBtnSpan"}
                       >
                           3
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Crea campos adicionales para los servicios
                            </h6>}
                            {props.lang === "en" && <h6>
                                Create additional fields for services
                            </h6>}
                            {props.lang === "es" && <label>
                                Permite que tus pasajeros puedan llenar
                                información adicional según el tipo de servicio. Si el servicio fuese de
                                paquetería, un campo adicional sería: ¿A quién debo entregar?
                            </label>}
                            {props.lang === "en" && <label>
                                Allow your passengers to fill
                                additional information depending on the type of service. If the service were
                                parcel delivery, an additional field would be: Who should I deliver to?
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 3 ? "IndexAppAdminServiceSection2DesktopSnapControlBtnFocus" : "IndexAppAdminServiceSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(3);
                            setIndex(3);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 3 ? "IndexAppAdminServiceSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminServiceSection2DesktopSnapControlBtnSpan"}
                       >
                           4
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Personaliza el servicio
                            </h6>}
                            {props.lang === "en" && <h6>
                                Customize the service
                            </h6>}
                            {props.lang === "es" && <label>
                                Una buena presentación de tus servicios en la app siempre es bien recibida por los
                                pasajeros, esto les permite identificar aún más el servicio que van a requerir.
                            </label>}
                            {props.lang === "en" && <label>
                                A good presentation of your services in the app is always well received by the
                                passengers, this allows them to further identify the service they will require.
                            </label>}
                        </section>
                    </button>
                </div>

                <div
                    ref={indexAppAdminServiceSection1DesktopSnapContentRef}
                    className={"IndexAppAdminServiceSection2DesktopSnapContent"}
                >
                    <div
                        className={"IndexAppAdminServiceSection2DesktopSnapContentBackground"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_monitor.png").default}
                            alt={""}
                        />
                    </div>
                    <section
                        ref={ref => items.current[0] = ref}
                        className={"IndexAppAdminServiceSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_service_1.png").default}
                            alt={"dvgeo_admin_service_1"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[1] = ref}
                        className={"IndexAppAdminServiceSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_service_2.png").default}
                            alt={"dvgeo_admin_service_2"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[2] = ref}
                        className={"IndexAppAdminServiceSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_service_3.png").default}
                            alt={"dvgeo_admin_service_3"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[3] = ref}
                        className={"IndexAppAdminServiceSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_service_4.png").default}
                            alt={"dvgeo_admin_service_4"}
                        />
                    </section>
                </div>
            </div>
        </div>
    )
}
export default IndexAppAdminServiceSection2;
