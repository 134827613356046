import React, {useEffect} from "react";
//theme
import "../styles.scss";
//models
import {Customer} from "../../../../api/customer/customerModel";
//components
import NavBarTop from "../../../_components/NavBarTop";
import NavBarMenu from "../../../_components/NavBarMenu";
import Footer from "../../../_components/Footer";
import IndexAppAdminVehicleBanner1 from "../_components/banner_1";
import IndexAppAdminVehicleSection1 from "../_components/section_1";
import IndexAppAdminVehicleSection2 from "../_components/section_2";
import IndexHomeSection2 from "../../home/_components/section_2";
import IndexAppAdminVehicleSection3 from "../_components/section_3";
//interfaces
interface Props {
    customer: Customer;
    deleteCustomer?: () => void;
    deleteToken?: () => void;
}
//view
const IndexAppAdminVehicleEs = (props: Props) => {
    //effects
    useEffect(() => {
        document.title = `Módulo de vehículos - Marcas, Colores, Asignaciones, Servicios | DvGeo Platform`
        return () => {

        }
    }, []);

    return (
        <div
            className={"IndexAppAdminVehicle"}
        >
            <NavBarTop
                lang={"es"}
                customer={props.customer}
                deleteCustomer={props.deleteCustomer}
                deleteToken={props.deleteToken}
            />
            <NavBarMenu
                lang={"es"}
            />
            <IndexAppAdminVehicleBanner1
                lang={"es"}
            />
            <IndexAppAdminVehicleSection1
                lang={"es"}
            />
            <IndexAppAdminVehicleSection2
                lang={"es"}
            />
            <IndexAppAdminVehicleSection3
                lang={"es"}
            />
            <IndexHomeSection2
                lang={"es"}
            />
            <Footer
                lang={"es"}
                customer={props.customer}
            />
        </div>
    )
}
export default IndexAppAdminVehicleEs;
