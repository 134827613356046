import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexFaqSection6 = (props: Props) => {
    //state
    const [indexGroup, setIndexGroup] = useState<Array<boolean>>([false, false, false, false]);

    const setIndexGroupFocus = (index: number) => {
        let indexGroupNew: Array<boolean> = [...indexGroup];
        for (let i = 0; i < indexGroupNew.length; i++) {
            if (i === index) {
                indexGroupNew[i] = !indexGroupNew[i];
            } else {
                indexGroupNew[i] = false;
            }
        }
        setIndexGroup(indexGroupNew);
    }

    return (
        <div
            className={"IndexFaqSection6"}
        >
            <div
                className={"IndexFaqSection6Title"}
            >
                {props.lang === "es" && <h1
                >
                    Soluciones de pago
                </h1>}
                {props.lang === "en" && <h1
                >
                    Payment solutions
                </h1>}
            </div>
            <section
                className={"IndexFaqSection6Sec"}
            >
                <button
                    className={"IndexFaqSection6SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(0)}
                >
                    {props.lang === "es" && <h2>
                        ¿Pueden los clientes pagar el servicio a través del app de pasajero?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can customers pay for the service through the passenger app?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[0] && <div
                    className={  "IndexFaqSection6SecGroup"}
                >
                    {props.lang === "es" && <p>
                        ¡Sí! Tus clientes puede pagar con tarjeta de crédito y registrar el número en su cuenta en el
                        app de pasajero. Tan solo tienes que crear una cuenta con los diferentes proveedores de pagos
                        que hay en el mercado, por ejemplo: <span
                        onClick={() => window.open("https://paymentez.com")}>Paymentez</span>, <span
                        onClick={() => window.open("https://pagomedios.com")}>PagoMedios</span>, <span
                        onClick={() => window.open("https://sites.placetopay.ec/placetopay_ec_link_de_pago")}>PlaceToPay</span>, <span
                        onClick={() => window.open("https://www.payphone.app/botondepago/?gclid=CjwKCAiAv_KMBhAzEiwAs-rX1HYFEva8XL6Lh-Nd1yLKWZycPcfQueQEAGt7P559EhZssssxRlyRthoCO_kQAvD_BwE")}>PayPhone</span> y
                        cuaqluier otro proveedor de pagos que desees y que cuente con integración API REST.
                    </p>}
                    {props.lang === "en" && <p>
                        Yes! The Driver App and Passenger App can be downloaded for free on Android and iPhone. The
                        smartphone or tablet used must have 3G / 4G and GPS support.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection6SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(1)}
                >
                    {props.lang === "es" && <h2>
                        ¿Qué formas de pago están disponibles en DvGeo?
                    </h2>}
                    {props.lang === "en" && <h2>
                        What forms of payment are available on DvGeo?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[1]  && <div
                    className={ "IndexFaqSection6SecGroup"}
                >
                    {props.lang === "es" && <p>
                        El sistema ofrece varias formas de configurar los precios, tanto en cantidad como en forma de
                        pago. Puedes configurar qué método de pago quieres habilitar en el Panel de Administración. El
                        conductor solo tiene que indicar cuál es el precio por el servicio y cómo se abona el mismo.
                    </p>}
                    {props.lang === "en" && <p>
                        The system offers several ways to configure prices, both in terms of quantity and payment. You
                        can configure which payment method you want to enable in the Administration Panel. The driver
                        only has to indicate what the price for the service is and how it is paid.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection6SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(2)}
                >
                    {props.lang === "es" && <h2>
                        ¿Hay algún proveedor de pago con tarjeta integrado en DvGeo?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Is there a card payment provider integrated in DvGeo?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[2]  && <div
                    className={ "IndexFaqSection6SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Por defecto la plataforma DvGeo permite cobrar en efectivo, si deseas más opciones de cobro o
                        pago debes buscar un proveedor de pagos con integración API REST.
                    </p>}
                    {props.lang === "en" && <p>
                        By default, the DvGeo platform allows you to charge in cash, if you want more payment or payment
                        options, you should look for a payment provider with REST API integration.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection6SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(3)}
                >
                    {props.lang === "es" && <h2>
                        ¿Se puede configurar los párametros del taxímetro digital para calcular el total de los servicios?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can you configure the parameters of the digital taximeter to calculate the total of services?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[3] && <div
                    className={  "IndexFaqSection6SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Si, puedes configurar todos los parámetros del taximetro digital por cada servicio y definir
                        diferentes rangos de distancia como de horarios para determinar precios más juntos.
                    </p>}
                    {props.lang === "en" && <p>
                        Yes, you can configure all the parameters of the digital taximeter for each service and define
                        different ranges of distance and hours to determine prices more together.
                    </p>}
                </div>}
            </section>
        </div>
    )
}
export default IndexFaqSection6;
