import React, {useEffect, useRef} from "react";
//store
import {deleteToken, hideSnackbar, postToken, showSnackbar, SystemSnackbar} from "../../../../store/actions/system.ac";
import {deleteCustomer, postCustomer, putCustomer} from "../../../../store/actions/customer.ac";
//models
import {Customer} from "../../../../api/customer/customerModel";
//libs
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
//components
import CustomerRecoveryView from "../_components/view";
//types
import {StoreDispatchProps, StoreStateProps} from "../../../../store/types";
import {ErrorException} from "../../../../types";

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

//interfaces
interface OwnProps {

}

//const
const TAG = `CustomerRecoveryEn`;
const isDev = (process.env.NODE_ENV !== "production");
//view
const CustomerRecoveryEn = (props: Props) => {
    //hooks
    const history = useHistory();
    const customer = useRef<Customer>(props.customer?.item);
    //state
    //effects
    useEffect(() => {
        document.title = `Inicia sesión - Software de despacho para taxi en la nube | Sistema para empresas de taxi | DvGeo Platform`;
        getData();
        return () => {

        }
    }, []);
    useEffect(() => {
        customer.current = props.customer?.item as Customer;
    }, [props.customer?.item]);

    const getData = () => {
        try {
            getSession();
        } catch (error: ErrorException | any) {
            isDev && console.error(TAG + "//getData", error);
        }
    }

    const getSession = () => {
        if (typeof customer.current === "undefined") {
            setTimeout(() => getSession, 1000);
        } else if (customer.current && customer.current.id) {
            history.push(`/es/application/customer`);
        }
    }

    return (
        <CustomerRecoveryView
            lang={"en"}
            customer={props.customer?.item}
            putCustomer={props.putCustomer}
            deleteCustomer={props.deleteCustomer}
            showSnackbar={props.showSnackbar}
            hideSnackbar={props.hideSnackbar}
            postToken={props.postToken}
            deleteToken={props.deleteToken}
        />
    )
}
const mapStateToProps = (state: StoreStateProps) => ({
    system: state.system,
    customer: state.customer,
});

const mapDispatchToProps = {
    //system
    postToken: (token: string) => (postToken(token)),
    deleteToken: () => (deleteToken()),
    showSnackbar: (snackbar: SystemSnackbar) => (showSnackbar(snackbar)),
    hideSnackbar: () => (hideSnackbar()),
    //customer
    postCustomer: (customer: Customer) => (postCustomer(customer)),
    putCustomer: (customer: Customer) => (putCustomer(customer)),
    deleteCustomer: () => (deleteCustomer()),
}
export default connect<StoreStateProps, StoreDispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(CustomerRecoveryEn);
