import React, {useEffect, useRef} from "react";
//theme
import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
//store
import {deleteToken, hideSnackbar, postToken, showSnackbar, SystemSnackbar} from "../../store/actions/system.ac";
import {deleteCustomer, postCustomer, putCustomer} from "../../store/actions/customer.ac";
//api
import {CustomerDocAPI} from "../../api/customer_doc/customerDocAPI";
//models
import {Customer} from "../../api/customer/customerModel";
import {CustomerDoc} from "../../api/customer_doc/customerDocModel";
//libs
import {connect} from "react-redux";
//views
import CustomerHomeEs from "./home/es";
import CustomerHomeEn from "./home/en";
import CustomerLoginEs from "./login/es";
import CustomerLoginEn from "./login/en";
import CustomerProfileUploadImageEs from "./profile/upload/image/es";
import CustomerProfileUploadImageEn from "./profile/upload/image/en";
import CustomerCompanyUploadRucEs from "./company/upload/ruc/es";
import CustomerCompanyUploadRucEn from "./company/upload/ruc/en";
import CustomerCompanyUploadIdentificationEs from "./company/upload/identification/es";
import CustomerCompanyUploadIdentificationEn from "./company/upload/identification/en";
import CustomerCompanyUploadLogoEs from "./company/upload/logo/es";
import CustomerCompanyUploadLogoEn from "./company/upload/logo/en";
import CustomerCompanyEs from "./company/es";
import CustomerCompanyEn from "./company/en";
import CustomerCompanyUpdateAddressEs from "./company/update/address/es";
import CustomerCompanyUpdateAddressEn from "./company/update/address/en";
import CustomerCompanyUpdatePasswordEs from "./company/update/password/es";
import CustomerCompanyUpdatePasswordEn from "./company/update/password/en";
import CustomerInvoiceEs from "./invoice/es";
import CustomerInvoiceEn from "./invoice/en";
import CustomerSupportEs from "./support/es";
import CustomerAppsEs from "./apps/es";
import CustomerSupportEn from "./support/en";
import CustomerAppsEn from "./apps/en";
//types
import {StoreDispatchProps, StoreStateProps} from "../../store/types";
import {ErrorException} from "../../types";
import CustomerAppsLogEs from "./apps/log/es";
import CustomerAppsLogEn from "./apps/log/en";
import CustomerSupportTrackingEs from "./support/tracking/es";
import CustomerSupportTrackingEn from "./support/tracking/en";
import CustomerSupportTrackingNewEs from "./support/new/es";
import CustomerSupportTrackingNewEn from "./support/new/en";
import CustomerSupportTrackingUpdateEs from "./support/update/es";
import CustomerSupportTrackingUpdateEn from "./support/update/en";
import CustomerRecoveryEs from "./recovery/es";
import CustomerRecoveryEn from "./recovery/en";

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

interface OwnProps {

}

//const
const TAG = `CustomerSwitch`;
const isDev = (process.env.NODE_ENV !== "production");
//component
const CustomerSwitch = (props: Props) => {
    //hooks
    const history = useHistory();
    const location = useLocation();
    //refs
    const customer = useRef<Customer>(props.customer?.item);
    const customer_doc = useRef<CustomerDoc>({});
    //state
    //effects
    useEffect(() => {
        getData();
        return () => {

        }
    }, []);
    useEffect(() => {
        customer.current = props.customer?.item as Customer;
    }, [props.customer?.item]);

    const getData = async () => {
        try {
            await getCustomerDoc();
            inspector();
        } catch (error: ErrorException | any) {
            isDev && console.error(TAG + "//getData", error)
        }
    }

    const getCustomerDoc = async () => {
        if (!customer.current || !customer.current.id) {
            setTimeout(() => getCustomerDoc(), 1000);
            return;
        }
        isDev && console.log(TAG + "//getCustomerDoc", "Cargando documentos del cliente...");
        await CustomerDocAPI.getByCustomerId(customer.current.id, "es").then(async res_1 => {
            isDev && console.log(TAG + "//getCustomerDoc", res_1.code, res_1.message, res_1.error, customer.current.id);
            if (res_1.code === 200) {
                customer_doc.current = res_1.customer_doc as CustomerDoc;
            } else if (res_1.code === 404) {
                await CustomerDocAPI.insert({
                    model: {
                        customer_id: customer.current.id,
                    }
                }, "es").then(async res_2 => {
                    isDev && console.log(TAG + "//insert", res_2.code, res_2.message, res_2.error);
                    if (res_2.code === 200) {
                        await getCustomerDoc();
                    } else {
                        props.showSnackbar && props.showSnackbar({
                            message: res_2.message,
                            severity: "warning"
                        });
                    }
                }).catch(async res_2 => {
                    isDev && console.error(TAG + "//getCustomerDoc", res_2.code, res_2.error, res_2);
                    props.showSnackbar && props.showSnackbar({
                        message: res_2.error ? res_2.error : JSON.stringify(res_2.error),
                        severity: "warning"
                    });
                });
            } else {
                props.showSnackbar && props.showSnackbar({
                    message: res_1.message,
                    severity: "warning"
                });
            }
        }).catch(async res_1 => {
            isDev && console.error(TAG + "//getCustomerDoc", res_1.code, res_1.error, res_1);
            props.showSnackbar && props.showSnackbar({
                message: res_1.error ? res_1.error : JSON.stringify(res_1.error),
                severity: "warning"
            });
        });
    }

    const inspector = () => {
        try {
            if ((!customer.current || !customer.current.id) || (!customer_doc.current || !customer_doc.current.id)) {
                setTimeout(() => inspector(), 1000);
            } else {
                if (!customer.current.url_image) {
                    const path_url = `/profile/upload/image`;
                    !location.pathname.includes(path_url) && history.push(location.pathname + path_url);
                    return;
                }
                if (!customer_doc.current.url_ruc) {
                    const path_url = `/company/upload/ruc`;
                    !location.pathname.includes(path_url) && history.push(location.pathname + path_url);
                    return;
                }
                if (!customer_doc.current.url_identification) {
                    const path_url = `/company/upload/identification`;
                    !location.pathname.includes(path_url) && history.push(location.pathname + path_url);
                    return;
                }
                if (!customer_doc.current.url_logo) {
                    const path_url = `/company/upload/logo`;
                    !location.pathname.includes(path_url) && history.push(location.pathname + path_url);
                    return;
                }
            }
        } catch (error: ErrorException | any) {
            isDev && console.error(TAG + "//inspector", error);
        }
    }

    return (
        <Switch>
            {/*es/home*/}
            <Route exact path="/es/application/customer"
                   component={CustomerHomeEs}
            />
            {/*es/login*/}
            <Route exact path="/es/application/customer/login"
                   component={CustomerLoginEs}
            />
            {/*en/recovery*/}
            <Route exact path="/es/application/customer/recovery"
                   component={CustomerRecoveryEs}
            />
            {/*es/profile*/}
            <Route exact path="/es/application/customer/profile/upload/image"
                   component={CustomerProfileUploadImageEs}
            />
            {/*es/company*/}
            <Route exact path="/es/application/customer/company"
                   component={CustomerCompanyEs}
            />
            <Route exact path="/es/application/customer/company/update/address"
                   component={CustomerCompanyUpdateAddressEs}
            />
            <Route exact path="/es/application/customer/company/update/password"
                   component={CustomerCompanyUpdatePasswordEs}
            />
            <Route exact path="/es/application/customer/company/upload/ruc"
                   component={CustomerCompanyUploadRucEs}
            />
            <Route exact path="/es/application/customer/company/upload/identification"
                   component={CustomerCompanyUploadIdentificationEs}
            />
            <Route exact path="/es/application/customer/company/upload/logo"
                   component={CustomerCompanyUploadLogoEs}/>
            {/*es/invoice*/}
            <Route exact path="/es/application/customer/invoice"
                   component={CustomerInvoiceEs}
            />
            {/*es/support*/}
            <Route exact path="/es/application/customer/support"
                   component={CustomerSupportEs}
            />
            <Route exact path="/es/application/customer/support/new"
                   component={CustomerSupportTrackingNewEs}
            />
            <Route exact path="/es/application/customer/support/update/:customer_support_id"
                   component={CustomerSupportTrackingUpdateEs}
            />
            <Route exact path="/es/application/customer/support/tracking/:customer_support_id"
                   component={CustomerSupportTrackingEs}
            />
            {/*es/apps*/}
            <Route exact path="/es/application/customer/apps"
                   component={CustomerAppsEs}
            />
            <Route exact path="/es/application/customer/apps/log/:id"
                   component={CustomerAppsLogEs}
            />
            {/*english*/}
            {/*en/home*/}
            <Route exact path="/en/application/customer"
                   component={CustomerHomeEn}
            />
            {/*en/login*/}
            <Route exact path="/en/application/customer/login"
                   component={CustomerLoginEn}
            />
            {/*en/recovery*/}
            <Route exact path="/en/application/customer/recovery"
                   component={CustomerRecoveryEn}
            />
            {/*en/profile/upload/image*/}
            <Route exact path="/en/application/customer/profile/upload/image"
                   component={CustomerProfileUploadImageEn}
            />
            {/*en/company*/}
            <Route exact path="/en/application/customer/company"
                   component={CustomerCompanyEn}
            />
            <Route exact path="/en/application/customer/company/update/address"
                   component={CustomerCompanyUpdateAddressEn}
            />
            <Route exact path="/en/application/customer/company/update/password"
                   component={CustomerCompanyUpdatePasswordEn}
            />
            <Route exact path="/en/application/customer/company/upload/ruc"
                   component={CustomerCompanyUploadRucEn}
            />
            <Route exact path="/en/application/customer/company/upload/identification"
                   component={CustomerCompanyUploadIdentificationEn}
            />
            <Route exact path="/en/application/customer/company/upload/logo"
                   component={CustomerCompanyUploadLogoEn}
            />
            {/*en/invoice*/}
            <Route exact path="/en/application/customer/invoice"
                   component={CustomerInvoiceEn}
            />
            {/*en/support*/}
            <Route exact path="/en/application/customer/support"
                   component={CustomerSupportEn}
            />
            <Route exact path="/en/application/customer/support/new"
                   component={CustomerSupportTrackingNewEn}
            />
            <Route exact path="/en/application/customer/support/update/:customer_support_id"
                   component={CustomerSupportTrackingUpdateEn}
            />
            <Route exact path="/en/application/customer/support/tracking/:customer_support_id"
                   component={CustomerSupportTrackingEn}
            />
            {/*en/apps*/}
            <Route exact path="/en/application/customer/apps"
                   component={CustomerAppsEn}
            />
            <Route exact path="/en/application/customer/apps/log/:id"
                   component={CustomerAppsLogEn}
            />
            {/*default*/}
            <Redirect from={"*"} to={"/es/application/customer"}/>
        </Switch>
    )
}
const mapStateToProps = (state: StoreStateProps) => ({
    system: state.system,
    customer: state.customer,
});

const mapDispatchToProps = {
    //system
    postToken: (token: string) => (postToken(token)),
    deleteToken: () => (deleteToken()),
    showSnackbar: (snackbar: SystemSnackbar) => (showSnackbar(snackbar)),
    hideSnackbar: () => (hideSnackbar()),
    //customer
    postCustomer: (customer: Customer) => (postCustomer(customer)),
    putCustomer: (customer: Customer) => (putCustomer(customer)),
    deleteCustomer: () => (deleteCustomer()),
}
export default connect<StoreStateProps, StoreDispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(CustomerSwitch);
