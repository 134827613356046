//libs
import config from '../../config';
import ResolverFetch from "../../libs/_resolver_fetch";
import {getToken} from "../system/_token";
//model
import {CustomerSupportPriorityResponse} from "./customerSupportPriorityModel";
//types
import {Lang} from "../../types";
//api
export const CustomerSupportPriorityAPI = {
    getByAll: async (lang: Lang) => {
        return await new Promise<CustomerSupportPriorityResponse>(async (resolve: (value: CustomerSupportPriorityResponse) => void, reject: (reason: CustomerSupportPriorityResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support/priority?type=getByAll&lang=${lang}`, {
                "method": "GET",
                "headers": {
                    "Accept": "application/json",
                    "authorization": await getToken()
                },
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support_prioritys: (res.is_resolve && res.data) && res.data.customer_support_priority,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    getBySearch: async (param: string, lang: Lang) => {
        return await new Promise<CustomerSupportPriorityResponse>(async (resolve: (value: CustomerSupportPriorityResponse) => void, reject: (reason: CustomerSupportPriorityResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support/priority?type=getBySearch&param=${param}&lang=${lang}`, {
                "method": "GET",
                "headers": {
                    "Accept": "application/json",
                    "authorization": await getToken()
                },
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support_prioritys: (res.is_resolve && res.data) && res.data.customer_support_priority,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
}
