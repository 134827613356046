import React from "react";
//theme
import "./styles.scss";
//libs
import {useHistory} from "react-router-dom";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppPassengerSection5 = (props: Props, ref: any) => {
    //hooks
    const history = useHistory();

    return (
        <div
            ref={ref}
            className={"IndexAppPassengerSection5Content"}
        >
            <div
                className={"IndexAppPassengerSection5 js-scroll slide-left"}
            >
                <div
                    className={"IndexAppPassengerSection5Img"}
                >
                    <img
                        src={require("../../../../../assets/img/passenger_5.png").default}
                        alt={"dvgeo_passenger_5"}
                    />
                </div>
                <section
                    className={"IndexAppPassengerSection5Sec"}
                >
                    {props.lang === "es" && <h1>
                        <strong>Notifica</strong> información importante
                    </h1>}
                    {props.lang === "en" && <h1>
                        <strong>Notify</strong> important news
                    </h1>}
                    {props.lang === "es" && <p>
                        Informa a los pasajeros sobre noticias importantes <strong>enviando paquetes de notificaciones push</strong> a sus teléfonos
                    </p>}
                    {props.lang === "en" && <p>
                        Inform passengers of important news by sending <strong>push notification packages</strong> to their phones
                    </p>}
                </section>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppPassengerSection5);
