import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppDriverSection4 = (props: Props, ref: any) => {
    //hooks

    return (
        <div
            ref={ref}
            className={"IndexAppDriverSection4Content"}
        >
            <div
                className={"IndexAppDriverSection4 js-scroll slide-right"}
            >
                <section
                    className={"IndexAppDriverSection4Sec"}
                >
                    {props.lang === "es" && <h1>
                        <strong>Historial</strong> de viajes y recibos
                    </h1>}
                    {props.lang === "en" && <h1>
                        Travel <strong>history</strong> and receipts
                    </h1>}
                    {props.lang === "es" && <p>
                        Revisa todos los <strong>detalles importantes</strong> de tus últimos viajes y recibos
                    </p>}
                    {props.lang === "en" && <p>
                        Check all the <strong>important details</strong> of your latest trips and receipts
                    </p>}
                </section>
                <div
                    className={"IndexAppDriverSection4Img"}
                >
                    <img
                        src={require("../../../../../assets/img/passenger_4.png").default}
                        alt={"dvgeo_passenger_4"}
                    />
                </div>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppDriverSection4);
