import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminGroupSection1 = (props: Props) => {

    return (
        <div
            className={"IndexAppAdminGroupSection1"}
        >
            <section
                className={"IndexAppAdminGroupSection1Sec"}
            >
                {props.lang === "es" && <h1>
                    <strong>Comunícate</strong> con tus conductores
                </h1>}
                {props.lang === "en" && <h1>
                    <strong>Communicate</strong> with your drivers
                </h1>}
                {props.lang === "es" && <p>
                    Mediante <strong>grupos de chat</strong> puedes asignar a tus socios conductores para mantener la comunicación constante en el mismo aplicativo
                </p>}
                {props.lang === "en" && <p>
                    Through <strong>chat groups</strong> you can assign your driving partners to maintain constant communication in the same application
                </p>}
                {props.lang === "es" && <p>
                    <strong>¡Desplázate hacia abajo</strong> para aprender más!
                </p>}
                {props.lang === "en" && <p>
                    <strong>Scroll down</strong> to learn more!
                </p>}
            </section>
            <div
                className={"IndexAppAdminGroupSection1Img"}
            >
                <img
                    src={require("../../../../../assets/img/admin_group_1.png").default}
                    alt={"dvgeo_admin_group_1"}
                />
            </div>
        </div>
    )
}
export default IndexAppAdminGroupSection1;
