//libs
import config from '../../config';
import ResolverFetch from "../../libs/_resolver_fetch";
import {getToken} from "../system/_token";
//model
import {CustomerSupport, CustomerSupportResponse} from "./customerSupportModel";
//types
import {Lang} from "../../types";
//api
export const CustomerSupportAPI = {
    getById: async (id: number, lang: Lang) => {
        return await new Promise<CustomerSupportResponse>(async (resolve: (value: CustomerSupportResponse) => void, reject: (reason: CustomerSupportResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support?type=getById&id=${id}&lang=${lang}`, {
                "method": "GET",
                "headers": {
                    "Accept": "application/json",
                    "authorization": await getToken()
                },
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support: (res.is_resolve && res.data) && res.data.customer_support,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    getByCustomerId: async (customer_id: number, lang: Lang) => {
        return await new Promise<CustomerSupportResponse>(async (resolve: (value: CustomerSupportResponse) => void, reject: (reason: CustomerSupportResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support?type=getByCustomerId&customer_id=${customer_id}&lang=${lang}`, {
                "method": "GET",
                "headers": {
                    "Accept": "application/json",
                    "authorization": await getToken()
                },
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_supports: (res.is_resolve && res.data) && res.data.customer_support,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    insert: async (body: { model: CustomerSupport }, lang: Lang) => {
        return await new Promise<CustomerSupportResponse>(async (resolve: (value: CustomerSupportResponse) => void, reject: (reason: CustomerSupportResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support?type=insert&lang=${lang}`, {
                "method": "POST",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "authorization": await getToken()
                },
                "body": JSON.stringify(body),
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support: (res.is_resolve && res.data) && res.data.customer_support,
                    id: (res.is_resolve && res.data) && res.data.id,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    update: async (body: { model: CustomerSupport }, lang: Lang) => {
        return await new Promise<CustomerSupportResponse>(async (resolve: (value: CustomerSupportResponse) => void, reject: (reason: CustomerSupportResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support?type=update&lang=${lang}`, {
                "method": "PUT",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "authorization": await getToken()
                },
                "body": JSON.stringify(body),
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support: (res.is_resolve && res.data) && res.data.customer_support,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    updateCustomerSupportType: async (body: { model: CustomerSupport }, lang: Lang) => {
        return await new Promise<CustomerSupportResponse>(async (resolve: (value: CustomerSupportResponse) => void, reject: (reason: CustomerSupportResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support?type=updateCustomerSupportType&lang=${lang}`, {
                "method": "PUT",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "authorization": await getToken()
                },
                "body": JSON.stringify(body),
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support: (res.is_resolve && res.data) && res.data.customer_support,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    updateCustomerSupportPriority: async (body: { model: CustomerSupport }, lang: Lang) => {
        return await new Promise<CustomerSupportResponse>(async (resolve: (value: CustomerSupportResponse) => void, reject: (reason: CustomerSupportResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support?type=updateCustomerSupportPriority&lang=${lang}`, {
                "method": "PUT",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "authorization": await getToken()
                },
                "body": JSON.stringify(body),
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support: (res.is_resolve && res.data) && res.data.customer_support,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    updateIsBadgeAdmin: async (body: { model: CustomerSupport }, lang: Lang) => {
        return await new Promise<CustomerSupportResponse>(async (resolve: (value: CustomerSupportResponse) => void, reject: (reason: CustomerSupportResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support?type=updateIsBadgeAdmin&lang=${lang}`, {
                "method": "PUT",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "authorization": await getToken()
                },
                "body": JSON.stringify(body),
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support: (res.is_resolve && res.data) && res.data.customer_support,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    updateIsBadgeCustomer: async (body: { model: CustomerSupport }, lang: Lang) => {
        return await new Promise<CustomerSupportResponse>(async (resolve: (value: CustomerSupportResponse) => void, reject: (reason: CustomerSupportResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support?type=updateIsBadgeCustomer&lang=${lang}`, {
                "method": "PUT",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "authorization": await getToken()
                },
                "body": JSON.stringify(body),
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support: (res.is_resolve && res.data) && res.data.customer_support,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    updateAdmin: async (body: { model: CustomerSupport }, lang: Lang) => {
        return await new Promise<CustomerSupportResponse>(async (resolve: (value: CustomerSupportResponse) => void, reject: (reason: CustomerSupportResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support?type=updateAdmin&lang=${lang}`, {
                "method": "PUT",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "authorization": await getToken()
                },
                "body": JSON.stringify(body),
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support: (res.is_resolve && res.data) && res.data.customer_support,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    updateIsClose: async (body: { model: CustomerSupport }, lang: Lang) => {
        return await new Promise<CustomerSupportResponse>(async (resolve: (value: CustomerSupportResponse) => void, reject: (reason: CustomerSupportResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support?type=updateIsClose&lang=${lang}`, {
                "method": "PUT",
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "authorization": await getToken()
                },
                "body": JSON.stringify(body),
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support: (res.is_resolve && res.data) && res.data.customer_support,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
    delete: async (id: number, lang: Lang) => {
        return await new Promise<CustomerSupportResponse>(async (resolve: (value: CustomerSupportResponse) => void, reject: (reason: CustomerSupportResponse) => void) => {
            await ResolverFetch(`${config.host}/api/customer/support?type=delete&id=${id}&lang=${lang}`, {
                "method": "DELETE",
                "headers": {
                    "Accept": "application/json",
                    "authorization": await getToken()
                },
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    customer_support: (res.is_resolve && res.data) && res.data.customer_support,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error
                });
            });
        });
    },
}
