import React from "react";
//theme
import "./view.scss";
//store
//models
import {Customer} from "../../../../../../api/customer/customerModel";
//libs
//components
import CustomerNavBarMenu from "../../../../_components/CustomerNavBarMenu";
import NavBarTop from "../../../../../_components/NavBarTop";
import CustomerProfileViewUploadImageFile1 from "../_components/file_1";
//types
import {Lang} from "../../../../../../types";
import {SystemSnackbar} from "../../../../../../store/actions/system.ac";

//interfaces
interface Props {
    lang: Lang;
    customer: Customer;
    deleteCustomer?: () => void;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
    deleteToken?: () => void;
}

//view
const CustomerProfileViewUploadImage = (props: Props) => {
    return (
        <div
            className={"CustomerProfileViewUploadImage"}
        >
            <NavBarTop
                lang={"es"}
                customer={props.customer}
                deleteCustomer={props.deleteCustomer}
                deleteToken={props.deleteToken}
            />
            <CustomerNavBarMenu
                lang={"es"}
            />
            <CustomerProfileViewUploadImageFile1
                lang={"es"}
                customer={props.customer}
                showSnackbar={props.showSnackbar}
                hideSnackbar={props.hideSnackbar}
            />
        </div>
    )
}
export default CustomerProfileViewUploadImage;
