import React from "react";
//theme
import "./styles.scss";
//libs
//type
import {Lang} from "../../../../../types";
//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexRegisterBanner1 = (props: Props, ref: any) => {

    return (
        <div
            ref={ref}
            className={"IndexRegisterBanner1"}
        >
            <div
                className={"IndexRegisterBanner1Background"}
            />
            <section
                className={"IndexRegisterBanner1Sec"}
            >
                {props.lang === "es" && <h1>
                    Necesitas una plataforma <strong>completa, rápida y fácil de usar</strong>
                </h1>}
                {props.lang === "en" && <h1>
                    You need a complete, <strong>fast and easy-to-use</strong> platform
                </h1>}
                {props.lang === "es" && <p>
                    <strong>DvGeo</strong> tiene un conjunto de herramientas, módulos y funciones actualizadas
                    que <strong>le permiten a tu
                    negocio establecerse en el mercado digital</strong> de forma competitiva.
                </p>}
                {props.lang === "en" && <p>
                    <strong>DvGeo</strong> has a set of updated tools, modules and functions <strong>that allow your
                    business to establish itself in the digital market</strong> competitively.
                </p>}
            </section>
        </div>
    )
}
export default React.forwardRef(IndexRegisterBanner1);
