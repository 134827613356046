import React, {FormEvent, useEffect, useRef, useState} from "react";
//theme
import "./styles.scss";
//API
import {CustomerSupportAPI} from "../../../../../../api/customer_support/customerSupportAPI";
//models
import {Customer} from "../../../../../../api/customer/customerModel";
//libs
import {useHistory} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
//components
import TextInput from "../../../../../_components/TextInput";
import CustomerSupportPrioritySelector from "../../../_components/CustomerSupportPriority";
import CustomerSupportTypeSelector from "../../../_components/CustomerSupportType";
//types
import {Lang} from "../../../../../../types";
import {SystemSnackbar} from "../../../../../../store/actions/system.ac";

type Fields = {
    issue: string;
    message: string;
    customer_support_type_id: string;
    customer_support_priority_id: string;
};
type Errors = {
    issue?: string;
    message?: string;
    customer_support_type_id?: string;
    customer_support_priority_id?: string;
};


//interfaces
interface Props {
    lang: Lang;
    customer: Customer;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
}

//const
const TAG = `CustomerSupportNewForm1`;
const isDev = (process.env.NODE_ENV !== "production");
//component
const CustomerSupportNewForm1 = (props: Props) => {
    //hooks
    const history = useHistory();
    //refs
    const inputsRef = useRef<Array<any>>([]);
    //state
    const [customer, setCustomer] = useState<Customer>(props.customer);
    const [fields, setFields] = useState<Fields>({
        issue: "",
        message: "",
        customer_support_priority_id: "",
        customer_support_type_id: "",
    });
    const [errors, setErrors] = useState<Errors>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    //effects
    useEffect(() => {
        if (props.customer && props.customer.id) {
            setCustomer(props.customer);
        }
    }, [props.customer]);

    const validForm = (id: keyof Fields, value: string) => {
        switch (id) {
            case "issue": {
                setFields((prevFields) => ({
                    ...prevFields,
                    issue: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 3) {
                    inputsRef.current[0] && inputsRef.current[0]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        issue: props.lang === "es" ? "Mínimo 4 caracteres..." : props.lang === "en" ? "Minimum 4 characters" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        issue: "",
                    }));
                    return true;
                }
            }
            case "message": {
                setFields((prevFields) => ({
                    ...prevFields,
                    message: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 3) {
                    inputsRef.current[1] && inputsRef.current[1]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        message: props.lang === "es" ? "Mínimo 4 caracteres..." : props.lang === "en" ? "Minimum 4 characters" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        message: "",
                    }));
                    return true;
                }
            }
            case "customer_support_priority_id": {
                setFields((prevFields) => ({
                    ...prevFields,
                    customer_support_priority_id: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || value === "" || value === "0") {
                    inputsRef.current[2] && inputsRef.current[2]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        customer_support_priority_id: "Seleccione una prioridad...",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        customer_support_priority_id: "",
                    }));
                    return true;
                }
            }
            case "customer_support_type_id": {
                setFields((prevFields) => ({
                    ...prevFields,
                    customer_support_type_id: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || value === "" || value === "0") {
                    inputsRef.current[3] && inputsRef.current[3]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        customer_support_type_id: "Seleccione un tipo...",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        customer_support_type_id: "",
                    }));
                    return true;
                }
            }
            default : {
                return false
            }
        }
    }

    const save = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let indexFields = 0;
        for (const field in fields) {
            if (!validForm(field as keyof Fields, Object.values(fields)[indexFields])) {
                return;
            }
            indexFields++;
        }
        setIsLoading(true);
        await CustomerSupportAPI.insert({
            model: {
                ...fields,
                customer_support_type_id: Number(fields.customer_support_type_id),
                customer_support_priority_id: Number(fields.customer_support_priority_id),
                customer_id: customer.id,
            }
        }, props.lang).then(async res_1 => {
            isDev && console.log(TAG + "//insert", res_1.code, res_1.message, res_1.error);
            if (res_1.code === 200) {
                history.goBack();
            } else {
                props.showSnackbar && props.showSnackbar({
                    message: res_1.message,
                    severity: "error"
                });
            }
        }).catch(async res_1 => {
            isDev && console.log(TAG + "//insert", res_1.code, res_1.message, res_1.error);
            props.showSnackbar && props.showSnackbar({
                message: res_1.error ? res_1.error : JSON.stringify(res_1),
                severity: "error"
            });
        });
        setIsLoading(false);
    }

    return (
        <div
            className={"CustomerSupportNewForm1"}
        >
            <form
                className={"CustomerSupportNewForm1Form"}
                onSubmit={(e) => save(e)}
            >
                <section>
                    <TextInput
                        inputRef={inputsRef.current[0]}
                        label={props.lang === "es" ? "Asunto:" : props.lang === "en" ? "Issue:" : ""}
                        placeholder={props.lang === "es" ? "Asunto" : props.lang === "en" ? "Issue" : ""}
                        value={fields.issue}
                        onChangeText={(text) => validForm("issue", text)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                inputsRef.current[1] && inputsRef.current[1].focus();
                            }
                        }}
                        error={errors?.issue}
                    />
                    <TextInput
                        inputRef={inputsRef.current[1]}
                        label={props.lang === "es" ? "Mensaje:" : props.lang === "en" ? "Message:" : ""}
                        placeholder={props.lang === "es" ? "Mensaje" : props.lang === "en" ? "Message" : ""}
                        value={fields.message}
                        multiline
                        onChangeText={(text) => validForm("message", text)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                inputsRef.current[2] && inputsRef.current[2].focus();
                            }
                        }}
                        error={errors?.message}
                    />
                    <CustomerSupportPrioritySelector
                        lang={props.lang}
                        selectorRef={inputsRef.current[2]}
                        defaultValue={fields.customer_support_priority_id}
                        size={"normal"}
                        limit={"all"}
                        onSelectedValue={async (customer_support_priority) => {
                            validForm("customer_support_priority_id", String(customer_support_priority?.id));
                            inputsRef.current[3] && inputsRef.current[3].focus();
                        }}
                        error={errors?.customer_support_priority_id}
                    />
                    <CustomerSupportTypeSelector
                        lang={props.lang}
                        selectorRef={inputsRef.current[3]}
                        defaultValue={fields.customer_support_type_id}
                        size={"normal"}
                        limit={"all"}
                        onSelectedValue={async (customer_support_type) => {
                            validForm("customer_support_type_id", String(customer_support_type?.id));
                        }}
                        error={errors?.customer_support_type_id}
                    />
                </section>
                <div
                    className={"CustomerSupportNewForm1FormContentBtn"}
                >
                    <button
                        className={"CustomerCompanyUpdateAddressViewForm1FormBtn"}
                        type={"submit"}
                        disabled={isLoading}
                    >
                        {!isLoading && props.lang === "es" && "Generar"}
                        {!isLoading && props.lang === "en" && "Generate"}

                        {isLoading && <CircularProgress
                            color={"inherit"}
                            size={20}
                        />}
                    </button>
                </div>
            </form>
        </div>
    )
}
export default CustomerSupportNewForm1;
