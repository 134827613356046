import React, {useEffect, useState} from "react";
//theme
import "./view.scss";
//api
import {CustomerDocAPI} from "../../../../api/customer_doc/customerDocAPI";
//models
import {Customer} from "../../../../api/customer/customerModel";
import {CustomerDoc} from "../../../../api/customer_doc/customerDocModel";
//components
import NavBarTop from "../../../_components/NavBarTop";
import CustomerNavBarMenu from "../../_components/CustomerNavBarMenu";
import CustomerCompanyViewBanner1 from "./banner_1";
import CustomerCompanyViewForm1 from "./form_1";
import CustomerCompanyViewFile1 from "./file_1";
import Footer from "../../../_components/Footer";
import SnackBar from "../../../_components/SnackBar";
//types
import {ErrorException, Lang} from "../../../../types";
import {SystemSnackbar} from "../../../../store/actions/system.ac";

//interfaces
interface Props {
    lang: Lang;
    customer: Customer;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
    deleteCustomer?: () => void;
    deleteToken?: () => void;
}

//const
const TAG = `CustomerCompanyView`;
const isDev = (process.env.NODE_ENV !== "production");
//view
const CustomerCompanyView = (props: Props) => {
    //hooks
    //refs
    //state
    const [customer_doc, setCustomerDoc] = useState<CustomerDoc>({});
    //effects
    useEffect(() => {
        getData();
        return () => {

        }
    }, []);
    useEffect(() => {
        if (props.customer && props.customer.id && typeof props.customer.id !== "undefined") {
            getCustomerDoc(Number(props.customer.id))
        }
    }, [props.customer]);

    const getData = async () => {
        try {

        } catch (error: ErrorException | any) {
            isDev && console.error(TAG + "//getData", error);
        }
    }
    const getCustomerDoc = async (customer_id: number) => {
        isDev && console.log(TAG + "//getCustomerDoc", "Cargando documentos del cliente...");
        await CustomerDocAPI.getByCustomerId(customer_id, "es").then(async res_1 => {
                isDev && console.log(TAG + "//getCustomerDoc", res_1.code, res_1.message, res_1.error);
                if (res_1.code === 200) {
                    setCustomerDoc(res_1.customer_doc as CustomerDoc);
                } else if (res_1.code === 409) {

                } else {
                    props.showSnackbar && props.showSnackbar({
                        message: res_1.message,
                        severity: "warning"
                    });
                }
            }
        ).catch(async res_1 => {
            isDev && console.error(TAG + "//getCustomerDoc", res_1.code, res_1.error, res_1);
            props.showSnackbar && props.showSnackbar({
                message: res_1.error ? res_1.error : JSON.stringify(res_1.error),
                severity: "warning"
            });
        });
    }

    return (
        <div
            className={"CustomerCompanyView"}
        >
            <NavBarTop
                lang={props.lang}
                customer={props.customer}
                deleteCustomer={props.deleteCustomer}
                deleteToken={props.deleteToken}
            />
            <CustomerNavBarMenu
                lang={props.lang}
            />
            <CustomerCompanyViewBanner1
                lang={props.lang}
            />
            <CustomerCompanyViewForm1
                lang={props.lang}
                customer={props.customer}
                showSnackbar={props.showSnackbar}
                hideSnackbar={props.hideSnackbar}
            />
            <CustomerCompanyViewFile1
                lang={props.lang}
                customer_doc={customer_doc}
            />
            <Footer
                lang={props.lang}
                customer={props.customer}
            />
            <SnackBar/>
        </div>
    )
}
export default CustomerCompanyView;
