import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexFaqSection1 = (props: Props) => {
    //state
    const [indexGroup, setIndexGroup] = useState<Array<boolean>>([false, false, false, false, false, false, false, false, false, false, false]);

    const setIndexGroupFocus = (index: number) => {
        let indexGroupNew: Array<boolean> = [...indexGroup];
        for (let i = 0; i < indexGroupNew.length; i++) {
            if (i === index) {
                indexGroupNew[i] = !indexGroupNew[i];
            } else {
                indexGroupNew[i] = false;
            }
        }
        setIndexGroup(indexGroupNew);
    }

    return (
        <div
            className={"IndexFaqSection1"}
        >
            <div
                className={"IndexFaqSection1Title"}
            >
                {props.lang === "es" && <h1
                >
                    General
                </h1>}
                {props.lang === "en" && <h1
                >
                    General
                </h1>}
            </div>
            <section
                className={"IndexFaqSection1Sec"}
            >
                <button
                    className={"IndexFaqSection1SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(0)}
                >
                    {props.lang === "es" && <h2>
                        ¿Las aplicaciones tendrán mi propio logotipo y nombre de mi empresa?
                    </h2>}
                    {props.lang === "en" && <h2>
                        The apps will have my own logo and company name?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[0] && <div
                    className={"IndexFaqSection1SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Efectivamente, las tres apps de la plataforma DvGeo: Admin, Pasajero y Conductor estarán
                        disponibles con el logo
                        de su empresa.
                    </p>}
                    {props.lang === "en" && <p>
                        Indeed, the three apps on the DvGeo platform: Admin, Passenger and Driver will be available with
                        your company logo.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection1SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(1)}
                >
                    {props.lang === "es" && <h2>
                        La competencia desleal de las apps están haciendo daño a mi negocio. ¿Cómo puede ayudar DvGeo?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Unfair competition from apps is hurting my business. How can DvGeo help?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[1] && <div
                    className={"IndexFaqSection1SecGroup"}
                >
                    {props.lang === "es" && <p>
                        A diferencia de otros proveedores de este tipo de soluciones, DvGeo si brinda todas las
                        herramientas con la marca comercial de la empresa que lo contrate, además te ayuda a mantenerte
                        competitivo en el mercado digital.
                    </p>}
                    {props.lang === "en" && <p>
                        A diferencia de otros proveedores de este tipo de soluciones, DvGeo si brinda todas las
                        herramientas con la marca comercial de la empresa que lo contrate, además te ayuda a mantenerte
                        competitivo en el mercado digital.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection1SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(2)}
                >
                    {props.lang === "es" && <h2>
                        Tengo una flota pequeña/grande de vehículos, soy emprendedor y quiero experimentar. ¿Funciona
                        DvGeo para mí?
                    </h2>}
                    {props.lang === "en" && <h2>
                        I have a small / large fleet of vehicles, I am an entrepreneur and I want to experiment. Does
                        DvGeo work for me?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[2] && <div
                    className={"IndexFaqSection1SecGroup"}
                >
                    {props.lang === "es" && <p>
                        ¡Sí! DvGeo es una plataforma basada en la nube y es escalable, así que es capaz de gestionar
                        cualquier empresa sin límites de ningun tipo.
                    </p>}
                    {props.lang === "en" && <p>
                        Yes! DvGeo is a cloud-based platform and it is scalable, so it is capable of managing any
                        company without limits of any kind.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection1SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(3)}
                >
                    {props.lang === "es" && <h2>
                        No tengo tiempo para poner en marcha un sistema de taxi y no soy muy bueno con la
                        tecnología.
                    </h2>}
                    {props.lang === "en" && <h2>
                        I don't have time to get a taxi system up and running and I'm not very good at
                        technology.
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[3] && <div
                    className={"IndexFaqSection1SecGroup"}
                >
                    {props.lang === "es" && <p>
                        DvGeo se encarga de la parte técnica. ¡Tú no tienes que preocuparte! Tenemos soporte 24/7 para
                        ayudarte con cualquier duda que tengas para que solo te tengas que centrar en tu negocio.
                    </p>}
                    {props.lang === "en" && <p>
                        DvGeo takes care of the technical part. You don't have to worry! We have 24/7 support to help
                        you with any questions you have so that you only have to focus on your business.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection1SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(4)}
                >
                    {props.lang === "es" && <h2>
                        Tengo otro tipo de compañía de transporte. ¿Puedo usar DvGeo?
                    </h2>}
                    {props.lang === "en" && <h2>
                        I have another type of transportation company. Can I use DvGeo?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[4] && <div
                    className={"IndexFaqSection1SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Si tienes una empresa de limosinas, furgonetas, transporte privado o transporte con conductor,
                        DvGeo te pone en primera linea de la competencia. Puedes configurar tu cuenta para gestionar tu
                        flotilla como quieras.
                    </p>}
                    {props.lang === "en" && <p>
                        If you have a limousine company, vans, private transport or transport with driver, DvGeo puts
                        you in the forefront of the competition. You can configure your account to manage your fleet
                        however you want.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection1SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(5)}
                >
                    {props.lang === "es" && <h2>
                        ¿Las ganancias que me resulte con la plataforma DvGeo son de ustedes, las comparto o son mías?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Are the profits that I get from the DvGeo platform yours, do I share them or are they mine?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[5] && <div
                    className={"IndexFaqSection1SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Todas las ganancias que se generen dentro de la plataforma con sus apps serán 100% de nuestro
                        cliente que lo contrate. Nosotros solo nos encargamos de toda la parte técnica y el único
                        indicador que tenemos para generar la factura de mantenimiento mensual es el total de viajes
                        mensuales.
                    </p>}
                    {props.lang === "en" && <p>
                        All the profits that you generate within the platform with your apps will be 100% of our client
                        who hires you. We only take care of all the technical part and the only indicator we have to
                        generate the monthly maintenance invoice is the total of monthly trips.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection1SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(6)}
                >
                    {props.lang === "es" && <h2>
                        Si adquiero la plataforma completa DvGeo, ¿Me dan el código fuente y los derechos de autor?
                    </h2>}
                    {props.lang === "en" && <h2>
                        If I purchase the full DvGeo platform, can I get the source code and copyright?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[6] && <div
                    className={"IndexFaqSection1SecGroup"}
                >
                    {props.lang === "es" && <p>
                        No, nosotros somos la empresa Devjos CIA LTDA, desarollador, dueño y responsable de la
                        plataforma DvGeo. Cuando un cliente nuestro adquiere una suscripción a la plataforma DvGeo solo
                        se concede una licencia para que puedan operar con su propia marca comercial bajo nuestros
                        servicios, por ende no otorgamos ni brindamos acceso al código fuente.
                    </p>}
                    {props.lang === "en" && <p>
                        No, we are the company Devjos CIA LTDA, developer, owner and manager of the DvGeo platform. When
                        a client of ours acquires a subscription to the DvGeo platform, only a license is granted so
                        that they can operate with their own commercial brand under our services, therefore we do not
                        grant or provide access to the source code.
                    </p>}
                </div>}
                <button
                    className={"IndexFaqSection1SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(7)}
                >
                    {props.lang === "es" && <h2>
                        ¿En cuánto tiempo me dan las apps con sus accesos?
                    </h2>}
                    {props.lang === "en" && <h2>
                        How long will it take for the apps with their accesses?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[7] && <div
                    className={"IndexFaqSection1SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Cuando se confirme el proyecto mediante el 50% de entrada con uno de nuestros representates,
                        iniciamos el proceso para tener las 3 apps con su propia marca comercial (logotipo y nombre),
                        tardará 4 días hábiles o laborales, a partir del segundo día se enviarán capturas de pantalla
                        para ver como va quedando.
                        <br/>En el 4 día se le confirmará el proceso terminado con una captura de
                        pantalla que muestre la publicación en Play Store (con estado en revisión), los enlaces y
                        credenciales para el Administrador.
                    </p>}
                    {props.lang === "en" && <p>
                        When the project is confirmed by 50% entry with one of our representatives, we start the process
                        to have the 3 apps with their own commercial brand (logo and name), it will take 4 business days
                        or business days, from the second day they will be sent screenshots to see how it looks. On the
                        4th day, the finished process will be confirmed with a screenshot that shows the publication in
                        the Play Store (with status under review), the links and credentials for the Administrator.
                    </p>}
                </div>}
            </section>
        </div>
    )
}
export default IndexFaqSection1;
