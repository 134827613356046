import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexAboutSection2 = (props: Props) => {
    return (
        <div
            className={"IndexAboutSection2"}
        >
            <div
                className={"IndexAboutSection2Sec"}
            >
                {props.lang === "es" && <h1>
                    ¿Quiénes somos?
                </h1>}
                {props.lang === "en" && <h1>
                    About us?
                </h1>}
                {props.lang === "es" && <p>
                    Somos una marca de la empresa <strong>Devjos CIA LTDA</strong>, con oficinas en Ecuador, Guayaquil.
                    Esta plataforma fue desarrollada por el Ing. Josue Chavez, ecuatoriano, con más de 10 años de
                    experiencia en tecnologías
                    de software. Le llevó cerca de 3 años y medio estudiar el mercado de transporte para lograr entender
                    la necesidad de los clientes de ese nicho.
                </p>}
                {props.lang === "en" && <p>
                    We are a brand of the company <strong> Devjos CIA LTDA </strong>, with offices in Ecuador, Guayaquil.
                    This platform was developed by Ing. Josue Chavez, an Ecuadorian, with more than 10 years of experience
                    technology experience
                    of software. It took him about 3 and a half years to study the transport market to understand
                    the need of customers in that niche.
                </p>}
            </div>
            <div
                className={"IndexAboutSection2Background"}
            >
                <div
                    className={"IndexAboutSection2BackgroundImg"}
                />
            </div>
        </div>
    )
}
export default IndexAboutSection2;
