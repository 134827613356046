import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminCouponSection1 = (props: Props) => {

    return (
        <div
            className={"IndexAppAdminCouponSection1"}
        >
            <section
                className={"IndexAppAdminCouponSection1Sec"}
            >
                {props.lang === "es" && <h1>
                    Ofrece <strong>cupones de descuento</strong> para tus pasajeros
                </h1>}
                {props.lang === "en" && <h1>
                    Offer <strong>discount coupons</strong> for your passengers
                </h1>}
                {props.lang === "es" && <p>
                    Potencia tu campaña de marketing con <strong>códigos promocionales</strong> para que tus nuevos usuarios se motiven aún más
                </p>}
                {props.lang === "en" && <p>
                    Boost your marketing campaign with <strong>promotional codes</strong> so that your new users get even more motivated
                </p>}
                {props.lang === "es" && <p>
                    <strong>¡Desplázate hacia abajo</strong> para aprender más!
                </p>}
                {props.lang === "en" && <p>
                    <strong>Scroll down</strong> to learn more!
                </p>}
            </section>
            <div
                className={"IndexAppAdminCouponSection1Img"}
            >
                <img
                    src={require("../../../../../assets/img/admin_coupon_1.png").default}
                    alt={"dvgeo_admin_coupon_1"}
                />
            </div>
        </div>
    )
}
export default IndexAppAdminCouponSection1;
