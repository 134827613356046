import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexAppAdminCommerceSection3 = (props: Props) => {
    //state
    const [indexGroup, setIndexGroup] = useState<Array<boolean>>([false, false, false, false, false, false, false]);

    const setIndexGroupFocus = (index: number) => {
        let indexGroupNew: Array<boolean> = [...indexGroup];
        for (let i = 0; i < indexGroupNew.length; i++) {
            if (i === index) {
                indexGroupNew[i] = !indexGroupNew[i];
            } else {
                indexGroupNew[i] = false;
            }
        }
        setIndexGroup(indexGroupNew);
    }

    return (
        <div
            className={"IndexAppAdminCommerceSection3"}
        >
            <div
                className={"IndexAppAdminCommerceSection3Title"}
            >
                {props.lang === "es" && <h1
                >
                    Preguntas Frecuentes
                </h1>}
                {props.lang === "en" && <h1
                >
                    FAQ
                </h1>}
            </div>
            <section
                className={"IndexAppAdminCommerceSection3Sec"}
            >
                <button
                    className={"IndexAppAdminCommerceSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(0)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo distribuir mis recargas en las tiendas?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I distribute my refills in stores?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[0] ? "IndexAppAdminCommerceSection3SecGroupFocus" : "IndexAppAdminCommerceSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Cuando asocias un comercio y lo registras en el admin le puedes dar saldo para que empiece a vender saldo a tus conductores, puede ser cualquier tiempo de comercio.
                    </p>}
                    {props.lang === "en" && <p>
                        When you associate a trade and register it in the admin you can give it a balance so that it can start selling balance to your drivers, it can be any time of the trade.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminCommerceSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(1)}
                >
                    {props.lang === "es" && <h2>
                        ¿Existe un límite de saldo para los comercios?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Is there a balance limit for businesses?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[1] ? "IndexAppAdminCommerceSection3SecGroupFocus" : "IndexAppAdminCommerceSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Los comercios pueden adquirir el saldo que deseen y venderlo en su tiempos comerciales.
                    </p>}
                    {props.lang === "en" && <p>
                        Merchants can acquire the balance they want and sell it in their commercial times.
                    </p>}
                </div>

            </section>
        </div>
    )
}
export default IndexAppAdminCommerceSection3;
