import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexFaqBanner1 = (props: Props) => {

    return (
        <div
            className={"IndexFaqBanner1"}
        >
            <div
                className={"IndexFaqBanner1Title"}
            >
                {props.lang === "es" && <h1
                >
                    Preguntas Frecuentes
                </h1>}
                {props.lang === "en" && <h1
                >
                    FAQ
                </h1>}
                {props.lang === "es" && <p>
                    <strong>Encuentra respuestas</strong> a tus preguntas sobre la plataforma <strong>DvGeo</strong>
                </p>}
                {props.lang === "en" && <p>
                    <strong>Find answers to your questions</strong> about the <strong>DvGeo</strong> platform
                </p>}
            </div>
        </div>
    )
}
export default IndexFaqBanner1;
