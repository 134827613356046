import React, {useEffect, useRef, useState} from "react";
//theme
import "./styles.scss";
import "emoji-mart/css/emoji-mart.css";
//api
import {CustomerSupportTrackingAPI} from "../../../../../../api/customer_support_tracking/customerSupportTrackingAPI";
//models
import {Customer} from "../../../../../../api/customer/customerModel";
import {CustomerSupport} from "../../../../../../api/customer_support/customerSupportModel";
import {CustomerSupportTracking} from "../../../../../../api/customer_support_tracking/customerSupportTrackingModel";
//libs
import config from "../../../../../../config";
import ReactLoading from 'react-loading';
import {FlatList, TouchableOpacity} from "react-native";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPaperclip,
    faPaperPlane, faSmileWink, faTimes,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {Picker} from "emoji-mart";
//components
import CustomerSupportTrackingEmisor from "../emisor";
import CustomerSupportTrackingReceptor from "../receptor";
//types
import {ErrorException, Lang} from "../../../../../../types";
import {SystemSnackbar} from "../../../../../../store/actions/system.ac";

//interfaces
interface Props {
    lang: Lang;
    customer: Customer;
    customer_support: CustomerSupport;
    parentRef: React.RefObject<any>;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
}

//const
const TAG = `CustomerSupportTrackingViewSection1`;
const isDev = (process.env.NODE_ENV !== "production");
//components
const CustomerSupportTrackingViewSection1 = (props: Props) => {
    //refs
    const componentRef = useRef<any>();
    //state
    const [customer_support_trackings, setCustomerSupportTrackings] = useState<Array<CustomerSupportTracking>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [fields, setFields] = useState<{
        message: string;
    }>({
        message: ""
    });
    const [is_emoji, setIsEmoji] = useState<boolean>(false);
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [file, setFile] = useState<{
        file: string, fileExtension: string; fileName: string;
    }>({
        file: "", fileExtension: "", fileName: ""
    });
    //effects
    useEffect(() => {
        getData();
        return () => {

        }
    }, []);
    useEffect(() => {
        props.customer_support && props.customer_support.id && getCustomerSupportTrackingsLogs(Number(props.customer_support.id));
    }, [props.customer_support]);
    useEffect(() => {
        setTimeout(() => {
            props.parentRef.current && props.parentRef.current.scrollTo(0, componentRef.current.clientHeight - 200)
        }, 1000);
    }, [customer_support_trackings]);

    const getData = async () => {
        try {

        } catch (error: ErrorException | any) {
            isDev && console.error(TAG + "//getData", error);
        }
    }

    const getCustomerSupportTrackingsLogs = async (customer_support_id: number) => {
        setIsLoading(true);
        isDev && console.log(TAG + "//getCustomerSupportTrackingsLogs", "Cargando documentos del cliente...");
        await CustomerSupportTrackingAPI.getByCustomerSupportId(customer_support_id, props.lang).then(async res_1 => {
            isDev && console.log(TAG + "//getCustomerSupportTrackings", res_1.code, res_1.message, res_1.error);
            if (res_1.code === 200) {
                setCustomerSupportTrackings(res_1.customer_support_trackings as Array<CustomerSupportTracking>);
            } else if (res_1.code === 409) {

            } else {
                props.showSnackbar && props.showSnackbar({
                    message: res_1.message,
                    severity: "warning"
                });
            }
        }).catch(async res_1 => {
            isDev && console.error(TAG + "//getCustomerSupportTrackings", res_1.code, res_1.error, res_1);
            props.showSnackbar && props.showSnackbar({
                message: res_1.error ? res_1.error : JSON.stringify(res_1.error),
                severity: "warning"
            });
        });
        setIsLoading(false);
    }

    const changeMessage = (message: string) => {
        setFields((prevFields) => ({
            ...prevFields,
            message,
        }));
    }

    const addEmoji = (e: any) => {
        setFields((prevFields) => ({
            ...prevFields,
            message: fields.message + e.native,
        }));
    }

    const readURL = async () => {
        let input = document.getElementById(`${TAG}-file`);
        // @ts-ignore
        if (!input.value.match(/(?:jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
            props.showSnackbar && props.showSnackbar({
                message: props.lang === "es" ? "Formato de imagen no válido" : props.lang === "en" ? "Invalid image format" : "error",
                severity: "error",
            });
            return;
        }
        // @ts-ignore
        if (input.files[0]) {
            // @ts-ignore
            let fileName = input.value.substr(input.value.lastIndexOf('\\') + 1).split('.')[0];
            let patternExt = /(?:\.([^.]+))?$/;
            // @ts-ignore
            let fileExtension = patternExt.exec(input.value)[1];
            let reader = new FileReader();
            reader.onload = async (e) => {
                setFile((prevCustomer) => ({
                    file: e?.target?.result as string,
                    fileName, fileExtension,
                }));
                setIsSelected(true);

            }
            // @ts-ignore
            reader.readAsDataURL(input.files[0]);
        }
    }

    const clearSelectedFile = () => {
        setFile({
            file: "", fileName: "", fileExtension: "",
        });
        // @ts-ignore
        document.getElementById(`${TAG}-file`).value = "";
        setIsSelected(false);
    }

    const sendMessage = async (iptMensajeDirect = "") => {
        setIsEmoji(false);
        let message = iptMensajeDirect ? iptMensajeDirect : fields.message;
        message = encodeURI(message);
        if (message === '' || !message) {
            props.showSnackbar && props.showSnackbar({
                message: "Debe escribir algo...", severity: "error", duration: 5
            });
            return false;
        }
        setIsLoading(true);
        setFields((prevFields) => ({
            ...prevFields,
            message: "",
        }));
        setCustomerSupportTrackings((prevCustomerSupportTrackings) => ([
            {
                id: new Date().getTime(),
                created_at: moment().format('YYYY-MM-DD HH:mm'),
                message_customer: message,
                url_image_customer: file.file,
            },
            ...prevCustomerSupportTrackings,
        ]));
        isDev && console.log(TAG + "//sendMessage", "Enviando message...");
        await CustomerSupportTrackingAPI.insert({
            model: {
                customer_support_id: props.customer_support.id,
                message_customer: message,
                customer_support: {
                    customer: props.customer,
                    admin: {
                        name: "",
                    }
                }
            }
        }, props.lang).then(async res => {
            isDev && console.log(TAG + "//sendMessage", res.code, res.message);
            if (res.code === 200) {
                //props.parentRef.current && props.parentRef.current.scrollTo(0, componentRef.current.clientHeight);
                if (isSelected) {
                    await CustomerSupportTrackingAPI.updateImageCustomer({
                        model: {
                            id: res.id,
                            ...file
                        }
                    }, props.lang).then(async res_2 => {
                        isDev && console.log(TAG + "//sendMessage", res_2.code, res_2.message);
                        if (res_2.code !== 200) {
                            props.showSnackbar && props.showSnackbar({
                                message: res.message, severity: "warning"
                            });
                        }
                    }).catch(async res_2 => {
                        isDev && console.log(TAG + "//sendMessage", res_2.code, res_2.message);
                        props.showSnackbar && props.showSnackbar({
                            message: res_2.error ? res_2.error : JSON.stringify(res_2), severity: "error"
                        });
                    });
                    clearSelectedFile();
                }
            } else {
                props.showSnackbar && props.showSnackbar({
                    message: res.message, severity: "warning", duration: 5
                });
            }
        }).catch(res => {
            isDev && console.error(TAG + "//sendMessage", res.code, res.error, res);
            props.showSnackbar && props.showSnackbar({
                message: res.error ? res.error : JSON.stringify(res), severity: "error"
            });
        });
        setIsLoading(false);
    }

    return (
        <div
            ref={componentRef}
            className={"CustomerSupportTrackingViewSection1"}
        >
            {isLoading && <ReactLoading
                type={"bubbles"}
                color={config.colorPrimary}
                height={50}
                width={120}
                delay={2000}
            />}
            <div
                className={"CustomerSupportTrackingViewSection1Items"}
            >
                {/*Lista*/}
                <FlatList
                    contentContainerStyle={{}}
                    data={customer_support_trackings}
                    inverted={true}
                    numColumns={1}
                    renderItem={({item, index}) => {
                        return (item.message_customer) ?
                            <CustomerSupportTrackingEmisor
                                key={"CustomerSupportTrackingViewSection1Emisor-" + index}
                                url_image={item.customer_support?.customer?.url_image ? item.customer_support?.customer?.url_image : `${config.host}/uploads/logo.png`}
                                name={item.customer_support?.customer?.name as string}
                                message={decodeURI(item.message_customer as string)}
                                url_media={item.url_image_customer as string}
                                created_at={item.created_at as string}
                            /> : <CustomerSupportTrackingReceptor
                                key={"CustomerSupportTrackingViewSection1Receptor-" + index}
                                url_image={item.customer_support?.admin?.url_image ? item.customer_support?.admin?.url_image : `${config.host}/uploads/logo.png`}
                                name={item.customer_support?.admin?.name as string}
                                message={decodeURI(item.message_admin as string)}
                                url_media={item.url_image_customer as string}
                                created_at={item.created_at as string}
                            />
                    }}
                    keyExtractor={item => `customer_support_trackings_${item.id}`}
                />
            </div>
            {/*Input de message*/}
            <div
                className={"CustomerSupportTrackingViewSection1Footer"}
            >
                <input
                    id={`${TAG}-file`}
                    type="file"
                    accept="image/*"
                    style={{
                        display: 'none'
                    }}
                    onChange={readURL}
                />
                {isSelected && <div
                    className={"CustomerSupportTrackingViewSection1FooterFile"}
                >
                    <button
                        className={"CustomerSupportTrackingViewSection1FooterFileClose"}
                        onClick={() => clearSelectedFile()}
                    >
                        <FontAwesomeIcon
                            icon={faTimes}
                        />
                    </button>
                    <img
                        src={file.file}
                        alt={"imagen"}
                    />
                </div>}
                <input
                    className={"CustomerSupportTrackingViewSection1FooterInput"}
                    placeholder={"Escriba aqui..."}
                    value={fields.message}
                    onChange={(e) => changeMessage(e.target.value)}
                    onKeyPress={(e) => e.key === "Enter" && sendMessage()}
                />
                <button
                    className={"CustomerSupportTrackingViewSection1FooterButton"}
                    onClick={() => {
                        // @ts-ignore
                        document.getElementById(`${TAG}-file`).value = "";
                        // @ts-ignore
                        document.getElementById(`${TAG}-file`).click();
                    }}
                >
                    <FontAwesomeIcon
                        icon={faPaperclip}
                        color={'#fff'}
                    />
                </button>
                <button
                    className={"CustomerSupportTrackingViewSection1FooterButton"}
                    onClick={() => setIsEmoji(!is_emoji)}
                >
                    <FontAwesomeIcon
                        icon={faSmileWink}
                        color={'#fff'}
                    />
                </button>
                <button
                    className={"CustomerSupportTrackingViewSection1FooterButton"}
                    onClick={() => sendMessage()}
                >
                    <FontAwesomeIcon
                        icon={faPaperPlane}
                        color={'#fff'}
                    />
                </button>
                {is_emoji ?
                    <Picker
                        onSelect={addEmoji}
                        set='apple'
                        i18n={{
                            search: 'Buscar',
                            categories: {
                                search: 'Resultados de búsqueda', recent: 'Recientes',
                                people: 'Caras y Gente',
                                nature: 'Animales y Naturaleza',
                                foods: 'Comidas y Bebidas',
                                activity: 'Actividades',
                                places: 'Lugares y Viajes',
                                objects: 'Objetos',
                                symbols: 'Símbolos',
                                flags: 'Banderas',
                                custom: 'Custom',
                            }
                        }}
                        style={{
                            position: 'absolute',
                            bottom: 100,
                            right: 0,
                            zIndex: 10000
                        }}
                    /> : null}
            </div>
        </div>
    )
}
export default CustomerSupportTrackingViewSection1;
