import React, {FormEvent, useEffect, useRef, useState} from "react";
//theme
import "./styles.scss";
//api
import {CustomerAPI} from "../../../../../../../api/customer/customerAPI";
//models
import {Customer} from "../../../../../../../api/customer/customerModel";
//libs
import {CircularProgress} from "@material-ui/core";
//components
import TextInput from "../../../../../../_components/TextInput";
//types
import {Lang} from "../../../../../../../types";
import {SystemSnackbar} from "../../../../../../../store/actions/system.ac";

type Fields = {
    password_current: string;
    password_new: string;
    password_new_repeat: string;
};
type Errors = {
    password_current?: string;
    password_new?: string;
    password_new_repeat?: string;
};

//interfaces
interface Props {
    lang: Lang;
    customer: Customer;
    showSnackbar?: (system: SystemSnackbar) => void;
    hideSnackbar?: () => void;
    putCustomer?: (customer: Customer) => void;
}

//const
const TAG = `CustomerCompanyUpdatePasswordViewForm1`;
const isDev = (process.env.NODE_ENV !== "production");
//component
const CustomerCompanyUpdatePasswordViewForm1 = (props: Props) => {
    //refs
    const inputsRef = useRef<Array<any>>([]);
    //state
    const [customer, setCustomer] = useState<Customer>(props.customer);
    const [fields, setFields] = useState<Fields>({
        password_current: "",
        password_new: "",
        password_new_repeat: "",
    });
    const [errors, setErrors] = useState<Errors>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    //effects
    useEffect(() => {
        setCustomer(props.customer);
    }, [props.customer]);

    const validForm = (id: keyof Fields, value: string) => {
        switch (id) {
            case "password_current": {
                setFields((prevFields) => ({
                    ...prevFields,
                    password_current: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 7) {
                    inputsRef.current[1] && inputsRef.current[1]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password_current: props.lang === "es" ? "Mínimo 8 caracteres..." : props.lang === "en" ? "Minimum 8 characters" : "error",
                    }));
                    return false;
                } else if (String(customer.password) && String(customer.password) !== value) {
                    inputsRef.current[1] && inputsRef.current[1]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password_current: props.lang === "es" ? "Ingrese la contraseña actual..." : props.lang === "en" ? "Enter current password" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password_current: "",
                    }));
                    return true;
                }
            }
            case "password_new": {
                setFields((prevFields) => ({
                    ...prevFields,
                    password_new: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 7) {
                    inputsRef.current[2] && inputsRef.current[2]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password_new: props.lang === "es" ? "Mínimo 8 caracteres..." : props.lang === "en" ? "Minimum 8 characters" : "error",
                    }));
                    return false;
                } else if (fields.password_current.length && fields.password_current === value) {
                    inputsRef.current[2] && inputsRef.current[2]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password_new: props.lang === "es" ? "No puede ingresar la misma contraseña..." : props.lang === "en" ? "You cannot enter the same password..." : "error",
                    }));
                    return false;
                } else if (fields.password_new_repeat.length && fields.password_new_repeat !== value) {
                    inputsRef.current[2] && inputsRef.current[2]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password_new: props.lang === "es" ? "Las contraseñas no coinciden..." : props.lang === "en" ? "Passwords do not match" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password_new: "",
                    }));
                    return true;
                }
            }
            case "password_new_repeat": {
                setFields((prevFields) => ({
                    ...prevFields,
                    password_new_repeat: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 7) {
                    inputsRef.current[6] && inputsRef.current[6]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password_new_repeat: props.lang === "es" ? "Mínimo 8 caracteres..." : props.lang === "en" ? "Minimum 8 characters" : "error",
                    }));
                    return false;
                } else if (fields.password_new.length && fields.password_new !== value) {
                    inputsRef.current[6] && inputsRef.current[6]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password_new_repeat: props.lang === "es" ? "Las contraseñas no coinciden..." : props.lang === "en" ? "Passwords do not match" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password_new_repeat: "",
                    }));
                    return true;
                }
            }
            default : {
                return false
            }
        }
    }

    const save = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!customer || !customer.id) {
            props.showSnackbar && props.showSnackbar({
                message: "Vuelva a cargar la página...",
                severity: "error"
            });
            return;
        }
        let indexFields = 0;
        for (const field in fields) {
            if (!validForm(field as keyof Fields, Object.values(fields)[indexFields])) {
                return;
            }
            indexFields++;
        }
        setIsLoading(true);
        isDev && console.log(TAG + "//save", "Guardando...");
        await CustomerAPI.updatePassword({
            model: {
                password: fields.password_new,
                id: customer.id,
            }
        }, props.lang).then(async res_1 => {
            isDev && console.log(TAG + "//save", res_1.code, res_1.message, res_1.error);
            if (res_1.code === 200) {
                props.putCustomer && props.putCustomer({
                    password: fields.password_new
                });
                props.showSnackbar && props.showSnackbar({
                    message: res_1.message,
                    severity: "success"
                });
            } else {
                props.showSnackbar && props.showSnackbar({
                    message: res_1.message,
                    severity: "error"
                });
            }
        }).catch(async res_1 => {
            isDev && console.error(TAG + "//save", res_1.code, res_1.error, res_1);
            props.showSnackbar && props.showSnackbar({
                message: res_1.error ? res_1.error : JSON.stringify(res_1),
                severity: "error"
            });
        });
        setIsLoading(false);
    }

    return (
        <div
            className={"CustomerCompanyUpdatePasswordViewForm1"}
        >
            <form
                className={"CustomerCompanyUpdatePasswordViewForm1Form"}
                onSubmit={(e) => save(e)}
            >
                <section>
                    <TextInput
                        inputRef={inputsRef.current[0]}
                        label={props.lang === "es" ? "Contraseña actual:" : props.lang === "en" ? "Current password:" : ""}
                        placeholder={props.lang === "es" ? "Contraseña actual" : props.lang === "en" ? "Current password" : ""}
                        value={fields.password_current}
                        type={"password"}
                        onChangeText={(text) => validForm("password_current", text)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                inputsRef.current[1] && inputsRef.current[1].focus();
                            }
                        }}
                        error={errors?.password_current}
                    />
                    <TextInput
                        inputRef={inputsRef.current[0]}
                        label={props.lang === "es" ? "Contraseña nueva:" : props.lang === "en" ? "New password:" : ""}
                        placeholder={props.lang === "es" ? "Contraseña nueva" : props.lang === "en" ? "New password" : ""}
                        value={fields.password_new}
                        type={"password"}
                        onChangeText={(text) => validForm("password_new", text)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                inputsRef.current[1] && inputsRef.current[1].focus();
                            }
                        }}
                        error={errors?.password_new}
                    />
                    <TextInput
                        inputRef={inputsRef.current[0]}
                        label={props.lang === "es" ? "Repita la contraseña:" : props.lang === "en" ? "Repeat password:" : ""}
                        placeholder={props.lang === "es" ? "Repita la contraseña" : props.lang === "en" ? "Repeat password" : ""}
                        value={fields.password_new_repeat}
                        type={"password"}
                        onChangeText={(text) => validForm("password_new_repeat", text)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                inputsRef.current[1] && inputsRef.current[1].focus();
                            }
                        }}
                        error={errors?.password_new_repeat}
                    />
                </section>
                <div
                    className={"CustomerCompanyUpdatePasswordViewForm1FormContentBtn"}
                >
                    <button
                        className={"CustomerCompanyUpdatePasswordViewForm1FormBtn"}
                        type={"submit"}
                        disabled={isLoading}
                    >
                        {!isLoading && props.lang === "es" && "Guardar"}
                        {!isLoading && props.lang === "en" && "Save"}

                        {isLoading && <CircularProgress
                            color={"inherit"}
                            size={20}
                        />}
                    </button>
                </div>
            </form>
        </div>
    )
}
export default CustomerCompanyUpdatePasswordViewForm1;
