import React, {useEffect} from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexIndexAppAdminSection1Section1 = (props: Props, ref: any) => {

    return (
        <div
            ref={ref}
            className={"IndexAppAdminSection1Content"}
        >
            <div
                className={"IndexAppAdminSection1 js-scroll slide-left"}
            >
                <div
                    className={"IndexAppAdminSection1Img"}
                >
                    <img
                        src={require("../../../../../assets/img/admin_1.png").default}
                        alt={"dvgeo_admin_1"}
                    />
                </div>
                <section
                    className={"IndexAppAdminSection1Sec"}
                >
                    {props.lang === "es" && <h1>
                        <strong>Administra y Monitorea</strong> tu oficina online
                    </h1>}
                    {props.lang === "en" && <h1>
                        <strong>Monitor</strong> the entire flow of your business
                    </h1>}
                    {props.lang === "es" && <p>
                        <strong>Administra</strong> tu empresa online con nuestros módulos esenciales, despacho para
                        call
                        center y funciones de rastreo satelital de las rutas de tus vehículos.
                    </p>}
                    {props.lang === "en" && <p>
                        <strong>Manage</strong> your company online with our essential modules, dispatch for call center
                        and
                        satellite tracking functions of the routes of your vehicles.
                    </p>}
                    {props.lang === "es" && <p>
                        <strong>¡Desplázate hacia abajo</strong> para aprender más!
                    </p>}
                    {props.lang === "en" && <p>
                        <strong>Scroll down</strong> to learn more!
                    </p>}
                </section>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexIndexAppAdminSection1Section1);
