import React, {useEffect, useRef, useState} from "react";
//theme
import "./view.scss";
//store
import {CustomerSupportAPI} from "../../../../../api/customer_support/customerSupportAPI";
//models
import {Customer} from "../../../../../api/customer/customerModel";
import {CustomerSupport} from "../../../../../api/customer_support/customerSupportModel";
//libs
import {useHistory, useParams} from "react-router-dom";
//components
import NavBarTop from "../../../../_components/NavBarTop";
import CustomerNavBarMenu from "../../../_components/CustomerNavBarMenu";
import SnackBar from "../../../../_components/SnackBar";
import Footer from "../../../../_components/Footer";
import CustomerSupportTrackingUpdateViewBanner1 from "./banner_1";
import CustomerSupportUpdateForm1 from "./form_1";
//types
import {ErrorException, Lang} from "../../../../../types";
import {SystemSnackbar} from "../../../../../store/actions/system.ac";
import {CustomerSupportUpdateStackParamList} from "../types";

//interfaces
interface Props {
    lang: Lang;
    customer: Customer;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
    deleteCustomer?: () => void;
}

//const
const TAG = `CustomerSupportTrackingUpdateView`;
const isDev = (process.env.NODE_ENV !== "production");
//view
const CustomerSupportTrackingUpdateView = (props: Props) => {
    //hooks
    const history = useHistory();
    const params = useParams<CustomerSupportUpdateStackParamList>();
    //refs
    const CustomerSupportTrackingUpdateViewRef = useRef<any>(null);
    //state
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [customer_support, setCustomerSupport] = useState<CustomerSupport>({});
    //effects
    useEffect(() => {
        getData();
        return () => {

        }
    }, []);

    const getData = async () => {
        try {
            if (!params.customer_support_id) {
                history.goBack();
                return;
            }
            await getCustomerSupportTracking(Number(params.customer_support_id));
        } catch (error: ErrorException | any) {
            isDev && console.error(TAG + "//getData", error);
        }
    }

    const getCustomerSupportTracking = async (customer_support_id: number) => {
        setIsLoading(true);
        isDev && console.log(TAG + "//getCustomerSupportTracking", "Cargando documentos del cliente...");
        await CustomerSupportAPI.getById(customer_support_id, "es").then(async res_1 => {
                isDev && console.log(TAG + "//getCustomerSupportTracking", res_1.code, res_1.message, res_1.error);
                if (res_1.code === 200) {
                    setCustomerSupport(res_1.customer_support as CustomerSupport);
                } else if (res_1.code === 409) {

                } else {
                    props.showSnackbar && props.showSnackbar({
                        message: res_1.message,
                        severity: "warning"
                    });
                }
            }
        ).catch(async res_1 => {
            isDev && console.error(TAG + "//getCustomerSupportTracking", res_1.code, res_1.error, res_1);
            props.showSnackbar && props.showSnackbar({
                message: res_1.error ? res_1.error : JSON.stringify(res_1.error),
                severity: "warning"
            });
        });
        setIsLoading(false);
    }

    return (
        <div
            ref={CustomerSupportTrackingUpdateViewRef}
            className={"CustomerSupportTrackingUpdateView"}
        >
            <NavBarTop
                lang={props.lang}
                customer={props.customer}
                deleteCustomer={props.deleteCustomer}
            />
            <CustomerNavBarMenu
                lang={props.lang}
            />
            <CustomerSupportTrackingUpdateViewBanner1
                lang={props.lang}
            />
            <CustomerSupportUpdateForm1
                lang={props.lang}
                isLoading={isLoading}
                customer_support={customer_support}
                showSnackbar={props.showSnackbar}
                hideSnackbar={props.hideSnackbar}
            />
            <Footer
                lang={props.lang}
                customer={props.customer}
            />
            <SnackBar/>
        </div>
    )
}
export default CustomerSupportTrackingUpdateView;
