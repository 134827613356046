import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppDriverSection8 = (props: Props, ref: any) => {

    return (
        <div
            ref={ref}
            className={"IndexAppDriverSection8Content"}
        >
            <div
                className={"IndexAppDriverSection8 js-scroll slide-right"}
            >
                <section
                    className={"IndexAppDriverSection8Sec"}
                >
                    {props.lang === "es" && <h1>
                        Realiza <strong>recargas de saldo</strong>
                    </h1>}
                    {props.lang === "en" && <h1>
                        Make a <strong>balance top-up</strong>
                    </h1>}
                    {props.lang === "es" && <p>
                        Cuando el saldo de tu cuenta llegue a cero <strong>necesitarás más saldo</strong> para seguir brindando el servicio
                    </p>}
                    {props.lang === "en" && <p>
                        When the account balance reaches zero <strong>you will need more balance</strong> to continue providing the service
                    </p>}
                </section>
                <div
                    className={"IndexAppDriverSection8Img"}
                >
                    <img
                        src={require("../../../../../assets/img/driver_10.png").default}
                        alt={"dvgeo_driver_10"}
                    />
                </div>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexAppDriverSection8);
