import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexAppAdminChannelSection3 = (props: Props) => {
    //state
    const [indexGroup, setIndexGroup] = useState<Array<boolean>>([false, false, false, false, false, false, false]);

    const setIndexGroupFocus = (index: number) => {
        let indexGroupNew: Array<boolean> = [...indexGroup];
        for (let i = 0; i < indexGroupNew.length; i++) {
            if (i === index) {
                indexGroupNew[i] = !indexGroupNew[i];
            } else {
                indexGroupNew[i] = false;
            }
        }
        setIndexGroup(indexGroupNew);
    }

    return (
        <div
            className={"IndexAppAdminChannelSection3"}
        >
            <div
                className={"IndexAppAdminChannelSection3Title"}
            >
                {props.lang === "es" && <h1
                >
                    Preguntas Frecuentes
                </h1>}
                {props.lang === "en" && <h1
                >
                    FAQ
                </h1>}
            </div>
            <section
                className={"IndexAppAdminChannelSection3Sec"}
            >
                <button
                    className={"IndexAppAdminChannelSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(0)}
                >
                    {props.lang === "es" && <h2>
                        ¿Se puede asignar varios conductores en diferentes canales?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can you assign multiple conductors on different channels?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[0] ? "IndexAppAdminChannelSection3SecGroupFocus" : "IndexAppAdminChannelSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Los conductores pueden ser asignados en diferentes canales aunque ya exista en otro canal.
                    </p>}
                    {props.lang === "en" && <p>
                        Drivers can be assigned on different channels even if it already exists on another channel.
                    </p>}
                </div>
                <button
                    className={"IndexAppAdminChannelSection3SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(1)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo ver el nombre del conductor en el mapa?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I see the driver's name on the map?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                <div
                    className={indexGroup[1] ? "IndexAppAdminChannelSection3SecGroupFocus" : "IndexAppAdminChannelSection3SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Al presionar en el ícono del vehículo se puede ver la información del conductor o al pasar el cursor encima también reflejará la información relevante.
                    </p>}
                    {props.lang === "en" && <p>
                        By pressing on the vehicle icon you can see the driver information or hovering over it will also reflect the relevant information.
                    </p>}
                </div>

            </section>
        </div>
    )
}
export default IndexAppAdminChannelSection3;
