import React, {FormEvent, useRef, useState} from "react";
//theme
import "./styles.scss";
//api
import {CustomerAPI} from "../../../../../api/customer/customerAPI";
//models
import {Customer} from "../../../../../api/customer/customerModel";
//libs
import valid from "../../../../../libs/valid";
import utils from "../../../../../libs/utils";
import {useHistory, useLocation, Link} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
//components
import SnackBar from "../../../../_components/SnackBar";
//types
import {Lang} from "../../../../../types";
import {SystemSnackbar} from "../../../../../store/actions/system.ac";

type Fields = {
    email: string;
}
type Errors = {
    email?: string;
}

//interfaces
interface Props {
    lang: Lang;
    putCustomer?: (customer: Customer) => void;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
    postToken?: (token: string) => void;
}

//const
const TAG = `CustomerRecoveryForm1`;
const isDev = (process.env.NODE_ENV !== "production");
//component
const CustomerRecoveryForm1 = (props: Props) => {
    //hooks
    const history = useHistory();
    const location = useLocation();
    //refs
    const inputs = useRef<Array<HTMLInputElement | null>>([]);
    //state
    const [fields, setFields] = useState<Fields>({
        email: "",
    });
    const [errors, setErrors] = useState<Errors>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowModalResult, setIsShowModalResult] = useState<boolean>(false);

    const validForm = (id: keyof Fields, value: string) => {
        switch (id) {
            case "email": {
                setFields((prevFields) => ({
                    ...prevFields,
                    email: value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 2) {
                    inputs.current[2] && inputs.current[2]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email: props.lang === "es" ? "Ingrese una dirección de correo electrónico..." : props.lang === "en" ? "Enter an email address..." : "error",
                    }));
                    return false;
                } else if (!valid.isValidEmailAddress(value)) {
                    inputs.current[2] && inputs.current[2]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email: props.lang === "es" ? "Ingrese una dirección de correo electrónico válida..." : props.lang === "en" ? "Please enter a valid email address" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email: "",
                    }));
                    return true;
                }
            }
            default : {
                return false
            }
        }
    }

    const recovery = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let indexFields = 0;
        for (const field in fields) {
            if (!validForm(field as keyof Fields, Object.values(fields)[indexFields])) {
                return;
            }
            indexFields++;
        }
        setIsLoading(true);
        isDev && console.log(TAG + "//recovery", "Iniciando...");
        await CustomerAPI.resetPassword({
            model: {
                email: fields.email.toLowerCase(),
                password: utils.generatePassword(8),
            }
        }, props.lang).then(async res_1 => {
            isDev && console.log(TAG + "//recovery", res_1.code, res_1.message, res_1.error);
            if (res_1.code === 200) {
                props.showSnackbar && props.showSnackbar({
                    message: `Revisa tu correo ${fields.email}, enviamos una contraseña temporal. Puede estar en bandeja de entrada, spam o correo no deseado`,
                    severity: "success"
                });
                history.push(`/${props.lang}/application/customer/login`)
            } else {
                setIsShowModalResult(true);
                props.showSnackbar && props.showSnackbar({
                    message: res_1.message,
                    severity: "warning"
                });
            }
        }).catch(res_1 => {
            isDev && console.error(TAG + "//recovery", res_1.code, res_1.error, res_1);
            setIsShowModalResult(true);
            props.showSnackbar && props.showSnackbar({
                message: res_1.message,
                severity: "warning"
            });
        })
        setIsLoading(false);
    }

    return (
        <div
            className={"CustomerRecoveryForm1"}
        >
            <form
                className={"CustomerRecoveryForm1GroupForm"}
                onSubmit={(e) => recovery(e)}
            >
                <Link
                    to={`/${props.lang}`}
                >
                    <img
                        className={"CustomerRecoveryForm1GroupFormImg"}
                        src={require("../../../../../assets/img/logo.png").default}
                        alt={"logo"}
                    />
                </Link>
                <h1
                    className={"CustomerRecoveryForm1Title"}
                >
                    {props.lang === "es" && "Recuperar Cuenta"}
                    {props.lang === "en" && "Recover account"}
                </h1>
                <p
                    className={"CustomerRecoveryForm1Subtitle"}
                >
                    {props.lang === "es" && "Ingresa el email de tu cuenta"}
                    {props.lang === "en" && "Enter your account email"}
                </p>
                <div
                    className={"CustomerRecoveryForm1GroupFormIpt"}
                >
                    <input
                        ref={(ele) => inputs.current[1] = ele}
                        style={errors?.email?.length ? {
                            border: "1px solid darkred",
                        } : {}}
                        type={"email"}
                        placeholder={props.lang === "es" ? "Correo electrónico" : props.lang === "en" ? "Email" : ""}
                        value={fields.email}
                        onChange={e => validForm("email", e.target.value)}
                    />
                    {(errors?.email?.length) ? <span
                        className={"CustomerRecoveryForm1GroupFormIptError"}
                    >
                            {errors.email}
                        </span> : null}
                </div>
                <button
                    className={"CustomerRecoveryForm1GroupFormBtn"}
                    type={"submit"}
                    disabled={isLoading}
                >
                    {!isLoading && props.lang === "es" && "Recuperar"}
                    {!isLoading && props.lang === "en" && "Recover"}
                    {isLoading && <CircularProgress
                        color={"inherit"}
                        size={20}
                    />}
                </button>
            </form>
            <SnackBar/>
        </div>
    )
}
export default CustomerRecoveryForm1;
