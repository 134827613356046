import React, {CSSProperties, HTMLInputTypeAttribute} from "react";
//theme
import "./TextInputAutocomplete.scss";
import {FlatList, ListRenderItem} from "react-native";
//libs
//types
//interfaces
interface Props {
    inputRef?: React.RefObject<any>;
    style?: CSSProperties;
    fullWidth?: boolean;
    placeholder?: string;
    type?: HTMLInputTypeAttribute | undefined;
    label?: string;
    error?: string;
    value: string;
    contentRight?: any;
    data?: Array<any>;
    renderItem?: ListRenderItem<any>;
    showRenderItem?: boolean;
    readOnly?: boolean;
    size?: "small" | "normal" | "full";
    onChangeText?: (text: string) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

//component
const TextInputAutocomplete: React.FC<Props> = (props: Props) => {
    return (
        <div
            className={"TextInputAutocompleteStack"}
        >
            <div
                style={{
                    ...props.style,
                    width: props.fullWidth ? "100%" : props.style?.width ? props.style?.width : "auto",
                }}
                className={"TextInputAutocompleteContent"}
            >
                {props.label && <div
                    className={"TextInputAutocompleteContentLabel"}
                >
                    {props.label}
                </div>}
                <input
                    ref={props.inputRef && props.inputRef}
                    className={props.error ? `TextInputAutocompleteError ${props.size && props.size === "small" ? "TextInputAutocompleteContentSmall" : props.size === "normal" ? "TextInputAutocompleteContentNormal" : props.size === "full" ? "TextInputAutocompleteContentFull" : ""}` : `TextInputAutocomplete ${props.size && props.size === "small" ? "TextInputAutocompleteContentSmall" : props.size === "normal" ? "TextInputAutocompleteContentNormal" : props.size === "full" ? "TextInputAutocompleteContentFull" : ""}`}
                    placeholder={props.placeholder && props.placeholder}
                    type={props.type}
                    value={props.value}
                    readOnly={(props.readOnly)}
                    onChange={(e) => props.onChangeText && props.onChangeText(e.target.value)}
                    onKeyPress={(e) => props.onKeyPress && props.onKeyPress(e)}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                />
                {!props.showRenderItem && props.error && <div
                    className={"TextInputAutocompleteErrorContent"}
                >
                    {props.error}
                </div>}
                {props.contentRight && <div
                    className={"TextInputAutocompleteContentRight"}
                >
                    {props.contentRight}
                </div>}
            </div>
            {(props.showRenderItem && props.data && props.data.length) ? <TextInputAutocompleteRender
                className={`TextInputAutocompleteRender ${props.size && props.size === "small" ? "TextInputAutocompleteRenderSmall" : props.size === "normal" ? "TextInputAutocompleteRenderNormal" : props.size === "full" ? "TextInputAutocompleteRenderFull" : ""}`}
            >
                <FlatList
                    data={props.data}
                    renderItem={props.renderItem}
                    keyExtractor={item => `TextInputAutocomplete_${item.id ? item.id : 1 + Math.random() * (99)}`}
                />
            </TextInputAutocompleteRender> : null}
        </div>
    )
}
TextInputAutocomplete.defaultProps = {
    size: "normal"
}
export const TextInputAutocompleteRender = (props: {
    containerRef?: React.RefObject<any>; className?: string; children?: any
}) => {
    return (
        <div
            ref={props.containerRef}
            className={props.className}
        >
            {props.children && <div>
                {props.children}
            </div>}
        </div>
    )
}

export const TextInputAutocompleteItem = (props: {
    children?: any; focus?: boolean; onSelect?: () => void;
}) => {
    return (
        <div
            className={props.focus ? "TextInputAutocompleteRenderItemFocus" : "TextInputAutocompleteRenderItem"}
            onClick={() => props.onSelect && props.onSelect()}
        >
            {props.children && <div>
                {props.children}
            </div>}
        </div>
    )
}

export default TextInputAutocomplete;
