import React, {useEffect, useRef, useState} from "react";
//theme
import {Platform} from "react-native";
//api
import {CustomerSupportPriorityAPI} from "../../../../api/customer_support_priority/customerSupportPriorityAPI";
//models
import {CustomerSupportPriority} from "../../../../api/customer_support_priority/customerSupportPriorityModel";
//libs
import ReactDOM from "react-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
//views
import TextInputAutocomplete, {TextInputAutocompleteItem} from "../../../_components/TextInputAutocomplete";
//types
import {ErrorException, Lang} from "../../../../types";

//interfaces
interface Props {
    lang: Lang;
    selectorRef?: React.RefObject<any> | undefined;
    label?: string;
    limit: string;
    defaultValue?: string;
    error?: string;
    size?: "small" | "normal" | "full";
    onSelectedValue?: (customer_support_priority: CustomerSupportPriority) => void;
    onGetData?: (customer_support_prioritys: Array<CustomerSupportPriority>) => void;
}

//const
const TAG = `${Platform.OS}//CustomerSupportPrioritySelector`;
const isDev = (process.env.NODE_ENV !== "production");
//component
const CustomerSupportPrioritySelector = (props: Props, ref: any) => {
    //refs
    const componentRef = useRef<any>(null);
    //state
    const [customer_support_prioritys, setCustomerSupportPrioritys] = useState<Array<CustomerSupportPriority>>([]);
    const [customer_support_priority, setCustomerSupportPriority] = useState<CustomerSupportPriority>({});
    const [fields, setFields] = useState<{ param: string }>({
        param: ""
    });
    const [errors, setErrors] = useState<{ param: string }>({
        param: ""
    });
    const [show, setShow] = useState<boolean>(false);
    const [index_selected, setIndexSelected] = useState<number>(0);
    const [service_id, setServiceId] = useState<number>(0);
    //effects
    useEffect(() => {
        //componentDidMount
        isDev && console.log(TAG + "//getCustomerSupportPrioritys", "componentDidMount");
        getData();
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            //componentWillUnmount
            isDev && console.log(TAG + "//getCustomerSupportPrioritys", "componentWillUnmount");
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, []);
    useEffect(() => {
        getData();
    }, [props.limit]);

    const getData = async () => {
        try {
            await getCustomerSupportPrioritys();
        } catch (error: ErrorException | any) {
            isDev && console.error(TAG + "//getData", error);
        }
    }

    const handleClickOutside = (event: MouseEvent) => {
        const domNode = ReactDOM.findDOMNode(componentRef.current);
        if (!domNode || !domNode.contains(event.target as Node)) {
            setShow(false);
        }
    }

    const _onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>, data: Array<CustomerSupportPriority>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            // @ts-ignore
            e.target.blur();
            setShow(false);
            props.onSelectedValue && props.onSelectedValue(customer_support_priority);
        }
        if (e.key === "ArrowUp" && index_selected) {
            let item: CustomerSupportPriority = {};
            if (index_selected === 0 && !customer_support_priority) {
                item = data[0]
                setIndexSelected(0);
            } else if (index_selected >= 0 && index_selected < data.length) {
                item = data[index_selected - 1];
                setIndexSelected(index_selected - 1);
            } else {
                item = data[0]
                setIndexSelected(0);
            }
            setCustomerSupportPriority(item);
        }
        if (e.key === "ArrowDown") {
            let item: CustomerSupportPriority = {};
            if (index_selected === 0 && !customer_support_priority) {
                item = data[0]
                setIndexSelected(0);
            } else if (index_selected >= 0 && index_selected < data.length - 1) {
                item = data[index_selected + 1];
                setIndexSelected(index_selected + 1);
            } else {
                item = data[0]
                setIndexSelected(0);
            }
            setCustomerSupportPriority(item);
        }
    }

    const getCustomerSupportPrioritys = async () => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            param: "",
        }));
        isDev && console.log(TAG + "//getCustomerSupportPrioritys", "Obteniendo las prioridades...");
        await CustomerSupportPriorityAPI.getByAll(props.lang).then(async res => {
            isDev && console.log(TAG + "//getCustomerSupportPrioritys", res.code, res.message, res.error);
            if (res.code === 200) {
                setCustomerSupportPrioritys(res.customer_support_prioritys as Array<CustomerSupportPriority>);
                props.onGetData && props.onGetData(res.customer_support_prioritys as Array<CustomerSupportPriority>);
            } else {
                setCustomerSupportPrioritys([]);
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    param: res.message,
                }));
            }
        }).catch(async res => {
            isDev && console.error(TAG + "//getCustomerSupportPrioritys", res.code, res.error, res);
            setCustomerSupportPrioritys([]);
            setErrors((prevErrors) => ({
                ...prevErrors,
                param: res.error ? res.error : JSON.stringify(res),
            }));
        });
    }

    const getCustomerSupportPrioritysBySearch = async (param: string) => {
        setFields((prevFields) => ({
            ...prevFields,
            param,
        }));
        if (!param) {
            getCustomerSupportPrioritys();
            return;
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            param: "",
        }));
        isDev && console.log(TAG + "//getCustomerSupportPrioritysBySearch", "Obteniendo las prioridades...");
        await CustomerSupportPriorityAPI.getBySearch(param, props.lang).then(async res => {
            isDev && console.log(TAG + "//getCustomerSupportPrioritysBySearch", res.code, res.message, res.error);
            if (res.code === 200) {
                setCustomerSupportPrioritys(res.customer_support_prioritys as Array<CustomerSupportPriority>);
                props.onGetData && props.onGetData(res.customer_support_prioritys as Array<CustomerSupportPriority>);
            } else {
                setCustomerSupportPrioritys([]);
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    param: res.message,
                }));
            }
        }).catch(async res => {
            isDev && console.error(TAG + "//getCustomerSupportPrioritysBySearch", res.code, res.error, res);
            setCustomerSupportPrioritys([]);
            setErrors((prevErrors) => ({
                ...prevErrors,
                param: res.error ? res.error : JSON.stringify(res),
            }));
        });
    }

    const getDefaultValue = (id: number): CustomerSupportPriority => {
        return customer_support_prioritys.filter((element) => element.id === id)[0];
    }

    return (
        <div
            ref={componentRef}
            onKeyDown={(e) => _onKeyPress(e, customer_support_prioritys)}
        >
            <TextInputAutocomplete
                inputRef={props.selectorRef}
                label={props.label ? props.label : "Prioridad:"}
                placeholder={getDefaultValue(Number(props.defaultValue))?.priority ? getDefaultValue(Number(props.defaultValue))?.priority : customer_support_priority.priority ? customer_support_priority.priority : "Seleccione la prioridad..."}
                value={fields.param}
                size={props.size}
                data={customer_support_prioritys}
                renderItem={({item, index}) => {
                    const customer_support_priority_: CustomerSupportPriority = item;
                    return (
                        <TextInputAutocompleteItem
                            key={`${TAG}customer_support_priority_${customer_support_priority_.id}_${index}`}
                            focus={(index_selected === index)}
                            onSelect={async () => {
                                setIndexSelected(index);
                                setCustomerSupportPriority(customer_support_priority_);
                                setShow(false);
                                setFields(() => ({
                                    param: ""
                                }));
                                props.onSelectedValue && props.onSelectedValue(customer_support_priority_);
                                await getCustomerSupportPrioritys();
                            }}
                        >
                            <div
                                style={{
                                    display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer"
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    size={"1x"}
                                    color={customer_support_priority_.id === 3 ? "green" : customer_support_priority_.id === 2 ? "orange" : customer_support_priority_.id === 1 ? "red" : "black"}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        paddingLeft: 10,
                                        flex: 1,
                                        cursor: "pointer"
                                    }}
                                >
                                    <label
                                        style={{
                                            fontWeight: "bold",
                                            maxWidth: "80%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            cursor: "pointer"
                                        }}
                                    >
                                        {customer_support_priority_.priority}
                                    </label>
                                </div>
                            </div>
                        </TextInputAutocompleteItem>
                    )
                }}
                showRenderItem={show}
                onChangeText={(text) => getCustomerSupportPrioritysBySearch(text)}
                onKeyPress={(e) => {
                    // @ts-ignore
                    if (e.key === "Enter") e.target.blur();
                }}
                onFocus={() => {
                    setShow(true);
                }}
                onBlur={() => {
                    //setShow(false);
                }}
                error={props.error ? props.error : errors?.param}
            />
        </div>
    )
}

export default React.forwardRef(CustomerSupportPrioritySelector);
