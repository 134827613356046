import React, {useState} from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexPriceSection2 = (props: Props) => {
    //state
    const [indexGroup, setIndexGroup] = useState<Array<boolean>>([false, false, false, false, false, false, false, false, false, false]);

    const setIndexGroupFocus = (index: number) => {
        let indexGroupNew: Array<boolean> = [...indexGroup];
        for (let i = 0; i < indexGroupNew.length; i++) {
            if (i === index) {
                indexGroupNew[i] = !indexGroupNew[i];
            } else {
                indexGroupNew[i] = false;
            }
        }
        setIndexGroup(indexGroupNew);
    }

    return (
        <div
            className={"IndexPriceSection2"}
        >
            <div
                className={"IndexPriceSection2Title"}
            >
                {props.lang === "es" && <h1
                >
                    ¿Más preguntas?, te responderemos
                </h1>}
                {props.lang === "en" && <h1
                >
                    More questions?, we will answer you
                </h1>}
            </div>
            <section
                className={"IndexPriceSection2Sec"}
            >
                <button
                    className={"IndexPriceSection2SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(0)}
                >
                    {props.lang === "es" && <h2>
                        ¿Ustedes crean el logo para mis apps?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Do you create the logo for my apps?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[0] && <div
                    className={"IndexPriceSection2SecGroup"}
                >
                    {props.lang === "es" && <p>
                        No, usted debe tener el logotipo de su empresa listo, nosotros solo adaptamos el logo al ícono
                        para las app. Recuerde que los aplicativos Pasajero, Conductor y Administrador tendrán su propio nombre y logotipo.
                    </p>}
                    {props.lang === "en" && <p>
                        No, you must have your company logo ready, we only adapt the logo to the icon for the app.
                    </p>}
                </div>}
                <button
                    className={"IndexPriceSection2SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(1)}
                >
                    {props.lang === "es" && <h2>
                        ¿Cuál de los dos valores debo pagar: Suscripción inicial o Manteniminento?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Which of the two values should I pay: Initial subscription or Maintenance?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[1] && <div
                    className={"IndexPriceSection2SecGroup"}
                >
                    {props.lang === "es" && <p>
                        El pago de los valores es en orden, para iniciar el proceso de empaquetado primero se paga el
                        50% de la suscripción inicial y luego de 4 días se paga el restante de la suscripción inicial.
                        El pago del mantenimiento mensual se genera luego de 30 días.
                    </p>}
                    {props.lang === "en" && <p>
                        The payment of the values is in order, to start the packaging process, first 50% of the initial
                        subscription is paid and after 4 days the remainder of the initial subscription is paid. The
                        monthly maintenance payment is generated after 30 days.
                    </p>}
                </div>}
                <button
                    className={"IndexPriceSection2SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(2)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo diferir en cuotas el pago de suscripción inicial?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I defer the initial subscription payment in installments?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[2] && <div
                    className={"IndexPriceSection2SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Se debe pagar el 50% de entrada para iniciar el proceso y le podemos esperar hasta 30 días
                        después el 50% restante.
                    </p>}
                    {props.lang === "en" && <p>
                        You must pay 50% of entry to start the process and we can wait until 30 days later for the
                        remaining 50%.
                    </p>}
                </div>}
                <button
                    className={"IndexPriceSection2SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(3)}
                >
                    {props.lang === "es" && <h2>
                        ¿Puedo pagar los USD 100 mensuales fijos hasta que tenga resultados?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Can I pay the fixed monthly USD 100 until I have results?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[3] && <div
                    className={"IndexPriceSection2SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Nosotros cobramos el valor del mantenimiento mensual para mantener activa la app en servidores
                        cloud escalables. <strong>Este costo se cobra por cada 10,000 viajes mensuales no
                        acomulables</strong>. Por ejemplo, si su empresa hizo:
                        <ul>
                            <li>
                                100 viajes en el mes de enero, se le cobra USD 100.
                            </li>
                            <li>
                                0 viajes en el mes de febrero, se le cobra USD 100.
                            </li>
                            <li>
                                20,000 viajes en el mes de marzo, se le cobra USD 200.
                            </li>
                            <li>
                                y así sucesivamente...
                            </li>
                        </ul>
                        El valor de <strong>mantenimiento mensual cubre desde 0 hasta 10,000</strong>, mayor a eso se
                        aumenta USD 100 por cada 10,000 viajes.
                        <br/>Si su empresa no tiene los resultados que usted espera, no va a tener problemas en pagar
                        únicamente USD 100 por tarifa base porque no superará dicho total de viajes mensuales.
                    </p>}
                    {props.lang === "en" && <p>
                        We charge the value of the monthly maintenance to keep the app active on servers
                        Scalable cloud. <strong> This cost is charged for every 10,000 monthly trips not
                        stackable </strong>. For example, if your company did:
                        <ul>
                            <li>
                                100 trips in the month of January, you are charged USD 100.
                            </li>
                            <li>
                                0 trips in the month of February, you are charged USD 100.
                            </li>
                            <li>
                                20,000 trips in the month of March, you are charged USD 200.
                            </li>
                            <li>
                                and so on...
                            </li>
                        </ul>
                        The value of <strong> monthly maintenance covers from 0 to 10,000 </strong>, higher than that is
                        increases $ 100 for every 10,000 trips.
                        <br/> If your company does not have the results you expect, you will have no problem paying
                        only USD 100 per base rate because it will not exceed said total of monthly trips.
                    </p>}
                </div>}
                <button
                    className={"IndexPriceSection2SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(4)}
                >
                    {props.lang === "es" && <h2>
                        ¿Ustedes crean la publicidad para mi app?, ¿Me búscan los usuarios para los viajes mensuales que necesito?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Do you create advertising for my app? Are users looking for me for the monthly trips I need?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[4] && <div
                    className={"IndexPriceSection2SecGroup"}
                >
                    {props.lang === "es" && <p>
                        ¡No!, no creamos publicidad para nuestros clientes, tampoco brindamos viajes o buscamos usuarios para nuestros clientes. Cada empresa que adquiere la plataforma DvGeo es responsable de toda la inversión necesaria para tener los resultados esperados. Nosotros solo brindamos la plataforma completa DvGeo como herramienta para su empresa.
                    </p>}
                    {props.lang === "en" && <p>
                        No, we do not create advertising for our clients, nor do we provide trips or search for users for our clients. Each company that acquires the DvGeo platform is responsible for all the investment necessary to have the expected results. We only provide the full DvGeo platform as a tool for your business.
                    </p>}
                </div>}
                <button
                    className={"IndexPriceSection2SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(5)}
                >
                    {props.lang === "es" && <h2>
                        ¿La suscripción inicial se renueva anualmente?
                    </h2>}
                    {props.lang === "en" && <h2>
                        Is the initial subscription renewed annually?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[5] && <div
                    className={"IndexPriceSection2SecGroup"}
                >
                    {props.lang === "es" && <p>
                        ¡No!, solo pagas una sola vez al inicio, este valor nos permite tener las 3 apps con el logotipo de su empresa.
                    </p>}
                    {props.lang === "en" && <p>
                        No, you only pay once at the beginning, this value allows you to have the 3 apps with your company logo.
                    </p>}
                </div>}
                <button
                    className={"IndexPriceSection2SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(6)}
                >
                    {props.lang === "es" && <h2>
                        ¿Qué sucede si no pago el mantenimiento mensual?
                    </h2>}
                    {props.lang === "en" && <h2>
                        What happens if I don't pay the monthly maintenance?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[6] && <div
                    className={"IndexPriceSection2SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Cuando no se paga la mensualidad la plataforma con la marca de su empresa entra en estado de suspensión hasta que pague la factura pendiente. Si paga la factura atrasada días antes de que se genere otra, tendrá que pagar ambas facturas por separado.
                    </p>}
                    {props.lang === "en" && <p>
                        When the monthly payment is not paid, the platform with the brand of your company goes into a state of suspension until you pay the pending invoice. If you pay the past due invoice days before another is generated, you will have to pay both invoices separately.
                    </p>}
                </div>}
                <button
                    className={"IndexPriceSection2SecGroupHeader"}
                    onClick={() => setIndexGroupFocus(7)}
                >
                    {props.lang === "es" && <h2>
                        ¿Cuándo emiten las facturas de mantenimiento mensual?
                    </h2>}
                    {props.lang === "en" && <h2>
                        When are monthly maintenance invoices issued?
                    </h2>}
                    <span>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                        />
                    </span>
                </button>
                {indexGroup[7] && <div
                    className={"IndexPriceSection2SecGroup"}
                >
                    {props.lang === "es" && <p>
                        Se emite el día 30 del mes actual con máximo a pagar hasta el 4 del mes entrante.
                    </p>}
                    {props.lang === "en" && <p>
                        It is issued on the 30th of the current month with a maximum to be paid until the 4th of the next month.
                    </p>}
                </div>}
            </section>
        </div>
    )
}
export default IndexPriceSection2;
