import React, {useEffect, useRef, useState} from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminCommerceSection2 = (props: Props) => {
    //refs
    const iTimer = useRef<NodeJS.Timeout | number>(0);
    const indexAppAdminServiceSection1DesktopSnapContentRef = useRef<any>(null);
    const items = useRef<Array<any>>([]);
    //state
    const [index, setIndex] = useState<number>(0);
    //effects
    useEffect(() => {
        init();
        return () => {
            clearIntervals();
        }
    }, []);

    const init = () => {
        try {
            iIndexTranslateX();
        } catch (error: any) {

        }
    }


    const iIndexTranslateX = () => {
        setIndex(0);
        setTranslateXValid(0);
        clearInterval(iTimer.current as NodeJS.Timeout);
        let indexCurrent = 0;
        iTimer.current = setInterval(() => {
            if (indexCurrent >= 0 && indexCurrent <= 1) {
                indexCurrent++;
                setTranslateXValid(indexCurrent);
                setIndex(indexCurrent);
            } else {
                iIndexTranslateX();
                indexCurrent = 0;
                setIndex(0);
            }
        }, 6000);
    }

    const clearIntervals = () => {
        clearInterval(iTimer.current as NodeJS.Timeout);
        iTimer.current = 0;
    }

    const setTranslateX = (index: number, value: string) => {
        try {
            items.current[index].style.transform = value;
        } catch (error: any) {
            setTimeout(() => setTranslateX(index, value), 1000 / 5);
        }
    }

    const setTranslateXValid = (index: number) => {
        if (index === 0) {
            setTimeout(() => setTranslateX(0, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-0vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-0vw)`), 1000 / 5);
        }
        if (index === 1) {
            setTimeout(() => setTranslateX(0, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-40vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-40vw)`), 1000 / 5);
        }
        if (index === 2) {
            setTimeout(() => setTranslateX(0, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-80vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-80vw)`), 1000 / 5);
        }
        if (index === 3) {
            setTimeout(() => setTranslateX(0, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(1, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(2, `translateX(-120vw)`), 1000 / 5);
            setTimeout(() => setTranslateX(3, `translateX(-120vw)`), 1000 / 5);
        }
    }

    return (
        <div
            className={"IndexAppAdminCommerceSection2"}
        >
            {props.lang === "es" && <h1
                className={"IndexAppAdminCommerceSection2Title"}
            >
                ¿Cómo funciona?
            </h1>}
            {props.lang === "en" && <h1
                className={"IndexAppAdminCommerceSection2Title"}
            >
                How does it work?
            </h1>}
            {/*apps en modo mobile*/}
            <div
                className={"IndexAppAdminCommerceSection2Mobile"}
            >
                <section
                    className={"IndexAppAdminCommerceSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminCommerceSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminCommerceSection2DesktopSnapControlBtnSpan"}
                        >
                           1
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Asóciate con otros comercios
                            </h6>}
                            {props.lang === "en" && <h6>
                                Partner with other businesses
                            </h6>}
                            {props.lang === "es" && <label>
                                Un socio comercial te ayuda a distribuir tu marca vendiendo recargas a tus usuarios conductores
                            </label>}
                            {props.lang === "en" && <label>
                                A business partner helps you distribute your brand by selling recharges to your driving users
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminCommerceSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_commerce_1.png").default}
                            alt={"dvgeo_admin_commerce_1"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppAdminCommerceSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminCommerceSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminCommerceSection2DesktopSnapControlBtnSpan"}
                        >
                           2
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Vende paquetes de recargas
                            </h6>}
                            {props.lang === "en" && <h6>
                                Sell refill packs
                            </h6>}
                            {props.lang === "es" && <label>
                                Cada comercio asociado tiene un saldo disponible para distribuir las recargas, ese saldo pueden comprarlo con descuento para que existe un margen de ganancias
                            </label>}
                            {props.lang === "en" && <label>
                                Each associated business has an available balance to distribute the recharges, that balance can be bought at a discount so that there is a profit margin
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminCommerceSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_commerce_2.png").default}
                            alt={"dvgeo_admin_commerce_2"}
                        />
                    </div>
                </section>
                <section
                    className={"IndexAppAdminCommerceSection2MobileSec"}
                >
                    <button
                        className={"IndexAppAdminCommerceSection2DesktopSnapControlBtn"}
                    >
                        <span
                            className={"IndexAppAdminCommerceSection2DesktopSnapControlBtnSpan"}
                        >
                           3
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Caja para saldo prepago
                            </h6>}
                            {props.lang === "en" && <h6>
                                Prepaid balance box
                            </h6>}
                            {props.lang === "es" && <label>
                                Los usuarios comerciantes permiten vender recargas a tus usuarios conductores cuando a estos se les acabe el saldo debitado por las comisiones de cada viaje
                            </label>}
                            {props.lang === "en" && <label>
                                Merchant users allow you to sell recharges to your driver users when they run out of the balance charged for the commissions of each trip
                            </label>}
                        </section>
                    </button>
                    <div
                        className={"IndexAppAdminCommerceSection2MobileImg"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_commerce_3.png").default}
                            alt={"dvgeo_admin_commerce_3"}
                        />
                    </div>
                </section>
            </div>
            {/*apps en modo desktop*/}
            <div
                className={"IndexAppAdminCommerceSection2Desktop"}
            >
                <div
                    ref={indexAppAdminServiceSection1DesktopSnapContentRef}
                    className={"IndexAppAdminCommerceSection2DesktopSnapControl"}
                >
                    <button
                        className={index === 0 ? "IndexAppAdminCommerceSection2DesktopSnapControlBtnFocus" : "IndexAppAdminCommerceSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(0);
                            setIndex(0);
                            clearIntervals();
                        }}
                    >
                        <span
                            className={index === 0 ? "IndexAppAdminCommerceSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminCommerceSection2DesktopSnapControlBtnSpan"}
                        >
                           1
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Asóciate con otros comercios
                            </h6>}
                            {props.lang === "en" && <h6>
                                Partner with other businesses
                            </h6>}
                            {props.lang === "es" && <label>
                                Un socio comercial te ayuda a distribuir tu marca vendiendo recargas a tus usuarios conductores
                            </label>}
                            {props.lang === "en" && <label>
                                A business partner helps you distribute your brand by selling recharges to your driving users
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 1 ? "IndexAppAdminCommerceSection2DesktopSnapControlBtnFocus" : "IndexAppAdminCommerceSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(1);
                            setIndex(1);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 1 ? "IndexAppAdminCommerceSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminCommerceSection2DesktopSnapControlBtnSpan"}
                       >
                           2
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Vende paquetes de recargas
                            </h6>}
                            {props.lang === "en" && <h6>
                                Sell refill packs
                            </h6>}
                            {props.lang === "es" && <label>
                                Cada comercio asociado tiene un saldo disponible para distribuir las recargas, ese saldo pueden comprarlo con descuento para que existe un margen de ganancias
                            </label>}
                            {props.lang === "en" && <label>
                                Each associated business has an available balance to distribute the recharges, that balance can be bought at a discount so that there is a profit margin
                            </label>}
                        </section>
                    </button>
                    <button
                        className={index === 2 ? "IndexAppAdminCommerceSection2DesktopSnapControlBtnFocus" : "IndexAppAdminCommerceSection2DesktopSnapControlBtn"}
                        onClick={() => {
                            setTranslateXValid(2);
                            setIndex(2);
                            clearIntervals();
                        }}
                    >
                       <span
                           className={index === 2 ? "IndexAppAdminCommerceSection2DesktopSnapControlBtnSpanFocus" : "IndexAppAdminCommerceSection2DesktopSnapControlBtnSpan"}
                       >
                           3
                        </span>
                        <section>
                            {props.lang === "es" && <h6>
                                Caja para saldo prepago
                            </h6>}
                            {props.lang === "en" && <h6>
                                Prepaid balance box
                            </h6>}
                            {props.lang === "es" && <label>
                                Los usuarios comerciantes permiten vender recargas a tus usuarios conductores cuando a estos se les acabe el saldo debitado por las comisiones de cada viaje
                            </label>}
                            {props.lang === "en" && <label>
                                Merchant users allow you to sell recharges to your driver users when they run out of the balance charged for the commissions of each trip
                            </label>}
                        </section>
                    </button>
                </div>
                <div
                    ref={indexAppAdminServiceSection1DesktopSnapContentRef}
                    className={"IndexAppAdminCommerceSection2DesktopSnapContent"}
                >
                    <div
                        className={"IndexAppAdminCommerceSection2DesktopSnapContentBackground"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_monitor.png").default}
                            alt={""}
                        />
                    </div>
                    <section
                        ref={ref => items.current[0] = ref}
                        className={"IndexAppAdminCommerceSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_commerce_1.png").default}
                            alt={"dvgeo_admin_commerce_1"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[1] = ref}
                        className={"IndexAppAdminCommerceSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_commerce_2.png").default}
                            alt={"dvgeo_admin_commerce_2"}
                        />
                    </section>
                    <section
                        ref={ref => items.current[2] = ref}
                        className={"IndexAppAdminCommerceSection2DesktopSec"}
                    >
                        <img
                            src={require("../../../../../assets/img/admin_commerce_3.png").default}
                            alt={"dvgeo_admin_commerce_3"}
                        />
                    </section>
                </div>
            </div>
        </div>
    )
}
export default IndexAppAdminCommerceSection2;
