const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://dvgeo.app' : 'http://192.168.1.106:3003',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://dvgeo.app' : 'http://192.168.1.106:3003',
    api: 'api/',
    apiSocket: 'dvgeo::1.2',
    nameDir: 'dvgeo',
    package: 'app.dvgeo.web',
    version: '1.6',
    googleAPIKey: 'AIzaSyDBXhaTlLCgVFvXL5Cnww3lC6oMM5SSB4Q',
    appName: 'DvGeo Platform',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#FF7601',
    colorDark: '#d25e01',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    appStoreId: '',
    email: "josue_chavez_alcivar@hotmail.com",
};
export default config;
