import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexAppAdminVehicleSection1 = (props: Props) => {

    return (
        <div
            className={"IndexAppAdminVehicleSection1"}
        >
            <section
                className={"IndexAppAdminVehicleSection1Sec"}
            >
                {props.lang === "es" && <h1>
                    <strong>Supervisa los vehículos</strong> de tus conductores
                </h1>}
                {props.lang === "en" && <h1>
                    Monitor your drivers' <strong>vehicles</strong>
                </h1>}
                {props.lang === "es" && <p>
                    Un conductor puede tener varios <strong>vehículos asignados</strong>, el aplicativo Admin permite
                    controlar el vehículo de turno para brindar seguridad y confianza a los usuarios
                </p>}
                {props.lang === "en" && <p>
                    A driver can have several <strong>assigned vehicles</strong>, the Admin application allows
                    controlling the vehicle on duty to provide security and confidence to users
                </p>}
                {props.lang === "es" && <p>
                    <strong>¡Desplázate hacia abajo</strong> para aprender más!
                </p>}
                {props.lang === "en" && <p>
                    <strong>Scroll down</strong> to learn more!
                </p>}
            </section>
            <div
                className={"IndexAppAdminVehicleSection1Img"}
            >
                <img
                    src={require("../../../../../assets/img/admin_vehicle_1.png").default}
                    alt={"dvgeo_admin_vehicle_1"}
                />
            </div>
        </div>
    )
}
export default IndexAppAdminVehicleSection1;
