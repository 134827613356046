import React from "react";
//theme
import "./styles.scss";
//libs
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang
}

//view
const IndexIndexAppPassengerSection1Section1 = (props: Props, ref: any) => {

    return (
        <div
            ref={ref}
            className={"IndexAppPassengerSection1Content"}
        >
            <div
                className={"IndexAppPassengerSection1 js-scroll slide-left"}
            >
                <div
                    className={"IndexAppPassengerSection1Img"}
                >
                    <img
                        src={require("../../../../../assets/img/passenger_1.png").default}
                        alt={"dvgeo_admin_1"}
                    />
                </div>
                <section
                    className={"IndexAppPassengerSection1Sec"}
                >
                    {props.lang === "es" && <h1>
                        <strong>Crea</strong> tu propia marca digital
                    </h1>}
                    {props.lang === "en" && <h1>
                        <strong>Create</strong> your own digital brand
                    </h1>}
                    {props.lang === "es" && <p>
                        <strong> Olvídate de la competencia desleal</strong>, atrae nuevos clientes y automatiza reservas
                        con tus propias apps
                        de pasajero
                    </p>}
                    {props.lang === "en" && <p>
                        <strong>Forget about unfair competition</strong>, attract new customers and automate reservations
                        with your own
                        passenger apps
                    </p>}
                    {props.lang === "es" && <p>
                        <strong>¡Desplázate hacia abajo</strong> para aprender más!
                    </p>}
                    {props.lang === "en" && <p>
                        <strong>Scroll down</strong> to learn more!
                    </p>}
                </section>
            </div>
        </div>
    )
}
export default React.forwardRef(IndexIndexAppPassengerSection1Section1);
