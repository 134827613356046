import React from "react";
//theme
import "./styles.scss";
//libs
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//types
import {Lang} from "../../../../../types";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexPriceSection1 = (props: Props) => {
    return (
        <div
            className={"IndexPriceSection1"}
        >
            {/*Suscripción*/}
            <div
                className={"IndexPriceSection1Group1"}
            >
                <section
                    className={"IndexPriceSection1SecTitle"}
                >
                    <span>
                        1
                    </span>
                    {props.lang === "es" && <legend>
                        Suscripción Inicial
                    </legend>}
                    {props.lang === "en" && <legend>
                        Initial Subscription
                    </legend>}
                </section>
                <section
                    className={"IndexPriceSection1SecPrice"}
                >
                    <label>
                        USD 400
                    </label>
                    {props.lang === "es" && <span>
                        Se paga una sola vez sin renovación
                    </span>}
                    {props.lang === "en" && <span>
                        It is paid only once without renewal
                    </span>}
                </section>
                <section
                    className={"IndexPriceSection1SecDescription"}
                >
                    {props.lang === "es" && <label>
                        Incluye:
                    </label>}
                    {props.lang === "en" && <label>
                        It includes:
                    </label>}
                    <ul>
                        {props.lang === "es" && <li>
                            Publicación en Google Play Store.
                        </li>}
                        {props.lang === "en" && <li>
                            Publication in Google Play Store.
                        </li>}

                        {props.lang === "es" && <li>
                            Publicación en Apple App Store.
                        </li>}
                        {props.lang === "en" && <li>
                            Publication in the Apple App Store.
                        </li>}

                        {props.lang === "es" && <li>
                            Apps con nombre y logotipo propio.
                        </li>}
                        {props.lang === "en" && <li>
                            Apps with their own name and logo.
                        </li>}
                    </ul>
                </section>
            </div>
            {/*Mantenimiento*/}
            <div
                className={"IndexPriceSection1Group2"}
            >
                <section
                    className={"IndexPriceSection1SecTitle"}
                >
                    <span>
                        2
                    </span>
                    {props.lang === "es" && <legend>
                        Mantenimiento
                    </legend>}
                    {props.lang === "en" && <legend>
                        Maintenance
                    </legend>}
                </section>
                <section
                    className={"IndexPriceSection1SecPrice"}
                >
                    <label>
                        USD 100
                    </label>
                    {props.lang === "es" && <span>
                        c/ 10,000 viajes mensuales
                    </span>}
                    {props.lang === "en" && <span>
                        c / 10,000 monthly trips
                    </span>}
                </section>
                <section
                    className={"IndexPriceSection1SecDescription"}
                >
                    {props.lang === "es" && <label>
                        Incluye:
                    </label>}
                    {props.lang === "en" && <label>
                        It includes:
                    </label>}
                    <ul>
                        {props.lang === "es" && <li>
                            Servidor cloud 24/7.
                        </li>}
                        {props.lang === "en" && <li>
                            24/7 cloud server.
                        </li>}

                        {props.lang === "es" && <li>
                            Mantenimiento y parches de seguridad.
                        </li>}
                        {props.lang === "en" && <li>
                            Maintenance and security patches.
                        </li>}

                        {props.lang === "es" && <li>
                            Google Maps Platform.
                        </li>}
                        {props.lang === "en" && <li>
                            Google Maps Platform.
                        </li>}

                        {props.lang === "es" && <li>
                            Todos los módulos personalizables.
                        </li>}
                        {props.lang === "en" && <li>
                            All customizable modules.
                        </li>}

                        {props.lang === "es" && <li>
                            Requerimientos nuevos.
                        </li>}
                        {props.lang === "en" && <li>
                            New requirements.
                        </li>}
                    </ul>
                </section>
            </div>
        </div>
    )
}
export default IndexPriceSection1;
