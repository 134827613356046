import React, {FormEvent, useRef, useState} from "react";
//theme
import "./styles.scss";
//api
import {CustomerAPI} from "../../../../../api/customer/customerAPI";
//models
import {Customer} from "../../../../../api/customer/customerModel";
//libs
import {useHistory, useLocation, Link} from "react-router-dom";
//types
import {Lang} from "../../../../../types";
import {CircularProgress} from "@material-ui/core";
import valid from "../../../../../libs/valid";
import AsyncStorage from "@react-native-community/async-storage";
import config from "../../../../../config";
import SnackBar from "../../../../_components/SnackBar";
import {SystemSnackbar} from "../../../../../store/actions/system.ac";

type Fields = {
    email: string;
    password: string;
}
type Errors = {
    email?: string;
    password?: string;
}

//interfaces
interface Props {
    lang: Lang;
    putCustomer?: (customer: Customer) => void;
    showSnackbar?: (snackbar: SystemSnackbar) => void;
    hideSnackbar?: () => void;
    postToken?: (token: string) => void;
}

//const
const TAG = `CustomerLoginForm1`;
const isDev = (process.env.NODE_ENV !== "production");
//component
const CustomerLoginForm1 = (props: Props) => {
    //hooks
    const history = useHistory();
    const location = useLocation();
    //refs
    const inputs = useRef<Array<HTMLInputElement | null>>([]);
    //state
    const [fields, setFields] = useState<Fields>({
        email: "",
        password: "",
    });
    const [errors, setErrors] = useState<Errors>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowModalResult, setIsShowModalResult] = useState<boolean>(false);

    const validForm = (id: keyof Fields, value: string) => {
        switch (id) {
            case "email": {
                setFields((prevFields) => ({
                    ...prevFields,
                    email: value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 2) {
                    inputs.current[2] && inputs.current[2]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email: props.lang === "es" ? "Ingrese una dirección de correo electrónico..." : props.lang === "en" ? "Enter an email address..." : "error",
                    }));
                    return false;
                } else if (!valid.isValidEmailAddress(value)) {
                    inputs.current[2] && inputs.current[2]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email: props.lang === "es" ? "Ingrese una dirección de correo electrónico válida..." : props.lang === "en" ? "Please enter a valid email address" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email: "",
                    }));
                    return true;
                }
            }
            case "password": {
                setFields((prevFields) => ({
                    ...prevFields,
                    password: value === undefined ? "" : value,
                }));
                if (value === undefined || !value || !value.length || value === "" || value.length <= 7) {
                    inputs.current[5] && inputs.current[5]?.focus();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password: props.lang === "es" ? "Mínimo 8 caracteres..." : props.lang === "en" ? "Minimum 8 characters" : "error",
                    }));
                    return false;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password: "",
                    }));
                    return true;
                }
            }
            default : {
                return false
            }
        }
    }

    const login = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let indexFields = 0;
        for (const field in fields) {
            if (!validForm(field as keyof Fields, Object.values(fields)[indexFields])) {
                return;
            }
            indexFields++;
        }
        setIsLoading(true);
        isDev && console.log(TAG + "//login", "Iniciando...");
        await CustomerAPI.login(fields.email, fields.password, props.lang).then(async res_1 => {
            isDev && console.log(TAG + "//login", res_1.code, res_1.message, res_1.error);
            if (res_1.code === 200) {
                const session = res_1.customer as Customer;
                const token = res_1.token;
                isDev && console.log(`${TAG}//login`, "session:", session);
                isDev && console.log(`${TAG}//login`, "token:", token);
                await AsyncStorage.removeItem(`user::${config.package}`);
                await AsyncStorage.setItem(`user::${config.package}`, JSON.stringify(session));
                await AsyncStorage.setItem(`token::${config.package}`, token);
                const query = new URLSearchParams(location.search);
                const url = query.get('url') ? query.get('url') : `/${props.lang}/application/customer`;
                history.push(url ? url.includes("login") ? `/${props.lang}/application/customer` : url : `/${props.lang}/application/customer`);
                props.putCustomer && props.putCustomer(session);
                props.postToken && props.postToken(token);
            } else {
                setIsShowModalResult(true);
                props.showSnackbar && props.showSnackbar({
                    message: res_1.message,
                    severity: "warning"
                });
            }
        }).catch(res_1 => {
            isDev && console.error(TAG + "//login", res_1.code, res_1.error, res_1);
            setIsShowModalResult(true);
            props.showSnackbar && props.showSnackbar({
                message: res_1.message,
                severity: "warning"
            });
        })
        setIsLoading(false);
    }

    return (
        <div
            className={"CustomerLoginForm1"}
        >
            <form
                className={"CustomerLoginForm1GroupForm"}
                onSubmit={(e) => login(e)}
            >
                <Link
                    to={`/${props.lang}`}
                >
                    <img
                        className={"CustomerLoginForm1GroupFormImg"}
                        src={require("../../../../../assets/img/logo.png").default}
                        alt={"logo"}
                    />
                </Link>
                <h1
                    className={"CustomerLoginForm1Title"}
                >
                    {props.lang === "es" && "Iniciar Sesión"}
                    {props.lang === "en" && "Log in"}
                </h1>
                <p
                    className={"CustomerLoginForm1Subtitle"}
                >
                    {props.lang === "es" && "Ingresa las credenciales de tu cuenta"}
                    {props.lang === "en" && "Enter your account credentials"}
                </p>
                <div
                    className={"CustomerLoginForm1GroupFormIpt"}
                >
                    <input
                        ref={(ele) => inputs.current[1] = ele}
                        style={errors?.email?.length ? {
                            border: "1px solid darkred",
                        } : {}}
                        type={"email"}
                        placeholder={props.lang === "es" ? "Correo electrónico" : props.lang === "en" ? "Email" : ""}
                        value={fields.email}
                        onChange={e => validForm("email", e.target.value)}
                    />
                    {(errors?.email?.length) ? <span
                        className={"CustomerLoginForm1GroupFormIptError"}
                    >
                            {errors.email}
                        </span> : null}
                </div>
                <div
                    className={"CustomerLoginForm1GroupFormIpt"}
                >
                    <input
                        ref={(ele) => inputs.current[1] = ele}
                        style={errors?.password?.length ? {
                            border: "1px solid darkred",
                        } : {}}
                        type={"password"}
                        placeholder={props.lang === "es" ? "Contraseña" : props.lang === "en" ? "Password" : ""}
                        value={fields.password}
                        onChange={e => validForm("password", e.target.value)}
                    />
                    {(errors?.password?.length) ? <span
                        className={"CustomerLoginForm1GroupFormIptError"}
                    >
                            {errors.password}
                        </span> : null}
                </div>
                <button
                    className={"CustomerLoginForm1GroupFormBtn"}
                    type={"submit"}
                    disabled={isLoading}
                >
                    {!isLoading && props.lang === "es" && "Iniciar"}
                    {!isLoading && props.lang === "en" && "Log in"}
                    {isLoading && <CircularProgress
                        color={"inherit"}
                        size={20}
                    />}
                </button>
                <div
                    className={"CustomerLoginForm1GroupFormFooter"}
                >
                    <label>
                        {props.lang === "es" && "No tienes cuenta?"}
                        {props.lang === "en" && "You do not have an account?"}
                    </label>
                    <Link
                        to={`/${props.lang}/register`}
                    >
                        {props.lang === "es" && "Registrarse"}
                        {props.lang === "en" && "Check in"}
                    </Link>
                </div>
                <div
                    className={"CustomerLoginForm1GroupFormFooter"}
                >
                    <label>
                        {props.lang === "es" && "No recuerdas tu contraseña?"}
                        {props.lang === "en" && "You do not have an account?"}
                    </label>
                    <Link
                        to={`/${props.lang}/application/customer/recovery`}
                    >
                        {props.lang === "es" && "Recuperar"}
                        {props.lang === "en" && "Recover"}
                    </Link>
                </div>
            </form>
            <SnackBar/>
        </div>
    )
}
export default CustomerLoginForm1;
