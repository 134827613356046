import React, {useRef} from "react";
//theme
import "./styles.scss";
//libs
import {useHistory} from "react-router-dom";
//type
import {Lang} from "../../../../../types";
import {Tooltip} from "@material-ui/core";

//interfaces
interface Props {
    lang: Lang;
}

//component
const IndexHomeSection2 = (props: Props, ref: any) => {
    //hooks
    const history = useHistory();
    //refs
    const btnGetRef = useRef<any>(null);
    //state

    return (
        <div
            ref={ref}
            className={"IndexHomeSection2"}
        >
            <div
                className={"IndexHomeSection2Background"}
            />
            <section
                className={"IndexHomeSection2Sec"}
            >
                {props.lang === "es" && <h1>
                    Necesitas una plataforma <strong>completa, rápida y fácil de usar</strong>
                </h1>}
                {props.lang === "en" && <h1>
                    You need a complete, <strong>fast and easy-to-use</strong> platform
                </h1>}
                {props.lang === "es" && <p>
                    <strong>DvGeo</strong> tiene un conjunto de herramientas, módulos y funciones actualizadas
                    que <strong>le permiten a tu
                    negocio establecerse en el mercado digital</strong> de forma competitiva.
                </p>}
                {props.lang === "en" && <p>
                    <strong>DvGeo</strong> has a set of updated tools, modules and functions <strong>that allow your
                    business to establish itself in the digital market</strong> competitively.
                </p>}
                <Tooltip
                    arrow

                    title={props.lang === "es" ? "Puedes solicitar una demotración virtual a través de Google Meet o Zoom" : props.lang === "en" ? "You can request a virtual demo through Google Meet or Zoom" : ""}
                >
                    <button
                        ref={btnGetRef}
                        onClick={() => {
                            history.push(`/${props.lang}/register`)
                        }}
                    >
                        {props.lang === "es" && <label>
                            Adquiérelo ahora mismo
                        </label>}
                        {props.lang === "en" && <label>
                            Get it right now
                        </label>}
                    </button>
                </Tooltip>
            </section>
        </div>
    )
}
export default React.forwardRef(IndexHomeSection2);
